import AccountSettingsIcon from '../assets/icons/accountSettings.svg'
import BillingSettingsIcon from '../assets/icons/billingSettings.svg'
import FilterConstants from '../constants/FilterConstants'
import GeneralSettingsIcon from '../assets/icons/generalSettingsIcon.svg'
import MemberSettingsIcon from '../assets/icons/membersSettings.svg'
import { OrganizationUserRoles } from '../enum/OrganizationUserRoles.enum'
import ProjectSettingsIcon from '../assets/icons/projectSettings.svg'
import React from 'react'
import { VscSettings } from 'react-icons/vsc'
import moment from 'moment-timezone'
import routeConstants from '../constants/RouteConstants'

/**
 * Util function to settings page's tab headers
 * @param {string} userRole - role of the logged in user
 * @returns {any} - tab headers
 */
export const getSettingsHeaderTabs = (userRole: string) => {
  if (userRole)
    return [
      {
        icon: <img src={GeneralSettingsIcon} style={{ maxHeight: '18px' }} />,
        label: 'General',
        to: routeConstants.ORGANIZATION_SETTINGS
      },
      ...(userRole !== OrganizationUserRoles.BILLING
        ? [
            {
              icon: (
                <img src={MemberSettingsIcon} style={{ maxHeight: '18px' }} />
              ),
              label: 'Members',
              to: routeConstants.MEMBER_SETTINGS
            },
            {
              icon: (
                <img src={ProjectSettingsIcon} style={{ maxHeight: '18px' }} />
              ),
              label: 'Projects',
              to: routeConstants.PROJECT_SETTING
            }
          ]
        : []),
      ...(userRole !== OrganizationUserRoles.MEMBER
        ? [
            {
              icon: (
                <img src={BillingSettingsIcon} style={{ maxHeight: '20px' }} />
              ),
              label: 'Billing',
              to: routeConstants.SUBSCRIPTION_SETTINGS
            }
          ]
        : []),
      {
        icon: <img src={AccountSettingsIcon} style={{ maxHeight: '18px' }} />,
        label: 'Account',
        to: routeConstants.ACCOUNT_SETTING
      },
      {
        icon: <VscSettings />,
        label: 'Preferences',
        to: routeConstants.PREFERENCES_SETTINGS
      }
    ]
  else
    return [
      {
        icon: <img src={AccountSettingsIcon} style={{ maxHeight: '18px' }} />,
        label: 'Account',
        to: routeConstants.ACCOUNT_SETTING
      }
    ]
}

/**
 * A function to construct to contexts from queryParams
 * @param query
 * @param contextMap
 * @param queryMap
 * @returns
 */
export const constructContextsFromQuery = (
  query: any,
  contextMap: any,
  queryMap: any
): any[] => {
  const contexts: any[] = []

  Object.keys(query).forEach((key) => {
    if (FilterConstants.SEARCH_QUERY.includes(key)) {
      const context = {
        id: contextMap[queryMap[key]].query,
        label: `${contextMap[queryMap[key]].label} : ${query[key]}`
      }
      contexts.push(context)
    }
  })
  return contexts
}

export const paginate = (data: any[], currentPage: number, maxSize: number) => {
  const startIndex = (currentPage - 1) * maxSize
  const endIndex = startIndex + maxSize
  return data.slice(startIndex, endIndex)
}

export const generateColors = (n: number) => {
  const colors = []
  for (let i = 0; i < n; i++) {
    const hue = Math.floor((360 * i) / n)
    const lightness = Math.floor(Math.random() * 20) + 40
    colors.push(`hsl(${hue}, 100%, ${lightness}%)`)
  }
  return colors
}

export const generateRandomId = () => {
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  let result = ''
  const charactersLength = characters.length

  for (let i = 0; i < 5; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }

  return result
}

export function calculateStartAndEndByDateRange(
  dateRange: string,
  timeZone: any
): { startDate: moment.Moment; endDate: moment.Moment } {
  const nowInOrgTimeZone = moment.tz(timeZone)

  // Start with the current date in the organization's timezone
  const currentLocalDate = nowInOrgTimeZone.clone()
  let startDate, endDate

  switch (dateRange) {
    case 'THIS_WEEK':
      startDate = currentLocalDate.clone().startOf('week')
      endDate = currentLocalDate.clone().endOf('day')
      break

    case 'LAST_WEEK':
      startDate = currentLocalDate.clone().subtract(1, 'week').startOf('week')
      endDate = startDate.clone().endOf('week')
      break

    case 'THIS_MONTH':
      startDate = currentLocalDate.clone().startOf('month')
      endDate = currentLocalDate.clone().endOf('day')
      break

    case 'LAST_MONTH':
      startDate = currentLocalDate.clone().subtract(1, 'month').startOf('month')
      endDate = startDate.clone().endOf('month')
      break

    case 'LAST_3_MONTHS':
      startDate = currentLocalDate
        .clone()
        .subtract(3, 'months')
        .startOf('month')
      endDate = currentLocalDate.clone().endOf('day')
      break

    default:
      throw new Error('Invalid date range filter')
  }

  // Ensure dates are in the correct timezone
  startDate = startDate.tz(timeZone)
  endDate = endDate.tz(timeZone)

  return { startDate, endDate }
}

export function calculateStartAndEndByDayWeekMonthYear({
  month,
  year,
  currentDate,
  timeZone
}: {
  month?: number
  year?: number
  currentDate: Date
  timeZone: any
}): { startDate: moment.Moment; endDate: moment.Moment } {
  let startDate = moment.tz(currentDate, timeZone)
  let endDate = moment.tz(currentDate, timeZone)

  // Handle year
  if (year) {
    startDate = moment.tz({ year, month: 0, day: 1 }, timeZone).startOf('day')
    endDate = moment.tz({ year, month: 11, day: 31 }, timeZone).endOf('day')
  }

  // Handle month
  if (month) {
    if (year) {
      startDate = moment
        .tz({ year, month: month - 1, day: 1 }, timeZone)
        .startOf('day')
      endDate = startDate.clone().endOf('month').endOf('day')
    } else {
      startDate = startDate
        .month(month - 1)
        .date(1)
        .startOf('day')
      endDate = startDate.clone().endOf('month').endOf('day')
    }
  }

  return { startDate, endDate }
}

export function calculateStartAndEndByDateString({
  dateString,
  timeZone
}: {
  dateString: string
  timeZone: string
}): { startDate: moment.Moment; endDate: moment.Moment } {
  // Validate input format
  if (!/^\d{4}-\d{2}-\d{2}$/.test(dateString)) {
    throw new Error('Invalid date format. Expected: YYYY-MM-DD')
  }

  // Parse the date string
  const [year, month, day] = dateString.split('-').map((num) => parseInt(num))

  // Create start and end dates in the specified timezone
  const startDate = moment
    .tz({ year, month: month - 1, day }, timeZone)
    .startOf('day')
  const endDate = startDate.clone().endOf('day')

  return { startDate, endDate }
}
