import { CaretDown, CaretUp, ChevronRight } from '@carbon/icons-react'
import React, { useEffect, useState } from 'react'
import {
  UrlWithOrgProjEnvIds,
  getAllQueryParamsAsObjectFromUrl
} from '../../../utils/UrlUtils'
import {
  getOrgTimeRange,
  msToTimeUnits,
  timeZone
} from '../../../utils/DateTimeUtils'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

import ApiGroupingStringConstants from '../../../constants/ApiGroupingStringConstants'
import { DDSTypography } from 'den-design-system'
import { Link } from '@mui/material'
import NoFilterImage from '../../../assets/images/folder-not-found 1.svg'
import NoRecord from '../../../components/common/NoContent/NoRecord'
import NoRecordImage from '../../../assets/images/setting.svg'
import Spinner from '../../../components/common/Spinner/Spinner'
import Status from '../../../components/APIMonitoring/Components/Status'
import StringConstants from '../../../constants/StringConstants'
import Table from '../../../components/common/DDS/Table/Table'
import { ToastMessageConstants } from '../../../constants/ToastMessageConstants'
import ToastNotification from '../../../components/common/DDS/Toast/Toast'
import Tooltip from '../../../components/New/Tooltip/Tooltip'
import dayjs from 'dayjs'
import { gaPageConstants } from '../../../constants/GAConstants'
import { getGroupsByApiUserId } from '../../../api/apiMonitoring/ApiMonitoring.service'
import { isNull } from 'lodash'
import moment from 'moment'
import { observer } from 'mobx-react-lite'
import pallete from '../../../global/newPallete'
import routeConstants from '../../../constants/RouteConstants'
import { timeFormats } from '../../../enum/TIME'
import { useAnalyticsEventTracker } from '../../../utils/GAUtils'
import { useStoreContext } from '../../../store/StoreContext'

type SortOrder = 'ASC' | 'DESC' | 'CLEAR'
type SortState = null | SortOrder | 'CLEAR'

const GroupedAPILogUserTable = () => {
  const [tableData, setTableData] = useState<any>()
  const [loading, setLoading] = useState<boolean>(false)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [totalCount, setTotalCount] = useState<number>(0)

  const [searchParams, setSearchParams] = useSearchParams()

  const navigate = useNavigate()
  const { id } = useParams()

  const [activeSortOrder, setActiveSortOrder] = useState<{
    field: string | null
    order: SortOrder | null
  }>({
    field: null,
    order: null
  })

  const store = useStoreContext()
  const gaEventTracker = useAnalyticsEventTracker(
    gaPageConstants.API_MONITORING_TABLE
  )

  const viewGroupedApiDataById = async () => {
    setLoading(true)
    store.uiStore.setGlobalLoader(true)
    const allQueryParams = getAllQueryParamsAsObjectFromUrl(location.search)
    // Retrieve sort parameters from URL
    const urlSortField = allQueryParams['sortField']
    const urlSortOrder = allQueryParams['sortOrder']

    const params: any = {
      id: id as string,
      ...allQueryParams,
      size: StringConstants.DEFAULT_TABLE_SIZE
    }
    if (urlSortField && urlSortOrder && urlSortOrder !== 'CLEAR') {
      params[urlSortField] = urlSortOrder
    }

    getGroupsByApiUserId(params)
      .then((response: any) => {
        setTableData(response.data)
        setTotalCount(response.totalCount)
        setCurrentPage(response.page)
        store.apiDetailsStore.setRefresh(false)
        store.filterStore.setFilters(response.filters)
      })
      .catch((e) => {
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.API_MONITORING.FETCH_DETAILS.ERROR
        })
      })
      .finally(() => {
        setLoading(false)
        store.uiStore.setGlobalLoader(false)
      })
  }

  const sortData = async (sortField: string, sortOrder: SortOrder) => {
    setLoading(true)
    store.uiStore.setGlobalLoader(true)
    const allQueryParams = getAllQueryParamsAsObjectFromUrl(location.search)

    const params: any = {
      id: id as string,
      ...allQueryParams,
      size: StringConstants.DEFAULT_TABLE_SIZE
    }

    // Handle "CLEAR" by removing sort-related params
    if (sortOrder !== 'CLEAR') {
      params[sortField] = sortOrder
      // Persist sort information in URL
      const searchParams = new URLSearchParams(location.search)
      searchParams.set('sortField', sortField)
      searchParams.set('sortOrder', sortOrder)
      window.history.replaceState({}, '', `?${searchParams.toString()}`)
    } else {
      delete params[sortField] // Ensure sorting is removed
      delete params[sortOrder]
      // Remove sort params from URL
      const searchParams = new URLSearchParams(location.search)
      searchParams.delete('sortField')
      searchParams.delete('sortOrder')
      window.history.replaceState({}, '', `?${searchParams.toString()}`)
    }

    getGroupsByApiUserId({ ...params })
      .then((response: any) => {
        setTableData(response.data)
        setTotalCount(response.totalCount)
        setCurrentPage(response.page)
        store.apiDetailsStore.setRefresh(false)
        store.filterStore.setFilters(response.filters)
      })
      .catch((e) => {
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.API_MONITORING.FETCH_DETAILS.ERROR
        })
      })
      .finally(() => {
        setLoading(false)
        store.uiStore.setGlobalLoader(false)
      })
  }
  const handleSortCycle = (sortField: string) => {
    setActiveSortOrder((prev) => {
      if (prev.field === sortField) {
        // Cycle through ASC, DESC, and CLEAR
        const nextOrder =
          prev.order === 'ASC'
            ? 'DESC'
            : prev.order === 'DESC'
              ? 'CLEAR'
              : 'ASC'
        // Always call sortData, even for "CLEAR"
        sortData(sortField, nextOrder)
        return {
          field: nextOrder !== 'CLEAR' ? sortField : null,
          order: nextOrder
        }
      } else {
        // Activate new field with ASC
        sortData(sortField, 'ASC')
        return { field: sortField, order: 'ASC' }
      }
    })
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const urlSortField = searchParams.get('sortField')
    const urlSortOrder = searchParams.get('sortOrder')
    if (urlSortField && urlSortOrder) {
      // Initialize the sort state from URL
      setActiveSortOrder({
        field: urlSortField,
        order: urlSortOrder as SortOrder
      })
    }
  }, [location.search])

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const sortField = searchParams.get('sortField')
    const sortOrder = searchParams.get('sortOrder') as SortOrder

    if (!sortField || !sortOrder) {
      setActiveSortOrder({ field: null, order: null })
    } else {
      setActiveSortOrder({ field: sortField, order: sortOrder })
    }
  }, [location.search])

  useEffect(() => {
    const pageFromQueryParams = searchParams.get('page')
    if (!isNull(pageFromQueryParams)) {
      setCurrentPage(Number(pageFromQueryParams))
      viewGroupedApiDataById()
    } else {
      setSearchParams({ page: '1' })
    }
  }, [store.scopeStore.getScope(), searchParams])

  useEffect(() => {
    if (store.apiDetailsStore.getRefresh() === true) {
      setLoading(true)
      store.uiStore.setGlobalLoader(true)
      viewGroupedApiDataById()
    }
  }, [store.apiDetailsStore.getRefresh()])

  const columns = [
    {
      columnHeaderStyle: {},
      columnDataStyle: {},
      heading: (
        <div className='flex whitespace-nowrap  space-x-[8px] '>
          <div>
            <DDSTypography.Paragraph
              size='caption'
              variant='semiBold'
              color={pallete.colors.textDark6}
            >
              {ApiGroupingStringConstants.ENDPOINT}
            </DDSTypography.Paragraph>
          </div>
          <div
            className='flex flex-col justify-center -space-y-[6px]'
            onClick={() => handleSortCycle('sort-grouped-url-template')}
          >
            <CaretUp
              size={12}
              style={{
                color:
                  activeSortOrder.field === 'sort-grouped-url-template' &&
                  activeSortOrder.order === 'ASC'
                    ? pallete.colors.FillButton
                    : activeSortOrder.field === 'sort-grouped-url-template' &&
                        activeSortOrder.order === 'DESC'
                      ? pallete.colors.outButtonDis
                      : pallete.colors.textDark6
              }}
              className='cursor-pointer'
            />
            <CaretDown
              size={12}
              style={{
                color:
                  activeSortOrder.field === 'sort-grouped-url-template' &&
                  activeSortOrder.order === 'ASC'
                    ? pallete.colors.outButtonDis
                    : activeSortOrder.field === 'sort-grouped-url-template' &&
                        activeSortOrder.order === 'DESC'
                      ? pallete.colors.FillButton
                      : pallete.colors.textDark6
              }}
              className='cursor-pointer'
            />
          </div>
        </div>
      ),
      dataLabel: 'urlTemplate',
      render: (text: any) => (
        <div className='flex justify-start text-start row items-start max-w-[200px]'>
          <div>
            <Tooltip
              id={`tooltip-name-${text}`}
              label={text}
              className={`override-tooltip-arrow ${
                typeof text === 'string' && text.length > 15 ? text : '!hidden'
              }`}
            >
              <div>
                <DDSTypography.Paragraph
                  size='para'
                  variant='medium'
                  color={pallete.colors.textDark4}
                  className='tooltip-container sm:block overflow-hidden truncate max-w-[200px]'
                >
                  {text || text === 0 ? text : '-'}
                </DDSTypography.Paragraph>
              </div>
            </Tooltip>
          </div>
        </div>
      )
    },
    {
      columnHeaderStyle: {},
      columnDataStyle: {},
      heading: (
        <div className='flex whitespace-nowrap justify-center text-center  space-x-[8px] items-center'>
          <div>
            <DDSTypography.Paragraph
              size='caption'
              variant='semiBold'
              color={pallete.colors.textDark6}
            >
              {ApiGroupingStringConstants.HTTP_METHOD}
            </DDSTypography.Paragraph>
          </div>
          <div
            className='flex flex-col justify-center -space-y-[6px]'
            onClick={() => handleSortCycle('sort-grouped-http-method')}
          >
            <CaretUp
              size={12}
              style={{
                color:
                  activeSortOrder.field === 'sort-grouped-http-method' &&
                  activeSortOrder.order === 'ASC'
                    ? pallete.colors.FillButton
                    : activeSortOrder.field === 'sort-grouped-http-method' &&
                        activeSortOrder.order === 'DESC'
                      ? pallete.colors.outButtonDis
                      : pallete.colors.textDark6
              }}
              className='cursor-pointer'
            />
            <CaretDown
              size={12}
              style={{
                color:
                  activeSortOrder.field === 'sort-grouped-http-method' &&
                  activeSortOrder.order === 'ASC'
                    ? pallete.colors.outButtonDis
                    : activeSortOrder.field === 'sort-grouped-http-method' &&
                        activeSortOrder.order === 'DESC'
                      ? pallete.colors.FillButton
                      : pallete.colors.textDark6
              }}
              className='cursor-pointer'
            />
          </div>
        </div>
      ),
      dataLabel: 'httpMethod',
      render: (text: any) => (
        <div className='flex whitespace-nowrap text-center space-x-[8px] items-center'>
          <DDSTypography.Paragraph
            size='para'
            variant='medium'
            color={pallete.colors.textDark4}
            className='tooltip-container sm:block overflow-hidden truncate'
          >
            {text || text === 0 ? text : '-'}
          </DDSTypography.Paragraph>
        </div>
      )
    },

    {
      columnHeaderStyle: {},
      columnDataStyle: {},
      heading: (
        <div className='flex whitespace-nowrap justify-center text-center  space-x-[8px] items-center'>
          <div>
            <DDSTypography.Paragraph
              size='caption'
              variant='semiBold'
              color={pallete.colors.textDark6}
            >
              {ApiGroupingStringConstants.API_COUNT}
            </DDSTypography.Paragraph>
          </div>
          <div
            className='flex flex-col justify-center -space-y-[6px]'
            onClick={() => handleSortCycle('sort-grouped-user-api-count')}
          >
            <CaretUp
              size={12}
              style={{
                color:
                  activeSortOrder.field === 'sort-grouped-user-api-count' &&
                  activeSortOrder.order === 'ASC'
                    ? pallete.colors.FillButton
                    : activeSortOrder.field === 'sort-grouped-user-api-count' &&
                        activeSortOrder.order === 'DESC'
                      ? pallete.colors.outButtonDis
                      : pallete.colors.textDark6
              }}
              className='cursor-pointer'
            />
            <CaretDown
              size={12}
              style={{
                color:
                  activeSortOrder.field === 'sort-grouped-user-api-count' &&
                  activeSortOrder.order === 'ASC'
                    ? pallete.colors.outButtonDis
                    : activeSortOrder.field === 'sort-grouped-user-api-count' &&
                        activeSortOrder.order === 'DESC'
                      ? pallete.colors.FillButton
                      : pallete.colors.textDark6
              }}
              className='cursor-pointer'
            />
          </div>
        </div>
      ),
      dataLabel: 'apiCount',
      render: (text: any) => (
        <div className='flex justify-center text-center row items-center'>
          <DDSTypography.Paragraph
            size='para'
            variant='medium'
            color={pallete.colors.textDark4}
            className='tooltip-container sm:block overflow-hidden truncate'
          >
            {text || text === 0 ? text : '-'}
          </DDSTypography.Paragraph>
        </div>
      )
    },
    {
      columnHeaderStyle: {},
      columnDataStyle: {},
      heading: (
        <div className='flex whitespace-nowrap justify-center text-center  space-x-[8px] items-center'>
          <div>
            <DDSTypography.Paragraph
              size='caption'
              variant='semiBold'
              color={pallete.colors.textDark6}
            >
              Last Call Date
            </DDSTypography.Paragraph>
          </div>
          <div
            className='flex flex-col justify-center -space-y-[6px]'
            onClick={() => handleSortCycle('sort-last-call-date')}
          >
            <CaretUp
              size={12}
              style={{
                color:
                  activeSortOrder.field === 'sort-last-call-date' &&
                  activeSortOrder.order === 'ASC'
                    ? pallete.colors.FillButton
                    : activeSortOrder.field === 'sort-last-call-date' &&
                        activeSortOrder.order === 'DESC'
                      ? pallete.colors.outButtonDis
                      : pallete.colors.textDark6
              }}
              className='cursor-pointer'
            />
            <CaretDown
              size={12}
              style={{
                color:
                  activeSortOrder.field === 'sort-last-call-date' &&
                  activeSortOrder.order === 'ASC'
                    ? pallete.colors.outButtonDis
                    : activeSortOrder.field === 'sort-last-call-date' &&
                        activeSortOrder.order === 'DESC'
                      ? pallete.colors.FillButton
                      : pallete.colors.textDark6
              }}
              className='cursor-pointer'
            />
          </div>
        </div>
      ),
      dataLabel: 'lastCallDate',
      render: (text: any) => (
        <div className='flex justify-center text-center row items-center'>
          <DDSTypography.Paragraph
            size='para'
            variant='medium'
            color={pallete.colors.textDark4}
            style={{ justifyContent: 'center' }}
            className='tooltip-container sm:block overflow-hidden truncate'
          >
            {dayjs(text).format(
              timeFormats.fullMonthDateYearTimeWithSecondsDayJs
            )}
          </DDSTypography.Paragraph>
        </div>
      )
    },
    {
      columnHeaderStyle: {},
      columnDataStyle: {},
      heading: (
        <div className='flex whitespace-nowrap justify-center text-center  space-x-[8px] items-center'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            Status
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'successApiCount',
      render: (text: any, fullItem: any) => {
        return (
          <div className='flex justify-center items-center'>
            <Status
              count={fullItem?.apiCount - fullItem?.successApiCount}
              label='Failure'
              textColor={pallete.colors.warningRed1}
              bgColor={pallete.colors.warningRed5}
              borderTopLeftRadius='4px'
              borderBottomLeftRadius='4px'
            />
            <Status
              count={fullItem?.successApiCount}
              label='Success'
              textColor={pallete.colors.warningGreen}
              bgColor={pallete.colors.warningGreenBg}
              borderTopRightRadius='4px'
              borderBottomRightRadius='4px'
            />
          </div>
        )
      }
    },
    {
      columnHeaderStyle: {},
      columnDataStyle: {},
      heading: (
        <div className='flex whitespace-nowrap justify-center text-center  space-x-[8px] items-center'>
          <div>
            <DDSTypography.Paragraph
              size='caption'
              variant='semiBold'
              color={pallete.colors.textDark6}
            >
              Average Response Time
            </DDSTypography.Paragraph>
          </div>
          <div
            className='flex flex-col justify-center -space-y-[6px]'
            onClick={() =>
              handleSortCycle('sort-grouped-average-response-time')
            }
          >
            <CaretUp
              size={12}
              style={{
                color:
                  activeSortOrder.field ===
                    'sort-grouped-average-response-time' &&
                  activeSortOrder.order === 'ASC'
                    ? pallete.colors.FillButton
                    : activeSortOrder.field ===
                          'sort-grouped-average-response-time' &&
                        activeSortOrder.order === 'DESC'
                      ? pallete.colors.outButtonDis
                      : pallete.colors.textDark6
              }}
              className='cursor-pointer'
            />
            <CaretDown
              size={12}
              style={{
                color:
                  activeSortOrder.field ===
                    'sort-grouped-average-response-time' &&
                  activeSortOrder.order === 'ASC'
                    ? pallete.colors.outButtonDis
                    : activeSortOrder.field ===
                          'sort-grouped-average-response-time' &&
                        activeSortOrder.order === 'DESC'
                      ? pallete.colors.FillButton
                      : pallete.colors.textDark6
              }}
              className='cursor-pointer'
            />
          </div>
        </div>
      ),
      dataLabel: 'averageResponseTimeInMilliSeconds',
      render: (text: any) => (
        <div className='flex justify-center text-center row items-center'>
          <DDSTypography.Paragraph
            size='para'
            variant='medium'
            color={pallete.colors.textDark4}
            style={{ justifyContent: 'center' }}
            className='tooltip-container sm:block overflow-hidden truncate'
          >
            {text ? msToTimeUnits(text) : '-'}
          </DDSTypography.Paragraph>
        </div>
      )
    },

    {
      columnHeaderStyle: { width: '20px' },
      columnDataStyle: { width: '20px' },
      heading: '',
      dataLabel: '',
      render: (text: any, fullItem: any) => (
        <div className='cursor-pointer'>
          <ChevronRight
            size={16}
            onClick={() => {
              const orgTimeZone =
                store.userStore.getOrganizationPreference()?.timeZone

              const { startOfMonth, endOfCurrentDayTimestamp } =
                getOrgTimeRange(orgTimeZone)

              const searchParams = new URLSearchParams(window.location.search)
              const currentFilters: Record<string, string> = {}

              currentFilters['created-from'] =
                searchParams.get('created-from') || String(startOfMonth)
              currentFilters['created-to'] =
                searchParams.get('created-to') ||
                String(endOfCurrentDayTimestamp)
              currentFilters['time-zone'] =
                searchParams.get('time-zone') || orgTimeZone

              const baseRoute = UrlWithOrgProjEnvIds(
                routeConstants.GROUPED_USER_API_LOGS_DETAILS.replace(
                  ':apiGroupId',
                  fullItem.id as string
                ).replace(':apiUserId', id as string),
                store
              )

              const newSearchParams = new URLSearchParams({
                apiOverviewTab: '1',
                ...currentFilters
              })

              navigate(`${baseRoute}?${newSearchParams.toString()}`)
            }}
            color={pallete.colors.FillButton}
          />
        </div>
      )
    }
  ]
  return (
    <>
      {loading || store.uiStore.getGlobalLoader() || !tableData ? (
        <Spinner />
      ) : totalCount > 0 ? (
        <div className='mt-[24px] flex w-full '>
          <Table
            tableStyle={{
              borderColor: pallete.colors.stroke2
            }}
            data={tableData}
            columns={columns}
            totalCount={totalCount}
            rowsPerPage={10}
            currentPage={currentPage}
            onPageChange={(page: number) => {
              const allQueryParams = getAllQueryParamsAsObjectFromUrl(
                location.search
              )
              setCurrentPage(page)
              setSearchParams({ ...allQueryParams, page: page.toString() })
            }}
            loading={loading}
          />
        </div>
      ) : (
        <>
          <NoRecord
            imageSrc={
              totalCount === 0 && store.filterStore.getFiltersApplied()
                ? NoFilterImage
                : NoRecordImage
            }
            style={{ height: '74vh' }}
            text={
              totalCount === 0 && store.filterStore.getFiltersApplied() ? (
                <>No results found for applied filter.</>
              ) : (
                <>
                  Configure API Monitoring.
                  <Link
                    href={StringConstants.FULLSTACK_URL}
                    target='_blank'
                    rel='noopener noreferrer'
                    style={{ marginLeft: '4px' }}
                  >
                    Learn More
                  </Link>
                </>
              )
            }
          />
        </>
      )}
    </>
  )
}

export default observer(GroupedAPILogUserTable)
