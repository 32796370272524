import React, { useEffect, useState } from 'react'

import { AddFilled } from '@carbon/icons-react'
import Chip from '../../New/Chip/Chip'
import { DDSTypography } from 'den-design-system'
import DropdownMenu from '../../New/DropdownMenu/DropdownMenu'
import Search from '../../New/Search/Search'
import Spinner from '../Spinner/Spinner'
import UserAvatar from '../UserAvatar/UserAvatar'
import emptyAvatar from '../../../assets/icons/emptyAvatar.svg'
import { isNull } from 'lodash'
import pallete from '../../../global/newPallete'

interface Props {
  label: string
  menuPosition?: 'left' | 'center' | 'right'
  options?: {
    id: string
    name: string
    role?: string
    imgUrl?: string
  }[]
  loading?: boolean
  disable?: boolean
  onclick?: (id: string) => any
  onremove?: () => any
  assignedUser?: any
  disableNoSubscription: boolean
}

const AssignMembers: React.FC<Props> = ({
  label,
  menuPosition = 'left',
  options = [],
  loading,
  disable = false,
  onclick,
  onremove,
  assignedUser,
  disableNoSubscription
}) => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false)
  const [memberOptions, setMemberOptions] = useState(options)
  //To get the position of the menu based on prop
  const getMenuPosition = () => {
    if (menuPosition === 'left') {
      return '-translate-x-[84%]'
    } else if (menuPosition === 'center') {
      return '-translate-x-[50%]'
    } else if (menuPosition === 'right') {
      return ''
    }
  }

  const handleChange = (value: string) => {
    const filterMembers = options.filter((member: any) =>
      member.name.toLowerCase().includes(value.toLowerCase())
    )
    setMemberOptions([...filterMembers])
  }

  //TODO, the below array of object is converted from above selectedUsers array for rendering the user avatars
  const avatarObjects: { name: string; avatar: string }[] = []

  const dropDownRef = React.useRef<HTMLDivElement>(null)
  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (
        menuOpen &&
        dropDownRef.current &&
        !dropDownRef.current.contains(e.target)
      ) {
        setMenuOpen(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  })

  return (
    <div className='relative h-full w-full'>
      <div
        className='flex items-center gap-[4px] justify-start cursor-pointer'
        style={{
          cursor: disable ? 'not-allowed' : 'pointer',
          opacity: disable ? 0.35 : 'none'
        }}
        onClick={() => {
          if (!disable) setMenuOpen(!menuOpen)
        }}
      >
        {!isNull(assignedUser) ? (
          <img
            id='menu-list-btn'
            src={assignedUser?.user.avatar}
            className='h-[28px] w-[28px] rounded-full '
          />
        ) : (
          <img
            id='menu-list-btn'
            src={emptyAvatar}
            className='h-[28px] w-[28px] rounded-full '
          />
        )}
        <UserAvatar
          id='menu-list-btn'
          avatarType='Multiple'
          imageObjectGroup={avatarObjects}
        />
        <div>
          <AddFilled
            id='menu-list-btn'
            size={16}
            color={pallete.colors.textDark2}
          />
        </div>
      </div>
      <div ref={dropDownRef}>
        <DropdownMenu
          id='uc-menu-list'
          open={menuOpen}
          parentId='menu-list-btn'
          className={`${disableNoSubscription && 'pointer-events-none'}  absolute z-50 ${getMenuPosition()}`}
          onClose={() => setMenuOpen(false)}
          arrowPosition={'right'}
        >
          <div className='flex flex-col gap-[8px] '>
            {loading ? (
              <div className='min-h-[250px] min-w-[300px]'>
                <Spinner />
              </div>
            ) : (
              <>
                <div className='flex flex-col overflow-y-scroll py-[4px] w-[280px] max-h-[120px] gap-[4px] override-background-color'>
                  {!isNull(assignedUser) ? (
                    <Chip
                      id='chip-menu-list'
                      label={assignedUser?.user.firstName}
                      className='w-[280px]'
                      startIcon={
                        <div className='h-[20px] w-[20px]'>
                          <img
                            src={assignedUser?.user.avatar}
                            className='h-[20px] w-[20px] rounded-full object-fill'
                          />
                        </div>
                      }
                      onClose={() => {
                        onremove && onremove()
                      }}
                    />
                  ) : (
                    ''
                  )}
                </div>
                <Search
                  id='search'
                  name='Search'
                  disabled={disableNoSubscription}
                  placeholder='Search'
                  onChange={(e) => {
                    handleChange(e.target.value)
                  }}
                />
                <div
                  className={`flex flex-col gap-[8px] max-h-[210px] overflow-scroll ${disableNoSubscription && 'opacity-50'}`}
                >
                  {memberOptions.map((item, index) => (
                    <div
                      key={item.id}
                      className={`flex flex-row h-[40px] rounded-md hover:bg-[${pallete.colors.surface4}] w-[280px] cursor-pointer items-center gap-[8px] p-[8px] mt-[4px]`}
                      onClick={() => {
                        onclick && onclick(item.id)
                      }}
                    >
                      <img
                        src={item.imgUrl}
                        className='w-[20px] h-[20px] rounded-full object-fill'
                      />

                      <DDSTypography.Paragraph
                        className='whitespace-nowrap'
                        variant='medium'
                        size='para'
                      >
                        {item.name}
                      </DDSTypography.Paragraph>
                      {item.role ? (
                        <DDSTypography.Paragraph
                          variant='regular'
                          size='caption'
                          color={pallete.colors.textDark9}
                        >
                          ({item.role})
                        </DDSTypography.Paragraph>
                      ) : (
                        ''
                      )}
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
        </DropdownMenu>
      </div>
    </div>
  )
}

export default AssignMembers
