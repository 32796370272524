import React, { useEffect, useRef, useState } from 'react'

import { Button } from '../../../components/New/Button/Button'
import { DDSTypography } from 'den-design-system'
import { Renew } from '@carbon/icons-react'
import StringConstants from '../../../constants/StringConstants'
import Tabs from '../../../components/common/DDS/Tabs/Tabs'
import Tooltip from '../../../components/New/Tooltip/Tooltip'
import UCSummary from '../../../components/UptimeChecksPage/UCSummary'
import UptimeStringConstants from '../../../constants/UptimeStringConstants'
import { gaPageConstants } from '../../../constants/GAConstants'
import pallete from '../../../global/newPallete'
import { replaceUrl } from '../../../utils/UrlUtils'
import routeConstants from '../../../constants/RouteConstants'
import { useAnalyticsEventTracker } from '../../../utils/GAUtils'
import { useNavigate } from 'react-router-dom'
import { useStoreContext } from '../../../store/StoreContext'

const UCDashboard = () => {
  const store = useStoreContext()
  const navigate = useNavigate()
  const [loading, setLoading] = useState<boolean>(false)
  const [summaryLoading, setSummaryLoading] = useState<boolean>(false)
  const handleRefreshRef = useRef<() => void>(() => {
    null
  })
  const gaEventTracker = useAnalyticsEventTracker(
    gaPageConstants.REFRESH_UPTIME_CHECK_SUMMARY
  )

  useEffect(() => {
    localStorage.setItem(
      StringConstants.ACTIVE_TAB,
      StringConstants.TAB_VALUES.UPTIME_CHECKS
    )
  }, [])

  const tabs = [
    {
      label: (
        <DDSTypography.Title
          type='h5'
          variant='semiBold'
          color={pallete.colors.primary2}
        >
          Summary
        </DDSTypography.Title>
      ),
      children: (
        <div>
          <UCSummary
            loading={loading}
            summaryLoading={summaryLoading}
            setSummaryLoading={setSummaryLoading}
            setLoading={setLoading}
            setFetchHealthSummary={(func: () => void) => {
              handleRefreshRef.current = func
            }}
          />
        </div>
      )
    }
  ]

  useEffect(() => {
    store.breadcrumbStore.setBreadcrumbsOptions({
      label: UptimeStringConstants.UPTIME_CHECKS,
      link: replaceUrl(
        routeConstants.AHC,
        store.scopeStore.getScope()['organization-id'] as string,
        store.scopeStore.getScope()['project-id'] as string,
        store.scopeStore.getScope()['environment-id'] as string
      )
    })
  }, [store.breadcrumbStore.getBreadcrumbsOptions()])

  return (
    <div className='flex flex-col w-full pt-[8px]'>
      <div className='relative w-full flex justify-between'>
        <div
          style={{
            width: '100%',
            display: 'flex'
          }}
          className='tab-child'
        >
          <Tabs
            tabOptionsStyle={{
              minWidth: 'fit-content',
              width: 'auto',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              alignSelf: 'stretch',
              padding: '8px 12px',
              marginRight: '8px'
            }}
            tabOptions={tabs}
            id='tabs-uc-summary'
          />
        </div>
        <div className='absolute flex right-[0px] items-center justify-end gap-[8px]'>
          <Tooltip id='refresh-tooltip' label='Refresh' position='top'>
            <Button
              id='refresh'
              type='neutral'
              disabled={
                loading || summaryLoading || store.uiStore.getGlobalLoader()
              }
              startIcon={
                <Renew size={16} color={pallete.colors.neuButtonText} />
              }
              size='small'
              onClick={() => {
                gaEventTracker(gaPageConstants.REFRESH_UPTIME_CHECK_SUMMARY)
                handleRefreshRef?.current()
              }}
            />
          </Tooltip>
          <Button
            id='add-checks'
            type='filled'
            disabled={
              loading || summaryLoading || store.uiStore.getGlobalLoader()
            }
            label='Add Check'
            size='small'
            onClick={() => navigate('add-check')}
          />
        </div>
      </div>
    </div>
  )
}

export default UCDashboard
