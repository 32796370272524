import { CaretDown, CaretUp, ChevronRight } from '@carbon/icons-react'
import React, { useEffect, useRef, useState } from 'react'
import {
  gaEventConstants,
  gaPageConstants
} from '../../../../constants/GAConstants'
import {
  getAllQueryParamsAsObjectFromUrl,
  replaceUrl
} from '../../../../utils/UrlUtils'
import { isEqual, isNull } from 'lodash'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import ApiGroupingStringConstants from '../../../../constants/ApiGroupingStringConstants'
import { DDSTypography } from 'den-design-system'
import { Link } from '@mui/material'
import NoFilterImage from '../../../../assets/images/folder-not-found 1.svg'
import NoRecord from '../../../common/NoContent/NoRecord'
import NoRecordImage from '../../../../assets/images/setting.svg'
import Spinner from '../../../common/Spinner/Spinner'
import StatusCodeGraph from '../../../common/StatusCodeGraph/StatusCodeGraph'
import StringConstants from '../../../../constants/StringConstants'
import Table from '../../../common/DDS/Table/Table'
import Tooltip from '../../../New/Tooltip/Tooltip'
import { color } from '../../../../utils/ApiStatusColorCode'
import dayjs from 'dayjs'
import { getOrgTimeRange } from '../../../../utils/DateTimeUtils'
import { observer } from 'mobx-react-lite'
import pallete from '../../../../global/newPallete'
import routeConstants from '../../../../constants/RouteConstants'
import { timeFormats } from '../../../../enum/TIME'
import { useAnalyticsEventTracker } from '../../../../utils/GAUtils'
import { useStoreContext } from '../../../../store/StoreContext'

type SortOrder = 'ASC' | 'DESC' | 'CLEAR'
type SortState = null | SortOrder | 'CLEAR'
interface UserViewProps {
  loading: boolean
  setLoading: (loading: boolean) => void
  totalCount: any
  groupedApiTableData: any
  groupedUserApiData: (queryParams: any) => void
  activeSortOrder: {
    field: string | null
    order: SortOrder | null
  }
  handleSortCycle: (sortField: string) => void
  setCurrentPage: any
  currentPage: any
  handlePageChange: (page: number) => void
  setActiveSortOrder: (field: any) => void
}

const UserView: React.FC<UserViewProps> = ({
  loading,
  setLoading,
  totalCount,
  groupedApiTableData,
  groupedUserApiData,
  activeSortOrder,
  handleSortCycle,
  setCurrentPage,
  currentPage,
  handlePageChange,
  setActiveSortOrder
}) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const navigate = useNavigate()
  const store = useStoreContext()
  const location = useLocation()
  const gaEventTracker = useAnalyticsEventTracker(
    gaPageConstants.API_MONITORING_TABLE
  )
  const tableRef = useRef<HTMLDivElement>(null)
  const [tableHeight, setTableHeight] = useState<number | null>(null)

  const allQueryParams = getAllQueryParamsAsObjectFromUrl(location.search)
  const orgTimeZone = store.userStore.getOrganizationPreference()?.timeZone

  const { startOfMonth, endOfCurrentDayTimestamp } =
    getOrgTimeRange(orgTimeZone)

  const paramsWithDateRange = {
    ...allQueryParams,
    'created-from': allQueryParams['created-from'] || String(startOfMonth),
    'created-to':
      allQueryParams['created-to'] || String(endOfCurrentDayTimestamp),
    'time-zone': orgTimeZone
  }

  sessionStorage.setItem('created-from', paramsWithDateRange['created-from'])
  sessionStorage.setItem('created-to', paramsWithDateRange['created-to'])
  sessionStorage.setItem('time-zone', paramsWithDateRange['time-zone'])

  useEffect(() => {
    if (store.filterStore.getFilterDisabled()) {
      store.filterStore.setFilterDisabled(false)
    }
  }, [store.filterStore.getFilterDisabled()])

  useEffect(() => {
    const pageFromQueryParams = searchParams.get('page')
    const queryParams = getAllQueryParamsAsObjectFromUrl(location.search)
    if (
      !isNull(pageFromQueryParams) &&
      isEqual(paramsWithDateRange, queryParams)
    ) {
      setCurrentPage(Number(pageFromQueryParams))
      groupedUserApiData({
        ...paramsWithDateRange,
        size: StringConstants.DEFAULT_TABLE_SIZE
      })
    } else {
      setSearchParams({ ...paramsWithDateRange, page: '1' })
      setCurrentPage(1)
    }
  }, [searchParams, store.scopeStore.getScope()])

  useEffect(() => {
    if (store.apiDetailsStore.getRefresh() === true) {
      groupedUserApiData({
        ...paramsWithDateRange,
        size: StringConstants.DEFAULT_TABLE_SIZE
      })
    }
  }, [store.apiDetailsStore.getRefresh()])

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const sortField = searchParams.get('sortField')
    const sortOrder = searchParams.get('sortOrder') as SortOrder

    if (!sortField || !sortOrder) {
      setActiveSortOrder({ field: null, order: null })
    } else {
      setActiveSortOrder({ field: sortField, order: sortOrder })
    }
  }, [location.search])

  const Columns = [
    {
      columnHeaderStyle: {},
      columnDataStyle: {},
      heading: (
        <div className='flex whitespace-nowrap space-x-[8px] items-center'>
          <div>
            <DDSTypography.Paragraph
              size='caption'
              variant='semiBold'
              color={pallete.colors.textDark6}
            >
              User
            </DDSTypography.Paragraph>
          </div>
          <div
            className='flex flex-col justify-center -space-y-[6px]'
            onClick={() => handleSortCycle('sort-user-view-name')}
          >
            <CaretUp
              size={12}
              style={{
                color:
                  activeSortOrder.field === 'sort-user-view-name' &&
                  activeSortOrder.order === 'ASC'
                    ? pallete.colors.FillButton
                    : activeSortOrder.field === 'sort-user-view-name' &&
                        activeSortOrder.order === 'DESC'
                      ? pallete.colors.outButtonDis
                      : pallete.colors.textDark6
              }}
              className='cursor-pointer'
            />
            <CaretDown
              size={12}
              style={{
                color:
                  activeSortOrder.field === 'sort-user-view-name' &&
                  activeSortOrder.order === 'ASC'
                    ? pallete.colors.outButtonDis
                    : activeSortOrder.field === 'sort-user-view-name' &&
                        activeSortOrder.order === 'DESC'
                      ? pallete.colors.FillButton
                      : pallete.colors.textDark6
              }}
              className='cursor-pointer'
            />
          </div>
        </div>
      ),
      dataLabel: 'userName',
      render: (text: any) => (
        <div className='flex justify-start text-start row items-start max-w-[120px]'>
          <div>
            <Tooltip
              id={`tooltip-name-${text}`}
              label={text}
              className={`override-tooltip-arrow ${
                typeof text === 'string' && text.length > 15 ? text : '!hidden'
              }`}
            >
              <div>
                <DDSTypography.Paragraph
                  size='para'
                  variant='medium'
                  color={pallete.colors.textDark4}
                  className='tooltip-container sm:block overflow-hidden truncate max-w-[120px]'
                >
                  {text || text === 0 ? text : '-'}
                </DDSTypography.Paragraph>
              </div>
            </Tooltip>
          </div>
        </div>
      )
    },
    {
      columnHeaderStyle: {},
      columnDataStyle: {},
      heading: (
        <div className='flex whitespace-nowrap justify-center text-center  space-x-[8px] items-center'>
          <div>
            <DDSTypography.Paragraph
              size='caption'
              variant='semiBold'
              color={pallete.colors.textDark6}
            >
              No. of Endpoints
            </DDSTypography.Paragraph>
          </div>
          <div
            className='flex flex-col justify-center -space-y-[6px]'
            onClick={() => handleSortCycle('sort-total-end-points')}
          >
            <CaretUp
              size={12}
              style={{
                color:
                  activeSortOrder.field === 'sort-total-end-points' &&
                  activeSortOrder.order === 'ASC'
                    ? pallete.colors.FillButton
                    : activeSortOrder.field === 'sort-total-end-points' &&
                        activeSortOrder.order === 'DESC'
                      ? pallete.colors.outButtonDis
                      : pallete.colors.textDark6
              }}
              className='cursor-pointer'
            />
            <CaretDown
              size={12}
              style={{
                color:
                  activeSortOrder.field === 'sort-total-end-points' &&
                  activeSortOrder.order === 'ASC'
                    ? pallete.colors.outButtonDis
                    : activeSortOrder.field === 'sort-total-end-points' &&
                        activeSortOrder.order === 'DESC'
                      ? pallete.colors.FillButton
                      : pallete.colors.textDark6
              }}
              className='cursor-pointer'
            />
          </div>
        </div>
      ),
      dataLabel: 'endpoints',
      render: (text: any) => (
        <div className='flex justify-center text-center row items-center'>
          <DDSTypography.Paragraph
            size='para'
            variant='medium'
            color={pallete.colors.textDark4}
            style={{
              display: 'flex',
              justifyContent: 'center'
            }}
            className='tooltip-container sm:block overflow-hidden truncate'
          >
            {text || text === 0 ? text : '-'}
          </DDSTypography.Paragraph>
        </div>
      )
    },

    {
      columnHeaderStyle: {},
      columnDataStyle: {},
      heading: (
        <div className='flex whitespace-nowrap justify-center text-center  space-x-[8px] items-center'>
          <div>
            <DDSTypography.Paragraph
              size='caption'
              variant='semiBold'
              color={pallete.colors.textDark6}
            >
              {ApiGroupingStringConstants.API_COUNT}
            </DDSTypography.Paragraph>
          </div>
          <div
            className='flex flex-col justify-center -space-y-[6px]'
            onClick={() => handleSortCycle('sort-total-api-calls')}
          >
            <CaretUp
              size={12}
              style={{
                color:
                  activeSortOrder.field === 'sort-total-api-calls' &&
                  activeSortOrder.order === 'ASC'
                    ? pallete.colors.FillButton
                    : activeSortOrder.field === 'sort-total-api-calls' &&
                        activeSortOrder.order === 'DESC'
                      ? pallete.colors.outButtonDis
                      : pallete.colors.textDark6
              }}
              className='cursor-pointer'
            />
            <CaretDown
              size={12}
              style={{
                color:
                  activeSortOrder.field === 'sort-total-api-calls' &&
                  activeSortOrder.order === 'ASC'
                    ? pallete.colors.outButtonDis
                    : activeSortOrder.field === 'sort-total-api-calls' &&
                        activeSortOrder.order === 'DESC'
                      ? pallete.colors.FillButton
                      : pallete.colors.textDark6
              }}
              className='cursor-pointer'
            />
          </div>
        </div>
      ),
      dataLabel: 'totalCalls',
      render: (text: any) => (
        <div className='flex justify-center text-center row items-center'>
          <DDSTypography.Paragraph
            size='para'
            variant='medium'
            color={pallete.colors.textDark4}
            style={{
              display: 'flex',
              justifyContent: 'center'
            }}
            className='tooltip-container sm:block overflow-hidden truncate'
          >
            {text || text === 0 ? text : '-'}
          </DDSTypography.Paragraph>
        </div>
      )
    },
    {
      columnHeaderStyle: {},
      columnDataStyle: {},
      heading: (
        <div className='flex whitespace-nowrap justify-center text-center  space-x-[8px] items-center'>
          <div>
            <DDSTypography.Paragraph
              size='caption'
              variant='semiBold'
              color={pallete.colors.textDark6}
            >
              Last Call Date
            </DDSTypography.Paragraph>
          </div>
          <div
            className='flex flex-col justify-center -space-y-[6px]'
            onClick={() => handleSortCycle('sort-last-call-date')}
          >
            <CaretUp
              size={12}
              style={{
                color:
                  activeSortOrder.field === 'sort-last-call-date' &&
                  activeSortOrder.order === 'ASC'
                    ? pallete.colors.FillButton
                    : activeSortOrder.field === 'sort-last-call-date' &&
                        activeSortOrder.order === 'DESC'
                      ? pallete.colors.outButtonDis
                      : pallete.colors.textDark6
              }}
              className='cursor-pointer'
            />
            <CaretDown
              size={12}
              style={{
                color:
                  activeSortOrder.field === 'sort-last-call-date' &&
                  activeSortOrder.order === 'ASC'
                    ? pallete.colors.outButtonDis
                    : activeSortOrder.field === 'sort-last-call-date' &&
                        activeSortOrder.order === 'DESC'
                      ? pallete.colors.FillButton
                      : pallete.colors.textDark6
              }}
              className='cursor-pointer'
            />
          </div>
        </div>
      ),
      dataLabel: 'lastCallDate',
      render: (text: any) => (
        <div className='flex justify-center text-center row items-center'>
          <DDSTypography.Paragraph
            size='para'
            variant='medium'
            color={pallete.colors.textDark4}
            style={{
              display: 'flex',
              justifyContent: 'center'
            }}
            className='tooltip-container sm:block overflow-hidden truncate'
          >
            {dayjs(text).format(timeFormats.monthDateYear)}
          </DDSTypography.Paragraph>
        </div>
      )
    },
    {
      heading: (
        <div className='flex row justify-center  text-center items-center'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            {ApiGroupingStringConstants.STATUS_CODE_GRAPH}
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'statusCodeGraph',
      render: (text: any, fullItem: any) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {fullItem['statusCodeGraph'] ? (
            <StatusCodeGraph
              width='144px'
              tableHeight={tableHeight}
              totalCount={totalCount}
              data={fullItem['statusCodeGraph']}
              color={color}
            />
          ) : (
            <>-</>
          )}
        </div>
      )
    },
    {
      columnHeaderStyle: { width: '20px' },
      columnDataStyle: { width: '20px' },
      heading: '',
      dataLabel: '',
      render: (text: any, fullItem: any) => (
        <div className='cursor-pointer'>
          <ChevronRight
            size={16}
            onClick={() => {
              gaEventTracker(gaEventConstants.VIEW_SINGLE_API_RECORD)

              const orgTimeZone =
                store.userStore.getOrganizationPreference()?.timeZone

              const { startOfMonth, endOfCurrentDayTimestamp } =
                getOrgTimeRange(orgTimeZone)

              const searchParams = new URLSearchParams(window.location.search)
              const currentFilters: Record<string, string> = {}

              currentFilters['created-from'] =
                searchParams.get('created-from') || String(startOfMonth)
              currentFilters['created-to'] =
                searchParams.get('created-to') ||
                String(endOfCurrentDayTimestamp)
              currentFilters['time-zone'] =
                searchParams.get('time-zone') || orgTimeZone

              const baseRoute = replaceUrl(
                routeConstants.GROUPED_USER_DETAILS,
                store.scopeStore.getScope()['organization-id'] as string,
                store.scopeStore.getScope()['project-id'] as string,
                store.scopeStore.getScope()['environment-id'] as string
              ).replace(':id', String(fullItem.id))

              const newSearchParams = new URLSearchParams({
                page: '1',
                tab: '1',
                ...currentFilters
              })

              navigate(`${baseRoute}?${newSearchParams.toString()}`)
            }}
            color={pallete.colors.FillButton}
          />
        </div>
      )
    }
  ]

  return (
    <>
      {loading || store.uiStore.getGlobalLoader() || !groupedApiTableData ? (
        <Spinner />
      ) : totalCount > 0 ? (
        <div className='py-[24px]' ref={tableRef}>
          <Table
            tableStyle={{
              borderColor: pallete.colors.stroke2
            }}
            data={groupedApiTableData}
            columns={Columns}
            totalCount={totalCount}
            rowsPerPage={10}
            currentPage={currentPage}
            onPageChange={handlePageChange}
            loading={loading || store.uiStore.getGlobalLoader()}
          />
        </div>
      ) : (
        <>
          <NoRecord
            imageSrc={
              totalCount === 0 && store.filterStore.getFiltersApplied()
                ? NoFilterImage
                : NoRecordImage
            }
            style={{ height: '80vh' }}
            text={
              totalCount === 0 && store.filterStore.getFiltersApplied() ? (
                StringConstants.NO_RECORDS_FILTER
              ) : (
                <>
                  Configure API Monitoring.
                  <Link
                    href={StringConstants.FULLSTACK_URL}
                    target='_blank'
                    rel='noopener noreferrer'
                    style={{ marginLeft: '4px' }}
                  >
                    Learn More
                  </Link>
                </>
              )
            }
          />
        </>
      )}
    </>
  )
}

export default observer(UserView)
