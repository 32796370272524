import React, { useEffect } from 'react'

import { AccessRoleConstants } from '../../constants/AccessRoleConstants'
import { DDSTypography } from 'den-design-system'
import SecureComponent from '../Layout/SecureComponent'
import Spinner from '../common/Spinner/Spinner'
import UCCard from './UCCard'
import UCStockChart from './UCStockChart'
import { isEmpty } from 'lodash'
import { observer } from 'mobx-react-lite'
import { openInNewTab } from '../../utils/UCUtils'
import pallete from '../../global/newPallete'
import { timeMetric } from '../../utils/DateTimeUtils'
import { useStoreContext } from '../../store/StoreContext'

interface UCOverviewProps {
  healthData: any
  loading: boolean
  fetchHealthOverview: () => void
}
const UCOverview: React.FC<UCOverviewProps> = ({
  healthData,
  loading,
  fetchHealthOverview
}) => {
  const store = useStoreContext()

  const getTimeData = (key = 'response') => {
    const report = healthData
    if (report)
      switch (key) {
        case 'response':
          return Object.keys(report.responsesByDate).map((key) => ({
            x: new Date(key).getTime(),
            y: report.responsesByDate[key].averageResponseTimeInMilliSeconds
          }))
        case 'down':
          return Object.keys(report.responsesByDate).map((key) => ({
            x: key,
            y: report.responsesByDate[key].downTimePercentage
          }))
        case 'up':
          return Object.keys(report.responsesByDate).map((key) => ({
            x: key,
            y: report.responsesByDate[key].upTimePercentage
          }))
      }
    return []
  }

  useEffect(() => {
    fetchHealthOverview()
  }, [])
  const cardDataTop = [
    {
      id: '1',
      heading: 'Application Name',
      content: (
        <DDSTypography.Title
          type='h5'
          variant='semiBold'
          className='truncate max-w-[200px]'
          color={pallete.colors.textDark3}
        >
          {healthData?.checkName ?? '-'}
        </DDSTypography.Title>
      )
    },
    {
      id: '2',
      heading: 'URL',
      content: healthData?.url ?? '-',
      onClick: healthData?.url ? () => openInNewTab(healthData?.url) : null
    },
    {
      id: '3',
      heading: 'Availability',
      content:
        healthData?.status !== 'Paused' &&
        healthData?.availability &&
        healthData?.availability !== 'Not enough data'
          ? healthData?.availability !== '-'
            ? healthData?.availability + '%'
            : '0%'
          : healthData?.status !== 'Paused'
            ? '0%'
            : '-'
    },
    {
      id: '4',
      heading: 'Check Interval',
      content: healthData?.checkInterval
        ? healthData?.checkInterval +
          ' min' +
          (healthData?.checkInterval === 1 ? '' : 's')
        : '-'
    }
  ]

  const cardDataBottom = [
    {
      id: '1',
      heading: 'Incidents',
      content: (
        <div className='flex flex-col w-fit'>
          <DDSTypography.Title variant='semiBold' type='h5'>
            {healthData?.numberOfIncidents !== 0
              ? healthData?.numberOfIncidents
              : '-'}
          </DDSTypography.Title>
          <div className='pb-[20px]'></div>
        </div>
      )
    },
    {
      id: '2',
      heading: 'Downtime',
      content: (
        <div className='flex flex-col'>
          <DDSTypography.Title variant='semiBold' type='h5'>
            {timeMetric(
              healthData?.downTime?.duration,
              healthData?.downTime?.unit
            )
              ? `${timeMetric(
                  healthData?.downTime?.duration,
                  healthData?.downTime?.unit
                )} (${healthData?.downTime?.percentage}%)`
              : '-'}
          </DDSTypography.Title>
          <div className='pb-[20px]'></div>
        </div>
      )
    },
    {
      id: '3',
      heading: 'Avg. Response Time',
      content: (
        <div className='flex flex-col'>
          <DDSTypography.Title variant='semiBold' type='h5'>
            {healthData?.averageResponseTime
              ? timeMetric(
                  healthData?.averageResponseTime.duration as number,
                  healthData?.averageResponseTime.unit as string
                ) || '-'
              : '-'}
          </DDSTypography.Title>
          <div className='pb-[20px]'></div>
        </div>
      )
    },
    {
      id: '4',
      heading: 'Longest Downtime',
      content: (
        <div className='flex flex-col'>
          <DDSTypography.Title variant='semiBold' type='h5'>
            {!isEmpty(
              timeMetric(
                healthData?.longestDownTime.duration as number,
                healthData?.longestDownTime.unit as string
              )
            )
              ? timeMetric(
                  healthData?.longestDownTime.duration as number,
                  healthData?.longestDownTime.unit as string
                )
              : '-'}
          </DDSTypography.Title>
          <div className='pb-[20px]'></div>
        </div>
      )
    }
  ]
  return loading || store.uiStore.getGlobalLoader() || !healthData ? (
    <div className='flex flex-col h-[70vh] self-stretch justify-center'>
      <Spinner />
    </div>
  ) : (
    <div className='flex flex-col gap-[24px] py-[24px]'>
      <div className='grid grid-cols-4 gap-[16px]'>
        {cardDataTop.map((item) => (
          <div key={item.id}>
            <UCCard
              id={item.id}
              heading={item.heading}
              content={item.content}
              onClick={item.onClick}
            />
          </div>
        ))}
      </div>
      <div className='flex justify-between gap-[16px]'>
        <div
          style={{ borderColor: pallete.colors.stroke2 }}
          className={`flex w-full h-full flex-col px-[20px] py-[16px] gap-[44px] self-stretch items-center border rounded-[6px]`}
        >
          <DDSTypography.Paragraph
            color={pallete.colors.textDark5}
            variant='regular'
            size='para'
          >
            Trend Graph
          </DDSTypography.Paragraph>
          <div>
            <UCStockChart
              data={getTimeData('response')}
              startDate={1620388800000}
              marker={false}
            />
          </div>
          <div className='flex gap-[4px] items-center justify-center'>
            <div
              style={{ backgroundColor: pallete.colors.primary2 }}
              className={`h-[1px] w-[15.433px]`}
            ></div>
            <DDSTypography.Paragraph
              variant='medium'
              size='caption'
              color={pallete.colors.lightWhite}
              className='flex items-center whitespace-nowrap'
              style={{
                fontSize: '9.569px',
                lineHeight: '12.759px'
              }}
            >
              Reported Data
            </DDSTypography.Paragraph>
          </div>
        </div>
        <div className='w-[40%] grid grid-cols-1 gap-[16px]'>
          {cardDataBottom.map((item) => (
            <div key={item.id}>
              <UCCard
                id={item.id}
                heading={item.heading}
                content={item.content}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default SecureComponent(observer(UCOverview), AccessRoleConstants.ALL)
