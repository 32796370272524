import {
  CartesianGrid,
  Legend,
  LegendProps,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts'
import React, { useEffect, useState } from 'react'
import {
  default as palette,
  default as pallete
} from '../../../global/newPallete'

import { CircleSolid } from '@carbon/icons-react'
import { DDSTypography } from 'den-design-system'
import { observer } from 'mobx-react-lite'

interface DataPoint {
  periodType: string
  successApiCount: number
  apiCount: number
  periodNumber: number
  periodMonth: number
  periodYear: number
  apiFailureCount: number
  hoverText: any
  displayText: any
  color: any
}

interface CustomLegendProps extends LegendProps {
  dataLength?: number
}
interface GraphProps {
  data: DataPoint[]
  timeFilter?: any
  title?: string
  loading?: boolean
  className?: string
  filteredTableData: (
    dateFilters: {
      displayText: string
      month?: number
      year?: number
      date?: number
    },
    isSuccess: boolean,
    hoverText: string
  ) => Promise<void>
  setSelectGraphPointIsSuccess: React.Dispatch<React.SetStateAction<string>>
}

interface CustomTooltipProps {
  active?: boolean
  payload?: Array<{
    name: string
    value: number
    payload?: any
    displayText?: any
  }>

  label?: any
}

export const CustomizedTick = (props: any) => {
  const { x, y, label, dataLength, timeFilter } = props

  // Extract just the day name from displayText if available
  const dayName = label?.displayText?.split(' ')[0] || ''

  return (
    <g transform={`translate(${x},${y})`}>
      {/* Date label */}
      <text
        x={0}
        y={0}
        dy={16}
        fill='#666'
        textAnchor='end'
        transform='rotate(-90)'
      >
        <tspan>{label?.date}</tspan>
      </text>

      {/* Conditional rendering of dayName based on timeFilter */}
      {dataLength <= 7 && timeFilter !== 'THIS_MONTH' && (
        <text
          x={-75}
          y={1}
          dy={34}
          fill='#666'
          textAnchor='start'
          transform='rotate(-90)'
        >
          <tspan>{dayName}</tspan>
        </text>
      )}
    </g>
  )
}

export const CustomTooltip: React.FC<CustomTooltipProps> = ({
  active,
  payload,
  label
}) => {
  if (active && payload && payload.length) {
    const formatValue = (value: string): string => {
      if (!value) return value // Return empty if the value is undefined/null

      // Regex for dates in `D-M-YYYY` format
      const dateRegex = /^(\d{1,2})-(\d{1,2})-(\d{4})$/
      const match = value.match(dateRegex)

      if (match) {
        const [_, day, month, year] = match

        // Format as `DD/MM/YYYY`
        return `${day.padStart(2, '0')}/${month.padStart(2, '0')}/${year}`
      }

      // Abbreviate full day names (e.g., Sunday -> Sun)
      const dayAbbreviations: Record<string, string> = {
        Sunday: 'Sun',
        Monday: 'Mon',
        Tuesday: 'Tue',
        Wednesday: 'Wed',
        Thursday: 'Thu',
        Friday: 'Fri',
        Saturday: 'Sat'
      }

      if (dayAbbreviations[value]) {
        return dayAbbreviations[value]
      }

      const monthAbbreviations: Record<string, string> = {
        January: 'Jan',
        February: 'Feb',
        March: 'Mar',
        April: 'Apr',
        May: 'May',
        June: 'Jun',
        July: 'Jul',
        August: 'Aug',
        September: 'Sep',
        October: 'Oct',
        November: 'Nov',
        December: 'Dec'
      }

      if (monthAbbreviations[value]) {
        return monthAbbreviations[value]
      }

      return value
    }

    return (
      <div className='bg-white py-[8px] cursor-pointer px-[10px] rounded-lg w-[27vh] shadow-lg border border-gray-200'>
        <DDSTypography.Paragraph
          size='caption'
          variant='regular'
          color={palette.colors.textDark5}
          className='mb-[4px]'
        >
          {formatValue(
            payload[0]?.payload?.hoverText || payload[1]?.payload?.hoverText
          )}
        </DDSTypography.Paragraph>
        <div className='flex items-center justify-between'>
          <span className='flex items-center'>
            <span
              className='w-[6px] h-[6px] bottom-[2px] relative rounded-full items-center mr-[4px]'
              style={{
                backgroundColor: palette.colors.warningRed2
              }}
            ></span>
            <DDSTypography.Paragraph
              size='para'
              variant='medium'
              color={palette.colors.textDark3}
              className='mb-[4px]'
            >
              Failure
            </DDSTypography.Paragraph>
          </span>
          <span
            style={{
              color: palette.colors.textDark3,
              fontSize: '14px',
              fontWeight: 500,
              lineHeight: '28px'
            }}
          >
            {payload[1]?.value}
          </span>
        </div>

        <div className='flex items-center justify-between'>
          <span className='flex items-center'>
            <span
              className='w-[6px] h-[6px] bottom-[2px] relative rounded-full mr-[4px]'
              style={{
                backgroundColor: palette.colors.warningGreen
              }}
            ></span>
            <DDSTypography.Paragraph
              size='para'
              variant='medium'
              color={palette.colors.textDark3}
              className='mb-[4px]'
            >
              Success
            </DDSTypography.Paragraph>
          </span>
          <span
            style={{
              color: palette.colors.textDark3,
              fontSize: '14px',
              fontWeight: 500,
              lineHeight: '28px'
            }}
          >
            {payload[0]?.value}
          </span>
        </div>

        <div>
          <DDSTypography.Paragraph
            size='caption'
            variant='regular'
            color={palette.colors.textDark6}
            className='mb-[4px]'
          >
            Click on the pointer to view more
          </DDSTypography.Paragraph>
        </div>
      </div>
    )
  }
  return null
}

export const CustomLegend: React.FC<CustomLegendProps> = ({
  payload,
  dataLength
}) => {
  return (
    <div
      className={`flex justify-center relative ${
        dataLength && dataLength > 7
          ? 'top-[72px] 2xl:top-[76px]'
          : 'top-[72px] 2xl:top-[76px]'
      } items-center space-x-[12px]`}
    >
      {payload?.map((entry, index) => (
        <div
          key={`legend-item-${index}`}
          className='flex items-center space-x-[6px]'
        >
          <div className='flex items-center justify-center'>
            <CircleSolid color={entry.color} size={8} />
          </div>
          <DDSTypography.Paragraph
            size='caption'
            variant='medium'
            color={palette.colors.textDark6}
            className='leading-none'
          >
            {entry.value}
          </DDSTypography.Paragraph>
        </div>
      ))}
    </div>
  )
}

const SuccessFailureGraph: React.FC<GraphProps> = ({
  data,
  title,
  filteredTableData,
  timeFilter,
  setSelectGraphPointIsSuccess,
  className = '',
  loading = false
}) => {
  const filteredData = data?.slice(-9)
  const [isHovered, setIsHovered] = useState(false)

  const formattedData = data?.map((item) => ({
    ...item,
    apiFailureCount: item?.apiCount - item?.successApiCount
  }))

  const allValues: number[] = filteredData
    ? filteredData.flatMap((d) => [
        d?.successApiCount || 0,
        (d?.apiCount || 0) - (d?.successApiCount || 0)
      ])
    : []

  const isAllZero = data?.every(
    (d) => (d?.successApiCount || 0) === 0 && (d?.apiCount || 0) === 0
  )

  const minValue = Math.min(...allValues)
  const maxValue = Math.max(...allValues)

  const generateTicks = (min: number, max: number): number[] => {
    const step = Math.ceil((max - min) / 8)
    const ticks = []
    for (let i = 0; i < 9; i++) {
      const tickValue = min + step * i
      if (tickValue !== 0) {
        ticks.push(tickValue)
      }
    }
    return ticks
  }

  const yAxisTicks = generateTicks(minValue, maxValue)

  return (
    <div
      className={`w-full h-full ${className} relative ${loading ? 'pointer-events-none cursor-not-allowed' : ''}`}
    >
      {title && (
        <h2 className='text-lg font-semibold text-gray-800'>{title}</h2>
      )}
      <ResponsiveContainer width='100%' height='100%'>
        <LineChart
          data={formattedData}
          margin={{
            top: 20,
            right: 40,
            left: 20,
            bottom: data?.length > 7 ? 60 : 60
          }}
          style={{ cursor: 'pointer' }}
        >
          <CartesianGrid
            horizontal={true}
            vertical={false}
            className='opacity-30'
          />
          <XAxis
            tick={(props) => (
              <CustomizedTick
                {...props}
                // eslint-disable-next-line react/prop-types
                label={formattedData?.[props?.payload?.index]}
                dataLength={data.length}
                timeFilter={timeFilter}
              />
            )}
            tickLine={false}
            axisLine={false}
            tickMargin={data?.length > 7 ? 10 : 10}
            angle={data?.length > 7 ? -90 : -90}
            textAnchor={data?.length > 7 ? 'end' : 'end'}

            // eslint-disable-next-line react/no-children-prop
          />
          {/* <XAxis
            dataKey='displayText'
            // tickFormatter={(value) => {
            //   // Check if the value matches the format `1-2-2024`
            //   const dateRegex = /^(\d{1,2})-(\d{1,2})-(\d{4})$/
            //   const match = value.match(dateRegex)

            //   if (match) {
            //     const [_, day, month, year] = match

            //     const formattedDate = `${day.padStart(2, '0')}/${month.padStart(2, '0')}/${year}`
            //     return formattedDate
            //   }

            //   if (value === 'Sunday') return 'Sun'
            //   if (value === 'Monday') return 'Mon'
            //   if (value === 'Tuesday') return 'Tue'
            //   if (value === 'Wednesday') return 'Wed'
            //   if (value === 'Thursday') return 'Thu'
            //   if (value === 'Friday') return 'Fri'
            //   if (value === 'Saturday') return 'Sat'

            //   return value
            // }}
            tick={{
              fill: '#808EA2',
              fontSize: '12px'
            }}
            tickLine={false}
            axisLine={false}
            tickMargin={data?.length > 7 ? 10 : 10}
            angle={data?.length > 7 ? -90 : -90}
            textAnchor={data?.length > 7 ? 'end' : 'end'}
          /> */}

          <YAxis
            type='number'
            domain={[yAxisTicks[0], yAxisTicks[yAxisTicks.length - 1]]}
            ticks={yAxisTicks}
            tick={{
              fill: '#000000',
              fontSize: '12px'
            }}
            tickLine={false}
            axisLine={false}
            tickFormatter={(value) => value.toLocaleString()}
            label={{
              value: 'API Count',
              angle: -90,
              style: {
                textAnchor: 'middle',
                fill: '#666',
                fontSize: '12px',
                fontWeight: 500,
                lineHeight: '16px',
                color: pallete.colors.textDark6
              },
              dx: -40
            }}
          />

          <Tooltip
            content={<CustomTooltip />}
            cursorStyle={{ cursor: 'transparent' }}
          />
          <Legend content={<CustomLegend dataLength={data?.length} />} />

          <Line
            type='linear'
            dataKey='successApiCount'
            name='Success'
            stroke={isAllZero ? '#97A3B4' : '#10B981'}
            legendType='circle'
            strokeWidth={1}
            dot={{
              r: isHovered ? 7 : 4,
              fill: isAllZero ? '#97A3B4' : '#56B320',
              cursor: 'pointer',
              strokeWidth: 0,
              onClick: (event: any, payload: any) => {
                const data = payload?.payload
                if (!data) return

                const specificPointData = {
                  displayText: data.displayText || '',
                  month: data.month,
                  year: data.year,
                  date: data.date
                }

                setSelectGraphPointIsSuccess('1')
                filteredTableData(specificPointData, true, data?.hoverText)
              },
              onMouseEnter: () => setIsHovered(true),
              onMouseLeave: () => setIsHovered(false)
            }}
            activeDot={{
              r: isHovered ? 7 : 4,
              onClick: (event: any, payload: any) => {
                const data = payload?.payload

                if (!data) return

                const specificPointData = {
                  displayText: data.displayText || '',
                  month: data.month,
                  year: data.year,
                  date: data.date
                }

                setSelectGraphPointIsSuccess('1')
                filteredTableData(specificPointData, true, data?.hoverText)
              },
              onMouseEnter: () => setIsHovered(true),
              onMouseLeave: () => setIsHovered(false)
            }}
            connectNulls
          />
          <Line
            type='linear'
            dataKey='apiFailureCount'
            name='Failure'
            stroke={isAllZero ? '#97A3B4' : '#EF4444'}
            legendType='circle'
            strokeWidth={1}
            dot={{
              r: isHovered ? 7 : 4,
              fill: isAllZero ? '#97A3B4' : '#B20707',
              cursor: 'pointer',
              strokeWidth: 0,
              onClick: (event: any, payload: any) => {
                const data = payload?.payload
                if (!data) return
                const specificPointData = {
                  displayText: data.displayText || '',
                  month: data.month,
                  year: data.year,
                  date: data.date
                }

                setSelectGraphPointIsSuccess('2')
                filteredTableData(specificPointData, false, data?.hoverText)
              },
              onMouseEnter: () => setIsHovered(true),
              onMouseLeave: () => setIsHovered(false)
            }}
            activeDot={{
              r: isHovered ? 7 : 4,
              onClick: (event: any, payload: any) => {
                const data = payload?.payload
                if (!data) return
                const specificPointData = {
                  displayText: data.displayText || '',
                  month: data.month,
                  year: data.year,
                  date: data.date
                }

                setSelectGraphPointIsSuccess('2')
                filteredTableData(specificPointData, false, data?.hoverText)
              },
              onMouseEnter: () => setIsHovered(true),
              onMouseLeave: () => setIsHovered(false)
            }}
            connectNulls
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  )
}

export default observer(SuccessFailureGraph)
