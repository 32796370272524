import { AtoB, replaceUrl } from '../../../../../utils/UrlUtils'
import Joi, { ValidationResult } from 'joi'
import {
  ProjectType,
  ProjectTypeDisplayNames
} from '../../../../../enum/ProjectType.enum'
import React, { useEffect, useRef, useState } from 'react'
import { cloneDeep, isEmpty, isEqual, isUndefined } from 'lodash'
import {
  disconnectSlackFromProject,
  getProjectById,
  updateProject
} from '../../../../../api/project/Project.service'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import AdditionalEmailInput from '../../../../../components/common/AdditionalEmailInput/AdditionalEmailInput'
import { Button } from '../../../../../components/New/Button/Button'
import { DDSTypography } from 'den-design-system'
import Input from '../../../../../components/New/Input/Input'
import { OrganizationUserRoles } from '../../../../../enum/OrganizationUserRoles.enum'
import { RefObject } from '../../../../../components/common/InputField/Interface/Interface'
import RegexConstants from '../../../../../constants/RegexConstants'
import SettingConstants from '../../../../../constants/SettingConstants'
import Slack from '../../../../../assets/icons/slack.png'
import Spinner from '../../../../../components/common/Spinner/Spinner'
import StringConstants from '../../../../../constants/StringConstants'
import { ToastMessageConstants } from '../../../../../constants/ToastMessageConstants'
import ToastNotification from '../../../../../components/common/DDS/Toast/Toast'
import UrlConstants from '../../../../../constants/UrlConstants'
import ValidationConstants from '../../../../../constants/ValidationConstants'
import { checkNameAvailability } from '../../../../../api/common/Common.service'
import { gaPageConstants } from '../../../../../constants/GAConstants'
import { getAddToSlackUrl } from '../../../../../utils/ProjectUtils'
import { getValidationStatus } from '../../../../../utils/ValidationUtils'
import { observer } from 'mobx-react-lite'
import pallete from '../../../../../global/newPallete'
import routeConstants from '../../../../../constants/RouteConstants'
import { useAnalyticsEventTracker } from '../../../../../utils/GAUtils'
import { useStoreContext } from '../../../../../store/StoreContext'

interface Project {
  loading: boolean
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
}

const GeneralProjectSetting: React.FC<Project> = ({
  loading = false,
  setLoading
}) => {
  const { projectId } = useParams()

  const location = useLocation()
  const [isNameAvailable, setIsNameAvailable] = useState<any>(null)

  const storeContext = useStoreContext()
  const [projectName, setProjectName] = useState<string>('')
  const [projectType, setProjectType] = useState<string>('')
  const [additionalEmails, setAdditionalEmails] = useState<any[]>([])
  const [initialAdditionalEmails, setInitialAdditionalEmails] = useState<any[]>(
    []
  )
  const [teamsWebhookURL, setTeamsWebhookURL] = useState<string | null>(null)

  const [deleteProjectName, setDeleteProjectName] = useState<string>('')

  const navigate = useNavigate()
  const { pathIds } = useParams()
  const [projectHelperText, setProjectHelperText] = React.useState<string>('')
  const store = useStoreContext()
  const gaEventTracker = useAnalyticsEventTracker(
    gaPageConstants.GENERAL_SETTINGS_CARD_PROJECT
  )
  const [projectDetails, setProjectDetails] = useState<any>()
  const [isDisconnecting, setIsDisconnecting] = useState(false)
  const [validationErrorResult, setValidationErrorResult] = useState<any[]>([])
  const [disableInputField, setDisableInputField] = useState<boolean>(false)
  const [consecutiveSpaceError, setConsecutiveSpaceError] =
    useState<boolean>(false)
  const nameReference = useRef<RefObject>(null)

  const teamsWebhookUrlReference = useRef<RefObject>(null)
  const slackWebhookUrlReference = useRef<RefObject>(null)
  const additionalEmailsReference = useRef<RefObject>(null)
  const [projectError, setProjectError] = useState('')
  const queryParams = new URLSearchParams(location.search)
  const scrollToBottom = queryParams.get('scrollToBottom')
  const [deleteButtonDisabled, setDeleteButtonDisabled] =
    useState<boolean>(false)

  useEffect(() => {
    const currentProject = store.projectStore
      .getProjects()
      .filter((project: any) => {
        return project.id === projectId
      })
    if (currentProject.length > 0) {
      setProjectType((currentProject[0] as any).type)
    } else {
      setProjectType(ProjectType.FULL_STACK_ONLY)
    }
  }, [store.projectStore.getProjects()])

  useEffect(() => {
    setLoading(true)
    storeContext.scopeStore.setOrganizationId(
      AtoB(pathIds as string).split('/')[0] as string
    )
    getProjectById(projectId as string)
      .then((response: any) => {
        setLoading(false)
        setProjectName(response.data.name)
        store.projectStore.setProjectName(response.data.name)
        setProjectType(response.data.type)
        setTeamsWebhookURL(response.data.teamsWebhookURL)
        if (response.data.additionalEmails) {
          setInitialAdditionalEmails(response.data.additionalEmails)
          setAdditionalEmails(response.data.additionalEmails)
        } else {
          setInitialAdditionalEmails([])
          setAdditionalEmails([])
        }
        setDeleteProjectName(response.data.name)
        setProjectDetails(response.data)
      })
      .catch((err: string) => {
        navigate(
          replaceUrl(
            routeConstants.PROJECT_SETTINGS,
            storeContext.scopeStore.getScope()['organization-id'] as string,
            '',
            ''
          )
        )
        if (err !== 'Unauthorized Exception') {
          ToastNotification({
            type: 'error',
            message: ToastMessageConstants.PROJECT.FIND_PROJECT.ERROR
          })
        }
        setLoading(false)
        storeContext.uiStore.setGlobalLoader(false)
      })
      .finally(() => {
        setLoading(false)
        storeContext.uiStore.setGlobalLoader(false)
      })

    const projectCount = store.projectStore.getProjects().length
    if (projectCount === 1) setDeleteButtonDisabled(true)
  }, [storeContext.scopeStore.getProjects()])

  /**
   * Function returns the object thats needs to be updated and the reference object of the respective input fields
   * @returns
   */

  const getUpdateObjectAndReference = (objectCanBeUpdated: any) => {
    if (
      objectCanBeUpdated.name &&
      objectCanBeUpdated.name !== projectDetails.name
    )
      return {
        updateObject: {
          name: objectCanBeUpdated.name.trim()
        },
        reference: nameReference
      }
    else if (
      (objectCanBeUpdated.teamsWebhookURL || projectDetails.teamsWebhookURL) &&
      objectCanBeUpdated.teamsWebhookURL !== projectDetails.teamsWebhookURL
    )
      return {
        updateObject: {
          teamsWebhookURL: isEmpty(objectCanBeUpdated.teamsWebhookURL)
            ? null
            : objectCanBeUpdated.teamsWebhookURL
        },
        reference: teamsWebhookUrlReference
      }
    else if (
      (objectCanBeUpdated.slackWebhookURL || projectDetails.slackWebhookURL) &&
      objectCanBeUpdated.slackWebhookURL !== projectDetails.slackWebhookURL
    )
      return {
        updateObject: {
          slackWebhookURL: isEmpty(objectCanBeUpdated.slackWebhookURL)
            ? null
            : objectCanBeUpdated.slackWebhookURL
        },
        reference: slackWebhookUrlReference
      }
    else if (
      (projectDetails.additionalEmails ||
        objectCanBeUpdated.additionalEmails.length > 0) &&
      objectCanBeUpdated.additionalEmails !== projectDetails.additionalEmails
    )
      return {
        updateObject: {
          additionalEmails: isEmpty(objectCanBeUpdated.additionalEmails)
            ? null
            : objectCanBeUpdated.additionalEmails
        },
        reference: additionalEmailsReference
      }
    else return null
  }

  const schema = Joi.object({
    name: Joi.string().pattern(RegexConstants.NAME_REGEX).required().messages({
      'string.empty': ValidationConstants.GENERIC.REQUIRED_VALUE,
      'string.pattern.base': ValidationConstants.GENERIC.INVALID_NAME_REGEX
    }),
    teamsWebhookURL: Joi.string()
      .uri({
        scheme: ['https', 'http']
      })
      .optional()
      .allow('')
      .messages({
        'string.uri': ValidationConstants.GENERIC.INVALID_URL,
        'string.uriCustomScheme': ValidationConstants.GENERIC.INVALID_URL
      }),
    additionalEmails: Joi.array().items(
      Joi.string()
        .email({ tlds: { allow: false } })
        .optional()
        .messages({
          'string.empty': ValidationConstants.GENERIC.REQUIRED_VALUE,
          'string.email': ValidationConstants.GENERIC.INVALID_EMAIL
        })
    )
  })

  const updateSingleProject = async () => {
    setLoading(true)
    storeContext.uiStore.setGlobalLoader(true)

    const projectValidationResult: ValidationResult = schema.validate(
      {
        name: projectName.trim(),
        teamsWebhookURL:
          teamsWebhookURL && !isEmpty(teamsWebhookURL)
            ? teamsWebhookURL.trim()
            : '',
        additionalEmails: additionalEmails
      },
      { abortEarly: false }
    )

    let objectCanBeUpdated = {}
    if (projectValidationResult.error) {
      setValidationErrorResult(projectValidationResult.error.details)
      objectCanBeUpdated = {
        ...(!getValidationStatus(projectValidationResult.error.details, 'name')
          ? { name }
          : {}),
        ...(!getValidationStatus(
          projectValidationResult.error.details,
          'teamsWebhookURL'
        )
          ? { teamsWebhookURL }
          : { teamsWebhookURL: projectDetails.teamsWebhookURL }),
        ...(!getValidationStatus(
          projectValidationResult.error.details,
          'additionalEmails'
        )
          ? { additionalEmails }
          : { additionalEmails: [] })
      }
    } else {
      setValidationErrorResult([])
      objectCanBeUpdated = {
        name: projectName,
        teamsWebhookURL,
        additionalEmails
      }
    }

    if (!isEqual(objectCanBeUpdated, {})) {
      setDisableInputField(true)

      const updateObjectAndReference =
        getUpdateObjectAndReference(objectCanBeUpdated)
      if (!updateObjectAndReference) {
        setDisableInputField(false)
        setLoading(false)
        storeContext.uiStore.setGlobalLoader(false)
        return
      }

      const { updateObject, reference } = updateObjectAndReference

      let isNameAvailable = true
      // check for name availability only if project name is changed
      if (updateObject.name) {
        reference.current?.startLoading()

        await checkNameAvailability(
          UrlConstants.CHECK_PROJECT_NAME_AVAILABILITY.USECASE,
          projectName.trim()
        )
          .then(() => {
            setProjectHelperText('')
          })
          .catch((error: any) => {
            isNameAvailable = false
            setDisableInputField(false)
            setLoading(false)
            storeContext.uiStore.setGlobalLoader(false)
            if (error === StringConstants.NAME_ALREADY_TAKEN) {
              setProjectHelperText(
                ValidationConstants.PROJECT.PROJECT_NAME_NOT_AVAILABLE
              )
            } else {
              ToastNotification({
                type: 'error',
                message: ToastMessageConstants.SOMETHING_WENT_WRONG
              })
            }
          })

        reference.current?.stopLoading()
        // exit function, if name is not available
        if (!isNameAvailable) {
          setLoading(false)
          storeContext.uiStore.setGlobalLoader(false)
          return
        }
      }

      reference.current?.startLoading()
      updateProject({
        ...updateObject,
        projectId: projectId as string
      })
        .then((response: any) => {
          ToastNotification({
            type: 'success',
            message: ToastMessageConstants.PROJECT.UPDATE_PROJECT.SUCCESS
          })
          setProjectName(response.data.name)
          if (!projectValidationResult.error) {
            setTeamsWebhookURL(response.data.teamsWebhookURL)
          }
          if (
            response.data.additionalEmails &&
            !projectValidationResult.error
          ) {
            setInitialAdditionalEmails(response.data.additionalEmails)
            setAdditionalEmails(response.data.additionalEmails)
          } else {
            setInitialAdditionalEmails([])
            setAdditionalEmails([])
          }
          setProjectDetails(response.data)
          setDisableInputField(false)

          // update only project name and slug in the scope store
          const projects = cloneDeep(
            storeContext.projectAndEnvironmentStore.getProjectsAndEnvironments()
          )

          const project_ = projects.find(
            (project: any) => project.id === response.data.id
          )
          if (!isUndefined(project_)) {
            project_.name = response.data.name
            project_.slug = response.data.slug
          }
          storeContext.projectAndEnvironmentStore.setProjectsAndEnvironments(
            projects
          )
          setLoading(false)
          reference.current?.stopLoading()
          storeContext.uiStore.setGlobalLoader(false)
          storeContext.scopeStore.setProjects(projects)
        })
        .catch((err: string) => {
          ToastNotification({
            type: 'error',
            message: ToastMessageConstants.PROJECT.UPDATE_PROJECT.ERROR
          })
          setTeamsWebhookURL(null)
          setAdditionalEmails([])
          setDisableInputField(false)
          setLoading(false)
          reference.current?.stopLoading()
          storeContext.uiStore.setGlobalLoader(false)
        })
    } else {
      // No changes to update, stop loaders
      setLoading(false)
      storeContext.uiStore.setGlobalLoader(false)
    }
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value

    if (inputValue.includes('  ')) {
      setConsecutiveSpaceError(true)
    } else {
      setConsecutiveSpaceError(false)
    }

    setProjectName(inputValue)
  }

  const handleDisconnectSlack = () => {
    setIsDisconnecting(true)
    disconnectSlackFromProject(projectId as string)
      .then(() => {
        setProjectDetails((prevDetails: any) => ({
          ...prevDetails,
          isSlackConfigured: false
        }))
        ToastNotification({
          type: 'success',
          message: ToastMessageConstants.PROJECT.SLACK_DISCONNECTION.SUCCESS
        })
      })
      .catch((err: string) => {
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.PROJECT.SLACK_DISCONNECTION.ERROR
        })
      })
      .finally(() => {
        setLoading(false)
        setIsDisconnecting(false)
      })
  }

  const checkAvailability = () => {
    if (projectName.trim() === '') return
    setLoading(true)
    store.uiStore.setGlobalLoader(true)
    checkNameAvailability(
      UrlConstants.CHECK_PROJECT_NAME_AVAILABILITY.USECASE,
      projectName
    )
      .then((response: any) => {
        setIsNameAvailable(true)

        updateSingleProject()
      })
      .catch((error) => {
        if (error === StringConstants.NAME_ALREADY_TAKEN) {
          setIsNameAvailable(false)
        } else {
          ToastNotification({
            type: 'error',
            message: ToastMessageConstants.SOMETHING_WENT_WRONG
          })
        }
        setLoading(false)
        store.uiStore.setGlobalLoader(false)
      })
  }

  const validateInput = (value: string | null) => {
    if (!value || value.trim() === '') {
      return {
        error: false,
        message: ''
      }
    }

    if (!RegexConstants.HOST_ADDRESS_REGEX.test(value)) {
      return {
        error: true,
        message: 'Please enter a valid webhook URL'
      }
    }

    return {
      error: false,
      message: ''
    }
  }

  const handleBlur = () => {
    const validationResult = validateInput(teamsWebhookURL)

    if (!validationResult.error) {
      updateSingleProject()
    }
  }

  useEffect(() => {
    const breadcrumbsOptions = store.breadcrumbStore.getBreadcrumbsOptions()
    const selectedProject = store.scopeStore.getSelectedProject()

    if (breadcrumbsOptions.length === 0 && !isEmpty(projectName)) {
      storeContext.breadcrumbStore.addMultipleBreadCrumbOptions([
        { label: 'Project Settings', link: routeConstants.PROJECT_SETTING },
        { label: projectDetails.name, link: '/' }
      ])
    }

    return () => {
      store.breadcrumbStore.reset()
    }
  }, [projectName, projectDetails, store.breadcrumbStore, store.scopeStore])

  return (
    <>
      {store.uiStore.getGlobalLoader() || loading ? (
        <Spinner />
      ) : (
        <div className='w-full h-full py-[24px]'>
          <div className='flex flex-col gap-[24px]'>
            <DDSTypography.Title
              type='h3'
              variant='semiBold'
              color={pallete.colors.textDark3}
            >
              {SettingConstants.GENERAL}
            </DDSTypography.Title>

            <div className='flex items-start self-stretch gap-[32px]'>
              <div className='relative flex flex-col w-1/2'>
                <DDSTypography.Paragraph
                  size='para'
                  variant='semiBold'
                  color={pallete.colors.textDark3}
                  className='pb-[4px] relative'
                >
                  {SettingConstants.PROJECT_NAME}
                  <span
                    className='absolute top-0  ml-[2px]'
                    style={{ color: pallete.colors.warningRed2 }}
                  >
                    *
                  </span>
                </DDSTypography.Paragraph>
                <Input
                  width='100%'
                  value={projectName}
                  onChange={handleInputChange}
                  onBlur={() => {
                    const trimmedProjectName = projectName.trim()

                    // Perform availability checks only if validation passes
                    if (trimmedProjectName === '') {
                      setProjectHelperText('Project name cannot be empty')
                      setValidationErrorResult(['Project name cannot be empty'])
                    } else if (
                      !RegexConstants.PROJECT_NAME_REGEX.test(
                        trimmedProjectName
                      )
                    ) {
                      setProjectHelperText('Please enter a valid project name')
                      setValidationErrorResult([
                        'Please enter a valid project name'
                      ])
                    } else {
                      setProjectHelperText('')
                      setValidationErrorResult([])
                      if (trimmedProjectName !== projectDetails.name) {
                        checkAvailability()
                        setDeleteProjectName(trimmedProjectName)
                      }
                    }
                  }}
                  labelPosition='top'
                  label={
                    <DDSTypography.Paragraph
                      size='para'
                      variant='regular'
                      color={pallete.colors.textDark6}
                    >
                      {SettingConstants.UNIQUE_NAME}
                    </DDSTypography.Paragraph>
                  }
                  validateInput={true}
                  validate={(value: string) => {
                    const trimmedValue = value.trim()
                    if (trimmedValue === '') {
                      return {
                        error: true,
                        message: 'Project name cannot be empty'
                      }
                    } else if (
                      !RegexConstants.PROJECT_NAME_REGEX.test(trimmedValue)
                    ) {
                      return {
                        error: true,
                        message: 'Please enter a valid project name'
                      }
                    }

                    return {
                      error: false,
                      message: ''
                    }
                  }}
                  id='username-input'
                  name='usernameInput'
                  maxLength={StringConstants.NAME_FIELD_MAX_LENGTH}
                  disabled={
                    storeContext.userStore.getNoActiveSubscription() ||
                    store.userStore.getUserRole() !==
                      OrganizationUserRoles.OWNER ||
                    disableInputField ||
                    loading
                  }
                />

                {isNameAvailable === false && (
                  <div>
                    <DDSTypography.Paragraph
                      size='caption'
                      variant='regular'
                      className='absolute -bottom-5'
                      color={pallete.colors.warningRed2}
                    >
                      Project name is already taken. Please enter a different
                      name
                    </DDSTypography.Paragraph>
                  </div>
                )}
              </div>
              <div className='w-1/2'>
                <DDSTypography.Paragraph
                  size='para'
                  variant='semiBold'
                  color={pallete.colors.textDark3}
                  className='pb-[4px]'
                >
                  {SettingConstants.PROJECT_TYPE}
                </DDSTypography.Paragraph>
                <Input
                  width='100%'
                  onChange={() => {
                    null
                  }}
                  value={
                    projectType
                      ? ProjectTypeDisplayNames[
                          projectType as keyof typeof ProjectTypeDisplayNames
                        ]
                      : '-'
                  }
                  labelPosition='top'
                  label={
                    <DDSTypography.Paragraph
                      size='para'
                      variant='regular'
                      color={pallete.colors.textDark6}
                    >
                      Full-stack / Salesforce / Mulesoft
                    </DDSTypography.Paragraph>
                  }
                  id='input'
                  name='Input'
                  disabled={true}
                />
              </div>
            </div>
          </div>
          <div className='py-[32px]'>
            <hr style={{ color: pallete.colors.stroke2 }} />
          </div>
          <div className='flex flex-col gap-[24px]'>
            <DDSTypography.Title
              type='h3'
              variant='semiBold'
              color={pallete.colors.textDark3}
            >
              {SettingConstants.NOTIFICATIONS}
            </DDSTypography.Title>

            <div className='flex flex-col gap-[12px]'>
              <div>
                <DDSTypography.Paragraph
                  size='para'
                  variant='semiBold'
                  color={pallete.colors.textDark3}
                  className='pb-[4px]'
                >
                  {SettingConstants.SLACK_NOTIFICATIONS}
                </DDSTypography.Paragraph>
                <DDSTypography.Paragraph
                  size='para'
                  variant='regular'
                  color={pallete.colors.textDark6}
                >
                  {SettingConstants.SLACK_NOTIFIED}
                </DDSTypography.Paragraph>
              </div>
              <div className='mb-[32px] w-full'>
                {process.env.REACT_APP_SLACK_INTEGRATION_URL && (
                  <>
                    {projectDetails?.isSlackConfigured ? (
                      // <Tooltip
                      //   id='slack-add'
                      //   label={
                      //     !storeContext.userStore.getNoActiveSubscription() &&
                      //     store.userStore.getUserRole() ===
                      //       OrganizationUserRoles.OWNER
                      //       ? ''
                      //       : storeContext.userStore.getNoActiveSubscription()
                      //         ? 'Please upgrade your plan to add a Slack channel'
                      //         : 'Access to modify restricted to owners only'
                      //   }
                      // >
                      <span className='w-full block'>
                        <Button
                          id='disconnect-slack'
                          type='danger'
                          onClick={handleDisconnectSlack}
                          startIcon={<img src={Slack} alt='Slack icon' />}
                          size='medium'
                          label='Disconnect Slack'
                          disabled={
                            storeContext.userStore.getNoActiveSubscription() ||
                            store.userStore.getUserRole() !==
                              OrganizationUserRoles.OWNER
                          }
                        />
                      </span>
                    ) : (
                      // </Tooltip>
                      // <Tooltip
                      //   id='add-slack'
                      //   label={
                      //     !storeContext.userStore.getNoActiveSubscription() &&
                      //     store.userStore.getUserRole() ===
                      //       OrganizationUserRoles.OWNER
                      //       ? ''
                      //       : storeContext.userStore.getNoActiveSubscription()
                      //         ? 'Please upgrade your plan to add a Slack channel'
                      //         : 'Access to modify restricted to owners only'
                      //   }
                      // >
                      <div
                        className='w-full flex justify-start items-start'
                        style={{ cursor: 'default' }}
                      >
                        <a
                          href={
                            !storeContext.userStore.getNoActiveSubscription() &&
                            store.userStore.getUserRole() ===
                              OrganizationUserRoles.OWNER
                              ? getAddToSlackUrl(
                                  storeContext.scopeStore.getOrganizationId() as string,
                                  projectId as string
                                )
                              : '#'
                          }
                          style={{
                            pointerEvents:
                              !storeContext.userStore.getNoActiveSubscription() &&
                              store.userStore.getUserRole() ===
                                OrganizationUserRoles.OWNER
                                ? 'auto'
                                : 'none',
                            cursor:
                              !storeContext.userStore.getNoActiveSubscription() &&
                              store.userStore.getUserRole() ===
                                OrganizationUserRoles.OWNER
                                ? 'pointer'
                                : 'not-allowed',
                            opacity:
                              !storeContext.userStore.getNoActiveSubscription() &&
                              store.userStore.getUserRole() ===
                                OrganizationUserRoles.OWNER
                                ? 1
                                : 0.5,
                            textDecoration: 'none',
                            display: 'inline-block'
                          }}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          <img
                            alt='Connect Slack'
                            src='https://platform.slack-edge.com/img/add_to_slack.png'
                            srcSet='https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x'
                          />
                        </a>
                      </div>
                      // </Tooltip>
                    )}
                  </>
                )}
                {projectDetails?.isSlackConfigured && (
                  <DDSTypography.Paragraph
                    size='para'
                    variant='medium'
                    color={pallete.colors.textDark3}
                    className='mt-2'
                  >
                    Connected
                    {projectDetails.slackChannelName &&
                      ` to ${projectDetails.slackChannelName}`}
                  </DDSTypography.Paragraph>
                )}
              </div>
            </div>
          </div>

          <div className='flex flex-col gap-[32px]'>
            <div>
              <DDSTypography.Paragraph
                size='para'
                variant='semiBold'
                color={pallete.colors.textDark3}
                className='pb-[4px]'
              >
                {SettingConstants.TEAM}
              </DDSTypography.Paragraph>
              <Input
                width='50%'
                validateInput={true}
                value={teamsWebhookURL ?? ''}
                placeholder='Enter Teams Webhook URL'
                onChange={(e) => {
                  const value = e.target.value

                  const sanitizedValue = value.replace(/^\s+/, '')

                  setTeamsWebhookURL(
                    isEmpty(sanitizedValue) ? null : sanitizedValue
                  )
                }}
                onBlur={handleBlur}
                labelPosition='top'
                errorMessage=''
                label={
                  <DDSTypography.Paragraph
                    size='para'
                    variant='regular'
                    color='textDark6'
                  >
                    {SettingConstants.MICROSOFT_NOTIFIED}
                  </DDSTypography.Paragraph>
                }
                id='teams-webhook-input'
                name='teamsWebhookInput'
                disabled={
                  storeContext.userStore.getNoActiveSubscription() ||
                  disableInputField ||
                  store.userStore.getUserRole() !== OrganizationUserRoles.OWNER
                }
                validationRegex={RegexConstants.HOST_ADDRESS_REGEX}
                validate={validateInput}
              />
            </div>
            <div>
              <DDSTypography.Paragraph
                size='para'
                variant='semiBold'
                color={pallete.colors.textDark3}
                className='pb-[4px]'
              >
                {SettingConstants.ADDITIONAL_EMAIL}
              </DDSTypography.Paragraph>
              <AdditionalEmailInput
                width='50%'
                value={additionalEmails}
                onChange={(e) => {
                  setAdditionalEmails(e.target.value)
                }}
                onBlur={() => {
                  if (!isEqual(additionalEmails, initialAdditionalEmails)) {
                    updateSingleProject()
                  }
                }}
                onUpdate={() => {
                  if (!isEqual(additionalEmails, initialAdditionalEmails)) {
                    updateSingleProject()
                  }
                }}
                validateInput={true}
                labelPosition='top'
                placeholder='Enter Additional emails'
                label={
                  <DDSTypography.Paragraph
                    size='para'
                    variant='regular'
                    color={pallete.colors.textDark6}
                  >
                    {SettingConstants.EMAIL_FROM_VIGIL}
                  </DDSTypography.Paragraph>
                }
                disabled={
                  storeContext.userStore.getNoActiveSubscription() ||
                  store.userStore.getUserRole() !==
                    OrganizationUserRoles.OWNER ||
                  disableInputField ||
                  loading
                }
                validationRegex={RegexConstants.EMAIL_ADDRESS_CHECK}
                validate={(value: string) => {
                  if (!value || value.trim() === '') {
                    return { error: false, message: '' }
                  } else if (!RegexConstants.EMAIL_REGEX.test(value)) {
                    return {
                      error: true,
                      message: 'Please enter a valid email'
                    }
                  } else if (additionalEmails.includes(value.trim())) {
                    return {
                      error: true,
                      message: 'This email is already exists'
                    }
                  }
                  return { error: false, message: '' }
                }}
                id='additionalEmails'
                name='additionalEmails'
              />
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default observer(GeneralProjectSetting)
