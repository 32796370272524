import { CaretDown, CaretUp, ChevronRight, Edit } from '@carbon/icons-react'
import { DDSTooltip, DDSTypography } from 'den-design-system'
import React, { useEffect, useRef, useState } from 'react'
import {
  checkNameAvailabilityFromServer,
  getNoDataMessageForStatus,
  schema,
  statusToColor,
  toTitleCase
} from '../../../utils/JobUtils'
import {
  getJobFilters,
  updateConfiguredJob
} from '../../../api/jobs/Jobs.service'
import { isEmpty, isNull } from 'lodash'
import { useNavigate, useSearchParams } from 'react-router-dom'

import AddEditJobPopUp from './AddEditPopUp'
import CopyButton from './CopyButton'
import Filter from '../../Filter/Filter'
import HttpConstants from '../../../constants/HttpConstants'
import JMStringConstants from '../../../constants/JMStringConstants'
import { Link } from '@mui/material'
import NoFilterImage from '../../../assets/images/folder-not-found 1.svg'
import NoRecord from '../../common/NoContent/NoRecord'
import NoRecordImage from '../../../assets/images/setting.svg'
import { ProjectType } from '../../../enum/ProjectType.enum'
import Search from '../../New/Search/Search'
import Spinner from '../../common/Spinner/Spinner'
import StringConstants from '../../../constants/StringConstants'
import Switch from '../../New/Switch/Switch'
import Table from '../../common/DDS/Table/Table'
import { ToastMessageConstants } from '../../../constants/ToastMessageConstants'
import ToastNotification from '../../common/DDS/Toast/Toast'
import Tooltip from '../../New/Tooltip/Tooltip'
import { addQueryParamsToUrl } from '../../../utils/UrlGenerator'
import { generateRandomId } from '../../../utils/CommonUtils'
import { getAllQueryParamsAsObjectFromUrl } from '../../../utils/UrlUtils'
import { observer } from 'mobx-react-lite'
import pallete from '../../../global/newPallete'
import { timeFormats } from '../../../enum/TIME'
import { timeZone } from '../../../utils/DateTimeUtils'
import { useStoreContext } from '../../../store/StoreContext'

type SortOrder = 'ASC' | 'DESC' | 'CLEAR'
interface jobsProps {
  jobs: any
  totalCount: number
  recordsPresent: boolean
  status?: string
  isLoading: boolean
  findAllJobs: (
    page: number | undefined,
    queryFilters: any,
    search?: any
  ) => void
}
const JMJobsPage: React.FC<jobsProps> = ({
  jobs,
  totalCount,
  recordsPresent,
  status,
  findAllJobs,
  isLoading
}) => {
  const [queryParams, setQueryParams] = useSearchParams()

  const initialPage = parseInt(
    queryParams.get('page') ? (queryParams.get('page') as string) : '1'
  )
  type FilterOption = { id: number; name: string } // Example type

  const [filterCache, setFilterCache] = useState<{
    [key: string]: FilterOption[]
  }>({})
  const [searchTerm, setSearchTerm] = useState('') // Holds the search term entered by the user
  const [showDropdown, setShowDropdown] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const [totalDataCount, setTotalDataCount] = useState<number>(totalCount)
  const [filterLoading, setFilterLoading] = useState<boolean>(false)
  const tableRef = useRef<HTMLDivElement>(null)
  const [tableHeight, setTableHeight] = useState<number | null>(null)
  const [currentPage, setCurrentPage] = useState<number>(initialPage)
  const store = useStoreContext()
  const [searchRecordLength, setSearchRecordLength] = useState<number>(0)
  const [editPopupOpen, setEditPopupOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(isLoading)
  const navigate = useNavigate()
  const [filterOptions, setFilterOptions] = useState([])
  const [isButtonDisabled, setIsButtonDisabled] = useState(false)
  const [buttonLoading, setButtonLoading] = useState(false)
  const [originalJob, setOriginalJob] = useState<any>({})
  const [selectedJob, setSelectedJob] = useState<any>({})
  const [isInvalidJobName, setIsInvalidJobName] = useState(false)
  const [isInvalidJobSlug, setIsInvalidJobSlug] = useState(false)
  const [initialLoad, setInitialLoad] = useState(true)

  const projectType = store.scopeStore.getSelectedProject().type
  const isFullStackProject = projectType === ProjectType.FULL_STACK_ONLY
  const isSalesforceProject = projectType === ProjectType.SALESFORCE_ONLY
  const isSearchTriggered = useRef(false)

  const rowDisabled = useRef(false)
  const [activeSortOrder, setActiveSortOrder] = useState<{
    field: string | null
    order: SortOrder | null
  }>({
    field: null,
    order: null
  })
  const isSortTriggered = useRef(false)

  useEffect(() => {
    if (tableRef.current) {
      setTableHeight(tableRef.current.clientHeight)
    }
  }, [jobs, loading])
  useEffect(() => {
    // Current project and environment IDs from the store
    const currentProjectId = store.scopeStore.getScope()['project-id']
    const currentEnvId = store.scopeStore.getScope()['environment-id']

    // Extract project and environment IDs from the URL
    const urlParts = location.pathname.split('/')
    const urlProjectId = urlParts[urlParts.indexOf('projects') + 1]
    const urlEnvId = urlParts[urlParts.indexOf('environments') + 1]

    // Check if the project or environment has changed
    if (currentProjectId !== urlProjectId || currentEnvId !== urlEnvId) {
      setSearchTerm('')
    }
  }, [location.pathname])

  useEffect(() => {
    if (
      selectedJob &&
      selectedJob.name &&
      selectedJob.slug &&
      selectedJob.cronExpression
    ) {
      const validated = schema.validate(
        {
          name: selectedJob.name.trim(),
          slug: selectedJob.slug.trim(),
          cronExpression: selectedJob.cronExpression,
          description: selectedJob.description
        },
        {
          abortEarly: false
        }
      )

      const areJobsEqual =
        originalJob &&
        selectedJob &&
        originalJob.name === selectedJob.name &&
        originalJob.slug === selectedJob.slug &&
        originalJob.cronExpression === selectedJob.cronExpression &&
        originalJob.description === selectedJob.description &&
        originalJob.monitorJob === selectedJob.monitorJob

      if (validated.error || areJobsEqual) {
        setIsButtonDisabled(true)
      } else {
        setIsButtonDisabled(false)
      }
    } else {
      setIsButtonDisabled(true)
    }
  }, [selectedJob, originalJob, schema])

  useEffect(() => {
    setLoading(isLoading)
    setTotalDataCount(totalCount)
  }, [totalCount, isLoading])

  useEffect(() => {
    if (store.filterStore.getFilterDisabled()) {
      store.filterStore.setFilterDisabled(false)
    }
  }, [store.filterStore.getFilterDisabled()])

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const urlSortField = searchParams.get('sortField')
    const urlSortOrder = searchParams.get('sortOrder')
    if (urlSortField && urlSortOrder) {
      // Initialize the sort state from URL
      setActiveSortOrder({
        field: urlSortField,
        order: urlSortOrder as SortOrder
      })
    }
  }, [location.search])

  const loadFilters = (key: string, apiCall: () => void) => {
    if (filterCache[key]) {
      setFilterCache((prev) => ({
        ...prev,
        [key]: filterCache[key] // This now matches the type
      }))
    } else {
      apiCall()
    }
  }

  const fetchJobFilters = () => {
    setFilterLoading(true)
    if (store.scopeStore.getScope()['environment-id']) {
      getJobFilters()
        .then((response: any) => {
          store.jobStore.setCallJobLogFilter(false)
          setFilterOptions(response.data)
          setFilterCache((prev) => ({
            ...prev,
            fetchJobFilters: response.data
          }))
        })
        .catch((err) => {
          store.jobStore.setCallJobLogFilter(false)
          ToastNotification({
            type: 'error',
            message: ToastMessageConstants.JOB_MONITORING.JOB_FILTERS.ERROR
          })
        })
        .finally(() => {
          setFilterLoading(false)
        })
    }
  }

  const handleFilterLoadUrl = () =>
    loadFilters('fetchExceptionFilters', fetchJobFilters)

  useEffect(() => {
    const allQueryParams = getAllQueryParamsAsObjectFromUrl(location.search)
    if (isSortTriggered.current) {
      isSortTriggered.current = false
      return
    }

    const pageFromQueryParams = searchParams.get('page')
    if (!isNull(pageFromQueryParams)) {
      setCurrentPage(Number(pageFromQueryParams))
    } else {
      const allQueryParams = getAllQueryParamsAsObjectFromUrl(location.search)
      setSearchParams({ ...allQueryParams })
      setCurrentPage(1)
    }

    const queryParams = getAllQueryParamsAsObjectFromUrl(location.search)

    if (searchTerm === '') {
      delete queryParams['search-string']
      delete queryParams['search-type']
    }
    if (!allQueryParams['search-string']) {
      setSearchTerm('')
    }

    const requestParams = {
      ...queryParams,
      size: StringConstants.DEFAULT_TABLE_SIZE,
      ...(status && { 'previous-run-status': status }),
      ...(allQueryParams['search-string'] && {
        'search-string': allQueryParams['search-string'],
        'search-type': allQueryParams['search-type'] || 'any'
      })
    }

    if (allQueryParams['search-string']) {
      setSearchTerm(allQueryParams['search-string'])
    }

    fetchJobRecords(requestParams)
  }, [searchParams, store.scopeStore.getScope()])

  useEffect(() => {
    if (!initialLoad) {
      if (selectedJob && selectedJob.name !== undefined) {
        if (selectedJob.name === '') {
          setSelectedJob((prevState: any) => ({ ...prevState, slug: '' }))
        } else {
          setSelectedJob((prevState: any) => ({
            ...prevState,
            slug: prevState.name
              .toLowerCase()
              .replace(/-/g, '')
              .replace(/_/g, '-')
              .replace(/[^a-z0-9\s-]/g, '')
              .replace(/\s+/g, '-')
          }))
        }
      }
    } else {
      setInitialLoad(false)
    }
  }, [selectedJob.name])

  const handleMonitorToggle = (value: boolean, id: string) => {
    const payload = {
      monitorJob: value,
      $jobId: id as string
    }
    const allQueryParams = getAllQueryParamsAsObjectFromUrl(location.search)
    setLoading(true)
    store.uiStore.setGlobalLoader(true)
    updateConfiguredJob(payload)
      .then(() => {
        ToastNotification({
          type: 'success',
          message: ToastMessageConstants.JOB_MONITORING.UPDATE_JOB.SUCCESS
        })
        store.exceptionStore.setRefresh(false)
        if (status) {
          fetchJobRecords({
            ...allQueryParams,
            ...searchParams,
            size: StringConstants.DEFAULT_TABLE_SIZE,
            page: currentPage,
            ['previous-run-status']: status
          })
        } else {
          fetchJobRecords({
            ...allQueryParams,
            ...searchParams,
            size: StringConstants.DEFAULT_TABLE_SIZE,
            page: currentPage
          })
        }
      })
      .catch(() =>
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.JOB_MONITORING.UPDATE_JOB.ERROR
        })
      )
      .finally(() => {
        setLoading(false)
      })
  }

  const handleSubmitEditedJob = () => {
    setButtonLoading(true)
    const nameCheckPromises = []

    if (!isEmpty(selectedJob.name) && originalJob.name !== selectedJob.name) {
      nameCheckPromises.push(
        checkNameAvailabilityFromServer(selectedJob.name.trim())
      )
    }

    if (!isEmpty(selectedJob.slug) && originalJob.slug !== selectedJob.slug) {
      nameCheckPromises.push(
        checkNameAvailabilityFromServer(selectedJob.slug.trim(), true)
      )
    }

    if (nameCheckPromises.length > 0) {
      Promise.allSettled(nameCheckPromises)
        .then((results) => {
          let success = true

          results.forEach((result, index) => {
            if (result.status !== 'fulfilled') {
              success = false
              const isNameCheck =
                index === 0 &&
                !isEmpty(selectedJob.name) &&
                originalJob.name !== selectedJob.name
              const isSlugCheck =
                index === 0 &&
                !isEmpty(selectedJob.slug) &&
                originalJob.slug !== selectedJob.slug

              if (isNameCheck) {
                if (result.reason === StringConstants.NAME_ALREADY_TAKEN) {
                  setIsInvalidJobName(true)
                } else {
                  ToastNotification({
                    type: 'error',
                    message: ToastMessageConstants.SOMETHING_WENT_WRONG
                  })
                }
              } else if (isSlugCheck) {
                if (result.reason === StringConstants.SLUG_ALREADY_TAKEN) {
                  setIsInvalidJobSlug(true)
                } else {
                  ToastNotification({
                    type: 'error',
                    message: ToastMessageConstants.SOMETHING_WENT_WRONG
                  })
                }
              }
            }
          })

          if (success) {
            if (selectedJob) {
              editJob()
            }
          } else {
            setButtonLoading(false)
          }
        })
        .catch((err) => {
          ToastNotification({
            type: 'error',
            message: ToastMessageConstants.SOMETHING_WENT_WRONG
          })
          setButtonLoading(false)
        })
    } else {
      editJob()
    }
  }

  const resetCheckNameValidation = () => {
    setIsInvalidJobSlug(false)
    setIsInvalidJobName(false)
  }
  const editJob = () => {
    setEditPopupOpen(false)
    setLoading(true)
    store.uiStore.setGlobalLoader(true)
    const payload = {
      name: selectedJob.name.trim(),
      slug: selectedJob.slug.trim(),
      description: selectedJob.description,
      cronExpression: selectedJob.cronExpression,
      monitorJob: selectedJob.monitorJob,
      $jobId: selectedJob.id as string
    }
    const allQueryParams = getAllQueryParamsAsObjectFromUrl(location.search)
    updateConfiguredJob(payload)
      .then(() => {
        ToastNotification({
          type: 'success',
          message: ToastMessageConstants.JOB_MONITORING.UPDATE_JOB.SUCCESS
        })
        resetCheckNameValidation()
        if (status) {
          fetchJobRecords({
            ...allQueryParams,
            ...searchParams,
            size: StringConstants.DEFAULT_TABLE_SIZE,
            page: currentPage,
            ['previous-run-status']: status
          })
        } else {
          fetchJobRecords({
            ...allQueryParams,
            ...searchParams,
            size: StringConstants.DEFAULT_TABLE_SIZE,
            page: currentPage
          })
        }
      })
      .catch(() => {
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.JOB_MONITORING.UPDATE_JOB.ERROR
        })
        setButtonLoading(false)
        store.uiStore.setGlobalLoader(false)
        setLoading(false)
      })
      .finally(() => {
        setButtonLoading(false)
        store.uiStore.setGlobalLoader(false)
        setLoading(false)
      })
  }

  const onJobPageChange = (page: number) => {
    setCurrentPage(page)
    const allQueryParams = getAllQueryParamsAsObjectFromUrl(location.search)

    setSearchParams({
      ...allQueryParams,
      page: page.toString()
    })
  }

  const handleStartClick = () => {
    setSelectedJob((prevJob: any) => ({
      ...prevJob,
      monitorJob: true
    }))
  }

  const handleStopClick = () => {
    setSelectedJob((prevJob: any) => ({
      ...prevJob,
      monitorJob: false
    }))
  }

  const cancelEdit = () => {
    resetCheckNameValidation()
    setEditPopupOpen(false)
  }

  const handleInputChange = (field: string, value: string) => {
    resetCheckNameValidation()
    setSelectedJob({ ...selectedJob, [field]: value })
  }

  const fetchJobRecords = (queryParams: any) => {
    store.uiStore.setGlobalLoader(true)
    const searchParams = new URLSearchParams(location.search)
    const urlSortField = searchParams.get('sortField')
    const urlSortOrder = searchParams.get('sortOrder')

    setLoading(true)
    const params: any = {
      ...queryParams
    }
    if (urlSortField && urlSortOrder && urlSortOrder !== 'CLEAR') {
      params[urlSortField] = urlSortOrder
    }
    findAllJobs(currentPage, params)
  }

  const sortData = (sortField: string, sortOrder: SortOrder) => {
    const allQueryParams = getAllQueryParamsAsObjectFromUrl(location.search)
    store.uiStore.setGlobalLoader(true)
    setLoading(true)
    const params: any = {
      ...allQueryParams,
      ...searchParams, // Convert searchParams to an object
      size: StringConstants.DEFAULT_TABLE_SIZE,
      page: currentPage,
      'expand-activity': true,
      ...(status !== undefined && { 'previous-run-status': status })
    }

    if (searchTerm === '') {
      delete params['search-string']
      delete params['search-type']
    }
    Object.keys(params).forEach((key) => {
      if (key.startsWith('sort-')) {
        delete params[key]
      }
    })

    if (sortOrder !== 'CLEAR') {
      params[sortField] = sortOrder
      const searchParams = new URLSearchParams(location.search)
      searchParams.set('sortField', sortField)
      searchParams.set('sortOrder', sortOrder)
      window.history.replaceState({}, '', `?${searchParams.toString()}`)
    } else {
      delete params[sortField]
      // Remove sort params from URL
      const searchParams = new URLSearchParams(location.search)
      searchParams.delete('sortField')
      searchParams.delete('sortOrder')
      window.history.replaceState({}, '', `?${searchParams.toString()}`)
    }
    setSearchParams(params)
    isSortTriggered.current = true
    findAllJobs(currentPage, params)
  }

  const handleSortCycle = (sortField: string) => {
    // Determine the next order synchronously
    let nextOrder: SortOrder = 'ASC'
    if (activeSortOrder.field === sortField) {
      nextOrder =
        activeSortOrder.order === 'ASC'
          ? 'DESC'
          : activeSortOrder.order === 'DESC'
            ? 'CLEAR'
            : 'ASC'
    }

    // Update the sort state and call sortData immediately
    setActiveSortOrder({
      field: nextOrder !== 'CLEAR' ? sortField : null,
      order: nextOrder
    })

    sortData(sortField, nextOrder)
  }

  const handleSearchEnter = async (searchValue: string) => {
    if (!searchValue || searchValue.trim() === '') {
      return
    }

    setLoading(true)
    store.uiStore.setGlobalLoader(true)

    const allQueryParams = getAllQueryParamsAsObjectFromUrl(location.search)
    const params = {
      ...allQueryParams,
      'search-string': searchValue,
      'search-type': 'any',
      ...(status !== undefined && { 'previous-run-status': status }),
      page: 1,
      size: StringConstants.DEFAULT_TABLE_SIZE
    }

    setSearchParams({ ...params })
    setCurrentPage(1)

    try {
      await findAllJobs(currentPage, params, 'true')
    } catch (error) {
      console.error(error)
    } finally {
      setShowDropdown(false)
      store.uiStore.setGlobalLoader(false)
      setLoading(false)
    }
  }

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.slice(0, 50)
    const allQueryParams = getAllQueryParamsAsObjectFromUrl(location.search)

    if (value === '' && allQueryParams['search-string']) {
      setSearchTerm('')
      setShowDropdown(false)

      const params = {
        ...allQueryParams,

        ...(status !== undefined && { 'previous-run-status': status }),
        size: StringConstants.DEFAULT_TABLE_SIZE,
        page: currentPage
      }

      delete params['search-string']
      delete params['search-type']

      setLoading(true)
      store.uiStore.setGlobalLoader(true)

      Promise.resolve(findAllJobs(currentPage, params)).finally(() => {
        store.uiStore.setGlobalLoader(false)
        setLoading(false)
      })

      setSearchParams({ ...params })
      setCurrentPage(currentPage)
      return
    }

    const isValidInput = /^[\w\s!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]*$/.test(
      value
    )
    if (!isValidInput) {
      return
    }

    setSearchTerm(value)
    setShowDropdown(value.trim() ? true : false)
  }

  const handleOptionSelect = (searchString: string, searchType: string) => {
    setLoading(true)
    store.uiStore.setGlobalLoader(true)

    const allQueryParams = getAllQueryParamsAsObjectFromUrl(location.search)
    const params = {
      ...allQueryParams,
      'search-string': searchString,
      'search-type': searchType,
      ...(status !== undefined && { 'previous-run-status': status }),
      page: 1,
      size: StringConstants.DEFAULT_TABLE_SIZE
    }

    Promise.resolve(findAllJobs(currentPage, params, 'true')).finally(() => {
      store.uiStore.setGlobalLoader(false)
      setLoading(false)
    })

    setSearchParams({ ...params })
    setCurrentPage(1) // Updates URL with selected option params
    setShowDropdown(false)
  }

  const columns = [
    {
      columnHeaderStyle: {},
      columnDataStyle: { width: '130px' },
      heading: (
        <div className='flex whitespace-nowrap space-x-[8px] items-center'>
          <div>
            <DDSTypography.Paragraph
              size='caption'
              variant='semiBold'
              color={pallete.colors.textDark6}
              className='cursor-default'
            >
              {JMStringConstants.JOB_NAME_HEADER}
            </DDSTypography.Paragraph>
          </div>
          <div
            className='flex flex-col justify-center -space-y-[6px]'
            onClick={() => handleSortCycle('sort-job-name')}
          >
            <CaretUp
              size={12}
              style={{
                color:
                  activeSortOrder.field === 'sort-job-name' &&
                  activeSortOrder.order === 'ASC'
                    ? pallete.colors.FillButton
                    : activeSortOrder.field === 'sort-job-name' &&
                        activeSortOrder.order === 'DESC'
                      ? pallete.colors.outButtonDis
                      : pallete.colors.textDark6
              }}
              className='cursor-pointer'
            />
            <CaretDown
              size={12}
              style={{
                color:
                  activeSortOrder.field === 'sort-job-name' &&
                  activeSortOrder.order === 'ASC'
                    ? pallete.colors.outButtonDis
                    : activeSortOrder.field === 'sort-job-name' &&
                        activeSortOrder.order === 'DESC'
                      ? pallete.colors.FillButton
                      : pallete.colors.textDark6
              }}
              className='cursor-pointer'
            />
          </div>
        </div>
      ),
      dataLabel: 'name',
      render: (text: any, fullItem: any) => (
        <div className='flex justify-start text-left max-w-[100px]'>
          <Tooltip
            id={`tooltip-name-${text}`}
            label={text ? text : '-'}
            className={`${
              typeof text === 'string' && text.length > 10 ? text : '!hidden'
            }`}
          >
            <DDSTypography.Paragraph
              size='para'
              variant='medium'
              className='truncate max-w-[100px]'
              color={pallete.colors.textDark4}
            >
              {text ? text : '-'}
            </DDSTypography.Paragraph>
          </Tooltip>
        </div>
      )
    },
    {
      columnHeaderStyle: {},
      columnDataStyle: { width: '130px' },
      heading: (
        <div className='flex whitespace-nowrap space-x-[8px] items-center'>
          <div>
            <DDSTypography.Paragraph
              size='caption'
              variant='semiBold'
              color={pallete.colors.textDark6}
              className='cursor-default'
            >
              {JMStringConstants.JOB_SLUG_HEADER}
            </DDSTypography.Paragraph>
          </div>
          <div
            className='flex flex-col justify-center -space-y-[6px]'
            onClick={() => handleSortCycle('sort-slug')}
          >
            <CaretUp
              size={12}
              style={{
                color:
                  activeSortOrder.field === 'sort-slug' &&
                  activeSortOrder.order === 'ASC'
                    ? pallete.colors.FillButton
                    : activeSortOrder.field === 'sort-slug' &&
                        activeSortOrder.order === 'DESC'
                      ? pallete.colors.outButtonDis
                      : pallete.colors.textDark6
              }}
              className='cursor-pointer'
            />
            <CaretDown
              size={12}
              style={{
                color:
                  activeSortOrder.field === 'sort-slug' &&
                  activeSortOrder.order === 'ASC'
                    ? pallete.colors.outButtonDis
                    : activeSortOrder.field === 'sort-slug' &&
                        activeSortOrder.order === 'DESC'
                      ? pallete.colors.FillButton
                      : pallete.colors.textDark6
              }}
              className='cursor-pointer'
            />
          </div>
        </div>
      ),
      dataLabel: 'slug',
      render: (text: any, fullItem: any) => (
        <div className='flex justify-start text-left gap-[12px] max-w-[80px]'>
          <Tooltip
            id={`'tooltip-id-${text}`}
            label={text ? text : '-'}
            className={`${
              typeof text === 'string' && text.length > 10 ? text : '!hidden'
            }`}
          >
            <div className=''>
              <DDSTypography.Paragraph
                size='para'
                variant='medium'
                className='truncate max-w-[80px]'
                color={pallete.colors.textDark4}
              >
                {text ? text : '-'}
              </DDSTypography.Paragraph>
            </div>
          </Tooltip>
          <div
            onClick={() => {
              rowDisabled.current = true
              setTimeout(() => {
                rowDisabled.current = false
              }, 2000)
            }}
          >
            <CopyButton copyText={text} />
          </div>
        </div>
      )
    },
    {
      columnHeaderStyle: {},
      columnDataStyle: { width: '130px' },
      heading: (
        <div className='flex whitespace-nowrap justify-center text-center space-x-[8px] items-center'>
          <div>
            <DDSTypography.Paragraph
              size='caption'
              variant='semiBold'
              color={pallete.colors.textDark6}
              className='cursor-default'
            >
              {JMStringConstants.LATEST_CLIENT_VERSION}
            </DDSTypography.Paragraph>
          </div>
          <div
            className='flex flex-col justify-center text-center -space-y-[6px]'
            onClick={() => handleSortCycle('sort-client-version')}
          >
            <CaretUp
              size={12}
              style={{
                color:
                  activeSortOrder.field === 'sort-client-version' &&
                  activeSortOrder.order === 'ASC'
                    ? pallete.colors.FillButton
                    : activeSortOrder.field === 'sort-client-version' &&
                        activeSortOrder.order === 'DESC'
                      ? pallete.colors.outButtonDis
                      : pallete.colors.textDark6
              }}
              className='cursor-pointer'
            />
            <CaretDown
              size={12}
              style={{
                color:
                  activeSortOrder.field === 'sort-client-version' &&
                  activeSortOrder.order === 'ASC'
                    ? pallete.colors.outButtonDis
                    : activeSortOrder.field === 'sort-client-version' &&
                        activeSortOrder.order === 'DESC'
                      ? pallete.colors.FillButton
                      : pallete.colors.textDark6
              }}
              className='cursor-pointer'
            />
          </div>
        </div>
      ),
      dataLabel: 'latestClientVersion',
      render: (text: any, fullItem: any) => (
        <div className='flex justify-center text-center'>
          <DDSTypography.Paragraph
            size='para'
            variant='medium'
            className=''
            color={pallete.colors.textDark4}
          >
            {text ? text : '-'}
          </DDSTypography.Paragraph>
        </div>
      )
    },

    {
      columnHeaderStyle: {},
      columnDataStyle: { width: '130px' },
      heading: (
        <div className='flex whitespace-nowrap justify-center text-center space-x-[8px] items-center'>
          <div>
            <DDSTypography.Paragraph
              size='caption'
              variant='semiBold'
              color={pallete.colors.textDark6}
              className='cursor-default'
            >
              {JMStringConstants.CREATED_BY_HEADER}
            </DDSTypography.Paragraph>
          </div>
          <div
            className='flex flex-col justify-center text-center -space-y-[6px]'
            onClick={() => handleSortCycle('sort-created-by')}
          >
            <CaretUp
              size={12}
              style={{
                color:
                  activeSortOrder.field === 'sort-created-by' &&
                  activeSortOrder.order === 'ASC'
                    ? pallete.colors.FillButton
                    : activeSortOrder.field === 'sort-created-by' &&
                        activeSortOrder.order === 'DESC'
                      ? pallete.colors.outButtonDis
                      : pallete.colors.textDark6
              }}
              className='cursor-pointer'
            />
            <CaretDown
              size={12}
              style={{
                color:
                  activeSortOrder.field === 'sort-created-by' &&
                  activeSortOrder.order === 'ASC'
                    ? pallete.colors.outButtonDis
                    : activeSortOrder.field === 'sort-created-by' &&
                        activeSortOrder.order === 'DESC'
                      ? pallete.colors.FillButton
                      : pallete.colors.textDark6
              }}
              className='cursor-pointer'
            />
          </div>
        </div>
      ),
      dataLabel: 'createdByUser',
      render: (item: any, fullItem: any) => (
        <div className='flex justify-center text-center'>
          <DDSTypography.Paragraph
            size='para'
            variant='medium'
            className='truncate'
            color={pallete.colors.textDark4}
          >
            {item.firstName}
          </DDSTypography.Paragraph>
        </div>
      )
    },
    {
      columnHeaderStyle: {},
      columnDataStyle: { width: '130px' },
      heading: (
        <div className='flex whitespace-nowrap justify-center text-center space-x-[8px] items-center'>
          <div>
            <DDSTypography.Paragraph
              size='caption'
              variant='semiBold'
              color={pallete.colors.textDark6}
              className='cursor-default'
            >
              {JMStringConstants.CREATED_ON_HEADER}
            </DDSTypography.Paragraph>
          </div>
          <div
            className='flex flex-col justify-center text-center -space-y-[6px]'
            onClick={() => handleSortCycle('sort-created-at')}
          >
            <CaretUp
              size={12}
              style={{
                color:
                  activeSortOrder.field === 'sort-created-at' &&
                  activeSortOrder.order === 'ASC'
                    ? pallete.colors.FillButton
                    : activeSortOrder.field === 'sort-created-at' &&
                        activeSortOrder.order === 'DESC'
                      ? pallete.colors.outButtonDis
                      : pallete.colors.textDark6
              }}
              className='cursor-pointer'
            />
            <CaretDown
              size={12}
              style={{
                color:
                  activeSortOrder.field === 'sort-created-at' &&
                  activeSortOrder.order === 'ASC'
                    ? pallete.colors.outButtonDis
                    : activeSortOrder.field === 'sort-created-at' &&
                        activeSortOrder.order === 'DESC'
                      ? pallete.colors.FillButton
                      : pallete.colors.textDark6
              }}
              className='cursor-pointer'
            />
          </div>
        </div>
      ),
      dataLabel: 'createdAt',
      render: (text: any, fullItem: any) => (
        <div className='flex justify-center text-center'>
          <DDSTypography.Paragraph
            size='para'
            variant='medium'
            className='truncate'
            color={pallete.colors.textDark4}
          >
            {timeZone(
              store.userStore.getUserPreference().timeZone,
              text ? text : '-',
              timeFormats.fullMonthDateYearTime
            )}
          </DDSTypography.Paragraph>
        </div>
      )
    },
    {
      columnHeaderStyle: {},
      columnDataStyle: { width: '130px' },
      heading: (
        <div className='flex row justify-center  text-center items-center'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
            className='cursor-default'
          >
            {JMStringConstants.MONITORING_HEADER}
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'monitorJob',
      render: (active: any, fullItem: any) => (
        <div className='flex justify-center text-center'>
          <Switch
            id='monitor-switch'
            value={active}
            disabled={store.userStore.getNoActiveSubscription()}
            onChange={(value) => {
              rowDisabled.current = true
              setTimeout(() => {
                rowDisabled.current = false
              }, 2000)
              handleMonitorToggle(value, fullItem['id'])
            }}
          />
        </div>
      )
    },
    {
      columnHeaderStyle: {},
      columnDataStyle: { width: '130px' },
      heading: (
        <div className='flex row justify-center  text-center items-center'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
            className='cursor-default'
          >
            {JMStringConstants.RECENT_STATUS_HEADER}
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'previousRunStatus',
      render: (text: any, fullItem: any) =>
        text ? (
          <div
            className='flex justify-center rounded-[4px] text-center px-[12px] py-[4px] items-center'
            style={{ backgroundColor: statusToColor(text) }}
            onClick={() => {
              rowDisabled.current = true
              setTimeout(() => {
                rowDisabled.current = false
              }, 2000)
            }}
          >
            <DDSTypography.Paragraph
              size='para'
              variant='medium'
              className='truncate'
              color={pallete.colors.surface1}
            >
              {toTitleCase(text)}
            </DDSTypography.Paragraph>
          </div>
        ) : (
          <div className='flex justify-center  text-center px-[12px] py-[4px] items-center'>
            <DDSTypography.Paragraph
              size='para'
              variant='medium'
              className='truncate'
              color={pallete.colors.textDark4}
            >
              {'-'}
            </DDSTypography.Paragraph>
          </div>
        )
    },

    {
      columnHeaderStyle: { width: '5%' },
      columnDataStyle: { width: '5%' },
      heading: '',
      dataLabel: '',
      render: (text: any, fullItem: any) => (
        <div
          className={`flex justify-center cursor-pointer ${
            store.userStore.getNoActiveSubscription() &&
            'cursor-not-allowed pointer-events-none opacity-50'
          }`}
        >
          <Edit
            size={16}
            color={pallete.colors.textDark4}
            onClick={() => {
              rowDisabled.current = true
              setTimeout(() => {
                rowDisabled.current = false
              }, 2000)
              setSelectedJob(fullItem)
              setOriginalJob(fullItem)
              setInitialLoad(true)
              setEditPopupOpen(true)
            }}
          />
        </div>
      )
    },
    {
      columnHeaderStyle: { width: '5%' },
      columnDataStyle: { width: '5%' },
      heading: '',
      dataLabel: '',
      render: (text: any, fullItem: any) => (
        <div className='flex justify-center cursor-pointer'>
          <ChevronRight
            size={16}
            color={pallete.colors.primary2}
            onClick={() => {
              store.jobStore.setSelectedJob([fullItem['id']])
              const url = addQueryParamsToUrl(
                './' + fullItem['id'],
                {
                  page: '1',
                  tab: '1',
                  previousTab: (queryParams.get('tab') as string) ?? '1'
                },
                HttpConstants.GET_METHOD
              )
              navigate(url)
            }}
          />
        </div>
      )
    }
  ]

  const routeJob = (id: any) => {
    if (!rowDisabled.current) {
      store.jobStore.setSelectedJob([id])
      const url = addQueryParamsToUrl(
        './' + id,
        {
          page: '1',
          tab: '1',
          previousTab: (queryParams.get('tab') as string) ?? '1'
        },
        HttpConstants.GET_METHOD
      )
      navigate(url)
    }
  }

  if (isLoading || store.uiStore.getGlobalLoader()) return <Spinner />
  return (
    <div className='flex flex-col gap-[16px] pt-[16px] pb-[20px]'>
      <div className='relative w-full'>
        {/* Search input field */}
        <Search
          id='search'
          name='Search'
          placeholder='Search'
          value={searchTerm}
          onChange={handleSearchChange}
          autoComplete='off'
          width='32vw'
          disabled={loading}
          onPressEnter={() => handleSearchEnter(searchTerm)}
          onBlur={() => {
            setTimeout(() => setShowDropdown(false), 200) // Hide dropdown after blur
          }}
        />

        {showDropdown && (
          <div
            className='absolute z-[99999] mt-[12px] flex flex-col bg-white border items-start p-[12px] rounded-md w-[32vw] shadow-lg'
            style={{ borderColor: pallete.colors.stroke2 }}
          >
            <div
              className={`flex p-[8px] gap-[8px] items-center cursor-pointer `}
              onClick={() => handleOptionSelect(searchTerm, 'name')}
            >
              <DDSTooltip
                id={generateRandomId()}
                label={searchTerm}
                className={`border override-tooltip-arrow max-w-[90%] break-all ${
                  typeof searchTerm === 'string' && searchTerm.length > 38
                    ? searchTerm
                    : '!hidden'
                }`}
                theme='light'
                style={{
                  borderColor: pallete.colors.stroke2,
                  zIndex: 99999
                }}
              >
                <div className='flex flex-row  gap-[8px]'>
                  <DDSTypography.Paragraph
                    size='para'
                    variant='medium'
                    color={pallete.colors.textDark4}
                    className='flex-start truncate  w-[290px] cursor-default'
                  >
                    {searchTerm}
                  </DDSTypography.Paragraph>
                  <DDSTypography.Paragraph
                    size='para'
                    variant='medium'
                    color={pallete.colors.textDark6}
                  >
                    in name column
                  </DDSTypography.Paragraph>
                </div>
              </DDSTooltip>
            </div>
            <div
              className='flex p-[8px] gap-[8px] items-center cursor-pointer'
              onClick={() => handleOptionSelect(searchTerm, 'slug')}
            >
              <DDSTooltip
                id={generateRandomId()}
                label={searchTerm}
                className={`border override-tooltip-arrow max-w-[90%] break-all ${
                  typeof searchTerm === 'string' && searchTerm.length > 38
                    ? searchTerm
                    : '!hidden'
                }`}
                theme='light'
                style={{
                  borderColor: pallete.colors.stroke2,
                  zIndex: 99999
                }}
              >
                <div className='flex flex-row  gap-[8px]'>
                  <DDSTypography.Paragraph
                    size='para'
                    variant='medium'
                    color={pallete.colors.textDark4}
                    className='flex-start truncate  w-[290px] cursor-default'
                  >
                    {searchTerm}
                  </DDSTypography.Paragraph>
                  <DDSTypography.Paragraph
                    size='para'
                    variant='medium'
                    color={pallete.colors.textDark6}
                  >
                    in slug column
                  </DDSTypography.Paragraph>
                </div>
              </DDSTooltip>
            </div>
            <div
              className='flex p-[8px] gap-[8px] items-center cursor-pointer'
              onClick={() => handleOptionSelect(searchTerm, 'any')}
            >
              <DDSTooltip
                id={generateRandomId()}
                label={searchTerm}
                className={`border override-tooltip-arrow max-w-[90%] break-all ${
                  typeof searchTerm === 'string' && searchTerm.length > 38
                    ? searchTerm
                    : '!hidden'
                }`}
                theme='light'
                style={{
                  borderColor: pallete.colors.stroke2,
                  zIndex: 99999
                }}
              >
                <div className='flex flex-row  gap-[8px]'>
                  <DDSTypography.Paragraph
                    size='para'
                    variant='medium'
                    color={pallete.colors.textDark4}
                    className='flex-start truncate  w-[290px] cursor-default'
                  >
                    {searchTerm}
                  </DDSTypography.Paragraph>
                  <DDSTypography.Paragraph
                    size='para'
                    variant='medium'
                    color={pallete.colors.textDark6}
                  >
                    in all column
                  </DDSTypography.Paragraph>
                </div>
              </DDSTooltip>
            </div>
          </div>
        )}
      </div>

      <div className='flex items-center custom-filter justify-end absolute right-0 top-[65px] space-x-[12px]'>
        <Filter
          dropdownLabel='Filter'
          onLoadOption={fetchJobFilters}
          dropdownLoader={filterLoading}
          disableDeleteChip={false}
          options={filterOptions}
          isScrollable={true}
          disabled={
            (searchTerm !== '' &&
              totalCount === 0 &&
              !store.filterStore.getFiltersApplied()) ||
            (searchTerm === '' &&
              totalCount === 0 &&
              !store.filterStore.getFiltersApplied()) ||
            (totalDataCount === 0 && !store.filterStore.getFiltersApplied()) ||
            loading ||
            filterLoading
          }
        />
      </div>
      {jobs && totalDataCount > 0 ? (
        <Table
          id='all-logs-table'
          tableStyle={{
            borderColor: pallete.colors.stroke2,
            cursor: 'pointer'
          }}
          data={jobs}
          columns={columns}
          totalCount={totalDataCount}
          rowsPerPage={10}
          currentPage={currentPage}
          onPageChange={(page: number) => {
            onJobPageChange(page)
            setCurrentPage(page)
          }}
          clickType='Job'
          clickable={routeJob}
          loading={store.uiStore.getGlobalLoader()}
        />
      ) : searchRecordLength === 0 && searchTerm != '' ? (
        <NoRecord
          style={{ height: '70vh' }}
          imageSrc={NoFilterImage}
          text='No results found'
        />
      ) : (
        <NoRecord
          style={{ height: '70vh' }}
          imageSrc={
            !recordsPresent
              ? NoRecordImage
              : store.filterStore.getFiltersApplied()
                ? NoFilterImage
                : NoFilterImage
          }
          text={
            !recordsPresent ? (
              <>
                {isFullStackProject
                  ? 'Configure Job Monitoring.'
                  : 'Start Monitoring APEX Jobs'}
                <Link
                  href={
                    isFullStackProject
                      ? StringConstants.FULLSTACK_URL
                      : isSalesforceProject
                        ? StringConstants.SALESFORCE_URL
                        : StringConstants.MULESOFT
                  }
                  target='_blank'
                  rel='noopener noreferrer'
                  style={{ marginLeft: '4px' }}
                >
                  Learn more
                </Link>{' '}
              </>
            ) : store.filterStore.getFiltersApplied() ? (
              StringConstants.NO_RECORDS_FILTER
            ) : (
              <>{getNoDataMessageForStatus(status || '')}</>
            )
          }
        />
      )}

      <AddEditJobPopUp
        open={editPopupOpen}
        setOpen={setEditPopupOpen}
        isAdd={false}
        loading={loading}
        onSubmit={handleSubmitEditedJob}
        onCancel={cancelEdit}
        buttonLoading={buttonLoading}
        buttonDisabled={isButtonDisabled}
        values={{
          name: selectedJob.name,
          slug: selectedJob.slug,
          cronExpression: selectedJob.cronExpression,
          description: selectedJob.description,
          monitorJob: selectedJob.monitorJob
        }}
        isInvalidJobName={isInvalidJobName}
        isInvalidJobSlug={isInvalidJobSlug}
        handleInputChange={handleInputChange}
        handleMonitorStart={handleStartClick}
        handleMonitorStop={handleStopClick}
      />
    </div>
  )
}

export default observer(JMJobsPage)
