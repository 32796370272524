import React, { useEffect } from 'react'
import { Renew, Time } from '@carbon/icons-react'
import {
  getAllQueryParamsAsObjectFromUrl,
  replaceUrl
} from '../../../utils/UrlUtils'

import AllAPITab from '../../../components/APIMonitoring/AllAPITab/AllAPITab'
import ApiGroupingStringConstants from '../../../constants/ApiGroupingStringConstants'
import { Button } from '../../../components/New/Button/Button'
import { DDSTypography } from 'den-design-system'
import DocumentHeader from '../../../components/Helmet/DocumentHeader'
import GroupedAPITab from '../../../components/APIMonitoring/GroupedAPITab/GroupedAPITab'
import { HeaderConstants } from '../../../constants/HeaderConstants'
import StringConstants from '../../../constants/StringConstants'
import Tabs from '../../../components/common/DDS/Tabs/Tabs'
import Timezone from '../../../components/common/Timezone/Timezone'
import TimezoneConstants from '../../../constants/TimezoneConstants'
import Tooltip from '../../../components/New/Tooltip/Tooltip'
import { gaPageConstants } from '../../../constants/GAConstants'
import { observer } from 'mobx-react-lite'
import pallete from '../../../global/newPallete'
import routeConstants from '../../../constants/RouteConstants'
import { useAnalyticsEventTracker } from '../../../utils/GAUtils'
import { useSearchParams } from 'react-router-dom'
import { useStoreContext } from '../../../store/StoreContext'

const APIGroupedAll: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const store = useStoreContext()
  const [tabIndex, setTabIndex] = React.useState<string>(
    searchParams.get('tab') ?? '1'
  )
  const organizationPreference =
    store.userStore.getOrganizationPreference()?.timeZone
  const matchedTimeZone = TimezoneConstants.find(
    (tz) => tz?.tzCode === organizationPreference
  )
  const gaEventTracker = useAnalyticsEventTracker(
    gaPageConstants.REFRESH_API_GROUPED_ALL
  )

  const tabs = [
    {
      label:
        tabIndex === '1' ? (
          <DDSTypography.Title
            type='h5'
            variant='semiBold'
            color={pallete.colors.primary2}
          >
            Grouped APIs
          </DDSTypography.Title>
        ) : (
          <DDSTypography.Title
            type='h5'
            variant='medium'
            color={pallete.colors.textDark5}
          >
            Grouped APIs
          </DDSTypography.Title>
        ),
      children: (
        <div>
          <GroupedAPITab groupedAPITabIndex={tabIndex} />
        </div>
      )
    },
    {
      label:
        tabIndex === '2' ? (
          <DDSTypography.Title
            type='h5'
            variant='semiBold'
            color={pallete.colors.primary2}
          >
            All APIs
          </DDSTypography.Title>
        ) : (
          <DDSTypography.Title
            type='h5'
            variant='medium'
            color={pallete.colors.textDark5}
          >
            All APIs
          </DDSTypography.Title>
        ),
      children: (
        <div>
          <AllAPITab allAPITabIndex={tabIndex} />
        </div>
      )
    }
  ]

  useEffect(() => {
    localStorage.setItem(
      StringConstants.ACTIVE_TAB,
      StringConstants.TAB_VALUES.API_MONITORING
    )
    const tabFromParams = searchParams.get('tab')
    const allQueryParams = getAllQueryParamsAsObjectFromUrl(location.search)
    if (tabFromParams) {
      setTabIndex(tabFromParams.toString())
    } else {
      setSearchParams({ ...allQueryParams, tab: '1' })
    }
    if (tabFromParams === '1') {
      store.breadcrumbStore.setBreadcrumbsOptions({
        label: ApiGroupingStringConstants.GROUPED_API,
        link:
          replaceUrl(
            routeConstants.API_MONITORING,
            store.scopeStore.getScope()['organization-id'] as string,
            store.scopeStore.getScope()['project-id'] as string,
            store.scopeStore.getScope()['environment-id'] as string
          ) + '?tab=1'
      })
    } else if (tabFromParams === '2') {
      store.breadcrumbStore.setBreadcrumbsOptions({
        label: ApiGroupingStringConstants.ALL_API,
        link:
          replaceUrl(
            routeConstants.API_MONITORING,
            store.scopeStore.getScope()['organization-id'] as string,
            store.scopeStore.getScope()['project-id'] as string,
            store.scopeStore.getScope()['environment-id'] as string
          ) + '?tab=2'
      })
    }
  }, [store.breadcrumbStore.getBreadcrumbsOptions(), tabIndex])

  return (
    <>
      <DocumentHeader header={HeaderConstants.API_MONITORING} />
      <div className='flex flex-col w-full'>
        <div className='relative w-full flex justify-between'>
          <div
            style={{
              width: '100%',
              display: 'flex'
            }}
            className='tab-child'
          >
            <Tabs
              tabOptionsStyle={{
                minWidth: 'fit-content',
                width: 'auto',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '8px 12px',
                marginRight: '8px'
              }}
              tabOptions={tabs}
              id='tabs-grouped-api'
              onChange={(index: any) => {
                if (tabIndex != index) {
                  setTabIndex(index.toString())
                  setSearchParams({
                    ...searchParams,
                    tab: index.toString(),
                    contextSwitcherTab:
                      searchParams.get('contextSwitcherTab') ?? '1'
                  })
                }
              }}
              activeTab={tabIndex}
              disabled={store.uiStore.getGlobalLoader()}
            />
          </div>

          <div className=''>
            <div className='absolute right-[50px] top-[2px]'>
              <Timezone
                tooltipText='Time zone is based on organization settings'
                text={
                  <div>
                    {matchedTimeZone?.tzCode}
                    {''}
                    {''} {matchedTimeZone?.label}
                  </div>
                }
                textColor={pallete.colors.neuButtonText}
              />
            </div>
            <div className='absolute right-0'>
              <Tooltip id='refresh-tooltip' label='Refresh' position='top'>
                <Button
                  id='outline-button'
                  type='neutral'
                  disabled={
                    store.apiDetailsStore.getRefresh() ||
                    store.uiStore.getGlobalLoader()
                  }
                  onClick={() => {
                    gaEventTracker(gaPageConstants.REFRESH_API_GROUPED_ALL)
                    store.apiDetailsStore.setRefresh(true)
                  }}
                  startIcon={<Renew color={pallete.colors.neuButtonText} />}
                />
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default observer(APIGroupedAll)
