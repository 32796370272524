import * as ExceptionTypes from './Exception.types'

import {
  ExceptionManagementGroupStatisticsData,
  ExceptionManagementGroupedData
} from '../../types/store.types'

import ApiManager from '../Api.manager'
import AwesomeDebouncePromise from 'awesome-debounce-promise'
import { DebounceConstants } from '../../constants/DebounceConstants'
import { FilterTypes } from '../../enum/Filter.enum'
import HttpConstants from '../../constants/HttpConstants'
import UrlConstants from '../../constants/UrlConstants'
import { getFiltersForApi } from '../../utils/FilterUtils'

/**
 *
 * @param queryParams - filters, page, size and other queries
 * @returns data-array of exceptions
 */
export const getAllExceptions = (queryParams: any) => {
  const filters = getFiltersForApi(FilterTypes.ERROR_MONITORING, queryParams)

  const usecase = UrlConstants.GET_ALL_EXCEPTIONS.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.GET_METHOD,
      { ...filters, includeCancelToken: true }
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          throw response.data
        }
      })
      .catch((error) => {
        console.log(`Error in ${usecase}:`, error)
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data.error.message
        )
      })
  })
}

export const getExceptionsTags = (queryParams?: any) => {
  const filters = getFiltersForApi(FilterTypes.ERROR_MONITORING, queryParams)

  const usecase = UrlConstants.GET_EXCEPTIONS_TAGS.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.GET_METHOD,

      { ...filters, includeCancelToken: true }
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          throw response.data
        }
      })
      .catch((error) => {
        console.log(`Error in ${usecase}:`, error)
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data.error.message
        )
      })
  })
}

export const deleteExceptionsTags = (payload: { $exceptionTagId: string }) => {
  const usecase = UrlConstants.DELETE_EXCEPTIONS_TAGS.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.DELETE_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          throw response.data
        }
      })
      .catch((error) => {
        console.log(`Error in ${usecase}:`, error)
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data.error.message
        )
      })
  })
}

/**
 *
 * @returns Filters for exception management
 */
export const getExceptionFilters = () => {
  const usecase = UrlConstants.GET_EXCEPTION_FILTERS.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.GET_METHOD,
      {}
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else throw response
      })
      .catch((error) => {
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data.error.message
        )
      })
  })
}

/**
 *
 * @returns Filters for grouped exception logs
 */
export const getGroupedExceptionsFilters = () => {
  const usecase = UrlConstants.GET_GROUPED_EXCEPTIONS_FILTERS.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.GET_METHOD,
      {}
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else throw response
      })
      .catch((error) => {
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data.error.message
        )
      })
  })
}

/**
 *
 * @param payload ExceptionLogId - fetched from the url
 * @returns data for a exception
 */
export const getAnException = (payload: { $exceptionLogId: string }) => {
  const usecase = UrlConstants.GET_AN_EXCEPTION.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.GET_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response)
        } else {
          throw response
        }
      })
      .catch((error) => {
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data.error
        )
      })
  })
}

/**
 *
 * @param payload {exceptionStatus, assignedTo,tags, severity, notifyFrequency, isBookmarked, isPublic, $exceptionLogId}
 * @returns updated Exception data
 */
export const updateAnException = async (
  payload: ExceptionTypes.UpdateExceptionPayload
) => {
  const usecase = UrlConstants.UPDATE_AN_EXCEPTION.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.PATCH_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data.error.message
        )
      })
  })
}

/**
 *
 * @param payload {exceptionStatus, assignedTo, severity}
 * @param queryParams - exceptionStatus, assignedTo, severity
 * @returns updated Exception data
 */
export const updateExceptionGroup = async (
  payload: ExceptionTypes.UpdateExceptionGroupPayload
) => {
  const usecase = UrlConstants.UPDATE_EXCEPTION_GROUP.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.PATCH_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data.error.message
        )
      })
  })
}

/**
 *
 * @param payload {exceptionStatus, assignedTo, severity}
 * @param queryParams - exceptionStatus, assignedTo, severity
 * @returns updated Exception data
 */
export const updateExceptionGrouped = async (
  payload: ExceptionTypes.UpdateExceptionGroupedPayload
) => {
  const usecase = UrlConstants.UPDATE_AN_EXCEPTION_GROUPED.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.PATCH_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data.error.message
        )
      })
  })
}

/**
 * This Api is used to delete an Exception Log
 * @param payload Exception Log Id
 * @returns
 */
export const deleteExceptionLog = (payload: { $exceptionLogId: string }) => {
  const usecase = UrlConstants.DELETE_AN_EXCEPTION.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.DELETE_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in ${usecase}:`, error)
        reject(error.data.message)
      })
  })
}

/**
 *
 * @param payload ExceptionLogId - fetched from the url
 * @returns Activity Data for an exception
 */
export const getAllCommentForException = (payload: {
  $exceptionLogId: string
}) => {
  const usecase = UrlConstants.GET_ALL_ACTIVITIES.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.GET_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response)
        } else {
          throw response
        }
      })
      .catch((error) => {
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data.error.message
        )
      })
  })
}

/**
 *
 * @param payload  ExceptionLogId,comment:string,threadId?:string
 * @returns The added comment
 */
export const addComment = (payload: ExceptionTypes.AddComment) => {
  const usecase = UrlConstants.ADD_A_COMMENT.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.POST_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 201) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data?.error?.message
        )
      })
  })
}

/**
 *
 * @param payload ExceptionLogId,ExceptionCommentId,comment:string
 * @returns The Updated comment
 */
export const updateComment = async (payload: ExceptionTypes.UpdateComment) => {
  const usecase = UrlConstants.UPDATE_A_COMMENT.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.PATCH_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data?.error?.message
        )
      })
  })
}

/**
 *
 * @param payload ExceptionLogId,ExceptionCommentId
 * @returns
 */
export const deleteComment = (payload: ExceptionTypes.DeleteComment) => {
  const usecase = UrlConstants.DELETE_A_COMMENT.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.DELETE_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in ${usecase}:`, error)
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data?.error?.message
        )
      })
  })
}

export const viewAllGroupedExceptions = (
  payload: any
): Promise<{ data: ExceptionManagementGroupedData[]; totalCount: number }> => {
  const filters = getFiltersForApi(FilterTypes.ERROR_MONITORING, payload)
  const usecase = UrlConstants.VIEW_ALL_GROUPED_EXCEPTIONS.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.GET_METHOD,
      { ...filters, includeCancelToken: true }
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in ${usecase}:`, error)
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data.error.message
        )
      })
  })
}

const getExceptionSearchContextsAPI = (
  query: string,
  queryParams: any
): Promise<{ data: string[] }> => {
  const usecase = UrlConstants.GET_EXCEPTION_SEARCH_CONTEXTS.USECASE
  const filters = getFiltersForApi(FilterTypes.ERROR_MONITORING, queryParams)
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.GET_METHOD,
      { query, ...filters, includeCancelToken: true }
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in ${usecase}:`, error)
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data.error.message
        )
      })
  })
}

/**
 * @param queryParams - exceptionType, exceptionStatus, assignedTo, severity,
 * @returns statistics of a particular grouped exception
 */

export const getStatisticsOfAnExceptionGroup = (
  payload: any
): Promise<{ data: ExceptionManagementGroupStatisticsData }> => {
  const usecase = UrlConstants.GET_STATISTICS_OF_EXCEPTION_GROUP.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.GET_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in ${usecase}:`, error)
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data.error.message
        )
      })
  })
}

export const downloadGroupedExceptions = (payload: any) => {
  const filters = getFiltersForApi(FilterTypes.ERROR_MONITORING, payload)
  const usecase = UrlConstants.DOWNLOAD_GROUPED_EXCEPTIONS.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.GET_METHOD,
      filters
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in ${usecase}:`, error)
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data.error.message
        )
      })
  })
}
export const downloadAllExceptions = (payload: any) => {
  const usecase = UrlConstants.DOWNLOAD_ALL_EXCEPTIONS.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.GET_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in ${usecase}:`, error)
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data.error.message
        )
      })
  })
}

/**
 * @param queryParams - exceptionType, exceptionStatus, assignedTo, severity,
 * @returns statistics of a particular grouped exception
 */
export const getStatisticsOfAnExceptionGroupByToken = (
  payload: any
): Promise<{ data: ExceptionManagementGroupStatisticsData }> => {
  const usecase =
    UrlConstants.GET_STATISTICS_OF_EXCEPTION_GROUP_BY_TOKEN.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCall(usecase, HttpConstants.GET_METHOD, payload)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        if (error.status === 400) {
          reject(error.data.error.message)
        } else if (error.status === 401 || error.status === 403) {
          reject(error.data.message)
        } else {
          reject(error.data)
        }
      })
  })
}

export const getStatisticsOfAnExceptionGroupByGroupId = (payload: {
  $exceptionGroupId: string
}): Promise<{ data: ExceptionManagementGroupStatisticsData }> => {
  const usecase =
    UrlConstants.GET_STATISTICS_OF_EXCEPTION_GROUP_BY_GROUP_ID.USECASE

  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.GET_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        if (error.status === 400) {
          reject(error.data.error.message)
        } else if (error.status === 401 || error.status === 403) {
          reject(error.data.message)
        } else {
          reject(error.data)
        }
      })
  })
}
/**
 *
 * @param payload token - fetched from the url
 * @returns data for a exception
 */
export const getAnExceptionByToken = (payload: { $token: string }) => {
  const usecase = UrlConstants.GET_AN_EXCEPTION_BY_TOKEN.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCall(usecase, HttpConstants.GET_METHOD, payload)
      .then((response) => {
        if (response.status === 200) {
          resolve(response)
        } else {
          throw response
        }
      })
      .catch((error) => {
        if (error.status === 400) {
          reject(error.data.error.message)
        } else if (error.status === 401 || error.status === 403) {
          reject(error.data.message)
        } else {
          reject(error.data)
        }
      })
  })
}

export const getExceptionLogsOfGroup = (payload: any) => {
  const filters = getFiltersForApi(FilterTypes.ERROR_MONITORING, payload)
  const usecase = UrlConstants.GET_EXCEPTIONS_OF_AN_EXCEPTION_GROUP.USECASE
  const { exceptionGroupId, ...rest } = payload
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.GET_METHOD,
      { ...rest, $exceptionGroupId: exceptionGroupId, filters }
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        if (error.status === 400) {
          reject(error.data.error.message)
        } else if (error.status === 401 || error.status === 403) {
          reject(error.data.message)
        } else {
          reject(error.data)
        }
      })
  })
}

export const updateSelectedExceptions = (payload: any) => {
  const usecase = UrlConstants.UPDATE_SELECTED_EXCEPTIONS.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.PATCH_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        if (error.status === 400) {
          reject(error.data.error.message)
        } else if (error.status === 401 || error.status === 403) {
          reject(error.data.message)
        } else {
          reject(error.data)
        }
      })
  })
}

export const deleteSelectedExceptions = (payload: any) => {
  const usecase = UrlConstants.DELETE_SELECTED_EXCEPTIONS.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.DELETE_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        if (error.status === 400) {
          reject(error.data.error.message)
        } else if (error.status === 401 || error.status === 403) {
          reject(error.data.message)
        } else {
          reject(error.data)
        }
      })
  })
}

export const viewSingleExceptionGorup = (payload: any) => {
  const usecase = UrlConstants.VIEW_SINGLE_GROUPED_EXCEPTION.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.GET_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        if (error.status === 400) {
          reject(error.data.error.message)
        } else if (error.status === 401 || error.status === 403) {
          reject(error.data.message)
        } else {
          reject(error.data)
        }
      })
  })
}

export const debouncedExceptionSearchContextsAPI = AwesomeDebouncePromise(
  getExceptionSearchContextsAPI,
  DebounceConstants.LOOK_UP.DELAY,
  DebounceConstants.LOOK_UP.OPTIONS
)

//Exception Escalation

/**
 *
 * @returns escalation sets of the environment
 */
export const getAllExceptionEscalationSets: any = () => {
  const usecase = UrlConstants.GET_ALL_EXCEPTION_ESCALATION_SETS.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.GET_METHOD,
      {}
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          throw response.data
        }
      })
      .catch((error) => {
        console.log(`Error in ${usecase}:`, error)
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data.error.message
        )
      })
  })
}

/**
 *
 * @returns escalation set with the given id
 */
export const getExceptionEscalationSetById: any = (
  $escalationSetId: string
) => {
  const usecase = UrlConstants.GET_AN_EXCEPTION_ESCALATION_SET.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.GET_METHOD,
      { $escalationSetId }
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          throw response.data
        }
      })
      .catch((error) => {
        console.log(`Error in ${usecase}:`, error)
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data.error.message
        )
      })
  })
}
/**
 *
 * @returns created escalation set
 */
export const createExceptionEscalationSet: any = (payload: any) => {
  const usecase = UrlConstants.CREATE_EXCEPTION_ESCALATION_SET.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.POST_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 201) {
          resolve(response.data)
        } else {
          throw response.data
        }
      })
      .catch((error) => {
        console.log(`Error in ${usecase}:`, error)
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data.error.message
        )
      })
  })
}

/**
 *
 * @param payload {  severity, $escalationSetId }
 * @returns updated exception escalation set
 */
export const updateAnExceptionEscalationSet = async (
  payload: ExceptionTypes.UpdateEscalationSetPayload
) => {
  const usecase = UrlConstants.UPDATE_AN_EXCEPTION_ESCALATION_SET.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.PATCH_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data.error.message
        )
      })
  })
}

export const deleteAnExceptionEscalationSet = ($escalationSetId: string) => {
  const usecase = UrlConstants.DELETE_AN_EXCEPTION_ESCALATION_SET.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.DELETE_METHOD,
      { $escalationSetId }
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        if (error.status === 400) {
          reject(error.data.error.message)
        } else if (error.status === 401 || error.status === 403) {
          reject(error.data.message)
        } else {
          reject(error.data)
        }
      })
  })
}

export const createAnExceptionEscalationRule: any = (payload: any) => {
  const usecase = UrlConstants.CREATE_AN_EXCEPTION_ESCALATION_RULE.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.POST_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 201) {
          resolve(response.data)
        } else {
          throw response.data
        }
      })
      .catch((error) => {
        console.log(`Error in ${usecase}:`, error)
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data.error.message
        )
      })
  })
}

export const updateAnExceptionEscalationRule = (payload: any) => {
  const usecase = UrlConstants.UPDATE_AN_EXCEPTION_ESCALATION_RULE.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.PATCH_METHOD,
      payload
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        reject(
          [401, 403].includes(error.status)
            ? error.data.message
            : error.data.error.message
        )
      })
  })
}

export const deleteManyExceptionEscalationRules = (ids: string[]) => {
  const usecase = UrlConstants.DELETE_MANY_EXCEPTION_ESCALATION_RULES.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCallWithAuthentication(
      usecase,
      HttpConstants.DELETE_METHOD,
      { ids }
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        if (error.status === 400) {
          reject(error.data.error.message)
        } else if (error.status === 401 || error.status === 403) {
          reject(error.data.message)
        } else {
          reject(error.data)
        }
      })
  })
}
