const FilterConstants = {
  JOB: [
    'page',
    'monitor-job',
    'search-type',
    'search-string',
    'sort-job-name',
    'sort-slug',
    'sort-client-version',
    'sort-created-by',
    'sort-created-at',
    'created-at',
    'created-from',
    'created-by',
    'created-till',
    'name-like',
    'description-like',
    'cron-like',
    'created-to',
    'previous-run-status',
    'sort',
    'time-zone'
  ],
  JOB_LOG: [
    'status',
    'sort-start-time',
    'sort-stop-time',
    'end-date',
    'start-date',
    'started-till',
    'started-from',
    'stopped-from',
    'stopped-till',
    'time-zone',
    'page'
  ],
  API_MONITORING: [
    'sort-start-time',
    'sort-end-time',
    'sort-last-call-date',
    'sort-url-template',
    'sort-api-count',
    'sort-average-response-time',
    'sort-http-method',
    'sort-end-point',
    'sort-response-status',
    'sort-client-version',
    'sort-response-time',
    'sort-grouped-url-template',
    'sort-grouped-http-method',
    'sort-grouped-api-count',
    'sort-grouped-average-response-time',
    'sort-grouped-user-api-count',
    'sort-total-api-calls',
    'sort-user-view-name',
    'sort-url-user-name',
    'sort-user-count',
    'sort-total-end-points',
    'sort-user-view-user-name',
    'sort-user-response-status',
    'sort-url',
    'created-from',
    'created-to',
    'response-status-code',
    'page',
    'size',
    'url-like',
    'view',
    'url-template',
    'http-method',
    'current-date',
    'current-date-graph',
    'date-range',
    'date',
    'client-version',
    'year',
    'month',
    'week',
    'day',
    'users',
    'sort',
    'time-zone',
    'api-group-id',
    'search-text',
    'is-success',
    'response-status-code'
  ],
  PROJECT: [
    'sort-by-name',
    'sort-created-at',
    'created-from',
    'created-to',
    'page',
    'size',
    'expand-created-user',
    'created-by',
    'time-zone'
  ],
  ENVIRONMENT: [
    'sort-by-name',
    'sort-created-at',
    'created-from',
    'created-to',
    'created-by',
    'page',
    'size',
    'type',
    'expand-created-user',
    'expand-project',
    'time-zone'
  ],
  EXISTING_MEMBERS: ['role', 'page', 'size', 'expand-user', 'time-zone'],
  ERROR_MONITORING: [
    'status',
    'is-bookmarked',
    'exception-status',
    'exception-type',
    'is-public',
    'notify-frequency',
    'severity',
    'sort-created-at',
    'assigned-to',
    'search-type',
    'search-string',
    'created-from',
    'created-to',
    'updated-from',
    'updated-to',
    'expand-activity',
    'page',
    'size',
    'exception-type-like',
    'exception-message-like',
    'sort',
    'time-zone',
    'sort-exception-group-type',
    'sort-latest-reported-time',
    'sort-exception-count',
    'sort-preferred-severity',
    'sort-all-exception-type',
    'sort-all-exception-message',
    'sort-all-exception-client-version',
    'sort-all-reported-time',
    'sort-all-preferred-severity',
    'sort-exception-status',
    'sort-log-exception-type',
    'sort-log-exception-message',
    'sort-log-exception-client-version',
    'sort-log-reported-time',
    'sort-log-preferred-severity',
    'sort-log-exception-status'
  ],
  INVITED_MEMBERS: [
    'sort-by-inviter',
    'sort-by-invitee',
    'role',
    'page',
    'size',
    'status',
    'time-zone'
  ],
  INCIDENT_MANAGEMENT: ['page', 'size'],
  AHC: [
    'page',
    'created-from',
    'created-to',
    'previous-check-status',
    'search-type',
    'search-string',
    'name-like',
    'url-like',
    'sort',
    'time-zone',
    'sort-check-name',
    'sort-url',
    'sort-availability',
    'sort-average-response-time'
  ],
  AHC_LOGS: [
    'created-from',
    'created-to',
    'app-health-status',
    'sort-created-at',
    'time-zone'
  ],
  NOTIFICATIONS: [
    'page',
    'size',
    'created-from',
    'created-to',
    'status',
    'time-zone'
  ],
  SEARCH_QUERY: [
    'name-like',
    'description-like',
    'cron-like',
    'url-like',
    'exception-type-like',
    'exception-message-like',
    'name-like'
  ]
}

export default FilterConstants
