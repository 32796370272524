import React, { useEffect, useState } from 'react'
import {
  downloadAPIGroupedURLView,
  downloadAPIGroupedUserView,
  fetchGroupedApiRecords,
  fetchGroupedApiUserRecords,
  getAPIGroupFilters
} from '../../../api/apiMonitoring/ApiMonitoring.service'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { Button } from '../../New/Button/Button'
import ContextSwitcherTabs from '../../common/DDS/ContextSwitcherTab/ContextSwitcherTab'
import { Download } from '@carbon/icons-react'
import Filter from '../../Filter/Filter'
import SideChip from '../../New/SideChip/SideChip'
import StringConstants from '../../../constants/StringConstants'
import { ToastMessageConstants } from '../../../constants/ToastMessageConstants'
import ToastNotification from '../../common/DDS/Toast/Toast'
import URLView from './Screen/URLView'
import UserView from './Screen/UserView'
import { convertJsonToCsvDownload } from '../../../utils/FileUtils'
import { convertToFormat } from '../../../utils/UCUtils'
import { getAllQueryParamsAsObjectFromUrl } from '../../../utils/UrlUtils'
import { getOrgTimeRange } from '../../../utils/DateTimeUtils'
import moment from 'moment'
import { observer } from 'mobx-react-lite'
import pallete from '../../../global/newPallete'
import { useStoreContext } from '../../../store/StoreContext'

type SortOrder = 'ASC' | 'DESC' | 'CLEAR'
type SortState = null | SortOrder | 'CLEAR'

interface GroupedAPITabProps {
  groupedAPITabIndex: string
}

const GroupedAPITab: React.FC<GroupedAPITabProps> = ({
  groupedAPITabIndex
}) => {
  const store = useStoreContext()
  const navigate = useNavigate()
  const [currentPage, setCurrentPage] = useState<number>(1)
  const pageSize = StringConstants.DEFAULT_TABLE_SIZE
  const [activeSortOrder, setActiveSortOrder] = useState<{
    field: string | null
    order: SortOrder | null
  }>({
    field: null,
    order: null
  })

  const [groupedApiTableData, setGroupedApiTableData] = useState<any>()
  const [groupedUserApiTableData, setGroupedUserApiTableData] = useState<any>()
  const [totalCount, setTotalCount] = useState<number>(0)
  const [dateFilter, setDateFilter] = useState<any>({})
  const [dateUserFilter, setDateUserFilter] = useState<any>({})
  const [filterCache, setFilterCache] = useState<{ [key: string]: any[] }>({})
  const [searchParams, setSearchParams] = useSearchParams()
  const [tabIndex, setTabIndex] = useState<string>(
    searchParams.get('contextSwitcherTab') ?? '1'
  )
  const [totalUserCount, setTotalUserCount] = useState<number>(0)
  const userTimeZone = store.userStore.getUserPreference().timeZone
  const organizationTimeZone =
    store.userStore.getOrganizationPreference()?.timeZone

  const [filterLoading, setFilterLoading] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [options, setOptions] = useState<any>([])

  const handleApiData = (sortField?: string, sortOrder?: SortOrder) => {
    const queryParams = getAllQueryParamsAsObjectFromUrl(location.search)
    const searchParams = new URLSearchParams(location.search)
    store.uiStore.setGlobalLoader(true)
    setLoading(true)

    // Handle sorting parameters
    const params: any = {
      ...queryParams,
      size: pageSize
    }

    if (sortField && sortOrder) {
      // Handle explicit sort request
      if (sortOrder !== 'CLEAR') {
        params[sortField] = sortOrder
        // Update URL with sort params
        searchParams.set('sortField', sortField)
        searchParams.set('sortOrder', sortOrder)
        window.history.replaceState({}, '', `?${searchParams.toString()}`)
      } else {
        // Clear sorting
        delete params[sortField]
        searchParams.delete('sortField')
        searchParams.delete('sortOrder')
        window.history.replaceState({}, '', `?${searchParams.toString()}`)
      }
    } else {
      // Handle existing URL sort parameters
      const urlSortField = searchParams.get('sortField')
      const urlSortOrder = searchParams.get('sortOrder')

      if (urlSortField && urlSortOrder && urlSortOrder !== 'CLEAR') {
        params[urlSortField] = urlSortOrder
      }
    }

    const timeZone = store.userStore.getOrganizationPreference()?.timeZone
    const { startOfMonth, endOfCurrentDayTimestamp } = getOrgTimeRange(timeZone)

    const paramsWithDateRange = {
      ...params,
      'created-from': queryParams['created-from'] || startOfMonth,
      'created-to': queryParams['created-to'] || endOfCurrentDayTimestamp,
      'time-zone': timeZone
    }

    // Fetch data
    fetchGroupedApiRecords({ ...paramsWithDateRange, size: pageSize })
      .then((response: any) => {
        setGroupedApiTableData(response.data)
        store.apiDetailsStore.setRefresh(false)
        setTotalCount(response.totalCount)

        // Handle progress data update
        if (response.totalCount >= 1) {
          const progressData = store.thingsToDoStore.getProgressData()
          if (!progressData.apiMonitoring) {
            const updatedProgressData = {
              ...progressData,
              apiMonitoring: true
            }

            const newProgressCount = Object.entries(updatedProgressData).reduce(
              (count, [key, value]) =>
                typeof value === 'boolean' && value ? count + 1 : count,
              0
            )

            updatedProgressData.progress = newProgressCount
            store.thingsToDoStore.setProgressData(updatedProgressData)
          }
        }

        const filters = {
          'created-from': moment
            .tz(response.filters?.['created-from'] || startOfMonth, timeZone)
            .toISOString(),
          'created-to': moment
            .tz(
              response.filters?.['created-to'] || endOfCurrentDayTimestamp,
              timeZone
            )
            .toISOString(),
          'time-zone': timeZone
        }

        store.filterStore.setFilters(response.filters)
        setDateFilter(filters)

        // Update active sort order if sorting was applied
        if (sortField && sortOrder) {
          setActiveSortOrder({
            field: sortField,
            order: sortOrder
          })
        }
      })
      .catch((error) => {
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.API_MONITORING.FETCH_DETAILS.ERROR
        })
      })
      .finally(() => {
        setLoading(false)
        store.uiStore.setGlobalLoader(false)
      })
  }

  function groupedApiData() {
    const queryParams = getAllQueryParamsAsObjectFromUrl(location.search)
    const searchParams = new URLSearchParams(location.search)
    // Retrieve sort parameters from URL
    const urlSortField = searchParams.get('sortField')
    const urlSortOrder = searchParams.get('sortOrder')
    store.uiStore.setGlobalLoader(true)
    setLoading(true)

    const timeZone = store.userStore.getOrganizationPreference()?.timeZone

    const nowInOrgTimeZone = moment.tz(timeZone)
    const startOfMonth = nowInOrgTimeZone.clone().startOf('month').toISOString()
    const endOfCurrentDay = nowInOrgTimeZone.clone().endOf('day').toISOString()

    const params: any = {
      ...queryParams,
      size: pageSize
    }

    if (urlSortField && urlSortOrder && urlSortOrder !== 'CLEAR') {
      params[urlSortField] = urlSortOrder
    }

    const paramsWithDateRange = {
      ...params,
      'created-from': queryParams['created-from'] || startOfMonth,
      'created-to': queryParams['created-to'] || endOfCurrentDay,
      'time-zone': timeZone
    }

    fetchGroupedApiRecords({ ...paramsWithDateRange, size: pageSize })
      .then((response: any) => {
        setGroupedApiTableData(response.data)
        store.apiDetailsStore.setRefresh(false)
        setTotalCount(response.totalCount)

        if (response.totalCount >= 1) {
          const progressData = store.thingsToDoStore.getProgressData()

          if (!progressData.apiMonitoring) {
            const updatedProgressData = {
              ...progressData,
              apiMonitoring: true
            }

            const newProgressCount = Object.entries(updatedProgressData).reduce(
              (count, [key, value]) =>
                typeof value === 'boolean' && value ? count + 1 : count,
              0
            )

            updatedProgressData.progress = newProgressCount

            store.thingsToDoStore.setProgressData(updatedProgressData)
          }
        }

        if (
          response.filters?.['created-to'] &&
          moment
            .tz(response.filters['created-to'], timeZone)
            .isAfter(nowInOrgTimeZone)
        ) {
          response.filters['created-to'] = nowInOrgTimeZone
            .startOf('second')
            .toISOString()
        }

        const filters = {
          'created-from': moment
            .tz(response.filters?.['created-from'] || startOfMonth, timeZone)
            .toISOString(),
          'created-to': moment
            .tz(response.filters?.['created-to'] || endOfCurrentDay, timeZone)
            .toISOString(),
          'time-zone': timeZone
        }

        store.filterStore.setFilters(response.filters)
        setDateFilter(filters)
        if (urlSortField && urlSortOrder) {
          setActiveSortOrder({
            field: urlSortField,
            order: urlSortOrder as SortOrder
          })
        }
      })
      .catch((error) => {
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.API_MONITORING.FETCH_DETAILS.ERROR
        })
      })
      .finally(() => {
        setLoading(false)
        store.uiStore.setGlobalLoader(false)
      })
  }

  const sortData = (sortField: string, sortOrder: SortOrder) => {
    const queryParams = getAllQueryParamsAsObjectFromUrl(location.search)
    store.uiStore.setGlobalLoader(true)
    setLoading(true)
    const params: any = {
      ...searchParams,
      ...queryParams
    }
    // Handle "CLEAR" by removing sort-related params
    if (sortOrder !== 'CLEAR') {
      params[sortField] = sortOrder
      // Persist sort information in URL
      const searchParams = new URLSearchParams(location.search)
      searchParams.set('sortField', sortField)
      searchParams.set('sortOrder', sortOrder)
      window.history.replaceState({}, '', `?${searchParams.toString()}`)
    } else {
      delete params[sortField] // Ensure sorting is removed
      delete params[sortField]
      // Remove sort params from URL
      const searchParams = new URLSearchParams(location.search)
      searchParams.delete('sortField')
      searchParams.delete('sortOrder')
      window.history.replaceState({}, '', `?${searchParams.toString()}`)
    }

    const urlSortField = searchParams.get('sortField')
    const urlSortOrder = searchParams.get('sortOrder')

    if (urlSortField && urlSortOrder && urlSortOrder !== 'CLEAR') {
      params[urlSortField] = urlSortOrder
    }

    const timeZone = store.userStore.getOrganizationPreference()?.timeZone
    const nowInOrgTimeZone = moment.tz(timeZone)
    const startOfMonth = nowInOrgTimeZone.clone().startOf('month').toISOString()
    const endOfCurrentDay = nowInOrgTimeZone.clone().endOf('day').toISOString()

    const paramsWithDateRange = {
      ...params,
      'created-from': queryParams['created-from'] || startOfMonth,
      'created-to': queryParams['created-to'] || endOfCurrentDay,
      'time-zone': timeZone
    }

    fetchGroupedApiRecords({ ...paramsWithDateRange, size: pageSize })
      .then((response: any) => {
        setGroupedApiTableData(response.data)
        store.apiDetailsStore.setRefresh(false)
        setTotalCount(response.totalCount)
        store.filterStore.setFilters(response.filters)
      })
      .catch((e) => {
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.API_MONITORING.FETCH_DETAILS.ERROR
        })
      })
      .finally(() => {
        store.uiStore.setGlobalLoader(false)
        setLoading(false)
      })
  }

  const handleSortCycle = (sortField: string) => {
    setActiveSortOrder((prev) => {
      if (prev.field === sortField) {
        // Cycle through ASC, DESC, and CLEAR
        const nextOrder =
          prev.order === 'ASC'
            ? 'DESC'
            : prev.order === 'DESC'
              ? 'CLEAR'
              : 'ASC'

        if (tabIndex === '1') {
          handleApiData(sortField, nextOrder)
        } else {
          sortUserData(sortField, nextOrder)
        }
        return {
          field: nextOrder !== 'CLEAR' ? sortField : null,
          order: nextOrder
        }
      } else {
        // Activate new field with ASC
        if (tabIndex === '1') {
          handleApiData(sortField, 'ASC')
        } else {
          sortUserData(sortField, 'ASC')
        }
        return { field: sortField, order: 'ASC' }
      }
    })
  }
  const groupedUserApiData = (queryParams: any) => {
    const allQueryParams = getAllQueryParamsAsObjectFromUrl(location.search)
    const searchParams = new URLSearchParams(location.search)
    // Retrieve sort parameters from URL
    const urlSortField = searchParams.get('sortField')
    const urlSortOrder = searchParams.get('sortOrder')
    setLoading(true)
    store.uiStore.setGlobalLoader(true)
    const params: any = {
      ...allQueryParams,
      size: pageSize
    }
    if (urlSortField && urlSortOrder && urlSortOrder !== 'CLEAR') {
      params[urlSortField] = urlSortOrder
    }

    const timeZone = store.userStore.getOrganizationPreference()?.timeZone
    const { startOfMonth, endOfCurrentDayTimestamp } = getOrgTimeRange(timeZone)

    fetchGroupedApiUserRecords({
      ...params,
      size: pageSize
    })
      .then((response: any) => {
        setGroupedUserApiTableData(response.data)
        store.apiDetailsStore.setRefresh(false)
        setTotalUserCount(response.totalCount)
        if (urlSortField && urlSortOrder) {
          setActiveSortOrder({
            field: urlSortField,
            order: urlSortOrder as SortOrder
          })
        }

        if (response.totalCount >= 1) {
          const progressData = store.thingsToDoStore.getProgressData()

          if (!progressData.apiMonitoring) {
            const updatedProgressData = {
              ...progressData,
              apiMonitoring: true
            }

            const newProgressCount = Object.entries(updatedProgressData).reduce(
              (count, [key, value]) =>
                typeof value === 'boolean' && value ? count + 1 : count,
              0
            )

            updatedProgressData.progress = newProgressCount

            store.thingsToDoStore.setProgressData(updatedProgressData)
          }
        }

        const filters = {
          'created-from': moment
            .tz(response.filters?.['created-from'] || startOfMonth, timeZone)
            .toISOString(),
          'created-to': moment
            .tz(
              response.filters?.['created-to'] || endOfCurrentDayTimestamp,
              timeZone
            )
            .toISOString(),
          'time-zone': timeZone
        }

        store.filterStore.setFilters(response.filters)

        setDateUserFilter(filters)
      })
      .catch((e) => {
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.API_MONITORING.FETCH_DETAILS.ERROR
        })
      })
      .finally(() => {
        setLoading(false)
        store.uiStore.setGlobalLoader(false)
      })
  }
  const sortUserData = (sortField: string, sortOrder: SortOrder) => {
    const queryParams = getAllQueryParamsAsObjectFromUrl(location.search)
    Object.keys(queryParams).forEach((key) => {
      if (key.includes('sort-')) {
        delete queryParams[key]
      }
    })
    const searchParams = new URLSearchParams(location.search)

    store.uiStore.setGlobalLoader(true)
    setLoading(true)

    const timeZone = store.userStore.getOrganizationPreference()?.timeZone
    const { startOfMonth, endOfCurrentDayTimestamp } = getOrgTimeRange(timeZone)

    const urlSortField = searchParams.get('sortField')
    const urlSortOrder = searchParams.get('sortOrder')

    const params: any = {
      ...queryParams,
      size: pageSize
    }
    if (urlSortField && urlSortOrder && urlSortOrder !== 'CLEAR') {
      params[urlSortField] = urlSortOrder
    }

    const paramsWithDateRange: any = {
      ...queryParams,
      size: pageSize,
      'created-from': queryParams['created-from'] || startOfMonth,
      'created-to': queryParams['created-to'] || endOfCurrentDayTimestamp,
      'time-zone': timeZone
    }

    // Handle "CLEAR" by removing sort-related params
    if (sortOrder !== 'CLEAR') {
      paramsWithDateRange[sortField] = sortOrder
      // Persist sort information in URL
      searchParams.set('sortField', sortField)
      searchParams.set('sortOrder', sortOrder)
      window.history.replaceState({}, '', `?${searchParams.toString()}`)
    } else {
      delete paramsWithDateRange[sortField]
      // Remove sort params from URL
      searchParams.delete('sortField')
      searchParams.delete('sortOrder')
      window.history.replaceState({}, '', `?${searchParams.toString()}`)
    }

    fetchGroupedApiUserRecords({ ...paramsWithDateRange, size: pageSize })
      .then((response: any) => {
        setGroupedUserApiTableData(response.data)
        store.apiDetailsStore.setRefresh(false)
        setTotalUserCount(response.totalCount)

        const filters = {
          'created-from': moment
            .tz(response.filters?.['created-from'] || startOfMonth, timeZone)
            .toISOString(),
          'created-to': moment
            .tz(
              response.filters?.['created-to'] || endOfCurrentDayTimestamp,
              timeZone
            )
            .toISOString(),
          'time-zone': timeZone
        }

        setDateUserFilter(filters)
        store.filterStore.setFilters(response.filters)
      })
      .catch((e) => {
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.API_MONITORING.FETCH_DETAILS.ERROR
        })
      })
      .finally(() => {
        store.uiStore.setGlobalLoader(false)
        setLoading(false)
      })
  }

  const handlePageChange = (page: number) => {
    setLoading(true)
    store.uiStore.setGlobalLoader(true)
    const allQueryParams = getAllQueryParamsAsObjectFromUrl(location.search)
    Object.keys(allQueryParams).forEach((key) => {
      if (key.includes('sort-')) {
        delete allQueryParams[key]
      }
    })
    const searchParams = new URLSearchParams(location.search)

    const urlSortField = searchParams.get('sortField')
    const urlSortOrder = searchParams.get('sortOrder')

    const updatedParams = {
      ...allQueryParams,
      page: page.toString(),
      sortField: searchParams.get('sortField') || null,
      sortOrder: searchParams.get('sortOrder') || null,
      'created-from': allQueryParams['created-from'] || null,
      'created-to': allQueryParams['created-to'] || null,
      'time-zone': allQueryParams['time-zone'] || null
    }
    if (urlSortField && urlSortOrder && urlSortOrder !== 'CLEAR') {
      updatedParams[urlSortField] = urlSortOrder
    }

    const sanitizedParams = Object.fromEntries(
      Object.entries(updatedParams).filter(([_, value]) => value !== null)
    )

    const urlSearchParams = new URLSearchParams(
      sanitizedParams as Record<string, string>
    )

    setSearchParams(urlSearchParams)

    setCurrentPage(page)
  }

  const downloadRecords = () => {
    const allQueryParams = getAllQueryParamsAsObjectFromUrl(location.search)
    store.uiStore.setGlobalLoader(true)
    setLoading(true)
    const queryParamsForDownload = {
      ...allQueryParams,
      'time-zone': store.userStore.getOrganizationPreference().timeZone
    }
    downloadAPIGroupedURLView(queryParamsForDownload)
      .then((response: any) => {
        const date = new Date()
        const formattedDate = `${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}-${date.getFullYear()}`
        convertJsonToCsvDownload(
          response.data,
          `Grouped_API_${formattedDate}.csv`
        )
      })
      .catch((err) => {
        ToastNotification({
          type: 'error',
          message: 'Error downloading log in API Grouped URL view'
        })
      })
      .finally(() => {
        setLoading(false)
        store.uiStore.setGlobalLoader(false)
      })
  }

  const downloadUserRecords = () => {
    const allQueryParams = getAllQueryParamsAsObjectFromUrl(location.search)
    store.uiStore.setGlobalLoader(true)
    setLoading(true)
    const queryParamsForDownload = {
      ...allQueryParams,
      'time-zone': store.userStore.getOrganizationPreference().timeZone
    }
    downloadAPIGroupedUserView(queryParamsForDownload)
      .then((response: any) => {
        const date = new Date()
        const formattedDate = `${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}-${date.getFullYear()}`
        convertJsonToCsvDownload(
          response.data,
          `Grouped_API_${formattedDate}.csv`
        )
      })
      .catch((err) => {
        ToastNotification({
          type: 'error',
          message: 'Error downloading log in API Grouped User view'
        })
      })
      .finally(() => {
        setLoading(false)
        store.uiStore.setGlobalLoader(false)
      })
  }

  const downloadRecordsNoUserName = () => {
    const allQueryParams = getAllQueryParamsAsObjectFromUrl(location.search)
    store.uiStore.setGlobalLoader(true)
    setLoading(true)
    const queryParamsForDownload = {
      ...allQueryParams,
      'time-zone': store.userStore.getOrganizationPreference().timeZone
    }

    downloadAPIGroupedURLView(queryParamsForDownload)
      .then((response: any) => {
        // Filter out the apiUserName property
        const filteredData = response.data.map(
          ({
            userName,
            userCount,
            userId,
            ...rest
          }: {
            userName: string
            userCount: string
            userId: string
            [key: string]: any
          }) => rest
        )

        const date = new Date()
        const formattedDate = `${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}-${date.getFullYear()}`
        convertJsonToCsvDownload(
          filteredData,
          `Grouped_API_${formattedDate}.csv`
        )
      })
      .catch((err) => {
        ToastNotification({
          type: 'error',
          message: 'Error downloading log in all API URL view'
        })
      })
      .finally(() => {
        setLoading(false)
        store.uiStore.setGlobalLoader(false)
      })
  }

  useEffect(() => {
    localStorage.setItem(
      StringConstants.ACTIVE_TAB,
      StringConstants.TAB_VALUES.API_MONITORING
    )
  }, [tabIndex, store.scopeStore.getScope()])

  const loadFilters = (type: string, apiCall: () => void) => {
    if (filterCache[type]) {
      setOptions(filterCache[type])
    } else {
      apiCall()
    }
  }

  const getGroupedUrlFilter = () => {
    setFilterLoading(true)
    getAPIGroupFilters()
      .then((response: any) => {
        const filteredOptions = response.data.filter(
          (item: any) => item?.heading !== 'Users'
        )
        setOptions(filteredOptions)
        setFilterCache((prev) => ({ ...prev, url: filteredOptions }))
      })
      .catch((err) => {
        return ToastNotification({
          type: 'error',
          message:
            ToastMessageConstants.API_MONITORING.API_MONITORING_FILTERS.ERROR
        })
      })
      .finally(() => setFilterLoading(false))
  }

  const getGroupedUserFilter = () => {
    setFilterLoading(true)
    getAPIGroupFilters()
      .then((response: any) => {
        const filteredOptions = response.data.filter(
          (item: any) =>
            item?.heading !== 'URL Template' && item?.heading !== 'HTTP Method'
        )
        setOptions(filteredOptions)
        setFilterCache((prev) => ({ ...prev, user: filteredOptions }))
      })
      .catch((err) => {
        return ToastNotification({
          type: 'error',
          message:
            ToastMessageConstants.API_MONITORING.API_MONITORING_FILTERS.ERROR
        })
      })
      .finally(() => setFilterLoading(false))
  }

  useEffect(() => {
    const fetchFilters = async () => {
      try {
        store.uiStore.setGlobalLoader(true)

        if (groupedAPITabIndex === '1' && tabIndex === '1') {
          await getGroupedUrlFilter()
        }
        if (groupedAPITabIndex === '1' && tabIndex === '2') {
          await getGroupedUserFilter()
        }
      } catch (error) {
        ToastNotification({
          type: 'error',
          message: String(error) || 'Unable to fetch organization data'
        })
      } finally {
        store.uiStore.setGlobalLoader(false)
      }
    }

    fetchFilters()
  }, [tabIndex, groupedAPITabIndex])

  // const handleFilterLoadUrl = () => loadFilters('url', getGroupedUrlFilter)
  // const handleFilterLoadUser = () => loadFilters('user', getGroupedUserFilter)

  const organizations = store.userStore.getOrganizations()
  const foundOrganization = organizations.find(
    (organization: any) =>
      organization.id === store.scopeStore.getOrganizationId()
  )

  const ContextSwitcherTab = [
    {
      id: '1',
      label: 'URL View',
      children: (
        <div>
          <URLView
            totalCount={totalCount}
            setActiveSortOrder={setActiveSortOrder}
            groupedApiTableData={groupedApiTableData}
            groupedApiData={handleApiData}
            loading={loading}
            setLoading={setLoading}
            activeSortOrder={activeSortOrder}
            handleSortCycle={handleSortCycle}
          />
        </div>
      )
    },
    {
      id: '2',
      label: 'User View',
      children: (
        <div className='relative'>
          <UserView
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            totalCount={totalUserCount}
            setActiveSortOrder={setActiveSortOrder}
            groupedApiTableData={groupedUserApiTableData}
            groupedUserApiData={groupedUserApiData}
            loading={loading}
            setLoading={setLoading}
            handlePageChange={handlePageChange}
            activeSortOrder={activeSortOrder}
            handleSortCycle={handleSortCycle}
          />
        </div>
      )
    }
  ]

  return (
    <>
      {foundOrganization?.subscription?.trackApiUsage === true ? (
        <div className='pt-[24px]'>
          <div className='flex items-center justify-between w-full'>
            <div className='flex-grow override-view-more-tabs'>
              <ContextSwitcherTabs
                tabOptions={ContextSwitcherTab}
                shape='roundedRectangle'
                tabStyle={{
                  border: '1px solid',
                  borderColor: pallete.colors.stroke2,
                  userSelect: 'none',
                  width: '204px'
                }}
                tabTitleClassName='text-center select-none'
                activeTab={tabIndex}
                onChange={(index: any) => {
                  setTabIndex(index)
                  setSearchParams({
                    page: '1',
                    tab: searchParams.get('tab') ?? '1',
                    contextSwitcherTab: index
                  })
                }}
                disabled={loading || store.uiStore.getGlobalLoader()}
                className='override-height override-view-more-tabs'
              />

              <div className='flex items-center custom-filter justify-end absolute right-0 top-[72px] space-x-[12px]'>
                <Filter
                  dropdownLoader={filterLoading}
                  options={options}
                  APITimeFormat={true}
                  hideFilterChipAPI={true}
                  isScrollableAPI={true}
                  clearFilterDisabled={true}
                  disabled={loading || store.uiStore.getGlobalLoader()}
                />

                <Button
                  id='outline-button'
                  label='Export'
                  type='neutral'
                  size='small'
                  disabled={
                    loading ||
                    store.uiStore.getGlobalLoader() ||
                    tabIndex === '1'
                      ? totalCount === 0
                      : totalUserCount === 0
                  }
                  onClick={() => {
                    if (tabIndex === '1') downloadRecords()
                    else {
                      downloadUserRecords()
                    }
                  }}
                  startIcon={<Download color={pallete.colors.neuButtonText} />}
                />
                {!(
                  loading ||
                  store.uiStore.getGlobalLoader() ||
                  store.uiStore.getprojectEnvironmentLoader()
                ) ? (
                  <SideChip
                    id='date-range-chip'
                    label='Date Range'
                    value={
                      tabIndex === '1'
                        ? convertToFormat(dateFilter, organizationTimeZone)
                        : convertToFormat(dateUserFilter, organizationTimeZone)
                    }
                  />
                ) : null}

                {/* <Search
              placeholder='Search by URL, HTTP Method...'
              id='log-search'
              name='logSearch'
              width='35vh'
              onChange={() => {
                // Implement search functionality
              }}
            /> */}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className='pt-[24px]'>
            <div className='flex items-center justify-between w-full'>
              <div className='flex-grow override-view-more-tabs'></div>
            </div>

            <div
              className={`flex items-center custom-filter justify-end absolute  right-0 space-x-[12px]`}
            >
              <Filter
                dropdownLoader={filterLoading}
                options={options}
                APITimeFormat={true}
                hideFilterChipAPI={true}
                isScrollable={true}
                clearFilterDisabled={true}
                disabled={loading || store.uiStore.getGlobalLoader()}
              />

              <Button
                id='outline-button'
                label='Export'
                type='neutral'
                size='small'
                disabled={
                  loading ||
                  store.uiStore.getGlobalLoader() ||
                  filterLoading ||
                  totalCount === 0
                }
                onClick={() => downloadRecordsNoUserName()}
                startIcon={<Download color={pallete.colors.neuButtonText} />}
              />
              {!(
                loading ||
                store.uiStore.getGlobalLoader() ||
                store.uiStore.getprojectEnvironmentLoader()
              ) ? (
                <SideChip
                  id='date-range-chip'
                  label='Date Range'
                  value={convertToFormat(dateFilter, organizationTimeZone)}
                />
              ) : null}
            </div>

            <div className='relative top-[32px]'>
              <URLView
                totalCount={totalCount}
                setActiveSortOrder={setActiveSortOrder}
                groupedApiTableData={groupedApiTableData}
                groupedApiData={handleApiData}
                loading={loading}
                setLoading={setLoading}
                activeSortOrder={activeSortOrder}
                handleSortCycle={handleSortCycle}
              />
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default observer(GroupedAPITab)
