import {} from '../../../constants/ExceptionsPageConstants'

import {
  AddFilled,
  Bookmark,
  BookmarkFilled,
  ChevronRight,
  Close,
  Edit,
  Share,
  TrashCan
} from '@carbon/icons-react'
import { DDSButton, DDSTypography } from 'den-design-system'
import {
  ExceptionStatusTypes,
  SeverityTypes
} from '../../../api/exception/Exception.types'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import {
  deleteExceptionsTags,
  getAnException,
  getExceptionsTags,
  updateAnException
} from '../../../api/exception/Exception.service'
import {
  getAllQueryParamsAsObjectFromUrl,
  replaceUrl
} from '../../../utils/UrlUtils'
import { isNull, isUndefined } from 'lodash'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

import Activity from '../../../components/common/Exceptions/Activity'
import AssignMembers from '../../../components/common/Exceptions/AssignMember'
import ConfirmationPopup from '../../../components/common/Exceptions/ConfirmationPopup'
import CopyOnClick from '../../../components/common/CopyOnClick/CopyOnClick'
import DisplayCard from '../../../components/common/Exceptions/DisplayCard'
import DocumentHeader from '../../../components/Helmet/DocumentHeader'
import { HeaderConstants } from '../../../constants/HeaderConstants'
import Input from '../../../components/New/Input/Input'
import NoRecord from '../../../components/common/NoContent/NoRecord'
import NoRecordImage from '../../../assets/images/folder-not-found 1.svg'
import Overview from './Screens/Overview'
import { ProjectType } from '../../../enum/ProjectType.enum'
import { SettingDeletePopup } from '../Settings/SettingPopups/SettingDeletePopup'
import SeverityDropDown from '../../../components/common/Exceptions/SeverityDropdown'
import Slider from '../../../components/common/Exceptions/Slider'
import Spinner from '../../../components/common/Spinner/Spinner'
import StatusDropDown from '../../../components/common/Exceptions/StatusDropdown'
import StringConstants from '../../../constants/StringConstants'
import Switch from '../../../components/New/Switch/Switch'
import Table from '../../../components/common/DDS/Table/Table'
import Tabs from '../../../components/common/DDS/Tabs/Tabs'
import { ToastMessageConstants } from '../../../constants/ToastMessageConstants'
import ToastNotification from '../../../components/common/DDS/Toast/Toast'
import Tooltip from '../../../components/New/Tooltip/Tooltip'
import copy from 'copy-to-clipboard'
import { getAllProjectMembers } from '../../../api/project/Project.service'
import { paginate } from '../../../utils/CommonUtils'
import pallete from '../../../global/newPallete'
import routeConstants from '../../../constants/RouteConstants'
import { severityColorMap } from '../../../constants/ExceptionsPageConstants'
import { timeFormats } from '../../../enum/TIME'
import { timeZone } from '../../../utils/DateTimeUtils'
import { useStoreContext } from '../../../store/StoreContext'

const SingleLogView: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [activeTabIndex, setActiveTabIndex] = useState<string>('1')
  const [exceptionDetails, setExceptionDetails] = useState<any>()
  const [exceptionDetailsTag, setExceptionDetailsTag] = useState<any>()

  const [assignProjectMemberLoading, setAssignProjectMemberLoading] =
    useState<boolean>(false)

  const [loadingTag, setLoadingTag] = useState<boolean>(false)
  const [isloadingTag, setIsLoadingTag] = useState<boolean>(false)

  const [linkCopied, SetLinkCopied] = useState<boolean>(false)
  const [assignMemberOptions, setAssignMemberOptions] = useState<any>([])
  const [isPublic, setIsPublic] = useState<boolean>(true)
  const [severityModalOpen, setSeverityModalOpen] =
    React.useState<boolean>(false)
  const [showCard, setShowCard] = useState(false)
  const [deleteTagPopupOpen, setDeleteTagPopupOpen] =
    React.useState<boolean>(false)
  const [totalCount, setTotalCount] = useState<number>(0)

  const [exceptionTagDetails, setExceptionTagDetails] = useState<any>()
  const [filteredTags, setFilteredTags] = useState<any[]>(exceptionTagDetails)

  const store = useStoreContext()

  const { id } = useParams()
  const [tags, setTags] = useState<string[]>(exceptionDetailsTag?.tags || [])
  const [currentPage, setCurrentPage] = useState(1)
  const [loading, setLoading] = React.useState(false)
  const [triggerLoading, setTriggerLoading] = React.useState(false)
  const [newExceptionTagDetails, setNewExceptionTagDetails] = useState<any>()
  const [selectedTagId, setSelectedTagId] = useState<string | null>(null)
  const [selectedTagName, setSelectedTagName] = useState<string | null>(null)
  const MAX_TAGS_LIMIT = 5

  // const fetchAnExceptionDetailsTag = async () => {
  //   try {
  //     const response: any = await getAnException({
  //       $exceptionLogId: id as string
  //     })
  //     setExceptionDetailsTag(response.data.data)
  //   } catch (error) {
  //     ToastNotification({
  //       type: 'error',
  //       message: 'Failed to fetch exception details'
  //     })
  //   }
  // }

  // const fetchAnExceptionTagDetails = async (): Promise<void> => {
  //   try {
  //     const response: any = await getExceptionsTags()
  //     setExceptionTagDetails(response.data)
  //     setTotalCount(response.totalCount)
  //   } catch (error) {
  //     ToastNotification({
  //       type: 'error',
  //       message: 'Failed to fetch exception tag details'
  //     })
  //   }
  // }

  // const updateExceptionTag = async (newExceptionTagDetails: any) => {
  //   const sanitizedTag =
  //     typeof newExceptionTagDetails === 'string'
  //       ? newExceptionTagDetails.trim().replace(/\s+$/, '')
  //       : newExceptionTagDetails

  //   if (!sanitizedTag) {
  //     ToastNotification({
  //       type: 'error',
  //       message: `The tag "${sanitizedTag}" already exists.`
  //     })
  //     return
  //   }

  //   const updatedTag = [sanitizedTag]

  //   setIsLoadingTag(true)
  //   store.uiStore.setTagLoader(true)

  //   try {
  //     await updateAnException({
  //       tags: updatedTag,
  //       $exceptionLogId: id as string
  //     })
  //     await Promise.all([
  //       fetchAnExceptionTagDetails(),
  //       fetchAnExceptionDetailsTag()
  //     ])

  //     ToastNotification({
  //       type: 'success',
  //       message: `Tag updated successfully`
  //     })
  //   } catch (error) {
  //     ToastNotification({
  //       type: 'error',
  //       message: isFullStackProject
  //         ? ToastMessageConstants.EXCEPTIONS.UPDATE_EXCEPTION_TAGS.ERROR
  //         : ToastMessageConstants.ERROR.UPDATE_ERROR_TAGS.ERROR
  //     })
  //   } finally {
  //     setIsLoadingTag(false)
  //     store.uiStore.setTagLoader(false)
  //   }
  // }

  // const clearExceptionTag = async (tagToRemove: string[]) => {
  //   setIsLoadingTag(true)
  //   store.uiStore.setTagLoader(true)

  //   try {
  //     await updateAnException({
  //       tagToRemove: tagToRemove,
  //       $exceptionLogId: id as string
  //     })

  //     await Promise.all([
  //       fetchAnExceptionTagDetails(),
  //       fetchAnExceptionDetailsTag()
  //     ])

  //     ToastNotification({
  //       type: 'success',
  //       message: `Tag "${tagToRemove.join(', ')}" cleared successfully.`
  //     })
  //   } catch (error) {
  //     ToastNotification({
  //       type: 'error',
  //       message: isFullStackProject
  //         ? ToastMessageConstants.EXCEPTIONS.UPDATE_EXCEPTION_TAGS.ERROR
  //         : ToastMessageConstants.ERROR.UPDATE_ERROR_TAGS.ERROR
  //     })
  //   } finally {
  //     setIsLoadingTag(false)
  //     store.uiStore.setTagLoader(false)
  //   }
  // }

  // const deleteExceptionTag = async (id: any) => {
  //   setIsLoadingTag(true)
  //   store.uiStore.setTagLoader(true)

  //   try {
  //     await deleteExceptionsTags({
  //       $exceptionTagId: id as string
  //     })

  //     await Promise.all([
  //       fetchAnExceptionTagDetails(),
  //       fetchAnExceptionDetailsTag()
  //     ])

  //     ToastNotification({
  //       type: 'success',
  //       message: 'Tag deleted successfully'
  //     })
  //   } catch (error) {
  //     ToastNotification({
  //       type: 'error',
  //       message: isFullStackProject
  //         ? ToastMessageConstants.EXCEPTIONS.DELETE_EXCEPTION_TAGS.ERROR
  //         : ToastMessageConstants.ERROR.DELETE_ERROR_TAGS.ERROR
  //     })
  //   } finally {
  //     setIsLoadingTag(false)
  //     store.uiStore.setTagLoader(false)
  //   }
  // }

  // const primaryButtonClick = async () => {
  //   if (selectedTagId) {
  //     setIsLoadingTag(true)
  //     store.uiStore.setTagLoader(true)
  //     setNewExceptionTagDetails('')
  //     try {
  //       await deleteExceptionTag(selectedTagId)
  //       setDeleteTagPopupOpen(false)
  //       await Promise.all([fetchAnExceptionTagDetails()])
  //     } catch (error) {
  //       ToastNotification({
  //         type: 'error',
  //         message: 'Failed to delete exception tag.'
  //       })
  //     } finally {
  //       setLoading(false)
  //       store.uiStore.setGlobalLoader(false)
  //     }
  //   }
  // }

  // useEffect(() => {
  //   const pageFromQueryParams = searchParams.get('page')
  //   if (!isNull(pageFromQueryParams)) {
  //     setCurrentPage(Number(pageFromQueryParams))
  //   } else {
  //     const allQueryParams = getAllQueryParamsAsObjectFromUrl(location.search)
  //     setSearchParams({ ...allQueryParams, page: '1' })
  //     setCurrentPage(1)
  //   }
  //   fetchAnExceptionDetailsTag()
  //   fetchAnExceptionTagDetails()
  // }, [searchParams, store.scopeStore.getScope()])

  // useEffect(() => {
  //   if (exceptionDetailsTag?.tags) {
  //     setTags(exceptionDetailsTag.tags)
  //   }
  // }, [exceptionDetailsTag?.tags])

  // const cardRef = useRef<HTMLDivElement>(null)

  // const handleButtonClick = () => {
  //   setShowCard((prevState) => !prevState)
  // }

  // const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   let searchValue = event.target.value

  //   searchValue = searchValue.replace(/^\s+/, '').replace(/\s{2,}/g, ' ')

  //   setNewExceptionTagDetails(searchValue)

  //   const filtered = exceptionTagDetails
  //     .sort((a: any, b: any) => {
  //       const aStartsWith = a.tag
  //         .toLowerCase()
  //         .startsWith(searchValue.toLowerCase())
  //       const bStartsWith = b.tag
  //         .toLowerCase()
  //         .startsWith(searchValue.toLowerCase())
  //       if (aStartsWith && !bStartsWith) return -1
  //       if (!aStartsWith && bStartsWith) return 1
  //       return a.tag.toLowerCase().includes(searchValue.toLowerCase()) ? -1 : 1
  //     })
  //     .filter((tag: any) =>
  //       tag.tag.toLowerCase().includes(searchValue.toLowerCase())
  //     )

  //   setFilteredTags(filtered)
  // }

  // const handleAddTag = () => {
  //   if (newExceptionTagDetails.trim()) {
  //     setTags((prevTags) => [...prevTags, newExceptionTagDetails.trim()])
  //     updateExceptionTag(newExceptionTagDetails)
  //   }
  // }

  // const handleRemoveTag = (tag: string[]) => {
  //   setExceptionTagDetails((prevTags: any) =>
  //     prevTags.filter((t: any) => t !== tag)
  //   )
  //   clearExceptionTag(tag)
  // }

  // const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
  //   if (event.key === 'Enter') {
  //     const trimmedTag = newExceptionTagDetails?.trim().replace(/\s+$/, '')
  //     setNewExceptionTagDetails('')
  //     if (trimmedTag) {
  //       // Check if the tag already exists in the `tags` array
  //       const isTagExisting = tags.some(
  //         (tag: string) => tag.toLowerCase() === trimmedTag.toLowerCase()
  //       )

  //       if (isTagExisting) {
  //         ToastNotification({
  //           type: 'error',
  //           message: `The tag "${trimmedTag}" already exists.`
  //         })
  //       } else {
  //         handleAddTag()
  //         setNewExceptionTagDetails('')
  //       }
  //     }
  //   }
  // }

  // const handleCloseCard = () => {
  //   setShowCard(false)
  // }

  // const handleOutsideClick = (event: MouseEvent) => {
  //   if (cardRef.current && !cardRef.current.contains(event.target as Node)) {
  //     handleCloseCard()
  //   }
  // }

  // const handleAddButtonAsTag = (tagText: string) => {
  //   // Ensure there's a valid tagText before processing

  //   if (exceptionDetailsTag?.tags?.length >= MAX_TAGS_LIMIT) {
  //     ToastNotification({
  //       type: 'error',
  //       message: 'Maximum limit of 5 tags reached. Cannot add more tags.'
  //     })
  //     return
  //   }

  //   if (tags.includes(tagText)) {
  //     ToastNotification({
  //       type: 'error',
  //       message: `The tag "${tagText}" already exists.`
  //     })
  //   } else {
  //     if (!tags.includes(tagText)) {
  //       setTags((prevTags) => [...prevTags, tagText])
  //       updateExceptionTag(tagText)
  //     }
  //   }
  // }

  // useEffect(() => {
  //   document.addEventListener('mousedown', handleOutsideClick)
  //   return () => {
  //     document.removeEventListener('mousedown', handleOutsideClick)
  //   }
  // }, [])

  const projectType = store.scopeStore.getSelectedProject().type
  const isFullStackProject = projectType === ProjectType.FULL_STACK_ONLY

  const navigate = useNavigate()
  const handleCopyLink = (link: string) => {
    copy(link)
    SetLinkCopied(true)
    setTimeout(() => {
      SetLinkCopied(false)
    }, 3000)
  }

  //Severity modal

  const [changeToPreference, setToPreference] = React.useState<any>()
  const updateSeverity = (severity: SeverityTypes) => {
    setLoading(true)
    setLoadingTag(true)
    const isSameSeverity = exceptionDetails.severity === severity

    if (!isSameSeverity) {
      updateAnException({
        severity: severity,
        $exceptionLogId: id as string
      })
        .then(() => {
          ToastNotification({
            type: 'success',
            message: isFullStackProject
              ? ToastMessageConstants.EXCEPTIONS.UPDATE_EXCEPTIONS.SUCCESS
              : ToastMessageConstants.ERROR.UPDATE_ERROR.SUCCESS
          })
          setLoading(false)
          setLoadingTag(false)
          setSeverityModalOpen(false)
          fetchAnExceptionDetails()
        })
        .catch(() => {
          ToastNotification({
            type: 'error',
            message: isFullStackProject
              ? ToastMessageConstants.EXCEPTIONS.UPDATE_EXCEPTIONS.ERROR
              : ToastMessageConstants.ERROR.UPDATE_ERROR.ERROR
          })
          setLoading(false)
          setLoadingTag(false)
        })
    }
  }
  const [toggleLoading, setToggleLoading] = useState<boolean>(false)
  const updateStatus = (status: ExceptionStatusTypes) => {
    const isSameStatus = exceptionDetails.exceptionStatus === status
    if (!isSameStatus) {
      setLoading(true)
      setLoadingTag(true)
      updateAnException({
        exceptionStatus: status,
        $exceptionLogId: id as string
      })
        .then(() => {
          ToastNotification({
            type: 'success',
            message: isFullStackProject
              ? ToastMessageConstants.EXCEPTIONS.UPDATE_EXCEPTIONS.SUCCESS
              : ToastMessageConstants.ERROR.UPDATE_ERROR.SUCCESS
          })
          fetchAnExceptionDetails()
        })
        .catch(() => {
          ToastNotification({
            type: 'success',
            message: isFullStackProject
              ? ToastMessageConstants.EXCEPTIONS.UPDATE_EXCEPTIONS.ERROR
              : ToastMessageConstants.ERROR.UPDATE_ERROR.ERROR
          })
          setLoading(false)
          setLoadingTag(false)
        })
    }
  }

  const tableColumn = [
    {
      columnHeaderStyle: { width: '40%' },
      columnDataStyle: { width: '40%' },
      heading: (
        <div className='flex'>
          <DDSTypography.Paragraph size='para' variant='semiBold'>
            Attributes
          </DDSTypography.Paragraph>
        </div>
      ),
      render: (text: any) => (
        <div className='flex justify-start text-start row items-start max-w-[120px]'>
          <div>
            <Tooltip
              id={`tooltip-name-${text}`}
              label={text}
              className={`override-tooltip-arrow max-w-[90%] break-all ${
                typeof text === 'string' && text.length > 15 ? text : '!hidden'
              }`}
            >
              <div>
                <DDSTypography.Paragraph
                  size='para'
                  variant='medium'
                  color={pallete.colors.textDark4}
                  className={`tooltip-container sm:block overflow-hidden truncate ${
                    store.uiStore.getSidebarExpanded()
                      ? 'max-w-[170px]'
                      : 'max-w-[210px]'
                  }`}
                >
                  {text || text === 0 ? text : '-'}
                </DDSTypography.Paragraph>
              </div>
            </Tooltip>
          </div>
        </div>
      ),
      dataLabel: 'attribute'
    },
    {
      columnHeaderStyle: { width: '60%' },
      columnDataStyle: { width: '60%' },
      heading: (
        <div className='flex'>
          <DDSTypography.Paragraph size='para' variant='semiBold'>
            Values
          </DDSTypography.Paragraph>
        </div>
      ),
      render: (text: any) => (
        <div className='flex items-center'>
          <div className='flex items-center w-full'>
            <Tooltip
              id={`tooltip-name-${text}`}
              label={text}
              position='top'
              className={`override-tooltip-arrow overflow-hidden max-h-[20vh] max-w-[90%] break-all ${
                typeof text === 'string' && text.length > 25 ? '' : '!hidden'
              }`}
            >
              <div className='flex justify-between gap-[8px]'>
                <div>
                  <DDSTypography.Paragraph
                    size='para'
                    variant='medium'
                    color={pallete.colors.textDark4}
                    className={`tooltip-container sm:block flex items-center overflow-hidden ${
                      store.uiStore.getSidebarExpanded()
                        ? 'max-w-[270px]'
                        : 'max-w-[320px]'
                    }`}
                  >
                    <span className='truncate'>
                      {text || text === 0 ? text : '-'}
                    </span>
                  </DDSTypography.Paragraph>
                </div>
              </div>
            </Tooltip>
            <div className='flex items-end justify-end'>
              <CopyOnClick value={text} />
            </div>
          </div>
        </div>
      ),
      dataLabel: 'value'
    }
  ]
  const [view, setView] = React.useState('Raw')

  const [shareModalOpen, setShareModalOpen] = React.useState<boolean>(false)

  useEffect(() => {
    localStorage.setItem(
      StringConstants.ACTIVE_TAB,
      StringConstants.TAB_VALUES.EXCEPTION
    )
  }, [])

  useEffect(() => {
    const tabFromParam = searchParams.get('tab')
    let firstLabelName: string
    if (
      store.scopeStore.getSelectedProject().type ===
        ProjectType.FULL_STACK_ONLY &&
      tabFromParam === '2'
    ) {
      firstLabelName = 'All Exceptions'
    } else if (
      store.scopeStore.getSelectedProject().type === ProjectType.FULL_STACK_ONLY
    ) {
      firstLabelName = 'Grouped Exceptions'
    } else if (
      (store.scopeStore.getSelectedProject().type ===
        ProjectType.SALESFORCE_ONLY ||
        store.scopeStore.getSelectedProject().type ===
          ProjectType.MULESOFT_ONLY) &&
      tabFromParam === '2'
    ) {
      firstLabelName = 'All Errors'
    } else if (
      store.scopeStore.getSelectedProject().type ===
        ProjectType.SALESFORCE_ONLY ||
      store.scopeStore.getSelectedProject().type === ProjectType.MULESOFT_ONLY
    ) {
      firstLabelName = 'Grouped Errors'
    } else {
      firstLabelName = 'Grouped Errors'
    }
    if (!isUndefined(exceptionDetails)) {
      store.breadcrumbStore.setMultipleBreadcrumbsOptions([
        {
          label: (
            <div
              style={{
                pointerEvents:
                  isloadingTag || store.uiStore.getTagLoader() ? 'none' : 'auto'
              }}
              onClick={(e) => {
                if (loading || isloadingTag || store.uiStore.getTagLoader()) {
                  e.stopPropagation()
                }
              }}
              className='whitespace-nowrap'
            >
              {firstLabelName}
            </div>
          ),
          link:
            routeConstants.GROUPED_ERROR_MONITORING +
            `?page=1&tab=${tabFromParam ? tabFromParam : '1'}`
        },
        {
          label: (
            <Tooltip
              id={`${exceptionDetails.exceptionType}`}
              position='bottom'
              className={`max-w-[45%] ${
                typeof exceptionDetails.exceptionType === 'string' &&
                exceptionDetails.exceptionType.length > 15
                  ? exceptionDetails.exceptionType
                  : '!hidden'
              }`}
              label={exceptionDetails.exceptionType}
            >
              <div
                style={{
                  pointerEvents:
                    isloadingTag || store.uiStore.getTagLoader()
                      ? 'none'
                      : 'auto'
                }}
                onClick={(e) => {
                  if (loading || isloadingTag || store.uiStore.getTagLoader()) {
                    e.stopPropagation()
                  }
                }}
                className='truncate max-w-[200px]'
              >
                {exceptionDetails.exceptionType}
              </div>
            </Tooltip>
          ),
          link:
            routeConstants.ERROR_MONITORING.replace(
              ':id',
              exceptionDetails.exceptionGroupId
            ) + `?tab=${tabFromParam ? tabFromParam : '1'}`
        },
        {
          label: (
            <Tooltip
              id='breadcrumb-tooltip'
              position='bottom'
              className='max-w-[45%]'
              label={exceptionDetails.exceptionMessage}
            >
              <div
                style={{
                  pointerEvents:
                    isloadingTag || store.uiStore.getTagLoader()
                      ? 'none'
                      : 'auto'
                }}
                onClick={(e) => {
                  if (loading || isloadingTag || store.uiStore.getTagLoader()) {
                    e.stopPropagation()
                  }
                }}
                className='truncate max-w-[400px]'
              >
                {exceptionDetails.exceptionMessage}
              </div>
            </Tooltip>
          ),
          link: '/'
        }
      ])
    }
    const logTabFromParams = searchParams.get('logTab')
    if (logTabFromParams) {
      setActiveTabIndex(logTabFromParams)
    }
  }, [location.search, exceptionDetails])

  const data = useMemo(() => {
    if (
      !exceptionDetails ||
      isNull(exceptionDetails.context) ||
      isUndefined(exceptionDetails.context)
    ) {
      return []
    }

    return Object.keys(exceptionDetails.context).map((item: string) => ({
      attribute: item,
      value: exceptionDetails.context[item]
    }))
  }, [exceptionDetails])

  const exceptionData = useMemo(
    () => paginate(data, currentPage, 4),
    [data, currentPage]
  )

  const tabOptions = [
    {
      label:
        activeTabIndex === '1' ? (
          <DDSTypography.Title
            type='h5'
            variant='semiBold'
            color={pallete.colors.primary2}
          >
            {'Group Overview'}
          </DDSTypography.Title>
        ) : (
          <DDSTypography.Title
            type='h5'
            variant='medium'
            color={pallete.colors.textDark5}
          >
            {'Group Overview'}
          </DDSTypography.Title>
        ),
      children: (
        <Overview setLoadingTag={setLoadingTag} loadingTag={loadingTag} />
      )
    },
    {
      label:
        activeTabIndex === '2' ? (
          <DDSTypography.Title
            type='h5'
            variant='semiBold'
            color={pallete.colors.primary2}
          >
            {'Overview'}
          </DDSTypography.Title>
        ) : (
          <DDSTypography.Title
            type='h5'
            variant='medium'
            color={pallete.colors.textDark5}
          >
            {'Overview'}
          </DDSTypography.Title>
        ),
      children: !loading ? (
        <div className='w-full flex flex-row gap-x-[16px] py-[24px]'>
          {/* Left Column */}
          <div className='flex flex-col gap-y-[16px] w-1/2'>
            {/* Exception Message */}
            <DisplayCard
              id='#'
              heading={
                isFullStackProject ? 'Exception Message' : 'Error Message'
              }
              content={exceptionDetails?.exceptionMessage}
            />

            {/* Exception Context */}
            <div className='px-[16px] py-[20px] flex flex-col border border-gray-300 rounded-md gap-y-[16px]'>
              <DDSTypography.Paragraph
                size='para'
                variant='regular'
                color={pallete.colors.textDark5}
              >
                {isFullStackProject ? 'Exception Context' : 'Error Context'}
              </DDSTypography.Paragraph>
              {!isNull(exceptionDetails?.context) &&
              !isUndefined(exceptionDetails?.context) &&
              Object.keys(exceptionDetails?.context).length > 0 ? (
                <Table
                  data={exceptionData}
                  columns={tableColumn}
                  currentPage={currentPage}
                  rowsPerPage={4}
                  totalCount={Object.entries(exceptionDetails?.context).length}
                  onPageChange={(page: number) => {
                    const allQueryParams = getAllQueryParamsAsObjectFromUrl(
                      location.search
                    )
                    setSearchParams({
                      ...allQueryParams,
                      page: page.toString()
                    })
                    setCurrentPage(page)
                  }}
                  loading={loading}
                />
              ) : (
                <NoRecord
                  imageSrc={NoRecordImage}
                  text={
                    <DDSTypography.Title
                      type='h5'
                      className='whitespace-nowrap'
                    >
                      No Records Found
                    </DDSTypography.Title>
                  }
                />
              )}
            </div>
          </div>

          {/* Right Column */}
          <div
            className={`w-1/2 px-[16px] py-[20px] overflow-y-auto flex flex-col border border-gray-300 rounded-md gap-y-[16px] max-h-[80vh]`}
          >
            <div className='w-full flex flex-row justify-between'>
              <DDSTypography.Paragraph
                size='para'
                variant='regular'
                color={pallete.colors.textDark5}
              >
                {isFullStackProject ? 'Exception Stack' : 'Error Stack'}
              </DDSTypography.Paragraph>
              {store.scopeStore.getSelectedProject().type ===
                ProjectType.FULL_STACK_ONLY &&
                exceptionDetails?.exceptionStack && (
                  <Slider
                    options={[{ label: 'Raw' }, { label: 'Full' }]}
                    selected={view}
                    onSelect={(option: string) => {
                      setView(option)
                    }}
                  />
                )}
            </div>
            {exceptionDetails?.exceptionStack ? (
              <div>
                {view === 'Raw' ? (
                  <div
                    style={{ backgroundColor: pallete.colors.surface6 }}
                    className='p-[16px] rounded-md overflow-scroll h-full'
                  >
                    <DDSTypography.Paragraph
                      size='para'
                      variant='regular'
                      color={pallete.colors.textDark5}
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html:
                            exceptionDetails?.exceptionStack ||
                            'No details available'
                        }}
                      />
                    </DDSTypography.Paragraph>
                  </div>
                ) : (
                  <div
                    style={{ backgroundColor: pallete.colors.surface8 }}
                    className='p-[16px] rounded-md overflow-scroll h-full'
                  >
                    {exceptionDetails?.stackFrames?.length > 0 ? (
                      exceptionDetails?.stackFrames?.map(
                        (item: any, index: any) =>
                          item.fileFullPath !== '' && (
                            <div
                              key={index}
                              style={{
                                backgroundColor: pallete.colors.surface6,
                                padding: '10px',
                                marginBottom: '10px',
                                borderRadius: '5px',
                                boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
                              }}
                            >
                              <DDSTypography.Paragraph
                                size='para'
                                variant='regular'
                                color={pallete.colors.textDark5}
                                style={{
                                  overflowWrap: 'break-word'
                                }}
                              >
                                {item.fileFullPath}
                                {!(item.functionName === '') && (
                                  <>
                                    <span> in </span>
                                    {item.functionName}
                                  </>
                                )}
                                {item.lineNo && (
                                  <>
                                    <span> at line </span>
                                    {item.columnNo ? (
                                      <>
                                        {item.lineNo}:{item.columnNo}
                                      </>
                                    ) : (
                                      <>{item.lineNo}</>
                                    )}
                                  </>
                                )}
                              </DDSTypography.Paragraph>
                            </div>
                          )
                      )
                    ) : (
                      <NoRecord
                        imageSrc={NoRecordImage}
                        text={
                          <DDSTypography.Title
                            type='h5'
                            className='whitespace-nowrap'
                          >
                            No Stack Frames Found
                          </DDSTypography.Title>
                        }
                      />
                    )}
                  </div>
                )}
              </div>
            ) : (
              <NoRecord
                imageSrc={NoRecordImage}
                text={
                  <DDSTypography.Title type='h5' className='whitespace-nowrap'>
                    {isFullStackProject
                      ? 'No Exception Stack'
                      : 'No Error Stack'}
                  </DDSTypography.Title>
                }
              />
            )}
          </div>
        </div>
      ) : (
        <Spinner />
      )
    },
    {
      label:
        activeTabIndex === '3' ? (
          <DDSTypography.Title
            type='h5'
            variant='semiBold'
            color={pallete.colors.primary2}
          >
            {'Activity'}
          </DDSTypography.Title>
        ) : (
          <DDSTypography.Title
            type='h5'
            variant='medium'
            color={pallete.colors.textDark5}
          >
            {'Activity'}
          </DDSTypography.Title>
        ),
      children: <Activity />
    }
  ]

  useEffect(() => {
    const pageFromQueryParams = searchParams.get('page')
    if (!isNull(pageFromQueryParams)) {
      setCurrentPage(Number(pageFromQueryParams))
    } else {
      const allQueryParams = getAllQueryParamsAsObjectFromUrl(location.search)
      setSearchParams({ ...allQueryParams, page: '1' })
      setCurrentPage(1)
    }
  }, [searchParams, store.scopeStore.getScope()])
  const fetchAnExceptionDetails = () => {
    setLoading(true)
    store.uiStore.setGlobalLoader(true)
    getAnException({ $exceptionLogId: id as string })
      .then((response: any) => {
        setExceptionDetails(response.data.data)
        setIsPublic(response.data.data.isPublic)
        store.exceptionsStoreV2.setCurrenExceptionId(
          response.data.data.exceptionGroupId
        )
      })
      .catch((e: any) => {
        navigate(
          replaceUrl(
            routeConstants.GROUPED_ERROR_MONITORING,
            store.scopeStore.getScope()['organization-id'] as string,
            store.scopeStore.getScope()['project-id'] as string,
            store.scopeStore.getScope()['environment-id'] as string
          )
        )
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.EXCEPTIONS.GET_AN_EXCEPTION.ERROR
        })
      })
      .finally(() => {
        store.uiStore.setGlobalLoader(false)
        setLoading(false)
      })
  }

  const handlePublicShare = () => {
    setToggleLoading(true)
    updateAnException({
      $exceptionLogId: id as string,
      isPublic: !exceptionDetails.isPublic
    }).then((response: any) => {
      setExceptionDetails(response.data)

      setToggleLoading(false)
    })
  }
  const updateBookmark = (bookmarkState: boolean) => {
    setLoading(true)
    const isSameBookmarkState = exceptionDetails.isBookmarked === bookmarkState
    if (!isSameBookmarkState) {
      updateAnException({
        isBookmarked: bookmarkState,
        $exceptionLogId: id as string
      })
        .then(() => {
          fetchAnExceptionDetails()
          ToastNotification({
            type: 'success',
            message: isFullStackProject
              ? ToastMessageConstants.EXCEPTIONS.UPDATE_EXCEPTIONS.SUCCESS
              : ToastMessageConstants.ERROR.UPDATE_ERROR.SUCCESS
          })
        })
        .catch(() => {
          ToastNotification({
            type: 'error',
            message: isFullStackProject
              ? ToastMessageConstants.EXCEPTIONS.UPDATE_EXCEPTIONS.ERROR
              : ToastMessageConstants.ERROR.UPDATE_ERROR.ERROR
          })
          setLoading(false)
        })
    }
  }
  const fetchProjectMembers = () => {
    const projectId = store.scopeStore.getSelectedProject().id
    getAllProjectMembers(projectId as string)
      .then((response: any) => {
        const allMembers = response.data.map((member: any) => {
          return {
            id: member.id,
            name: member.user.firstName + ' ' + member.user.lastName,
            imgUrl: member.user.avatar
          }
        })
        setAssignMemberOptions(allMembers)
      })
      .catch(() => {
        ToastNotification({
          type: 'error',
          message:
            ToastMessageConstants.PROJECT_MEMBER.FIND_ALL_PROJECT_MEMBERS.ERROR
        })
      })
  }

  const handleAssignMembers = (userId: string) => {
    setAssignProjectMemberLoading(true)
    updateAnException({
      $exceptionLogId: id as string,
      assignedTo: userId
    })
      .then((response: any) => {
        fetchAnExceptionDetails()
        fetchProjectMembers()
        ToastNotification({
          type: 'success',
          message: isFullStackProject
            ? ToastMessageConstants.EXCEPTIONS.UPDATE_EXCEPTIONS.SUCCESS
            : ToastMessageConstants.ERROR.UPDATE_ERROR.SUCCESS
        })
        setAssignProjectMemberLoading(false)
      })
      .catch((err) => {
        ToastNotification({
          type: 'error',
          message: isFullStackProject
            ? ToastMessageConstants.EXCEPTIONS.UPDATE_EXCEPTIONS.ERROR
            : ToastMessageConstants.ERROR.UPDATE_ERROR.ERROR
        })
        setAssignProjectMemberLoading(false)
      })
  }

  const handleRemoveMembers = () => {
    setAssignProjectMemberLoading(true)
    updateAnException({
      $exceptionLogId: id as string,
      assignedTo: ''
    })
      .then((response: any) => {
        setAssignMemberOptions([])
        fetchAnExceptionDetails()
        fetchProjectMembers()
        ToastNotification({
          type: 'success',
          message: isFullStackProject
            ? ToastMessageConstants.EXCEPTIONS.UPDATE_EXCEPTIONS.SUCCESS
            : ToastMessageConstants.ERROR.UPDATE_ERROR.SUCCESS
        })
        setAssignProjectMemberLoading(false)
      })
      .catch((err) => {
        ToastNotification({
          type: 'error',
          message: isFullStackProject
            ? ToastMessageConstants.EXCEPTIONS.UPDATE_EXCEPTIONS.ERROR
            : ToastMessageConstants.ERROR.UPDATE_ERROR.ERROR
        })
        setAssignProjectMemberLoading(false)
      })
  }
  useEffect(() => {
    localStorage.setItem(
      StringConstants.ACTIVE_TAB,
      StringConstants.TAB_VALUES.EXCEPTION
    )
    fetchAnExceptionDetails()
    fetchProjectMembers()
  }, [])
  if (loading && store.uiStore.getGlobalLoader()) return <Spinner />
  return (
    <div className='h-[100%] pt-[24px]'>
      <DocumentHeader
        header={
          store.scopeStore.getSelectedProject().type ===
          ProjectType.FULL_STACK_ONLY
            ? HeaderConstants.EXCEPTIONS
            : HeaderConstants.ERROR
        }
      />
      {loading ? (
        <Spinner />
      ) : (
        <>
          <div className=' h-full flex flex-col'>
            <div className='flex flex-row justify-between items-start '>
              <div className='flex gap-y-[12px] flex-col'>
                <div className='flex flex-row gap-x-[8px] items-center'>
                  <div>
                    <Tooltip
                      id='exceptionType-id-tooltip'
                      label={exceptionDetails?.exceptionType}
                      className={`max-w-[500px] ${
                        typeof exceptionDetails?.exceptionType === 'string' &&
                        exceptionDetails?.exceptionType.length > 50
                          ? exceptionDetails?.exceptionType
                          : '!hidden'
                      }`}
                    >
                      <DDSTypography.Title
                        type='h3'
                        variant='semiBold'
                        className='truncate max-w-[800px]'
                      >
                        {exceptionDetails?.exceptionType as string}
                      </DDSTypography.Title>
                    </Tooltip>
                  </div>
                  <div className='w-[16px] h-[16px]'>
                    {exceptionDetails?.isBookmarked ? (
                      <BookmarkFilled
                        color={pallete.colors.primary2}
                        className={` ${
                          store.userStore.getNoActiveSubscription() ||
                          loading ||
                          loadingTag ||
                          store.uiStore.getGlobalLoader() ||
                          isloadingTag ||
                          store.uiStore.getTagLoader()
                            ? 'cursor-not-allowed pointer-events-none'
                            : 'cursor-pointer'
                        }  cursor-pointer h-[16px] w-[16px]`}
                        onClick={() => updateBookmark(false)}
                      />
                    ) : (
                      <Bookmark
                        color={pallete.colors.textDark10}
                        className={` ${
                          store.userStore.getNoActiveSubscription() ||
                          loading ||
                          loadingTag ||
                          store.uiStore.getGlobalLoader() ||
                          isloadingTag ||
                          store.uiStore.getTagLoader()
                            ? 'cursor-not-allowed pointer-events-none'
                            : 'cursor-pointer'
                        }  cursor-pointer h-[16px] w-[16px]`}
                        onClick={() => updateBookmark(true)}
                      />
                    )}
                  </div>
                  <div className='h-[16px] w-[16px]'>
                    <Share
                      color={pallete.colors.textDark10}
                      className={` ${
                        store.userStore.getNoActiveSubscription() ||
                        loading ||
                        loadingTag ||
                        store.uiStore.getGlobalLoader() ||
                        isloadingTag ||
                        store.uiStore.getTagLoader()
                          ? 'cursor-not-allowed pointer-events-none'
                          : 'cursor-pointer'
                      }  cursor-pointer h-[16px] w-[16px]`}
                      onClick={() => setShareModalOpen(true)}
                    />
                  </div>
                </div>
                <div className='flex flex-row gap-x-[12px]'>
                  <div className='flex flex-row items-baseline gap-x-[6px]'>
                    <DDSTypography.Paragraph
                      size='caption'
                      variant='medium'
                      style={{ color: pallete.colors.textDark6 }}
                      className='whitespace-nowrap'
                    >
                      Reported at:
                    </DDSTypography.Paragraph>
                    <DDSTypography.Paragraph
                      size='para'
                      variant='medium'
                      style={{ color: pallete.colors.textDark3 }}
                      className='whitespace-nowrap'
                    >
                      {timeZone(
                        store.userStore.getUserPreference().timeZone,
                        exceptionDetails?.reportedAt,
                        timeFormats.fullMonthDateYearTime
                      )}
                    </DDSTypography.Paragraph>
                  </div>
                  <span className='border-r border-gray-300'></span>
                  <div className='flex flex-row items-baseline gap-x-[6px]'>
                    <DDSTypography.Paragraph
                      size='caption'
                      variant='medium'
                      style={{ color: pallete.colors.textDark6 }}
                      className='whitespace-nowrap'
                    >
                      Client Version:
                    </DDSTypography.Paragraph>
                    <DDSTypography.Paragraph
                      size='para'
                      variant='medium'
                      style={{ color: pallete.colors.textDark3 }}
                    >
                      {exceptionDetails?.clientVersion
                        ? exceptionDetails?.clientVersion
                        : '-'}
                    </DDSTypography.Paragraph>
                  </div>
                </div>
              </div>
              <div className='flex flex-row gap-x-[8px] items-center '>
                <div className=' flex flex-row gap-x-[12px] '>
                  <div>
                    <SeverityDropDown
                      disabled={
                        loading ||
                        exceptionDetails?.exceptionStatus == 'Resolved'
                      }
                      label={exceptionDetails?.severity}
                      onSelect={(value: SeverityTypes) => {
                        if (value !== exceptionDetails?.severity) {
                          setToPreference(value)
                          setSeverityModalOpen(true)
                        }
                      }}
                      insideTable={false}
                      disableNoSubscription={
                        store.userStore.getNoActiveSubscription() ||
                        loading ||
                        loadingTag ||
                        isloadingTag ||
                        store.uiStore.getTagLoader() ||
                        store.uiStore.getGlobalLoader()
                      }
                    />
                  </div>
                  <div className='override-single-log-status'>
                    <StatusDropDown
                      disabled={
                        store.userStore.getNoActiveSubscription() ||
                        loading ||
                        loadingTag ||
                        isloadingTag ||
                        store.uiStore.getTagLoader() ||
                        store.uiStore.getGlobalLoader()
                      }
                      label={exceptionDetails?.exceptionStatus}
                      onSelect={(status: ExceptionStatusTypes) =>
                        updateStatus(status)
                      }
                      style={{ height: ' 32px' }}
                    />
                  </div>
                </div>
                <DDSButton
                  label='Report an Incident'
                  type='filled'
                  shape='roundedRectangle'
                  buttonHoverTextColor={pallete.colors.textDark10}
                  size='small'
                  style={{
                    color: pallete.colors.white
                  }}
                  className='whitespace-nowrap'
                  buttonHoverBackgroundColor={pallete.colors.fillButtonhover}
                  onClick={() => {
                    const route = replaceUrl(
                      routeConstants.MANAGE_INCIDENTS_FROM_EXCEPTION,
                      store.scopeStore.getScope()['organization-id'] as string,
                      store.scopeStore.getScope()['project-id'] as string,
                      store.scopeStore.getScope()['environment-id'] as string
                    ).replace(':exceptionLogId', String(id))

                    navigate(route)
                  }}
                  disabled={
                    store.userStore.getNoActiveSubscription() ||
                    loading ||
                    loadingTag ||
                    isloadingTag ||
                    store.uiStore.getTagLoader() ||
                    store.uiStore.getGlobalLoader()
                  }
                />
                <AssignMembers
                  disableNoSubscription={
                    store.userStore.getNoActiveSubscription() ||
                    loading ||
                    loadingTag ||
                    store.uiStore.getGlobalLoader()
                  }
                  label='Assign Members'
                  menuPosition='left'
                  disable={
                    exceptionDetails?.exceptionStatus == 'Resolved' ||
                    isloadingTag ||
                    store.uiStore.getTagLoader()
                  }
                  options={assignMemberOptions}
                  onclick={(id: string) => handleAssignMembers(id)}
                  assignedUser={exceptionDetails?.assignedTo}
                  onremove={handleRemoveMembers}
                  loading={assignProjectMemberLoading}
                />
              </div>
            </div>

            <div className='flex flex-row relative w-full'>
              <div className='w-full '>
                <Tabs
                  tabOptions={tabOptions}
                  className='h-full'
                  tabOptionsStyle={{
                    minWidth: 'fit-content',
                    width: 'auto',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '8px 12px',
                    marginRight: '8px'
                  }}
                  onChange={(value: string) => {
                    if (activeTabIndex != value) {
                      setActiveTabIndex(value)
                      setSearchParams({
                        ...getAllQueryParamsAsObjectFromUrl(location.search),
                        logTab: value
                      })
                    }
                  }}
                  activeTab={activeTabIndex}
                />
              </div>
              {/* {loading ||
              assignProjectMemberLoading ||
              toggleLoading ||
              loadingTag ||
              store.uiStore.getGlobalLoader() ? (
                '-'
              ) : (
                <div className='absolute right-0 flex flex-row gap-x-[12px] '>
                  <div className='flex flex-col gap-y-[16px] relative'>
                    <div className='flex flex-row gap-x-[8px] gap-y-[8px] relative top-[7px] flex-wrap'>
                      {exceptionDetailsTag?.tags?.length > 0
                        ? exceptionDetailsTag?.tags
                            ?.slice(0, 5)
                            ?.map((tag: string, index: number) => (
                              <span
                                className='px-[8px] py-[4px] rounded-[100px] flex items-center'
                                key={index}
                                style={{
                                  backgroundColor: pallete.colors.surface4
                                }}
                              >
                                <DDSTypography.Paragraph
                                  size='caption'
                                  variant='medium'
                                  color={pallete.colors.textDark4}
                                  className='text-center flex items-center'
                                >
                                  {tag}
                                </DDSTypography.Paragraph>
                              </span>
                            ))
                        : '-'}

                      <div className='flex justify-center items-center'>
                        {loading ||
                        loadingTag ||
                        store.uiStore.getGlobalLoader() ? (
                          '-'
                        ) : exceptionDetailsTag?.tags?.length >= 5 ? (
                          <div
                            className='flex items-center justify-center px-[8px] py-[4px] rounded-full cursor-pointer hover:bg-blue-200'
                            style={{
                              backgroundColor: pallete.colors.surface4,
                              cursor:
                                isloadingTag || store.uiStore.getTagLoader()
                                  ? 'not-allowed'
                                  : 'pointer',
                              pointerEvents:
                                isloadingTag || store.uiStore.getTagLoader()
                                  ? 'none'
                                  : 'auto'
                            }}
                            onClick={handleButtonClick}
                          >
                            <button
                              disabled={
                                isloadingTag || store.uiStore.getTagLoader()
                              }
                              style={{
                                fontWeight: 500,
                                fontSize: '12px',
                                color: pallete.colors.textDark4
                              }}
                              className='mr-[8px]'
                            >
                              Edit tags
                            </button>
                            <Edit
                              style={{
                                cursor:
                                  isloadingTag || store.uiStore.getTagLoader()
                                    ? 'not-allowed'
                                    : 'pointer',
                                pointerEvents:
                                  isloadingTag || store.uiStore.getTagLoader()
                                    ? 'none'
                                    : 'auto'
                              }}
                              size={16}
                              color={pallete.colors.FillButton}
                            />
                          </div>
                        ) : (
                          <div
                            className='flex items-center justify-center px-[8px] py-[4px] rounded-full shadow-md cursor-pointer hover:bg-blue-200'
                            style={{
                              backgroundColor: pallete.colors.surface4,
                              cursor:
                                isloadingTag || store.uiStore.getTagLoader()
                                  ? 'not-allowed'
                                  : 'pointer',
                              pointerEvents:
                                isloadingTag || store.uiStore.getTagLoader()
                                  ? 'none'
                                  : 'auto'
                            }}
                            onClick={handleButtonClick}
                          >
                            <button
                              disabled={
                                isloadingTag || store.uiStore.getTagLoader()
                              }
                              style={{
                                fontWeight: 500,
                                fontSize: '12px',
                                color: pallete.colors.textDark4
                              }}
                              className='mr-[8px]'
                            >
                              Add tags
                            </button>
                            <AddFilled
                              style={{
                                cursor:
                                  isloadingTag || store.uiStore.getTagLoader()
                                    ? 'not-allowed'
                                    : 'pointer',
                                pointerEvents:
                                  isloadingTag || store.uiStore.getTagLoader()
                                    ? 'none'
                                    : 'auto'
                              }}
                              size={16}
                              color={pallete.colors.FillButton}
                            />
                          </div>
                        )}
                      </div>
                    </div>

                    {showCard && (
                      <div
                        ref={cardRef}
                        className='absolute border top-[0px] right-[0px] z-[999999] bg-white rounded-[8px] shadow-sm p-[8px]'
                        style={{
                          width: store.uiStore.getSidebarExpanded()
                            ? '64vh'
                            : '76vh'
                        }}
                      >
                        <div className='flex items-center gap-[8px] flex-wrap'>
                          {tags?.slice(0, 5)?.map((tag, index) => (
                            <div
                              key={index}
                              style={{
                                backgroundColor: pallete.colors.surface4
                              }}
                              className='flex items-center h-[30px] overflow-y-auto space-x-[8px] px-[8px] py-[4px] rounded-full'
                            >
                              <span
                                style={{
                                  fontSize: '12px',
                                  color: pallete.colors.textDark4,
                                  fontWeight: 500,
                                  lineHeight: '16px'
                                }}
                              >
                                {tag}
                              </span>
                              <button
                                style={{
                                  cursor:
                                    isloadingTag || store.uiStore.getTagLoader()
                                      ? 'not-allowed'
                                      : 'pointer',
                                  pointerEvents:
                                    isloadingTag || store.uiStore.getTagLoader()
                                      ? 'none'
                                      : 'auto'
                                }}
                                disabled={
                                  isloadingTag || store.uiStore.getTagLoader()
                                }
                                onClick={(event) => {
                                  if (
                                    isloadingTag ||
                                    store.uiStore.getTagLoader()
                                  ) {
                                    event.stopPropagation()
                                    return
                                  }
                                  handleRemoveTag([tag])
                                  setNewExceptionTagDetails('')
                                }}
                              >
                                <Close
                                  className='break-words cursor-pointer'
                                  style={{
                                    width: '12px',
                                    height: '12px',
                                    minWidth: '12px',
                                    minHeight: '12px'
                                  }}
                                  size={12}
                                  color={pallete.colors.textDark4}
                                />
                              </button>
                            </div>
                          ))}

                          <input
                            type='text'
                            placeholder={
                              tags.length === 0
                                ? 'Search or add tag'
                                : tags.length >= 5
                                  ? ''
                                  : 'Search or add new'
                            }
                            value={newExceptionTagDetails || ''}
                            disabled={
                              tags.length >= 5 ||
                              isloadingTag ||
                              store.uiStore.getTagLoader()
                            }
                            maxLength={20}
                            onChange={handleInputChange}
                            onKeyDown={handleKeyPress}
                            className='w-[10%] flex-grow bg-white text-sm text-black caret-black focus:outline-none placeholder:text-[12px] placeholder:text-textDark4 placeholder:font-medium placeholder:leading-[16px]'
                            style={{
                              caretColor: 'black'
                            }}
                          />
                        </div>
                        <div className='pt-[8px]'>
                          <hr style={{ color: pallete.colors.textDark2 }} />
                        </div>
                        <p
                          className='pt-[8px]'
                          style={{
                            fontSize: '12px',
                            color: pallete.colors.textDark4,
                            fontWeight: 400,
                            lineHeight: '16px'
                          }}
                        >
                          Select up to 5 options or create a new tag
                        </p>

                        <div
                          className='mt-[8px] max-h-[200px] overflow-y-auto space-y-2'
                          id='scrollableDiv'
                        >
                          {newExceptionTagDetails &&
                          filteredTags.length === 0 ? (
                            <div className='mt-[8px] flex items-center gap-x-[8px] cursor-pointer'>
                              <button
                                onClick={() => {
                                  updateExceptionTag(newExceptionTagDetails)
                                  setNewExceptionTagDetails('')
                                }}
                                disabled={
                                  isloadingTag || store.uiStore.getTagLoader()
                                }
                                style={{
                                  fontSize: '14px',
                                  color: pallete.colors.textDark4,
                                  fontWeight: 600,
                                  lineHeight: '20px'
                                }}
                              >
                                Create:
                              </button>
                              <span
                                className='px-[8px] py-[4px] rounded-full'
                                style={{
                                  backgroundColor: pallete.colors.surface4,
                                  fontSize: '12px',
                                  color: pallete.colors.textDark4,
                                  fontWeight: 500,
                                  lineHeight: '16px'
                                }}
                                onClick={() => {
                                  updateExceptionTag(newExceptionTagDetails)
                                  setNewExceptionTagDetails('')
                                }}
                              >
                                {newExceptionTagDetails}
                              </span>
                            </div>
                          ) : (
                            (newExceptionTagDetails
                              ? filteredTags
                              : exceptionTagDetails
                            )?.map((exceptionTag: any) => (
                              <div
                                style={{
                                  cursor:
                                    isloadingTag || store.uiStore.getTagLoader()
                                      ? 'not-allowed'
                                      : 'pointer',
                                  pointerEvents:
                                    isloadingTag || store.uiStore.getTagLoader()
                                      ? 'none'
                                      : 'auto'
                                }}
                                key={exceptionTag.id}
                                className='flex items-center'
                              >
                                <button
                                  type='button'
                                  disabled={
                                    tags.length >= 5 ||
                                    isloadingTag ||
                                    store.uiStore.getTagLoader()
                                  }
                                  onClick={(event) => {
                                    if (
                                      isloadingTag ||
                                      store.uiStore.getTagLoader()
                                    ) {
                                      event.stopPropagation()
                                      return
                                    }
                                    handleAddButtonAsTag(exceptionTag.tag)
                                    setNewExceptionTagDetails('')
                                  }}
                                  className='flex-grow flex items-center justify-between w-full px-[8px] py-[8px] bg-white text-left hover:bg-gray-50'
                                >
                                  <span
                                    className='text-[14px] font-medium leading-[16px]'
                                    style={{
                                      color: pallete.colors.textDark5
                                    }}
                                  >
                                    {exceptionTag.tag}
                                  </span>
                                </button>

                                <TrashCan
                                  className='cursor-pointer'
                                  size={16}
                                  color={pallete.colors.warningRed1}
                                  onClick={(event) => {
                                    event.stopPropagation()
                                    if (
                                      isloadingTag ||
                                      store.uiStore.getTagLoader()
                                    ) {
                                      return
                                    }
                                    setShowCard(false)
                                    setSelectedTagId(exceptionTag.id)
                                    setSelectedTagName(exceptionTag.tag)
                                    setDeleteTagPopupOpen(true)
                                    setNewExceptionTagDetails('')
                                  }}
                                />
                              </div>
                            ))
                          )}
                        </div>

                        {(isloadingTag || store.uiStore.getTagLoader()) && (
                          <div className='flex justify-center items-center'>
                            <Spinner />
                          </div>
                        )}
                      </div>
                    )}
                    <SettingDeletePopup
                      showHeader
                      title='Delete Tag'
                      modalOpen={deleteTagPopupOpen}
                      setModalOpen={setDeleteTagPopupOpen}
                      primaryButtonText='Delete'
                      primaryButtonDisabled={
                        isloadingTag || store.uiStore.getTagLoader()
                      }
                      secondaryButtonDisabled={
                        isloadingTag || store.uiStore.getTagLoader()
                      }
                      loading={isloadingTag || store.uiStore.getTagLoader()}
                      secondaryButtonText='Cancel'
                      primaryButtonClick={primaryButtonClick}
                      secondaryButtonClick={() => {
                        setDeleteTagPopupOpen(false)
                      }}
                      showFooter={true}
                      content={
                        <div className='flex flex-col p-[24px] gap-[24px]'>
                          <div>
                            <DDSTypography.Title
                              variant='semiBold'
                              type='h4'
                              color={pallete.colors.textDark3}
                            >
                              Are you sure you want to delete{' '}
                              <span
                                style={{
                                  color: pallete.colors.textDark3,
                                  fontWeight: 600
                                }}
                              >
                                {` "${selectedTagName}" `}
                              </span>
                              {''} ?
                            </DDSTypography.Title>
                          </div>
                        </div>
                      }
                    />
                  </div>
                </div>
              )} */}
            </div>
          </div>
          <ConfirmationPopup
            modalOpen={shareModalOpen}
            setModalOpen={setShareModalOpen}
            title={isFullStackProject ? 'Share Exception' : 'Share Error'}
            primaryButtonText={
              exceptionDetails?.isPublic
                ? linkCopied
                  ? 'Copied'
                  : 'Copy Link'
                : ''
            }
            primaryButtonClick={() =>
              handleCopyLink(
                window.location.origin +
                  '/' +
                  'shares' +
                  '/' +
                  exceptionDetails?.publicAccessToken
              )
            }
            showFooter={exceptionDetails?.isPublic}
            primaryButtonDisabled={toggleLoading || linkCopied}
            loading={toggleLoading}
            content={
              <div className='flex flex-col gap-y-[24px]'>
                <div className='flex flex-row overflow-y-scroll  justify-between'>
                  <div className='flex flex-col w-full gap-y-[4px] items-start justify-start'>
                    <DDSTypography.Title
                      variant='medium'
                      type='h5'
                      className='w-5/6'
                    >
                      {isFullStackProject
                        ? 'Anyone with the link can view the exceptions. To '
                        : 'Anyone with the link can view the errors. To '}
                      <span>
                        {exceptionDetails?.isPublic
                          ? 'unshare, disable'
                          : 'share, enable'}{' '}
                        the toggle.
                      </span>
                    </DDSTypography.Title>
                  </div>
                  <div className='flex items-center'>
                    {/* <DDSSwitch
                      onChange={(status) => {
                        updateAnException({
                          $exceptionLogId: id as string,
                          isPublic: status
                        }).then(() => {
                          fetchAnExceptionDetails()
                          setIsPublic(status)
                        })
                      }}
                      value={isPublic}
                    /> */}
                    <Switch
                      onChange={handlePublicShare}
                      id='toggle-share'
                      disabled={toggleLoading}
                      defaultChecked={exceptionDetails?.isPublic}
                    />
                  </div>
                </div>
                {exceptionDetails?.isPublic ? (
                  <Input
                    id='input-share'
                    onChange={() => {
                      null
                    }}
                    width='100%'
                    name='input'
                    value={
                      window.location.origin +
                      '/' +
                      'shares' +
                      '/' +
                      exceptionDetails?.publicAccessToken
                    }
                  />
                ) : null}
              </div>
            }
          />
          <ConfirmationPopup
            modalOpen={severityModalOpen}
            setModalOpen={setSeverityModalOpen}
            title='Severity Type'
            primaryButtonClick={() => updateSeverity(changeToPreference)}
            secondaryButtonClick={() => {
              setSeverityModalOpen(false)
            }}
            primaryButtonDisabled={loading}
            loading={loading}
            secondaryButtonDisabled={loading}
            primaryButtonText='Yes'
            secondaryButtonText='No'
            content={
              <div className='flex flex-col w-full gap-y-[24px] items-center justify-center'>
                <DDSTypography.Title
                  variant='semiBold'
                  type='h4'
                  className='text-center'
                >
                  Are you sure to change the severity type from
                </DDSTypography.Title>
                <div className='flex flex-row justify-center items-center gap-x-[8px] '>
                  <div
                    className='flex items-center justify-center h-[32px] px-[11px] rounded-md'
                    style={{
                      color: 'red !important',
                      border: '1px solid #D9E0EA'
                    }}
                  >
                    <DDSTypography.Title
                      variant='semiBold'
                      type='h4'
                      className='text-center'
                      color={severityColorMap[exceptionDetails?.severity]}
                    >
                      {exceptionDetails?.severity}
                    </DDSTypography.Title>
                  </div>
                  <div>
                    <ChevronRight size={24} />
                  </div>

                  <div
                    className={`flex items-center justify-center h-[32px] px-[11px] rounded-md`}
                    style={{
                      color: 'red !important',
                      border: '1px solid #D9E0EA'
                    }}
                  >
                    <DDSTypography.Title
                      variant='semiBold'
                      type='h4'
                      className='text-center'
                      color={severityColorMap[changeToPreference]}
                    >
                      {changeToPreference as string}
                    </DDSTypography.Title>
                  </div>
                </div>
              </div>
            }
          />
        </>
      )}
    </div>
  )
}
export default SingleLogView
