import { CaretDown, CaretUp, ChevronRight } from '@carbon/icons-react'
import { DDSChips, DDSTypography } from 'den-design-system'
import React, { useEffect, useState } from 'react'
import {
  gaEventConstants,
  gaPageConstants
} from '../../../constants/GAConstants'
import {
  msToTimeUnits,
  timeZone,
  timeZoneOrg
} from '../../../utils/DateTimeUtils'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

import ApiGroupingStringConstants from '../../../constants/ApiGroupingStringConstants'
import { Link } from '@mui/material'
import NoFilterImage from '../../../assets/images/folder-not-found 1.svg'
import NoRecord from '../../../components/common/NoContent/NoRecord'
import NoRecordImage from '../../../assets/images/setting.svg'
import Spinner from '../../../components/common/Spinner/Spinner'
import StringConstants from '../../../constants/StringConstants'
import Table from '../../../components/common/DDS/Table/Table'
import { ToastMessageConstants } from '../../../constants/ToastMessageConstants'
import ToastNotification from '../../../components/common/DDS/Toast/Toast'
import Tooltip from '../../../components/New/Tooltip/Tooltip'
import { findRecords } from '../../../api/apiMonitoring/ApiMonitoring.service'
import { getAllQueryParamsAsObjectFromUrl } from '../../../utils/UrlUtils'
import { isNull } from 'lodash'
import { mapStatusCodeToColor } from '../../../utils/ApiStatusColorCode'
import { observer } from 'mobx-react-lite'
import pallete from '../../../global/newPallete'
import { timeFormats } from '../../../enum/TIME'
import { useAnalyticsEventTracker } from '../../../utils/GAUtils'
import { useStoreContext } from '../../../store/StoreContext'

type ApiRecord = { [key: string]: string }
type SortOrder = 'ASC' | 'DESC' | 'CLEAR'
type SortState = null | SortOrder | 'CLEAR'

interface viewHeaderGroupedApiDataProp {
  viewHeaderGroupedApiData: () => Promise<void>
  routeGroupedDetails: (id: any) => void
  setTriggerFilter?: (value: boolean) => void
}

const GroupedAPILogTable: React.FC<viewHeaderGroupedApiDataProp> = ({
  viewHeaderGroupedApiData,
  routeGroupedDetails,
  setTriggerFilter
}) => {
  const [tableData, setTableData] = useState<any>()
  const [loading, setLoading] = useState<boolean>(false)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [totalCount, setTotalCount] = useState<number>(0)
  const [activeSortOrder, setActiveSortOrder] = useState<{
    field: string | null
    order: SortOrder | null
  }>({
    field: null,
    order: null
  })

  const [searchParams, setSearchParams] = useSearchParams()

  const navigate = useNavigate()
  const { id } = useParams()

  const store = useStoreContext()
  const gaEventTracker = useAnalyticsEventTracker(
    gaPageConstants.API_MONITORING_TABLE
  )

  const organizations = store.userStore.getOrganizations()
  const foundOrganization = organizations.find(
    (organization: any) =>
      organization.id === store.scopeStore.getOrganizationId()
  )

  const fetchGroupApiRecord = async () => {
    store.apiDetailsStore.reset()
    store.uiStore.setGlobalLoader(true)
    setLoading(true)
    const queryParams = getAllQueryParamsAsObjectFromUrl(location.search)
    const pageSize = StringConstants.DEFAULT_TABLE_SIZE
    const searchParams = new URLSearchParams(location.search)
    // Retrieve sort parameters from URL
    const urlSortField = searchParams.get('sortField')
    const urlSortOrder = searchParams.get('sortOrder')
    const params: any = {
      ...queryParams,
      'api-group-id': id,
      size: pageSize
    }
    if (urlSortField && urlSortOrder && urlSortOrder !== 'CLEAR') {
      params[urlSortField] = urlSortOrder
    }
    if (!id) return
    try {
      const { data, totalCount, filters } = (await findRecords({
        ...params
      })) as {
        data: ApiRecord[]
        totalCount: number
        filters: any
      }
      if (data.length > 0 || store.filterStore.getFiltersApplied()) {
        store.filterStore.setFilterDisabled(false)
      } else {
        store.filterStore.setFilterDisabled(true)
      }
      setTableData(data)
      setTotalCount(totalCount)
      store.filterStore.setFilters(filters)
    } catch (error) {
      store.apiDetailsStore.setIsError(true)
      const errorMessage =
        error instanceof Error ? error.message : 'An unknown error occurred'
      ToastNotification({
        type: 'error',
        message:
          ToastMessageConstants.API_MONITORING.FETCH_DETAILS.ERROR ||
          errorMessage
      })
      store.uiStore.setGlobalLoader(false)
      setLoading(false)
    } finally {
      store.uiStore.setGlobalLoader(false)
      setLoading(false)
    }
  }
  const sortData = async (sortField: string, sortOrder: SortOrder) => {
    store.apiDetailsStore.reset()
    store.uiStore.setGlobalLoader(true)
    setLoading(true)
    const queryParams = getAllQueryParamsAsObjectFromUrl(location.search)
    const pageSize = StringConstants.DEFAULT_TABLE_SIZE
    const params: any = {
      ...queryParams,
      'api-group-id': id,
      size: pageSize
    }
    if (sortOrder !== 'CLEAR') {
      params[sortField] = sortOrder
      // Persist sort information in URL
      const searchParams = new URLSearchParams(location.search)
      searchParams.set('sortField', sortField)
      searchParams.set('sortOrder', sortOrder)
      window.history.replaceState({}, '', `?${searchParams.toString()}`)
    } else {
      delete params[sortField] // Ensure sorting is removed
      delete params[sortField]
      // Remove sort params from URL
      const searchParams = new URLSearchParams(location.search)
      searchParams.delete('sortField')
      searchParams.delete('sortOrder')
      window.history.replaceState({}, '', `?${searchParams.toString()}`)
    }
    if (!id) return
    try {
      const { data, totalCount, filters } = (await findRecords({
        ...params
      })) as {
        data: ApiRecord[]
        totalCount: number
        filters: any
      }
      if (data.length > 0 || store.filterStore.getFiltersApplied()) {
        store.filterStore.setFilterDisabled(false)
      } else {
        store.filterStore.setFilterDisabled(true)
      }
      setTableData(data)
      setTotalCount(totalCount)
      store.filterStore.setFilters(filters)
    } catch (error) {
      store.apiDetailsStore.setIsError(true)
      const errorMessage =
        error instanceof Error ? error.message : 'An unknown error occurred'
      ToastNotification({
        type: 'error',
        message:
          ToastMessageConstants.API_MONITORING.FETCH_DETAILS.ERROR ||
          errorMessage
      })
      store.uiStore.setGlobalLoader(false)
      setLoading(false)
    } finally {
      if (setTriggerFilter) {
        setTriggerFilter(false) // Only call if defined
      }
      store.uiStore.setGlobalLoader(false)
      setLoading(false)
    }
  }
  const handleSortCycle = (sortField: string) => {
    setActiveSortOrder((prev) => {
      if (prev.field === sortField) {
        // Cycle through ASC, DESC, and CLEAR
        const nextOrder =
          prev.order === 'ASC'
            ? 'DESC'
            : prev.order === 'DESC'
              ? 'CLEAR'
              : 'ASC'
        // Always call sortData, even for "CLEAR"
        sortData(sortField, nextOrder)
        return {
          field: nextOrder !== 'CLEAR' ? sortField : null,
          order: nextOrder
        }
      } else {
        // Activate new field with ASC
        sortData(sortField, 'ASC')
        return { field: sortField, order: 'ASC' }
      }
    })
  }
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const urlSortField = searchParams.get('sortField')
    const urlSortOrder = searchParams.get('sortOrder')
    if (urlSortField && urlSortOrder) {
      // Initialize the sort state from URL
      setActiveSortOrder({
        field: urlSortField,
        order: urlSortOrder as SortOrder
      })
    }
  }, [location.search])

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const sortField = searchParams.get('sortField')
    const sortOrder = searchParams.get('sortOrder') as SortOrder

    if (!sortField || !sortOrder) {
      setActiveSortOrder({ field: null, order: null })
    } else {
      setActiveSortOrder({ field: sortField, order: sortOrder })
    }
  }, [location.search])

  const fetchGroupSubscriptionApiRecord = async () => {
    store.apiDetailsStore.reset()
    store.uiStore.setGlobalLoader(true)
    setLoading(true)
    const queryParams = getAllQueryParamsAsObjectFromUrl(location.search)
    const searchParams = new URLSearchParams(location.search)

    const urlSortField = searchParams.get('sortField')
    const urlSortOrder = searchParams.get('sortOrder')

    const allowedSortOrders = ['ASC', 'DESC']

    if (
      urlSortField &&
      urlSortOrder &&
      allowedSortOrders.includes(urlSortOrder)
    ) {
      queryParams[urlSortField] = urlSortOrder
    }
    const pageSize = StringConstants.DEFAULT_TABLE_SIZE
    if (!id) return
    try {
      const { data, totalCount, filters } = (await findRecords({
        ...queryParams,
        'api-group-id': id,
        size: pageSize
      })) as {
        data: ApiRecord[]
        totalCount: number
        filters: any
      }
      if (data.length > 0 || store.filterStore.getFiltersApplied()) {
        store.filterStore.setFilterDisabled(false)
      } else {
        store.filterStore.setFilterDisabled(true)
      }
      setTableData(data)
      setTotalCount(totalCount)
      store.filterStore.setFilters(filters)
    } catch (error) {
      store.apiDetailsStore.setIsError(true)
      const errorMessage =
        error instanceof Error ? error.message : 'An unknown error occurred'
      ToastNotification({
        type: 'error',
        message:
          ToastMessageConstants.API_MONITORING.FETCH_DETAILS.ERROR ||
          errorMessage
      })
      store.uiStore.setGlobalLoader(false)
      setLoading(false)
    } finally {
      store.uiStore.setGlobalLoader(false)
      setLoading(false)
    }
  }

  useEffect(() => {
    const pageFromQueryParams = searchParams.get('page')

    if (!isNull(pageFromQueryParams)) {
      setCurrentPage(Number(pageFromQueryParams))

      if (foundOrganization?.subscription?.trackApiUsage !== true) {
        fetchGroupSubscriptionApiRecord()
      } else {
        fetchGroupApiRecord()
      }
    } else {
      setSearchParams({ page: '1' })
    }
  }, [store.scopeStore.getScope(), searchParams])

  useEffect(() => {
    if (store.apiDetailsStore.getRefresh() === true) {
      setLoading(true)
      store.uiStore.setGlobalLoader(true)
      fetchGroupApiRecord()
      viewHeaderGroupedApiData()
    }
  }, [store.apiDetailsStore.getRefresh()])

  const columns = [
    ...(foundOrganization?.subscription?.trackApiUsage === true
      ? [
          {
            columnHeaderStyle: { width: '300px' },
            columnDataStyle: { width: '300px' },
            heading: (
              <div className='flex whitespace-nowrap justify-start space-x-[8px] items-start'>
                <div>
                  <DDSTypography.Paragraph
                    size='caption'
                    variant='semiBold'
                    color={pallete.colors.textDark6}
                  >
                    {ApiGroupingStringConstants.USER}
                  </DDSTypography.Paragraph>
                </div>
                <div
                  className='flex flex-col justify-center -space-y-[6px]'
                  onClick={() => handleSortCycle('sort-url-user-name')}
                >
                  <CaretUp
                    size={12}
                    style={{
                      color:
                        activeSortOrder.field === 'sort-url-user-name' &&
                        activeSortOrder.order === 'ASC'
                          ? pallete.colors.FillButton
                          : activeSortOrder.field === 'sort-url-user-name' &&
                              activeSortOrder.order === 'DESC'
                            ? pallete.colors.outButtonDis
                            : pallete.colors.textDark6
                    }}
                    className='cursor-pointer'
                  />
                  <CaretDown
                    size={12}
                    style={{
                      color:
                        activeSortOrder.field === 'sort-url-user-name' &&
                        activeSortOrder.order === 'ASC'
                          ? pallete.colors.outButtonDis
                          : activeSortOrder.field === 'sort-url-user-name' &&
                              activeSortOrder.order === 'DESC'
                            ? pallete.colors.FillButton
                            : pallete.colors.textDark6
                    }}
                    className='cursor-pointer'
                  />
                </div>
              </div>
            ),
            dataLabel: 'apiUserName',
            render: (text: any) => (
              <div className='flex justify-start text-start row items-start max-w-[230px]'>
                <div>
                  <Tooltip
                    id={`tooltip-name-${text}`}
                    label={text}
                    className={`override-tooltip-arrow ${
                      typeof text === 'string' && text.length > 2
                        ? text
                        : '!hidden'
                    }`}
                  >
                    <div>
                      <DDSTypography.Paragraph
                        size='para'
                        variant='medium'
                        color={pallete.colors.textDark4}
                        className='tooltip-container sm:block overflow-hidden truncate max-w-[230px]'
                      >
                        {text || text === 0 ? text : '-'}
                      </DDSTypography.Paragraph>
                    </div>
                  </Tooltip>
                </div>
              </div>
            )
          }
        ]
      : []),
    {
      columnHeaderStyle: {},
      columnDataStyle: {},
      heading: (
        <div className='flex whitespace-nowrap justify-center space-x-[8px] items-center'>
          <div>
            <DDSTypography.Paragraph
              size='caption'
              variant='semiBold'
              color={pallete.colors.textDark6}
            >
              {ApiGroupingStringConstants.CLIENT_VERSION}
            </DDSTypography.Paragraph>
          </div>
          <div
            className='flex flex-col justify-center -space-y-[6px]'
            onClick={() => handleSortCycle('sort-client-version')}
          >
            <CaretUp
              size={12}
              style={{
                color:
                  activeSortOrder.field === 'sort-client-version' &&
                  activeSortOrder.order === 'ASC'
                    ? pallete.colors.FillButton
                    : activeSortOrder.field === 'sort-client-version' &&
                        activeSortOrder.order === 'DESC'
                      ? pallete.colors.outButtonDis
                      : pallete.colors.textDark6
              }}
              className='cursor-pointer'
            />
            <CaretDown
              size={12}
              style={{
                color:
                  activeSortOrder.field === 'sort-client-version' &&
                  activeSortOrder.order === 'ASC'
                    ? pallete.colors.outButtonDis
                    : activeSortOrder.field === 'sort-client-version' &&
                        activeSortOrder.order === 'DESC'
                      ? pallete.colors.FillButton
                      : pallete.colors.textDark6
              }}
              className='cursor-pointer'
            />
          </div>
        </div>
      ),
      dataLabel: 'clientVersion',
      render: (text: any) => (
        <div className='flex justify-center text-center row items-center'>
          <DDSTypography.Paragraph
            size='para'
            variant='medium'
            color={pallete.colors.textDark4}
            className='tooltip-container sm:block overflow-hidden truncate'
          >
            {text || text === 0 ? text : '-'}
          </DDSTypography.Paragraph>
        </div>
      )
    },

    {
      columnHeaderStyle: {},
      columnDataStyle: {},
      heading: (
        <div className='flex whitespace-nowrap justify-center space-x-[8px] items-center'>
          <div>
            <DDSTypography.Paragraph
              size='caption'
              variant='semiBold'
              color={pallete.colors.textDark6}
            >
              {ApiGroupingStringConstants.RESPONSE_STATUS}
            </DDSTypography.Paragraph>
          </div>
          <div
            className='flex flex-col justify-center -space-y-[6px]'
            onClick={() => handleSortCycle('sort-response-status')}
          >
            <CaretUp
              size={12}
              style={{
                color:
                  activeSortOrder.field === 'sort-response-status' &&
                  activeSortOrder.order === 'ASC'
                    ? pallete.colors.FillButton
                    : activeSortOrder.field === 'sort-response-status' &&
                        activeSortOrder.order === 'DESC'
                      ? pallete.colors.outButtonDis
                      : pallete.colors.textDark6
              }}
              className='cursor-pointer'
            />
            <CaretDown
              size={12}
              style={{
                color:
                  activeSortOrder.field === 'sort-response-status' &&
                  activeSortOrder.order === 'ASC'
                    ? pallete.colors.outButtonDis
                    : activeSortOrder.field === 'sort-response-status' &&
                        activeSortOrder.order === 'DESC'
                      ? pallete.colors.FillButton
                      : pallete.colors.textDark6
              }}
              className='cursor-pointer'
            />
          </div>
        </div>
      ),
      dataLabel: 'responseStatusCode',
      render: (text: any) => (
        <div
          className={`flex justify-center override-statusCode-center override-status-chip`}
        >
          <DDSChips
            label={text}
            type='filled'
            shape='rounded'
            size='small'
            className='override-status-chip'
            style={{
              backgroundColor: mapStatusCodeToColor(text),
              borderColor: 'transparent',
              height: '24px',
              width: '60px',
              justifyContent: 'center'
            }}
          />
        </div>
      )
    },
    {
      columnHeaderStyle: {},
      columnDataStyle: {},
      heading: (
        <div className='flex whitespace-nowrap justify-center space-x-[8px] items-center'>
          <div>
            <DDSTypography.Paragraph
              size='caption'
              variant='semiBold'
              color={pallete.colors.textDark6}
            >
              {ApiGroupingStringConstants.START_TIME}
            </DDSTypography.Paragraph>
          </div>
          <div
            className='flex flex-col justify-center -space-y-[6px]'
            onClick={() => handleSortCycle('sort-start-time')}
          >
            <CaretUp
              size={12}
              style={{
                color:
                  activeSortOrder.field === 'sort-start-time' &&
                  activeSortOrder.order === 'ASC'
                    ? pallete.colors.FillButton
                    : activeSortOrder.field === 'sort-start-time' &&
                        activeSortOrder.order === 'DESC'
                      ? pallete.colors.outButtonDis
                      : pallete.colors.textDark6
              }}
              className='cursor-pointer'
            />
            <CaretDown
              size={12}
              style={{
                color:
                  activeSortOrder.field === 'sort-start-time' &&
                  activeSortOrder.order === 'ASC'
                    ? pallete.colors.outButtonDis
                    : activeSortOrder.field === 'sort-start-time' &&
                        activeSortOrder.order === 'DESC'
                      ? pallete.colors.FillButton
                      : pallete.colors.textDark6
              }}
              className='cursor-pointer'
            />
          </div>
        </div>
      ),
      dataLabel: 'startTime',
      render: (text: any) => (
        <div className='flex justify-center text-center row items-center'>
          <DDSTypography.Paragraph
            size='para'
            variant='medium'
            color={pallete.colors.textDark4}
            style={{ justifyContent: 'center' }}
            className='tooltip-container sm:block overflow-hidden truncate'
          >
            {timeZone(
              store.userStore.getOrganizationPreference().timeZone,
              text,
              timeFormats.fullMonthDateYearTimeWithSeconds
            )}
          </DDSTypography.Paragraph>
        </div>
      )
    },
    {
      columnHeaderStyle: {},
      columnDataStyle: {},
      heading: (
        <div className='flex whitespace-nowrap justify-center space-x-[8px] items-center'>
          <div>
            <DDSTypography.Paragraph
              size='caption'
              variant='semiBold'
              color={pallete.colors.textDark6}
            >
              {ApiGroupingStringConstants.RESPONSE_TIME}
            </DDSTypography.Paragraph>
          </div>
          <div
            className='flex flex-col justify-center -space-y-[6px]'
            onClick={() => handleSortCycle('sort-response-time')}
          >
            <CaretUp
              size={12}
              style={{
                color:
                  activeSortOrder.field === 'sort-response-time' &&
                  activeSortOrder.order === 'ASC'
                    ? pallete.colors.FillButton
                    : activeSortOrder.field === 'sort-response-time' &&
                        activeSortOrder.order === 'DESC'
                      ? pallete.colors.outButtonDis
                      : pallete.colors.textDark6
              }}
              className='cursor-pointer'
            />
            <CaretDown
              size={12}
              style={{
                color:
                  activeSortOrder.field === 'sort-response-time' &&
                  activeSortOrder.order === 'ASC'
                    ? pallete.colors.outButtonDis
                    : activeSortOrder.field === 'sort-response-time' &&
                        activeSortOrder.order === 'DESC'
                      ? pallete.colors.FillButton
                      : pallete.colors.textDark6
              }}
              className='cursor-pointer'
            />
          </div>
        </div>
      ),
      dataLabel: 'responseTimeInMilliseconds',
      render: (text: any) => (
        <div className='flex justify-center text-center row items-center'>
          <DDSTypography.Paragraph
            size='para'
            variant='medium'
            color={pallete.colors.textDark4}
            style={{ justifyContent: 'center' }}
            className='tooltip-container sm:block overflow-hidden truncate'
          >
            {text ? msToTimeUnits(text) : '-'}
          </DDSTypography.Paragraph>
        </div>
      )
    },

    {
      columnHeaderStyle: { width: '20px' },
      columnDataStyle: { width: '20px' },
      heading: '',
      dataLabel: '',
      render: (text: any, fullItem: any) => (
        <div className='cursor-pointer'>
          <ChevronRight
            size={16}
            onClick={() => {
              gaEventTracker(gaEventConstants.VIEW_SINGLE_API_RECORD)
              routeGroupedDetails(fullItem.id)
            }}
            color={pallete.colors.FillButton}
          />
        </div>
      )
    }
  ]
  return (
    <>
      {loading || store.uiStore.getGlobalLoader() || !tableData ? (
        <Spinner />
      ) : tableData.length !== 0 ? (
        <div className='mt-[24px] flex w-full '>
          <Table
            tableStyle={{
              borderColor: pallete.colors.stroke2
            }}
            data={tableData}
            columns={columns}
            totalCount={totalCount}
            rowsPerPage={10}
            currentPage={currentPage}
            onPageChange={(page: number) => {
              const allQueryParams = getAllQueryParamsAsObjectFromUrl(
                location.search
              )
              setCurrentPage(page)
              setSearchParams({ ...allQueryParams, page: page.toString() })
            }}
            loading={loading}
          />
        </div>
      ) : (
        <>
          <NoRecord
            imageSrc={
              totalCount === 0 && store.filterStore.getFiltersApplied()
                ? NoFilterImage
                : NoRecordImage
            }
            style={{ height: '74vh' }}
            text={
              totalCount === 0 && store.filterStore.getFiltersApplied() ? (
                <>No results found for applied filter.</>
              ) : (
                <>
                  Configure API Monitoring.
                  <Link
                    href={StringConstants.FULLSTACK_URL}
                    target='_blank'
                    rel='noopener noreferrer'
                    style={{ marginLeft: '4px' }}
                  >
                    Learn More
                  </Link>
                </>
              )
            }
          />
        </>
      )}
    </>
  )
}

export default observer(GroupedAPILogTable)
