import React, { useEffect, useState } from 'react'
import {
  downloadAllUrlView,
  findRecords,
  getAPIFilters
} from '../../../api/apiMonitoring/ApiMonitoring.service'
import { useNavigate, useSearchParams } from 'react-router-dom'

import AllAPIViewURL from './Screen/AllAPIViewURL'
import AllAPIViewUser from './Screen/AllAPIViewUser'
import { Button } from '../../New/Button/Button'
import ContextSwitcherTabs from '../../common/DDS/ContextSwitcherTab/ContextSwitcherTab'
import { Download } from '@carbon/icons-react'
import Filter from '../../Filter/Filter'
import SideChip from '../../New/SideChip/SideChip'
import StringConstants from '../../../constants/StringConstants'
import { ToastMessageConstants } from '../../../constants/ToastMessageConstants'
import ToastNotification from '../../common/DDS/Toast/Toast'
import { convertJsonToCsvDownload } from '../../../utils/FileUtils'
import { convertToFormat } from '../../../utils/UCUtils'
import { getAllQueryParamsAsObjectFromUrl } from '../../../utils/UrlUtils'
import { getOrgTimeRange } from '../../../utils/DateTimeUtils'
import moment from 'moment'
import { observer } from 'mobx-react-lite'
import pallete from '../../../global/newPallete'
import { useStoreContext } from '../../../store/StoreContext'

type SortOrder = 'ASC' | 'DESC' | 'CLEAR'
type SortState = null | SortOrder | 'CLEAR'

interface AllAPITabProps {
  allAPITabIndex: string
}

const AllAPITab: React.FC<AllAPITabProps> = ({ allAPITabIndex }) => {
  const store = useStoreContext()
  const navigate = useNavigate()
  const [activeSortOrder, setActiveSortOrder] = useState<{
    field: string | null
    order: SortOrder | null
  }>({
    field: null,
    order: null
  })

  const handleSortCycle = (sortField: string) => {
    setActiveSortOrder((prev) => {
      if (prev.field === sortField) {
        // Cycle through ASC, DESC, and CLEAR
        const nextOrder =
          prev.order === 'ASC'
            ? 'DESC'
            : prev.order === 'DESC'
              ? 'CLEAR'
              : 'ASC'
        // Always call sortData, even for "CLEAR"
        if (tabIndex === '1') {
          sortData(sortField, nextOrder)
        } else {
          sortUserData(sortField, nextOrder)
        }

        return {
          field: nextOrder !== 'CLEAR' ? sortField : null,
          order: nextOrder
        }
      } else {
        // Activate new field with ASC
        if (tabIndex === '1') {
          sortData(sortField, 'ASC')
        } else {
          sortUserData(sortField, 'ASC')
        }
        return { field: sortField, order: 'ASC' }
      }
    })
  }
  const orgTimeZone = store.userStore.getOrganizationPreference().timeZone
  const [searchParams, setSearchParams] = useSearchParams()
  const [tabIndex, setTabIndex] = useState<string>(
    searchParams.get('contextSwitcherTab') ?? '1'
  )
  const [userTotalCount, setUserTotalCount] = useState<number>(0)
  const [userData, setUserData] = useState<any>()
  const [filterCache, setFilterCache] = useState<{ [key: string]: any[] }>({})
  const [filterLoading, setFilterLoading] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [options, setOptions] = useState<any>([])
  const pageSize = StringConstants.DEFAULT_TABLE_SIZE
  const [totalCount, setTotalCount] = useState<number>()
  const [data, setData] = useState<any>()
  const [dateFilter, setDateFilter] = useState<any>({})
  const [UserDateFilter, setUserDateFilter] = useState<any>({})

  const loadFilters = (type: string, apiCall: () => void) => {
    if (filterCache[type]) {
      setOptions(filterCache[type])
    } else {
      apiCall()
    }
  }

  const fetchAllRecords = (queryParams: any) => {
    const allQueryParams = getAllQueryParamsAsObjectFromUrl(location.search)
    const searchParams = new URLSearchParams(location.search)
    // Retrieve sort parameters from URL
    const urlSortField = searchParams.get('sortField')
    const urlSortOrder = searchParams.get('sortOrder')
    setLoading(true)
    store.uiStore.setGlobalLoader(true)

    const params: any = {
      ...allQueryParams,
      size: pageSize
    }
    if (urlSortField && urlSortOrder && urlSortOrder !== 'CLEAR') {
      params[urlSortField] = urlSortOrder
    }

    const timeZone = store.userStore.getOrganizationPreference()?.timeZone

    const { startOfMonth, endOfCurrentDayTimestamp } = getOrgTimeRange(timeZone)

    findRecords({ ...params, size: pageSize })
      .then((apiReport: any) => {
        if (
          apiReport.data.length > 0 ||
          store.filterStore.getFiltersApplied()
        ) {
          store.filterStore.setFilterDisabled(false)
        } else {
          store.filterStore.setFilterDisabled(true)
        }

        setData(apiReport.data)
        store.apiDetailsStore.setRefresh(false)
        setTotalCount(apiReport.totalCount)

        const filters = {
          'created-from': moment
            .tz(apiReport.filters?.['created-from'] || startOfMonth, timeZone)
            .toISOString(),
          'created-to': moment
            .tz(
              apiReport.filters?.['created-to'] || endOfCurrentDayTimestamp,
              timeZone
            )
            .toISOString(),
          'time-zone': timeZone
        }

        store.filterStore.setFilters(apiReport.filters)
        setDateFilter(filters)
        if (urlSortField && urlSortOrder) {
          setActiveSortOrder({
            field: urlSortField,
            order: urlSortOrder as SortOrder
          })
        }
      })
      .catch((e) => {
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.API_MONITORING.FETCH_DETAILS.ERROR
        })
      })
      .finally(() => {
        setLoading(false)
        store.uiStore.setGlobalLoader(false)
      })
  }

  const fetchAllUserRecords = (queryParams: any) => {
    const allQueryParams = getAllQueryParamsAsObjectFromUrl(location.search)
    const searchParams = new URLSearchParams(location.search)
    // Retrieve sort parameters from URL
    const urlSortField = searchParams.get('sortField')
    const urlSortOrder = searchParams.get('sortOrder')
    setLoading(true)
    store.uiStore.setGlobalLoader(true)
    const params: any = {
      ...allQueryParams,
      size: pageSize
    }
    if (urlSortField && urlSortOrder && urlSortOrder !== 'CLEAR') {
      params[urlSortField] = urlSortOrder
    }

    const timeZone = store.userStore.getOrganizationPreference()?.timeZone

    const { startOfMonth, endOfCurrentDayTimestamp } = getOrgTimeRange(timeZone)

    findRecords({ ...params, size: pageSize, view: 'USER' })
      .then((apiReport: any) => {
        if (
          apiReport.data.length > 0 ||
          store.filterStore.getFiltersApplied()
        ) {
          store.filterStore.setFilterDisabled(false)
        } else {
          store.filterStore.setFilterDisabled(true)
        }
        setUserData(apiReport.data)
        store.apiDetailsStore.setRefresh(false)
        setUserTotalCount(apiReport.totalCount)
        if (urlSortField && urlSortOrder) {
          setActiveSortOrder({
            field: urlSortField,
            order: urlSortOrder as SortOrder
          })
        }

        const filters = {
          'created-from': moment
            .tz(apiReport.filters?.['created-from'] || startOfMonth, timeZone)
            .toISOString(),
          'created-to': moment
            .tz(
              apiReport.filters?.['created-to'] || endOfCurrentDayTimestamp,
              timeZone
            )
            .toISOString(),
          'time-zone': timeZone
        }

        store.filterStore.setFilters(apiReport.filters)
        setUserDateFilter(filters)
      })
      .catch((e) => {
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.API_MONITORING.FETCH_DETAILS.ERROR
        })
      })
      .finally(() => {
        setLoading(false)
        store.uiStore.setGlobalLoader(false)
      })
  }

  const sortData = (sortField: string, sortOrder: SortOrder) => {
    const queryParams = getAllQueryParamsAsObjectFromUrl(location.search)
    store.uiStore.setGlobalLoader(true)
    setLoading(true)
    const params: any = {
      ...searchParams,
      ...queryParams,
      size: pageSize
    }
    // Handle "CLEAR" by removing sort-related params
    if (sortOrder !== 'CLEAR') {
      params[sortField] = sortOrder
      // Persist sort information in URL
      const searchParams = new URLSearchParams(location.search)
      searchParams.set('sortField', sortField)
      searchParams.set('sortOrder', sortOrder)
      window.history.replaceState({}, '', `?${searchParams.toString()}`)
    } else {
      delete params[sortField] // Ensure sorting is removed
      //delete params[sortField]
      // Remove sort params from URL
      const searchParams = new URLSearchParams(location.search)
      searchParams.delete('sortField')
      searchParams.delete('sortOrder')
      window.history.replaceState({}, '', `?${searchParams.toString()}`)
    }
    const timeZone = store.userStore.getOrganizationPreference()?.timeZone
    const { startOfMonth, endOfCurrentDayTimestamp } = getOrgTimeRange(timeZone)

    const paramsWithDateRange = {
      ...params,
      'created-from': queryParams['created-from'] || startOfMonth,
      'created-to': queryParams['created-to'] || endOfCurrentDayTimestamp,
      'time-zone': timeZone
    }

    findRecords({ ...paramsWithDateRange })
      .then((apiReport: any) => {
        setData(apiReport.data)
        store.apiDetailsStore.setRefresh(false)
        setTotalCount(apiReport.totalCount)
        store.filterStore.setFilters(apiReport.filters)
      })
      .catch((e) => {
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.API_MONITORING.FETCH_DETAILS.ERROR
        })
      })
      .finally(() => {
        setLoading(false)
        store.uiStore.setGlobalLoader(false)
      })
  }

  const sortUserData = (sortField: string, sortOrder: SortOrder) => {
    const queryParams = getAllQueryParamsAsObjectFromUrl(location.search)
    store.uiStore.setGlobalLoader(true)
    setLoading(true)
    const params: any = {
      ...searchParams,
      ...queryParams,
      size: pageSize
    }
    // Handle "CLEAR" by removing sort-related params
    if (sortOrder !== 'CLEAR') {
      params[sortField] = sortOrder
      // Persist sort information in URL
      const searchParams = new URLSearchParams(location.search)
      searchParams.set('sortField', sortField)
      searchParams.set('sortOrder', sortOrder)
      window.history.replaceState({}, '', `?${searchParams.toString()}`)
    } else {
      delete params[sortField] // Ensure sorting is removed
      //delete params[sortField]
      // Remove sort params from URL
      const searchParams = new URLSearchParams(location.search)
      searchParams.delete('sortField')
      searchParams.delete('sortOrder')
      window.history.replaceState({}, '', `?${searchParams.toString()}`)
    }
    const timeZone = store.userStore.getOrganizationPreference()?.timeZone
    const { startOfMonth, endOfCurrentDayTimestamp } = getOrgTimeRange(timeZone)

    const paramsWithDateRange = {
      ...params,
      'created-from': queryParams['created-from'] || startOfMonth,
      'created-to': queryParams['created-to'] || endOfCurrentDayTimestamp,
      'time-zone': timeZone
    }

    findRecords({ ...paramsWithDateRange, size: pageSize, view: 'USER' })
      .then((apiReport: any) => {
        setUserData(apiReport.data)
        store.apiDetailsStore.setRefresh(false)
        setUserTotalCount(apiReport.totalCount)
        store.filterStore.setFilters(apiReport.filters)
      })
      .catch((e) => {
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.API_MONITORING.FETCH_DETAILS.ERROR
        })
      })
      .finally(() => {
        setLoading(false)
        store.uiStore.setGlobalLoader(false)
      })
  }

  // Initialize sorting from URL on component mount
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const urlSortField = searchParams.get('sortField')
    const urlSortOrder = searchParams.get('sortOrder')
    if (urlSortField && urlSortOrder) {
      // Initialize the sort state from URL
      setActiveSortOrder({
        field: urlSortField,
        order: urlSortOrder as SortOrder
      })
    }
  }, [location.search, searchParams])

  useEffect(() => {
    const fetchFilters = async () => {
      try {
        store.uiStore.setGlobalLoader(true)

        if (allAPITabIndex === '2' && tabIndex === '1') {
          await allAPIUrlFilter()
        }
        if (allAPITabIndex === '2' && tabIndex === '2') {
          await allAPIUserFilter()
        }
      } catch (error) {
        ToastNotification({
          type: 'error',
          message: String(error) || 'Unable to fetch organization data'
        })
      } finally {
        store.uiStore.setGlobalLoader(false)
      }
    }

    fetchFilters()
  }, [tabIndex, allAPITabIndex])

  const allAPIUrlFilter = () => {
    setFilterLoading(true)
    getAPIFilters()
      .then((response: any) => {
        const filterOptions = response.data.map((item: any) => {
          if (item?.heading === 'Start time') {
            return {
              ...item,
              heading: 'Date Range'
            }
          }
          return item
        })
        setOptions(filterOptions)
        setFilterCache((prev) => ({ ...prev, url: filterOptions }))
      })
      .catch(() => {
        ToastNotification({
          type: 'error',
          message:
            ToastMessageConstants.API_MONITORING.API_MONITORING_FILTERS.ERROR
        })
      })
      .finally(() => {
        setFilterLoading(false)
      })
  }

  const allAPIUserFilter = () => {
    setFilterLoading(true)
    getAPIFilters()
      .then((response: any) => {
        const filteredOptions = response.data
          .map((item: any) => {
            if (item?.heading === 'Start time') {
              return {
                ...item,
                heading: 'Date Range'
              }
            }
            return item
          })
          .filter((item: any) => item?.heading !== 'URL Template')

        setOptions(filteredOptions)
        setFilterCache((prev) => ({ ...prev, user: filteredOptions }))
      })
      .catch(() => {
        ToastNotification({
          type: 'error',
          message:
            ToastMessageConstants.API_MONITORING.API_MONITORING_FILTERS.ERROR
        })
      })
      .finally(() => {
        setFilterLoading(false)
      })
  }

  // const handleFilterLoadUrl = () => loadFilters('url', allAPIUrlFilter)

  // const handleFilterLoadUser = () => loadFilters('user', allAPIUserFilter)

  useEffect(() => {
    localStorage.setItem(
      StringConstants.ACTIVE_TAB,
      StringConstants.TAB_VALUES.API_MONITORING
    )
  }, [tabIndex, store.scopeStore.getScope()])

  const downloadRecords = () => {
    const allQueryParams = getAllQueryParamsAsObjectFromUrl(location.search)
    store.uiStore.setGlobalLoader(true)
    setLoading(true)
    const queryParamsForDownload = {
      ...allQueryParams,
      'time-zone': store.userStore.getOrganizationPreference().timeZone
    }
    downloadAllUrlView(queryParamsForDownload)
      .then((response: any) => {
        const date = new Date()
        const formattedDate = `${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}-${date.getFullYear()}`
        convertJsonToCsvDownload(response.data, `All_API_${formattedDate}.csv`)
      })
      .catch((err) => {
        ToastNotification({
          type: 'error',
          message: 'Error downloading log in all API URL view'
        })
      })
      .finally(() => {
        setLoading(false)
        store.uiStore.setGlobalLoader(false)
      })
  }

  const downloadRecordsNoUserName = () => {
    const allQueryParams = getAllQueryParamsAsObjectFromUrl(location.search)
    store.uiStore.setGlobalLoader(true)
    setLoading(true)
    const queryParamsForDownload = {
      ...allQueryParams,
      'time-zone': store.userStore.getOrganizationPreference().timeZone
    }

    downloadAllUrlView(queryParamsForDownload)
      .then((response: any) => {
        const filteredData = response.data.map(
          ({
            apiUserName,
            apiUserId,
            ...rest
          }: {
            apiUserName: string
            apiUserId: string
            [key: string]: any
          }) => rest
        )

        const date = new Date()
        const formattedDate = `${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}-${date.getFullYear()}`
        convertJsonToCsvDownload(filteredData, `All_API_${formattedDate}.csv`)
      })
      .catch((err) => {
        ToastNotification({
          type: 'error',
          message: 'Error downloading log in all API URL view'
        })
      })
      .finally(() => {
        setLoading(false)
        store.uiStore.setGlobalLoader(false)
      })
  }

  const organizations = store.userStore.getOrganizations()
  const foundOrganization = organizations.find(
    (organization: any) =>
      organization.id === store.scopeStore.getOrganizationId()
  )

  const ContextSwitcherTab = [
    {
      id: '1',
      label: 'URL View',
      children: (
        <div>
          <AllAPIViewURL
            loading={loading}
            setLoading={setLoading}
            totalCount={totalCount}
            data={data}
            setActiveSortOrder={setActiveSortOrder}
            fetchAllRecords={fetchAllRecords}
            activeSortOrder={activeSortOrder}
            handleSortCycle={handleSortCycle}
          />
        </div>
      )
    },
    {
      id: '2',
      label: 'User View',
      children: (
        <div className='relative'>
          <AllAPIViewUser
            loading={loading}
            setLoading={setLoading}
            setActiveSortOrder={setActiveSortOrder}
            totalCount={userTotalCount}
            data={userData}
            fetchAllRecords={fetchAllUserRecords}
            activeSortOrder={activeSortOrder}
            handleSortCycle={handleSortCycle}
          />
        </div>
      )
    }
  ]

  return (
    <>
      {foundOrganization?.subscription?.trackApiUsage === true ? (
        <div className='pt-[24px]'>
          <div className='flex items-center justify-between w-full'>
            <div className='flex-grow override-view-more-tabs'>
              <ContextSwitcherTabs
                tabOptions={ContextSwitcherTab}
                shape='roundedRectangle'
                tabStyle={{
                  border: '1px solid',
                  borderColor: pallete.colors.stroke2,
                  userSelect: 'none',
                  width: '204px'
                }}
                tabTitleClassName='text-center select-none'
                activeTab={tabIndex}
                onChange={(index: any) => {
                  setTabIndex(index)
                  setSearchParams({
                    page: '1',
                    tab: searchParams.get('tab') ?? '1',
                    contextSwitcherTab: index
                  })
                }}
                disabled={loading || store.uiStore.getGlobalLoader()}
                className='override-height override-view-more-tabs'
              />

              <div className='flex items-center custom-filter justify-end absolute right-0 top-[72px] space-x-[12px]'>
                <Filter
                  dropdownLoader={filterLoading}
                  clearFilterDisabled={true}
                  options={options}
                  APITimeFormat={true}
                  hideFilterChipAPI={true}
                  isScrollableAPI={true}
                  disabled={loading || store.uiStore.getGlobalLoader()}
                />

                <Button
                  id='outline-button'
                  label='Export'
                  disabled={
                    loading ||
                    store.uiStore.getGlobalLoader() ||
                    filterLoading ||
                    tabIndex === '1'
                      ? totalCount === 0
                      : userTotalCount === 0
                  }
                  type='neutral'
                  size='small'
                  onClick={() => downloadRecords()}
                  startIcon={<Download color={pallete.colors.neuButtonText} />}
                />
                {!(
                  loading ||
                  store.uiStore.getGlobalLoader() ||
                  store.uiStore.getprojectEnvironmentLoader()
                ) ? (
                  <SideChip
                    id='date-range-chip'
                    label='Date Range'
                    value={
                      tabIndex === '1'
                        ? convertToFormat(dateFilter, orgTimeZone)
                        : convertToFormat(UserDateFilter, orgTimeZone)
                    }
                  />
                ) : null}

                {/* <Search
              placeholder='Search by URL, HTTP Method...'
              id='log-search'
              name='logSearch'
              width='35vh'
              onChange={() => {
                // Implement search functionality
              }}
            /> */}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className='pt-[24px]'>
          <div className='flex items-center justify-between w-full'>
            <div className='flex-grow override-view-more-tabs'></div>
          </div>
          <div
            className={`flex items-center custom-filter absolute right-0  justify-end  space-x-[12px] `}
          >
            <Filter
              options={options}
              hideFilterChipAPI={true}
              APITimeFormat={true}
              dropdownLoader={filterLoading}
              clearFilterDisabled={true}
              isScrollable={true}
              disabled={loading || store.uiStore.getGlobalLoader()}
            />

            <Button
              id='outline-button'
              label='Export'
              disabled={
                loading ||
                store.uiStore.getGlobalLoader() ||
                filterLoading ||
                totalCount === 0
              }
              type='neutral'
              size='small'
              onClick={() => downloadRecordsNoUserName()}
              startIcon={<Download color={pallete.colors.neuButtonText} />}
            />

            {!(
              loading ||
              store.uiStore.getGlobalLoader() ||
              store.uiStore.getprojectEnvironmentLoader()
            ) ? (
              <SideChip
                id='date-range-chip'
                label='Date Range'
                value={convertToFormat(dateFilter, orgTimeZone)}
              />
            ) : null}
          </div>

          <div className='relative top-[32px]'>
            <AllAPIViewURL
              loading={loading}
              setActiveSortOrder={setActiveSortOrder}
              setLoading={setLoading}
              fetchAllRecords={fetchAllRecords}
              data={data}
              totalCount={totalCount}
              activeSortOrder={activeSortOrder}
              handleSortCycle={handleSortCycle}
            />
          </div>
        </div>
      )}
    </>
  )
}

export default observer(AllAPITab)
