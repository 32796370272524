import { ChevronRight, Renew } from '@carbon/icons-react'
import React, { useEffect, useState } from 'react'
import { getEventDuration, getIconForStatus } from '../../utils/UCUtils'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { Button } from '../New/Button/Button'
import { DDSTypography } from 'den-design-system'
import Filter from '../Filter/Filter'
import NoFilterImage from '../../assets/images/folder-not-found 1.svg'
import NoRecord from '../common/NoContent/NoRecord'
import Spinner from '../common/Spinner/Spinner'
import StringConstants from '../../constants/StringConstants'
import Table from '../common/DDS/Table/Table'
import Tooltip from '../New/Tooltip/Tooltip'
import UptimeStringConstants from '../../constants/UptimeStringConstants'
import { gaPageConstants } from '../../constants/GAConstants'
import { getAllQueryParamsAsObjectFromUrl } from '../../utils/UrlUtils'
import { isNull } from 'lodash'
import pallete from '../../global/newPallete'
import { timeFormats } from '../../enum/TIME'
import { timeZone } from '../../utils/DateTimeUtils'
import { useAnalyticsEventTracker } from '../../utils/GAUtils'
import { useStoreContext } from '../../store/StoreContext'

interface UCLogTableProps {
  logsData: any
  fetchHealthEventLogs: (page?: number, filters?: any) => void
  logLoading: boolean
  eventLogFilters: any
  totalLogCount: number
  fetchEventLogFilters: () => void
  filterLoading: boolean
}
const UCLogTable: React.FC<UCLogTableProps> = ({
  totalLogCount,
  logsData,
  fetchEventLogFilters,
  filterLoading,
  eventLogFilters,
  fetchHealthEventLogs,
  logLoading
}) => {
  const store = useStoreContext()
  const [searchParams, setSearchParams] = useSearchParams()
  const [currentPage, setCurrentPage] = useState<number>(1)
  const navigate = useNavigate()
  const gaEventTracker = useAnalyticsEventTracker(
    gaPageConstants.REFRESH_UPTIME_CHECK_LOG
  )
  useEffect(() => {
    const pageFromQueryParams = searchParams.get('page')
    if (!isNull(pageFromQueryParams)) {
      setCurrentPage(Number(pageFromQueryParams))
    } else {
      const allQueryParams = getAllQueryParamsAsObjectFromUrl(location.search)
      setSearchParams({ ...allQueryParams, page: '1' })
      setCurrentPage(1)
    }
    const allQueryParams = getAllQueryParamsAsObjectFromUrl(location.search)

    fetchHealthEventLogs(currentPage, allQueryParams)
  }, [searchParams, store.scopeStore.getScope()])

  useEffect(() => {
    if (store.filterStore.getFilterDisabled()) {
      store.filterStore.setFilterDisabled(false)
    }
  }, [store.filterStore.getFilterDisabled()])

  const routeLog = (all: any) => {
    store.ahcLogsStore.setEventStartTime(all.eventStartTime)
    store.ahcLogsStore.setEventEndTime(all.eventEndTime)
    navigate('logs/:eventId'.replace(':eventId', all.id))
  }

  const columns = [
    {
      columnHeaderStyle: { width: '5%' },
      columnDataStyle: { width: '5%' },
      heading: '',
      dataLabel: 'eventStatus',
      render: (text: any) => (
        <div className='flex justify-center'>{getIconForStatus(text)}</div>
      )
    },
    {
      columnHeaderStyle: {
        width: store.uiStore.getSidebarExpanded() ? '18%' : '15%'
      },
      columnDataStyle: {
        width: store.uiStore.getSidebarExpanded() ? '18%' : '15%'
      },
      heading: (
        <div className='flex justify-center text-center row items-center'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            From
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'eventStartTime',
      render: (text: any) => (
        <div className='flex justify-center text-center'>
          <DDSTypography.Paragraph
            size='para'
            variant='medium'
            className='whitespace-nowrap'
            color={pallete.colors.textDark4}
          >
            {timeZone(
              store.userStore.getUserPreference().timeZone,
              text,
              timeFormats.fullMonthDateYearTime
            )}
          </DDSTypography.Paragraph>
        </div>
      )
    },
    {
      columnHeaderStyle: {
        width: store.uiStore.getSidebarExpanded() ? '18%' : '15%'
      },
      columnDataStyle: {
        width: store.uiStore.getSidebarExpanded() ? '18%' : '15%'
      },
      heading: (
        <div className='flex justify-center text-center row items-center'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            To
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'eventEndTime',
      render: (text: any) => (
        <div className='flex justify-center text-center'>
          <DDSTypography.Paragraph
            size='para'
            variant='medium'
            className='whitespace-nowrap'
            color={pallete.colors.textDark4}
          >
            {text
              ? timeZone(
                  store.userStore.getUserPreference().timeZone,
                  text,
                  timeFormats.fullMonthDateYearTime
                )
              : 'Present'}
          </DDSTypography.Paragraph>
        </div>
      )
    },
    {
      columnHeaderStyle: { width: '22.5%' },
      columnDataStyle: { width: '22.5%' },
      heading: (
        <div className='flex row justify-center text-center items-center'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            Duration
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'eventStartTime',
      render: (text: any, fullItem: any) => (
        <div className='flex justify-center'>
          {fullItem.eventEndTime
            ? getEventDuration(fullItem.eventStartTime, fullItem.eventEndTime)
            : '-'}
        </div>
      )
    },
    {
      columnHeaderStyle: { width: '15%' },
      columnDataStyle: { width: '15%' },
      heading: '',
      dataLabel: '',
      render: (text: any) => <div className=''>{text}</div>
    },
    {
      columnHeaderStyle: { width: '5%' },
      columnDataStyle: { width: '5%' },
      heading: '',
      dataLabel: 'id',
      render: (id: any, fullItem: any) =>
        fullItem.eventStatus !== 'Paused' && (
          <div className='flex justify-center cursor-pointer'>
            <Button
              id='right-btn'
              type='ghost'
              startIcon={
                <ChevronRight size={16} color={pallete.colors.primary2} />
              }
              onClick={() => {
                store.ahcLogsStore.setEventStartTime(fullItem.eventStartTime)
                store.ahcLogsStore.setEventEndTime(fullItem.eventEndTime)
                navigate('logs/:eventId'.replace(':eventId', id))
              }}
            />
          </div>
        )
    }
  ]

  const handleRefreshClick = () => {
    fetchHealthEventLogs()
  }

  return logLoading || !logsData ? (
    <div className='flex flex-col h-[70vh] self-stretch justify-center'>
      <Spinner />
    </div>
  ) : (
    <div className='flex flex-col gap-[24px] py-[24px]'>
      <div className='flex justify-end gap-[8px] items-center'>
        <div className='flex w-full justify-between custom-filter'>
          <Filter
            options={eventLogFilters}
            dropdownLoader={filterLoading}
            onLoadOption={fetchEventLogFilters}
            disabled={filterLoading}
          />
        </div>
        <div>
          <Tooltip id='refresh-tooltip' label='Refresh' position='top'>
            <Button
              id='refresh'
              type='neutral'
              disabled={logLoading || filterLoading}
              startIcon={
                <Renew
                  size={16}
                  color={pallete.colors.neuButtonText}
                  onClick={() => {
                    gaEventTracker(gaPageConstants.REFRESH_UPTIME_CHECK_LOG)
                    handleRefreshClick()
                  }}
                />
              }
              size='small'
            />
          </Tooltip>
        </div>
      </div>
      {logsData.length > 0 ? (
        <Table
          tableStyle={{
            borderColor: pallete.colors.stroke2
          }}
          data={logsData}
          columns={columns}
          totalCount={totalLogCount}
          rowsPerPage={10}
          currentPage={currentPage}
          onPageChange={(page) => {
            const allQueryParams = getAllQueryParamsAsObjectFromUrl(
              location.search
            )
            setCurrentPage(page)
            setSearchParams({ ...allQueryParams, page: page.toString() })
          }}
          clickType='ucLog'
          clickable={routeLog}
        />
      ) : (
        <NoRecord
          style={{ height: '70vh' }}
          imageSrc={
            totalLogCount === 0 && store.filterStore.getFiltersApplied()
              ? NoFilterImage
              : NoFilterImage
          }
          text={
            totalLogCount === 0 && store.filterStore.getFiltersApplied()
              ? StringConstants.NO_RECORDS_FILTER
              : UptimeStringConstants.NO_RECORD
          }
        />
      )}
    </div>
  )
}

export default UCLogTable
