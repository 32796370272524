import { CaretDown, CaretUp, ChevronRight } from '@carbon/icons-react'
import { DDSTooltip, DDSTypography } from 'den-design-system'
import React, { useEffect, useRef, useState } from 'react'
import {
  availabilityToColor,
  dateRange,
  statusToIcon
} from '../../utils/OverviewUtils'
import { convertToFormatUC, reportFilterOptions } from '../../utils/UCUtils'
import {
  deleteHealthCheck,
  getHealthCheckReports,
  getHealthSummary
} from '../../api/appHealthCheck/AHC.service'
import {
  getAllQueryParamsAsObjectFromUrl,
  replaceUrl
} from '../../utils/UrlUtils'
import { isEmpty, isNull, isUndefined } from 'lodash'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { Button } from '../New/Button/Button'
import Filter from '../Filter/Filter'
import { FilterTypes } from '../../enum/Filter.enum'
import NoFilterImage from '../../assets/images/folder-not-found 1.svg'
import NoRecord from '../common/NoContent/NoRecord'
import NoRecordImage from '../../assets/images/UCNoRecord.svg'
import { ProjectType } from '../../enum/ProjectType.enum'
import Search from '../New/Search/Search'
import SideChip from '../New/SideChip/SideChip'
import Spinner from '../common/Spinner/Spinner'
import StringConstants from '../../constants/StringConstants'
import Table from '../common/DDS/Table/Table'
import { ToastMessageConstants } from '../../constants/ToastMessageConstants'
import ToastNotification from '../common/DDS/Toast/Toast'
import Tooltip from '../New/Tooltip/Tooltip'
import UCDeleteCheckPopup from './UCDeleteCheckPopup'
import UCResponseTimeGraph from './UCResponseTimeGraph'
import UCSummaryCard from './UCCard'
import UCSummaryDropdown from './UCSummaryDropdown'
import UptimeStringConstants from '../../constants/UptimeStringConstants'
import { generateRandomId } from '../../utils/CommonUtils'
import { getFiltersForApi } from '../../utils/FilterUtils'
import moment from 'moment'
import pallete from '../../global/newPallete'
import routeConstants from '../../constants/RouteConstants'
import { timeFormats } from '../../enum/TIME'
import { timeMetric } from '../../utils/DateTimeUtils'
import { useStoreContext } from '../../store/StoreContext'

type SortOrder = 'ASC' | 'DESC' | 'CLEAR'
const UCSummary: React.FC<{
  setFetchHealthSummary: any
  summaryLoading: boolean
  setSummaryLoading: any
  loading: boolean
  setLoading: any
}> = ({
  setFetchHealthSummary,
  summaryLoading,
  setSummaryLoading,
  loading,
  setLoading
}) => {
  const store = useStoreContext()
  const [searchTerm, setSearchTerm] = useState('') // Holds the search term entered by the user
  const [status, setStatus] = useState('')
  const [showDropdown, setShowDropdown] = useState(false)
  const [uptimeData, setUptimeData] = useState<any>(null)
  const [summaryData, setSummaryData] = useState<any>(null)
  const [buttonLoading, setButtonLoading] = useState<boolean>(false)
  const [selectedCheck, setSelectedCheck] = useState<any>({})
  const [searchRecordLength, setSearchRecordLength] = useState<number>(0)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [searchParams, setSearchParams] = useSearchParams()
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false)
  const [activeSortOrder, setActiveSortOrder] = useState<{
    field: string | null
    order: SortOrder | null
  }>({
    field: null,
    order: null
  })
  const isSortTriggered = useRef(false)

  const rowDisabled = useRef(false)
  const projectType = store.scopeStore.getSelectedProject().type
  const isFullStackProject = projectType === ProjectType.FULL_STACK_ONLY
  const getInitialSelectedCardId = () => {
    const status = searchParams.get('previous-check-status')
    switch (status) {
      case 'Up':
        return '2'
      case 'Down':
        return '3'
      case 'Paused':
        return '4'
      default:
        return '1'
    }
  }
  const [selectedCardId, setSelectedCardId] = useState<any>(
    getInitialSelectedCardId()
  )

  const [dateFilter, setDateFilter] = useState<any>({})
  const userTimeZone = store.userStore.getUserPreference().timeZone
  const formattedDateRange = dateRange(userTimeZone)
  const navigate = useNavigate()
  const fetchUCSummary = () => {
    setSummaryLoading(true)
    getHealthSummary()
      .then((response: any) => setSummaryData(response.data))
      .catch(() =>
        ToastNotification({
          type: 'error',
          message: 'Failed to fetch uptime checks summary'
        })
      )
      .finally(() => {
        setSummaryLoading(false)
      })
  }

  const fetchUCData = () => {
    store.uiStore.setGlobalLoader(true)
    setLoading(true)
    const filter = getAllQueryParamsAsObjectFromUrl(location.search)
    const filters = !isUndefined(
      store.ahcReportsStore.getStatusFilter()?.['previous-check-status']
    )
      ? {
          ...filter,
          'previous-check-status':
            store.ahcReportsStore.getStatusFilter()?.['previous-check-status']
        }
      : filter

    const timeZone =
      store.userStore.getOrganizationPreference()?.timeZone || 'UTC'

    const nowInOrgTimeZone = moment.tz(timeZone)
    const sevenDaysAgo = nowInOrgTimeZone
      .clone()
      .subtract(6, 'days')
      .startOf('day')
      .valueOf()
    const endOfCurrentDay = nowInOrgTimeZone.valueOf()

    const paramawithDateRange = {
      ...filters,
      'created-from': filter['created-from'] || String(sevenDaysAgo),
      'created-to': filter['created-to'] || String(endOfCurrentDay),
      'time-zone': timeZone
    }

    const apiFilters = getFiltersForApi(FilterTypes.AHC, paramawithDateRange)
    getHealthCheckReports({
      page: currentPage,
      size: 5,
      ...(status !== undefined &&
        status !== '' && {
          'previous-check-status': status
        }),
      ...apiFilters
    })
      .then((response: any) => {
        if (response.data.length > 0 || store.filterStore.getFiltersApplied()) {
          store.filterStore.setFilterDisabled(false)
        }

        setUptimeData(response)
        if (window.location.pathname.split('/').slice(-1)[0] === 'dashboard') {
          if (
            response.filters['created-to'] &&
            moment(response.filters['created-to']).isAfter(new Date())
          ) {
            const currentDay = new Date()
            currentDay.setSeconds(0)
            response.filters['created-to'] = currentDay.toISOString()
          }

          const filteredDateFilters = {
            'created-from': response.filters['created-from'],
            'created-to': response.filters['created-to'],
            'time-zone': response.filters['time-zone']
          }

          store.filterStore.setFilters(response.filters)
          setDateFilter(filteredDateFilters)
        }
      })
      .catch((error) =>
        ToastNotification({
          type: 'error',
          message: error
        })
      )
      .finally(() => {
        store.uiStore.setGlobalLoader(false)
        setLoading(false)
      })
  }

  const handleDeleteCheck = () => {
    setButtonLoading(true)
    deleteHealthCheck({ $ahcId: selectedCheck?.id })
      .then(() => {
        ToastNotification({
          type: 'success',
          message: ToastMessageConstants.AHC.DELETE_AHC.SUCCESS
        })
        handleRefreshClick()
      })
      .catch((err) => {
        ToastNotification({
          type: 'error',
          message: err
        })
      })
      .finally(() => {
        setButtonLoading(false)
        setDeleteOpen(false)
      })
  }
  const handleRefreshClick = () => {
    fetchUCSummary()
    fetchUCData()
  }

  const routeUC = (id: any) => {
    if (!rowDisabled.current) {
      navigate(
        replaceUrl(
          routeConstants.AHC_DASHBOARD,
          store.scopeStore.getScope()['organization-id'] as string,
          store.scopeStore.getScope()['project-id'] as string,
          store.scopeStore.getScope()['environment-id'] as string
        ).replace(':id', id as string)
      )
    }
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const urlSortField = searchParams.get('sortField')
    const urlSortOrder = searchParams.get('sortOrder')
    if (urlSortField && urlSortOrder) {
      // Initialize the sort state from URL
      setActiveSortOrder({
        field: urlSortField,
        order: urlSortOrder as SortOrder
      })
    }
  }, [location.search])

  const fetchUC = () => {
    const allQueryParams = getAllQueryParamsAsObjectFromUrl(location.search)
    if (!allQueryParams['search-string']) {
      setSearchTerm('')
    }
    if (isSortTriggered.current) {
      isSortTriggered.current = false
      return
    }
    const filter = getAllQueryParamsAsObjectFromUrl(location.search)
    const filters = !isUndefined(
      store.ahcReportsStore.getStatusFilter()?.['previous-check-status']
    )
      ? {
          ...filter,
          'previous-check-status':
            store.ahcReportsStore.getStatusFilter()?.['previous-check-status']
        }
      : filter
    const apiFilters = getFiltersForApi(FilterTypes.AHC, filters)

    const timeZone =
      store.userStore.getOrganizationPreference()?.timeZone || 'UTC'

    const nowInOrgTimeZone = moment.tz(timeZone)
    const sevenDaysAgo = nowInOrgTimeZone
      .clone()
      .subtract(6, 'days')
      .startOf('day')
      .valueOf()
    const endOfCurrentDay = nowInOrgTimeZone.valueOf()
    const paramawithDateRange = {
      ...apiFilters,
      'created-from': allQueryParams['created-from'] || String(sevenDaysAgo),
      'created-to': allQueryParams['created-to'] || String(endOfCurrentDay),
      'time-zone': timeZone
    }

    setSearchParams({ ...paramawithDateRange })

    const params = getAllQueryParamsAsObjectFromUrl(location.search)
    const apiParams = getFiltersForApi(FilterTypes.AHC, params)

    getHealthCheckReports({
      page: currentPage,
      size: 5,
      ...(status !== undefined &&
        status !== '' && {
          'previous-check-status': status
        }),
      ...apiParams
    })
      .then((response: any) => {
        setUptimeData(response)
        setSearchRecordLength(response.totalCount)
        setCurrentPage(response.page)
        if (window.location.pathname.split('/').slice(-1)[0] === 'dashboard') {
          if (
            response.filters['created-to'] &&
            moment(response.filters['created-to']).isAfter(new Date())
          ) {
            const currentDay = new Date()
            currentDay.setSeconds(0)
            response.filters['created-to'] = currentDay.toISOString()
          }

          const filteredDateFilters = {
            'created-from': response.filters['created-from'],
            'created-to': response.filters['created-to'],
            'time-zone': response.filters['time-zone']
          }
          store.filterStore.setFilters(response.filters)
          setDateFilter(filteredDateFilters)
        }
      })
      .catch((e) => {
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.AHC.SEARCH.ERROR
        })
      })
      .finally(() => {
        store.uiStore.setGlobalLoader(false)
        setLoading(false)
      })
    fetchUCSummary()
  }
  useEffect(() => {
    setFetchHealthSummary(fetchUC)
  }, [])

  const fetchUCRecords = (queryParams: any) => {
    store.uiStore.setGlobalLoader(true)
    setLoading(true)

    const searchParams = new URLSearchParams(location.search)
    const urlSortField = searchParams.get('sortField')
    const urlSortOrder = searchParams.get('sortOrder')

    const params: any = {
      ...queryParams,
      ...(store.ahcReportsStore.getStatusFilter()?.['previous-check-status'] !==
        undefined && {
        'previous-check-status':
          store.ahcReportsStore.getStatusFilter()?.['previous-check-status']
      })
    }
    if (urlSortField && urlSortOrder && urlSortOrder !== 'CLEAR') {
      params[urlSortField] = urlSortOrder
    }

    const timeZone =
      store.userStore.getOrganizationPreference()?.timeZone || 'UTC'

    const allQueryParams = getAllQueryParamsAsObjectFromUrl(location.search)
    const nowInOrgTimeZone = moment.tz(timeZone)
    const sevenDaysAgo = nowInOrgTimeZone
      .clone()
      .subtract(6, 'days')
      .startOf('day')
      .valueOf()
    const endOfCurrentDay = nowInOrgTimeZone.valueOf()

    const paramawithDateRange = {
      ...params,
      'created-from': allQueryParams['created-from'] || String(sevenDaysAgo),
      'created-to': allQueryParams['created-to'] || String(endOfCurrentDay),
      'time-zone': timeZone
    }

    const apiFilters = getFiltersForApi(FilterTypes.AHC, paramawithDateRange)

    getHealthCheckReports({
      ...apiFilters,
      page: currentPage,
      size: 5
    })
      .then((response: any) => {
        if (response.data.length > 0 || store.filterStore.getFiltersApplied()) {
          store.filterStore.setFilterDisabled(false)
        }
        setSearchParams(params)
        setUptimeData(response)
        if (window.location.pathname.split('/').slice(-1)[0] === 'dashboard') {
          if (
            response.filters['created-to'] &&
            moment(response.filters['created-to']).isAfter(new Date())
          ) {
            const currentDay = new Date()
            currentDay.setSeconds(0)
            response.filters['created-to'] = currentDay.toISOString()
          }

          const filteredDateFilters = {
            'created-from': response.filters['created-from'],
            'created-to': response.filters['created-to'],
            'time-zone': response.filters['time-zone']
          }

          store.filterStore.setFilters(response.filters)
          setDateFilter(filteredDateFilters)
        }
      })
      .catch((error) =>
        ToastNotification({
          type: 'error',
          message: error
        })
      )
      .catch((e) => {
        ToastNotification({
          type: 'error',
          message: isFullStackProject
            ? ToastMessageConstants.AHC.FETCH_CHECKS.ERROR
            : ToastMessageConstants.ERROR.GET_ALL_ERROR.ERROR
        })
      })
      .finally(() => {
        store.uiStore.setGlobalLoader(false)
        setLoading(false)
      })
  }

  useEffect(() => {
    // Current project and environment IDs from the store
    const currentProjectId = store.scopeStore.getScope()['project-id']
    const currentEnvId = store.scopeStore.getScope()['environment-id']

    // Extract project and environment IDs from the URL
    const urlParts = location.pathname.split('/')
    const urlProjectId = urlParts[urlParts.indexOf('projects') + 1]
    const urlEnvId = urlParts[urlParts.indexOf('environments') + 1]

    // Check if the project or environment has changed
    if (currentProjectId !== urlProjectId || currentEnvId !== urlEnvId) {
      setSearchTerm('')
    }
  }, [location.pathname])
  useEffect(() => {
    const initializeFiltersFromUrl = () => {
      const allQueryParams = getAllQueryParamsAsObjectFromUrl(location.search)
      const searchString = allQueryParams['search-string'] || ''

      // Initialize state from query params
      if (searchString) {
        setSearchTerm(searchString)
      }
    }
    if (isSortTriggered.current) {
      isSortTriggered.current = false
      return
    }

    initializeFiltersFromUrl()
    const pageFromQueryParams = searchParams.get('page')
    const allQueryParams = getAllQueryParamsAsObjectFromUrl(location.search)
    if (!pageFromQueryParams) {
      const updatedParams = { ...allQueryParams, page: '1' }
      if (JSON.stringify(updatedParams) !== JSON.stringify(allQueryParams)) {
        setSearchParams(updatedParams)
      }
      setCurrentPage(1)
    } else {
      setCurrentPage(Number(pageFromQueryParams))
    }

    // Prepare API request parameters
    const requestParams = {
      ...allQueryParams,
      size: 5
    }
    if (allQueryParams['search-string']) {
      requestParams['search-string'] = allQueryParams['search-string']
      requestParams['search-type'] = allQueryParams['search-type'] || 'any'
    }

    setLoading(true)
    store.uiStore.setGlobalLoader(true)

    if (!isEmpty(store.scopeStore.getScope()['environment-id'])) {
      fetchUC()
    }
  }, [location.search, store.scopeStore.getScope()])

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.slice(0, 50)
    const allQueryParams = getAllQueryParamsAsObjectFromUrl(location.search)
    const params: any = {
      ...allQueryParams,
      ...(status !== undefined &&
        status !== '' && {
          'previous-check-status': status
        }),
      page: currentPage,
      size: 5
    }
    if (value === '' && allQueryParams['search-string']) {
      setSearchTerm('')
      setShowDropdown(false)
      setLoading(true)
      store.uiStore.setGlobalLoader(true)
      setCurrentPage(currentPage)
      delete params['search-string']
      delete params['search-type']

      const timeZone =
        store.userStore.getOrganizationPreference()?.timeZone || 'UTC'

      const allQueryParams = getAllQueryParamsAsObjectFromUrl(location.search)
      const nowInOrgTimeZone = moment.tz(timeZone)
      const sevenDaysAgo = nowInOrgTimeZone
        .clone()
        .subtract(6, 'days')
        .startOf('day')
        .valueOf()
      const endOfCurrentDay = nowInOrgTimeZone.valueOf()
      const paramawithDateRange = {
        ...params,
        'created-from': allQueryParams['created-from'] || String(sevenDaysAgo),
        'created-to': allQueryParams['created-to'] || String(endOfCurrentDay),
        'time-zone': timeZone
      }

      setSearchParams({ ...paramawithDateRange, searchTerm: '' })
      const apiFilters = getFiltersForApi(FilterTypes.AHC, paramawithDateRange)

      getHealthCheckReports({ ...apiFilters, page: currentPage, size: 5 })
        .then((response: any) => {
          setUptimeData(response)
          setSearchRecordLength(response.totalCount)
          setCurrentPage(response.page)
          if (
            window.location.pathname.split('/').slice(-1)[0] === 'dashboard'
          ) {
            if (
              response.filters['created-to'] &&
              moment(response.filters['created-to']).isAfter(new Date())
            ) {
              const currentDay = new Date()
              currentDay.setSeconds(0)
              response.filters['created-to'] = currentDay.toISOString()
            }

            const filteredDateFilters = {
              'created-from': response.filters['created-from'],
              'created-to': response.filters['created-to'],
              'time-zone': response.filters['time-zone']
            }

            store.filterStore.setFilters(response.filters)
            setDateFilter(filteredDateFilters)
          }
        })
        .catch((e) => {
          ToastNotification({
            type: 'error',
            message: ToastMessageConstants.AHC.SEARCH.ERROR
          })
        })
        .finally(() => {
          store.uiStore.setGlobalLoader(false)
          setLoading(false)
        })
      return
    }
    const isValidInput = /^[\w\s!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]*$/.test(
      value
    )
    if (!isValidInput) {
      return
    }
    setSearchTerm(value)
    if (value.trim()) {
      setShowDropdown(true)
    }
  }
  const handleSearchEnter = (searchValue: string) => {
    if (!searchValue || searchValue.trim() === '') {
      return
    }
    setLoading(true)
    store.uiStore.setGlobalLoader(true)
    setCurrentPage(1)
    const allQueryParams = getAllQueryParamsAsObjectFromUrl(location.search)
    const params = {
      ...allQueryParams,
      'search-string': searchValue || '',
      'search-type': 'any',
      ...(status !== undefined &&
        status !== '' && {
          'previous-check-status': status
        }),
      page: 1,
      size: 5
    }
    setSearchParams({ ...params, searchTerm: searchTerm })

    const timeZone =
      store.userStore.getOrganizationPreference()?.timeZone || 'UTC'

    const nowInOrgTimeZone = moment.tz(timeZone)
    const sevenDaysAgo = nowInOrgTimeZone
      .clone()
      .subtract(6, 'days')
      .startOf('day')
      .valueOf()
    const endOfCurrentDay = nowInOrgTimeZone.valueOf()

    const paramawithDateRange = {
      ...params,
      'created-from': allQueryParams['created-from'] || String(sevenDaysAgo),
      'created-to': allQueryParams['created-to'] || String(endOfCurrentDay),
      'time-zone': timeZone
    }

    const apiFilters = getFiltersForApi(FilterTypes.AHC, paramawithDateRange)

    getHealthCheckReports({ ...apiFilters, page: 1, size: 5 })
      .then((response: any) => {
        setUptimeData(response)
        setSearchRecordLength(response.totalCount)
        setCurrentPage(response.page)
        if (window.location.pathname.split('/').slice(-1)[0] === 'dashboard') {
          if (
            response.filters['created-to'] &&
            moment(response.filters['created-to']).isAfter(new Date())
          ) {
            const currentDay = new Date()
            currentDay.setSeconds(0)
            response.filters['created-to'] = currentDay.toISOString()
          }

          const filteredDateFilters = {
            'created-from': response.filters['created-from'],
            'created-to': response.filters['created-to'],
            'time-zone': response.filters['time-zone']
          }

          store.filterStore.setFilters(response.filters)
          setDateFilter(filteredDateFilters)
        }
      })
      .catch((e) => {
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.AHC.SEARCH.ERROR
        })
      })
      .finally(() => {
        store.uiStore.setGlobalLoader(false)
        setLoading(false)
      })
    setShowDropdown(false)
  }

  const handleOptionSelect = (searchString: string, searchType: string) => {
    setLoading(true)
    store.uiStore.setGlobalLoader(true)
    setCurrentPage(1)
    const allQueryParams = getAllQueryParamsAsObjectFromUrl(location.search)
    const params: any = {
      ...allQueryParams,
      'search-string': searchString,
      'search-type': searchType,
      ...(status !== undefined &&
        status !== '' && {
          'previous-check-status': status
        }),
      page: 1,
      size: 5
    }
    setSearchParams({ ...params, searchTerm: searchTerm })

    const timeZone =
      store.userStore.getOrganizationPreference()?.timeZone || 'UTC'

    const nowInOrgTimeZone = moment.tz(timeZone)
    const sevenDaysAgo = nowInOrgTimeZone
      .clone()
      .subtract(6, 'days')
      .startOf('day')
      .valueOf()
    const endOfCurrentDay = nowInOrgTimeZone.valueOf()

    const paramawithDateRange = {
      ...params,
      'created-from': allQueryParams['created-from'] || String(sevenDaysAgo),
      'created-to': allQueryParams['created-to'] || String(endOfCurrentDay),
      'time-zone': timeZone
    }

    const apiFilters = getFiltersForApi(FilterTypes.AHC, paramawithDateRange)

    getHealthCheckReports({ ...apiFilters, page: 1, size: 5 })
      .then((response: any) => {
        setUptimeData(response)
        setSearchRecordLength(response.totalCount)
        setCurrentPage(response.page)
        if (window.location.pathname.split('/').slice(-1)[0] === 'dashboard') {
          if (
            response.filters['created-to'] &&
            moment(response.filters['created-to']).isAfter(new Date())
          ) {
            const currentDay = new Date()
            currentDay.setSeconds(0)
            response.filters['created-to'] = currentDay.toISOString()
          }

          const filteredDateFilters = {
            'created-from': response.filters['created-from'],
            'created-to': response.filters['created-to'],
            'time-zone': response.filters['time-zone']
          }

          store.filterStore.setFilters(response.filters)
          setDateFilter(filteredDateFilters)
        }
      })
      .catch((e) => {
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.AHC.SEARCH.ERROR
        })
      })
      .finally(() => {
        store.uiStore.setGlobalLoader(false)
        setLoading(false)
      })
    setShowDropdown(false)
  }

  const sortData = (sortField: string, sortOrder: SortOrder) => {
    const allQueryParams = getAllQueryParamsAsObjectFromUrl(location.search)

    store.uiStore.setGlobalLoader(true)
    setLoading(true)
    const params: any = {
      ...allQueryParams,
      ...searchParams,
      size: 5,
      page: currentPage,
      'expand-activity': true
    }

    if (searchTerm === '') {
      delete params['search-string']
      delete params['search-type']
    }
    Object.keys(params).forEach((key) => {
      if (key.startsWith('sort-')) {
        delete params[key]
      }
    })
    if (sortOrder !== 'CLEAR') {
      params[sortField] = sortOrder
      // Persist sort information in URL
      const searchParams = new URLSearchParams(location.search)
      searchParams.set('sortField', sortField)
      searchParams.set('sortOrder', sortOrder)
      window.history.replaceState({}, '', `?${searchParams.toString()}`)
    } else {
      delete params[sortField]
      // Remove sort params from URL
      const searchParams = new URLSearchParams(location.search)
      searchParams.delete('sortField')
      searchParams.delete('sortOrder')
      window.history.replaceState({}, '', `?${searchParams.toString()}`)
    }

    isSortTriggered.current = true
    fetchUCRecords(params)
  }

  const handleSortCycle = (sortField: string) => {
    // Determine the next order synchronously
    let nextOrder: SortOrder = 'ASC'
    if (activeSortOrder.field === sortField) {
      nextOrder =
        activeSortOrder.order === 'ASC'
          ? 'DESC'
          : activeSortOrder.order === 'DESC'
            ? 'CLEAR'
            : 'ASC'
    }

    // Update the sort state and call sortData immediately
    setActiveSortOrder({
      field: nextOrder !== 'CLEAR' ? sortField : null,
      order: nextOrder
    })

    sortData(sortField, nextOrder)
  }

  const cardData = [
    {
      id: '1',
      heading: 'Total Checks',
      content: (
        <DDSTypography.Title
          type='h5'
          variant='semiBold'
          color={pallete.colors.textDark3}
        >
          {summaryData?.totalChecks?.toString().padStart(2, '0')}
        </DDSTypography.Title>
      ),
      onClick: () => {
        // Only allow clicking if this is not the initially selected card
        if (selectedCardId !== '1' && summaryData?.totalChecks > 0) {
          setSearchParams({})
          setSearchTerm('')

          setSelectedCardId('1')
        }
        setStatus('')
      },
      disableChevron: summaryData?.totalChecks === 0,
      selected: true
    },
    {
      id: '2',
      heading: 'Total Up',
      content: (
        <DDSTypography.Title
          type='h5'
          variant='semiBold'
          color={pallete.colors.warningGreen}
        >
          {summaryData?.totalUp?.toString().padStart(2, '0')}
        </DDSTypography.Title>
      ),
      onClick: () => {
        if (summaryData?.totalChecks > 0) {
          setSearchParams({
            page: '1',
            ['previous-check-status']: 'Up'
          })
          setSearchTerm('')
          setStatus('Up')
        }
        setSelectedCardId('2')
      },
      disableChevron: summaryData?.totalChecks === 0,
      selected: false
    },
    {
      id: '3',
      heading: 'Total Down',
      content: (
        <DDSTypography.Title
          type='h5'
          variant='semiBold'
          color={pallete.colors.warningRed2}
        >
          {summaryData?.totalDown?.toString().padStart(2, '0')}
        </DDSTypography.Title>
      ),
      onClick: () => {
        if (summaryData?.totalChecks > 0) {
          setSearchParams({
            page: '1',
            ['previous-check-status']: 'Down'
          })
          setSearchTerm('')
          setStatus('Down')
        }
        setSelectedCardId('3')
      },
      disableChevron: summaryData?.totalChecks === 0,
      selected: false
    },
    {
      id: '4',
      heading: 'Total Paused',
      content: (
        <DDSTypography.Title
          type='h5'
          variant='semiBold'
          color={pallete.colors.warningOrange}
        >
          {summaryData?.totalPaused?.toString().padStart(2, '0')}
        </DDSTypography.Title>
      ),
      onClick: () => {
        if (summaryData?.totalChecks > 0) {
          setSearchParams({
            page: '1',
            ['previous-check-status']: 'Paused'
          })
          setSearchTerm('')
          setStatus('Paused')
        }
        setSelectedCardId('4')
      },
      disableChevron: summaryData?.totalChecks === 0,
      selected: false
    }
  ]

  const Columns = [
    {
      columnHeaderStyle: { width: '110px' },
      columnDataStyle: { width: '110px' },
      heading: (
        <div className='flex whitespace-nowrap space-x-[8px] items-center'>
          <div>
            <DDSTypography.Paragraph
              size='caption'
              variant='semiBold'
              color={pallete.colors.textDark6}
            >
              Application Name
            </DDSTypography.Paragraph>
          </div>
          <div
            className='flex flex-col justify-center -space-y-[6px]'
            onClick={() => handleSortCycle('sort-check-name')}
          >
            <CaretUp
              size={12}
              style={{
                color:
                  activeSortOrder.field === 'sort-check-name' &&
                  activeSortOrder.order === 'ASC'
                    ? pallete.colors.FillButton
                    : activeSortOrder.field === 'sort-check-name' &&
                        activeSortOrder.order === 'DESC'
                      ? pallete.colors.outButtonDis
                      : pallete.colors.textDark6
              }}
              className='cursor-pointer'
            />
            <CaretDown
              size={12}
              style={{
                color:
                  activeSortOrder.field === 'sort-check-name' &&
                  activeSortOrder.order === 'ASC'
                    ? pallete.colors.outButtonDis
                    : activeSortOrder.field === 'sort-check-name' &&
                        activeSortOrder.order === 'DESC'
                      ? pallete.colors.FillButton
                      : pallete.colors.textDark6
              }}
              className='cursor-pointer'
            />
          </div>
        </div>
      ),
      dataLabel: 'checkName',
      render: (text: any) => (
        <div className='flex justify-start max-w-[110px]'>
          <Tooltip
            id={`tooltip-url-${text}`}
            label={text}
            className={`${
              typeof text === 'string' && text.length > 10 ? text : '!hidden'
            }`}
          >
            <DDSTypography.Paragraph
              size='para'
              variant='medium'
              className='truncate max-w-[100px]'
              color={pallete.colors.textDark4}
            >
              {text ?? '-'}
            </DDSTypography.Paragraph>
          </Tooltip>
        </div>
      )
    },
    {
      columnHeaderStyle: {
        width: '200px'
      },
      columnDataStyle: {
        width: '200px'
      },
      heading: (
        <div className='flex justify-center text-center whitespace-nowrap space-x-[8px] items-center'>
          <div>
            <DDSTypography.Paragraph
              size='caption'
              variant='semiBold'
              color={pallete.colors.textDark6}
            >
              URL
            </DDSTypography.Paragraph>
          </div>
          <div
            className='flex flex-col justify-center -space-y-[6px]'
            onClick={() => handleSortCycle('sort-url')}
          >
            <CaretUp
              size={12}
              style={{
                color:
                  activeSortOrder.field === 'sort-url' &&
                  activeSortOrder.order === 'ASC'
                    ? pallete.colors.FillButton
                    : activeSortOrder.field === 'sort-url' &&
                        activeSortOrder.order === 'DESC'
                      ? pallete.colors.outButtonDis
                      : pallete.colors.textDark6
              }}
              className='cursor-pointer'
            />
            <CaretDown
              size={12}
              style={{
                color:
                  activeSortOrder.field === 'sort-url' &&
                  activeSortOrder.order === 'ASC'
                    ? pallete.colors.outButtonDis
                    : activeSortOrder.field === 'sort-url' &&
                        activeSortOrder.order === 'DESC'
                      ? pallete.colors.FillButton
                      : pallete.colors.textDark6
              }}
              className='cursor-pointer'
            />
          </div>
        </div>
      ),
      dataLabel: 'url',
      render: (text: any) => (
        <div className='flex justify-center max-w-[200px] text-center '>
          <Tooltip
            id={`tooltip-url-${text}`}
            label={text}
            className={`max-w-[300px] break-all ${
              typeof text === 'string' && store.uiStore.getSidebarExpanded()
                ? text.length > 10
                  ? text
                  : '!hidden'
                : text.length > 15
                  ? text
                  : '!hidden'
            }`}
          >
            <DDSTypography.Paragraph
              size='para'
              variant='medium'
              className={`truncate max-w-[200px]`}
              color={pallete.colors.textDark4}
            >
              {text ?? '-'}
            </DDSTypography.Paragraph>
          </Tooltip>
        </div>
      )
    },

    {
      columnHeaderStyle: {},
      columnDataStyle: {},
      heading: (
        <div className='flex row justify-center  text-center items-center'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            Status
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'status',
      render: (status: any, fullItem: any) => {
        let updatedStatus = status
        if (!fullItem?.monitorAppHealthCheck) updatedStatus = 'Paused'
        return (
          <div className='flex justify-center'>
            {statusToIcon(updatedStatus)}
          </div>
        )
      }
    },
    {
      columnHeaderStyle: {},
      columnDataStyle: {},
      heading: (
        <div className='flex row justify-center whitespace-nowrap space-x-[8px] text-center items-center'>
          <div>
            <DDSTypography.Paragraph
              size='caption'
              variant='semiBold'
              color={pallete.colors.textDark6}
            >
              Availability
            </DDSTypography.Paragraph>
          </div>
          <div
            className='flex flex-col justify-center -space-y-[6px]'
            onClick={() => handleSortCycle('sort-availability')}
          >
            <CaretUp
              size={12}
              style={{
                color:
                  activeSortOrder.field === 'sort-availability' &&
                  activeSortOrder.order === 'ASC'
                    ? pallete.colors.FillButton
                    : activeSortOrder.field === 'sort-availability' &&
                        activeSortOrder.order === 'DESC'
                      ? pallete.colors.outButtonDis
                      : pallete.colors.textDark6
              }}
              className='cursor-pointer'
            />
            <CaretDown
              size={12}
              style={{
                color:
                  activeSortOrder.field === 'sort-availability' &&
                  activeSortOrder.order === 'ASC'
                    ? pallete.colors.outButtonDis
                    : activeSortOrder.field === 'sort-availability' &&
                        activeSortOrder.order === 'DESC'
                      ? pallete.colors.FillButton
                      : pallete.colors.textDark6
              }}
              className='cursor-pointer'
            />
          </div>
        </div>
      ),
      dataLabel: 'availability',
      render: (text: any, fullItem: any) => (
        <div className='flex justify-center text-center'>
          <DDSTypography.Paragraph
            size='para'
            variant='medium'
            color={availabilityToColor(
              text ? (text !== '-' ? text : '-') : '0'
            )}
          >
            {fullItem.status !== 'Paused' && text && text != 'Not enough data'
              ? text !== '-'
                ? text + '%'
                : '0%'
              : fullItem.status !== 'Paused'
                ? '0%'
                : '-'}
          </DDSTypography.Paragraph>
        </div>
      )
    },
    {
      columnHeaderStyle: {},
      columnDataStyle: {},
      heading: (
        <div className='flex row justify-center whitespace-nowrap space-x-[8px]  text-center items-center'>
          <div>
            <DDSTypography.Paragraph
              size='caption'
              variant='semiBold'
              color={pallete.colors.textDark6}
            >
              Avg. Response Time
            </DDSTypography.Paragraph>
          </div>
          <div
            className='flex flex-col justify-center -space-y-[6px]'
            onClick={() => handleSortCycle('sort-average-response-time')}
          >
            <CaretUp
              size={12}
              style={{
                color:
                  activeSortOrder.field === 'sort-average-response-time' &&
                  activeSortOrder.order === 'ASC'
                    ? pallete.colors.FillButton
                    : activeSortOrder.field === 'sort-average-response-time' &&
                        activeSortOrder.order === 'DESC'
                      ? pallete.colors.outButtonDis
                      : pallete.colors.textDark6
              }}
              className='cursor-pointer'
            />
            <CaretDown
              size={12}
              style={{
                color:
                  activeSortOrder.field === 'sort-average-response-time' &&
                  activeSortOrder.order === 'ASC'
                    ? pallete.colors.outButtonDis
                    : activeSortOrder.field === 'sort-average-response-time' &&
                        activeSortOrder.order === 'DESC'
                      ? pallete.colors.FillButton
                      : pallete.colors.textDark6
              }}
              className='cursor-pointer'
            />
          </div>
        </div>
      ),
      dataLabel: 'averageResponseTime',
      render: (avgResponse: any) => {
        const response = avgResponse
          ? `${timeMetric(avgResponse.duration, avgResponse.unit) || '-'}`
          : '-'
        return (
          <div className='flex justify-center text-center'>
            <DDSTypography.Paragraph
              size='para'
              variant='medium'
              color={pallete.colors.textDark4}
            >
              {response}
            </DDSTypography.Paragraph>
          </div>
        )
      }
    },
    {
      columnHeaderStyle: {},
      columnDataStyle: {},
      heading: (
        <div className='flex row justify-center text-center items-center'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            Downtime
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'downTime',
      render: (downTime: any, fullItem: any) => (
        <div className='flex justify-center text-center'>
          <DDSTypography.Paragraph
            size='para'
            variant='medium'
            className='whitespace-nowrap'
            color={pallete.colors.textDark4}
          >
            {timeMetric(downTime?.duration, downTime?.unit)
              ? `${timeMetric(downTime?.duration, downTime?.unit)} (${
                  downTime?.percentage
                }%)`
              : '-'}
          </DDSTypography.Paragraph>
        </div>
      )
    },
    {
      columnHeaderStyle: { width: '20%' },
      columnDataStyle: {
        width: '20%'
      },
      heading: (
        <div className='flex row justify-center text-center items-center'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            Response Time Graph
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'responsesByDate',
      render: (responsesByDate: any) => {
        const responseTimeData = Object.keys(responsesByDate).map(
          (key, index) => ({
            x: index,
            y: responsesByDate[key].averageResponseTimeInMilliSeconds
          })
        )
        return (
          <div className='flex justify-center'>
            {isEmpty(responsesByDate) || responseTimeData.length === 1 ? (
              <DDSTypography.Paragraph
                size='para'
                variant='medium'
                className='text-center whitespace-nowrap'
                color={pallete.colors.textDark4}
              >
                {' '}
                -{' '}
              </DDSTypography.Paragraph>
            ) : (
              <UCResponseTimeGraph data={responseTimeData} />
            )}
          </div>
        )
      }
    },
    {
      columnHeaderStyle: { width: '5%' },
      columnDataStyle: { width: '5%' },
      heading: '',
      dataLabel: 'id',
      render: (id: any) => (
        <div className='cursor-pointer'>
          <Button
            id='right-btn'
            type='ghost'
            startIcon={
              <ChevronRight size={16} color={pallete.colors.primary2} />
            }
            onClick={() => {
              navigate(
                replaceUrl(
                  routeConstants.AHC_DASHBOARD,
                  store.scopeStore.getScope()['organization-id'] as string,
                  store.scopeStore.getScope()['project-id'] as string,
                  store.scopeStore.getScope()['environment-id'] as string
                ).replace(':id', id as string)
              )
            }}
          />
        </div>
      )
    },
    {
      columnHeaderStyle: { width: '5%' },
      columnDataStyle: { width: '5%' },
      heading: '',
      dataLabel: 'key',
      render: (text: any, fullItem: any) => (
        <div
          className={`flex justify-center`}
          onClick={() => {
            rowDisabled.current = true
            setTimeout(() => {
              rowDisabled.current = false
            }, 2000)
          }}
        >
          <UCSummaryDropdown
            itemKey={text}
            id={fullItem.id}
            item={fullItem}
            setSelectedCheck={setSelectedCheck}
            setDeleteOpen={setDeleteOpen}
          />
        </div>
      )
    }
  ]

  return loading || summaryLoading || !summaryData || !uptimeData ? (
    <div className='flex flex-col h-[70vh]  self-stretch justify-center'>
      <Spinner />
    </div>
  ) : (
    <div className='flex flex-col gap-[24px] py-[24px]'>
      <div className='grid grid-cols-4 gap-[16px]'>
        {cardData.map((item) => (
          <div key={item.id}>
            <UCSummaryCard
              id={item.id}
              heading={item.heading}
              content={item.content}
              onClick={item.onClick}
              summary={true}
              disableChevron={item.disableChevron}
              selectedCardId={selectedCardId}
              selected={item.selected}
            />
          </div>
        ))}
      </div>
      <div
        style={{ backgroundColor: pallete.colors.stroke2 }}
        className={`h-[1px] w-full self-stretch`}
      />

      <div className='flex justify-between items-center gap-[8px]'>
        {/* Left-aligned Search */}
        <div className='flex-shrink-0'>
          <Search
            id='search'
            name='Search'
            placeholder='Search'
            value={searchTerm}
            onChange={handleSearchChange}
            autoComplete='off'
            width='32vw'
            disabled={loading}
            onPressEnter={() => handleSearchEnter(searchTerm)}
            onBlur={() => {
              setTimeout(() => setShowDropdown(false), 200)
            }}
          />
          {showDropdown && (
            <div
              className='absolute z-[99999] mt-[12px] flex flex-col bg-white border items-start p-[12px] rounded-md w-[32vw] shadow-lg'
              style={{ borderColor: pallete.colors.stroke2 }}
            >
              <div
                className={`flex p-[8px] gap-[8px] items-center cursor-pointer `}
                onClick={() => handleOptionSelect(searchTerm, 'checkName')}
              >
                <DDSTooltip
                  id={generateRandomId()}
                  label={searchTerm}
                  className={`border override-tooltip-arrow max-w-[90%] break-all ${
                    typeof searchTerm === 'string' && searchTerm.length > 38
                      ? searchTerm
                      : '!hidden'
                  }`}
                  theme='light'
                  style={{
                    borderColor: pallete.colors.stroke2,
                    zIndex: 99999
                  }}
                >
                  <div className='flex flex-row  gap-[8px]'>
                    <DDSTypography.Paragraph
                      size='para'
                      variant='medium'
                      color={pallete.colors.textDark4}
                      className='flex-start truncate  w-[290px] cursor-default'
                    >
                      {searchTerm}
                    </DDSTypography.Paragraph>
                    <DDSTypography.Paragraph
                      size='para'
                      variant='medium'
                      color={pallete.colors.textDark6}
                    >
                      in name column
                    </DDSTypography.Paragraph>
                  </div>
                </DDSTooltip>
              </div>
              <div
                className='flex p-[8px] gap-[8px] items-center cursor-pointer'
                onClick={() => handleOptionSelect(searchTerm, 'url')}
              >
                <DDSTooltip
                  id={generateRandomId()}
                  label={searchTerm}
                  className={`border override-tooltip-arrow max-w-[90%] break-all ${
                    typeof searchTerm === 'string' && searchTerm.length > 38
                      ? searchTerm
                      : '!hidden'
                  }`}
                  theme='light'
                  style={{
                    borderColor: pallete.colors.stroke2,
                    zIndex: 99999
                  }}
                >
                  <div className='flex flex-row  gap-[8px]'>
                    <DDSTypography.Paragraph
                      size='para'
                      variant='medium'
                      color={pallete.colors.textDark4}
                      className='flex-start truncate  w-[290px] cursor-default'
                    >
                      {searchTerm}
                    </DDSTypography.Paragraph>
                    <DDSTypography.Paragraph
                      size='para'
                      variant='medium'
                      color={pallete.colors.textDark6}
                    >
                      in URL column
                    </DDSTypography.Paragraph>
                  </div>
                </DDSTooltip>
              </div>
              <div
                className='flex p-[8px] gap-[8px] items-center cursor-pointer'
                onClick={() => handleOptionSelect(searchTerm, 'any')}
              >
                <DDSTooltip
                  id={generateRandomId()}
                  label={searchTerm}
                  className={`border override-tooltip-arrow max-w-[90%] break-all ${
                    typeof searchTerm === 'string' && searchTerm.length > 38
                      ? searchTerm
                      : '!hidden'
                  }`}
                  theme='light'
                  style={{
                    borderColor: pallete.colors.stroke2,
                    zIndex: 99999
                  }}
                >
                  <div className='flex flex-row  gap-[8px]'>
                    <DDSTypography.Paragraph
                      size='para'
                      variant='medium'
                      color={pallete.colors.textDark4}
                      className='flex-start truncate  w-[290px] cursor-default'
                    >
                      {searchTerm}
                    </DDSTypography.Paragraph>
                    <DDSTypography.Paragraph
                      size='para'
                      variant='medium'
                      color={pallete.colors.textDark6}
                    >
                      in all column
                    </DDSTypography.Paragraph>
                  </div>
                </DDSTooltip>
              </div>
            </div>
          )}
        </div>

        {/* Right-aligned Filter and SideChip */}
        <div className='flex justify-end gap-[8px] items-center'>
          <div>
            <SideChip
              id='date-range-chip'
              label='Date Range'
              value={convertToFormatUC(dateFilter, userTimeZone)}
            />
          </div>
          <div className='flex custom-filter override-filter-margin'>
            <Filter
              options={reportFilterOptions}
              hideFilterChips
              AHCMinDate={true}
              disabled={
                (searchTerm !== '' && uptimeData?.data?.length === 0) ||
                (uptimeData?.data?.length === 0 &&
                  !store.filterStore.getFiltersApplied())
              }
            />
          </div>
        </div>
      </div>
      {uptimeData?.data?.length > 0 ? (
        <Table
          data={uptimeData?.data}
          columns={Columns}
          totalCount={uptimeData?.totalCount}
          rowsPerPage={5}
          currentPage={currentPage}
          onPageChange={(page) => {
            setCurrentPage(page)
            const allQueryParams = getAllQueryParamsAsObjectFromUrl(
              location.search
            )

            setSearchParams({ ...allQueryParams, page: page.toString() })
          }}
          loading={loading}
          clickType='Uptime'
          clickable={routeUC}
        />
      ) : searchRecordLength === 0 && searchTerm != '' ? (
        <NoRecord
          style={{ height: '70vh' }}
          imageSrc={NoFilterImage}
          text='No results found'
        />
      ) : (
        <NoRecord
          imageSrc={
            uptimeData?.totalCount === 0 &&
            store.filterStore.getFiltersApplied()
              ? NoFilterImage
              : NoRecordImage
          }
          style={{ height: '50vh' }}
          text={
            uptimeData?.totalCount === 0 &&
            store.filterStore.getFiltersApplied()
              ? StringConstants.NO_RECORDS_FILTER
              : UptimeStringConstants.ADD_CHECK_TO_GET_STARTED
          }
        />
      )}
      <UCDeleteCheckPopup
        open={deleteOpen}
        check={selectedCheck}
        buttonLoading={buttonLoading}
        setOpen={setDeleteOpen}
        onCancel={() => setDeleteOpen(false)}
        onDelete={handleDeleteCheck}
      />
    </div>
  )
}

export default UCSummary
