import 'den-design-system/style.css'
import './styles.css'

import { DDSToast, DesignSystemEnum, ThemeContext } from 'den-design-system'
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes
} from 'react-router-dom'
import React, { Profiler, useContext, useEffect, useLayoutEffect } from 'react'

import AHCBadgeView from './pages/AHCPage/AHCBadge'
import APIGroupedAll from './pages/NewVersion/APIMonitoring/APIGroupedAll'
import APIOverViewRequestResponseAttribute from './pages/NewVersion/APIMonitoring/APIOverViewRequestResponseAttribute'
import AccessDeniedPage from './components/common/MiscellaneousScreen/AccessDeniedPage/AccessDeniedPage'
import AccountSetting from './pages/NewVersion/Settings/AccountSetting/AccountSetting'
import AddHost from './pages/NewVersion/Settings/ProjectSetting/Components/AddHost'
import ArchivedAPIRecords from './pages/ArchivedAPIRecords/ArchivedAPIRecords'
import ComingSoon from './components/common/ComingSoon/ComingSoon'
import CreateNewPassword from './components/common/Password/Screen/CreateNewPassword'
import CreateOrgInviteMembers from './components/CreateOrgInviteMembers/CreateOrgInviteMembers'
import ErrorPage from './components/common/MiscellaneousScreen/ErrorPage/ErrorPage'
import EscalationsRouter from './pages/NewVersion/Escalations/EscalationsRouter'
import Exceptions from './pages/NewVersion/Exceptions/Exceptions'
import ForgotPassword from './pages/ForgotPassword/ForgotPassword'
import GAComponent from './components/common/GAComponent'
import { GatewayEventConstants } from './constants/GatewayEventConstants'
import GroupedUserAPILogDetails from './components/APIMonitoring/GroupedAPITab/GroupedUserAPILogDetails'
import GroupedUserViewDetails from './components/APIMonitoring/GroupedAPITab/GroupedUserViewDetails'
import HealthStatus from './pages/HealthStatus/HealthStatus'
import Home from './pages/Home/Home'
import Incident from './pages/IncidentPage/IncidentPage'
import IncidentOverView from './pages/NewVersion/IncidentManagement/IncidentOverView'
import IncidentPublicShare from './pages/NewVersion/PublicShare/IncidentPublicShare'
import Invites from './pages/NotificationNew/Page/Invites'
import JMRouter from './pages/NewVersion/JobMonitoringPage/JMRouter'
import Layout from './components/New/Layout/NewLayout'
import ManageIncident from './pages/NewVersion/IncidentManagement/ManageIncident'
import NoOrganizationJoined from './components/CreateOrgInviteMembers/NoOrg/NoOrg'
import NoProjectPage from './pages/NoProjectPage/NoProjectPage'
import NoRecord from './pages/NoRecordPage/NoRecordPage'
import Notification from './pages/NotificationNew/Screen/NotificationInvites'
import NotificationInvites from './pages/NotificationNew/Screen/NotificationInvites'
// import Notifications from './pages/Notifications/Notifications'
import OrganizationSetting from './pages/NewVersion/Settings/OrganizationSetting/OrganizationSetting'
import OverviewPage from './pages/NewVersion/Overview/OverviewPage'
import PageTracker from './components/common/PageTrackerComponent'
import PartnerRedirect from './pages/Invites/PartnerRedirect'
import PreventBackNavigation from './utils/PreventNavigationUtils'
import ProjectSettings from './pages/NewVersion/Settings/ProjectSetting/ProjectSettings'
import PublicShare from './pages/NewVersion/PublicShare/PublicShare'
import PublicViewPage from './pages/NewVersion/UptimeChecksPage/PublicViewPage'
import ReactGA from 'react-ga4'
import RedirectScreen from './pages/RedirectScreen'
import ReportIncidentPage from './pages/IncidentPage/ReportIncident'
import RolesAction from './pages/NewVersion/Settings/AccountSetting/Components/RolesAction'
import SalesforceAudit from './pages/SalesforceAudit/SalesforceAudit'
import SalesforceConnect from './pages/SalesforceAudit/SalesforceConnect'
import SalesforcePage from './pages/SalesforcePage/Salesforce'
import SandboxScreen from './pages/SandboxScreen/SandboxScreen'
import SignIn from './pages/NewVersion/SignIn/SignIn'
import SignUp from './pages/NewVersion/SignUp/SignUp'
import SingleExceptionView from './pages/NewVersion/Exceptions/SingleExceptionGroup'
import SingleLogView from './pages/NewVersion/Exceptions/SingleLogView'
import SlackOauthRedirect from './pages/Slack/SlackOauthRedirect'
import StringConstants from './constants/StringConstants'
import ThingsToDoSection from './pages/ThingsToDo/thingsToDo'
import UCPageRouter from './pages/NewVersion/UptimeChecksPage/UCPageRouter'
import UnderMaintenancePage from './components/common/MiscellaneousScreen/UnderMaintenancePage/UnderMaintenancePage'
import UpdateIncident from './pages/IncidentPage/UpdateIncident'
import UserInviteRedirect from './pages/Invites/UserInviteRedirect'
import VerifyUserRedirect from './pages/EmailRedirects/AccountSettings/VerifyUserRedirect'
import ViewAllProject from './pages/NewVersion/Settings/ProjectSetting/ViewAllProject'
import ViewGroupedAPIDetails from './pages/NewVersion/APIMonitoring/ViewGroupedAPIURLDetails'
import breakpoints from './global/breakpoints'
import { loadProgressBar } from 'x-axios-progress-bar'
import { observer } from 'mobx-react-lite'
import palette from './global/pallete'
import pallete from './global/newPallete'
import routeConstants from './constants/RouteConstants'
import { socket } from './gateway'
import styled from '@emotion/styled'
import useRefeshOnToken from './utils/useRefreshOnToken'
import { useStoreContext } from './store/StoreContext'

const SidebarOverlay = styled.div<{ expanded?: boolean }>`
  position: fixed;
  background: ${palette.colors.sidebarOverlay};
  z-index: 12;
  width: 100vw;
  height: 100vh;
  display: ${({ expanded }) => !expanded && 'none'};
  @media (min-width: ${breakpoints.lg}) {
    display: none;
  }
`
if (process.env.REACT_APP_TRACK_GA === 'TRUE')
  ReactGA.initialize(process.env.REACT_APP_REACTGA as string)
const App = () => {
  const { uiStore } = useStoreContext()
  const { userStore } = useStoreContext()
  const { saveTheme }: any = useContext(ThemeContext)

  //to refresh the tabs on accessToken change
  useRefeshOnToken()
  const switchToNewDesign = () => {
    localStorage.setItem(
      StringConstants.LOCAL_STORAGE.NEW_DESIGN_SYSTEM_FLAG,
      'true'
    )
  }

  const shouldShowRecaptcha = [
    '/signIn',
    '/signUp',
    '/forgotPassword'
  ].includes(location.pathname)

  useEffect(() => {
    uiStore.setSidebarExpanded(false)

    socket.on(GatewayEventConstants.IN_APP_NOTIFICATION, (notification) => {
      console.log(
        'No. of unread notifications = ',
        notification.unreadNotificationCount
      )
      console.log(
        'No. of pending invitations = ',
        notification.pendingInvitesCount
      )

      userStore.setNotificationCount(notification.unreadNotificationCount)
      userStore.setInvitationCount(notification.pendingInvitesCount)
    })

    return () => {
      socket.off(GatewayEventConstants.IN_APP_NOTIFICATION)
    }
  }, [])
  useEffect(() => {
    saveTheme({
      designSystem: DesignSystemEnum.TAILWIND,
      fontFamily: 'Mulish, sans serif',
      styling: {
        component: {
          Button: {
            minWidth: 'fit-content'
          }
        }
      },
      color: {
        primary: pallete.colors.primary2,
        component: {
          Button: {
            Filled: {
              backgroundColor: pallete.colors.FillButton,
              hoverBackgroundColor: pallete.colors.fillButtonhover,
              hoverTextColor: pallete.colors.white,
              textColor: pallete.colors.white
            },
            Outline: {
              borderColor: pallete.colors.outButton,
              hoverBackgroundColor: 'none',
              hoverTextColor: 'none',
              textColor: pallete.colors.textDark2
            }
          },
          DatePicker: {
            datePickerRangeSelectBg: palette.colors.primary5
          },
          Table: {
            headerBg: pallete.colors.surface6,
            rowSelectedBg: pallete.colors.surface4,
            rowSelectedHoverBg: pallete.colors.surface4,
            rowHoverBg: pallete.colors.surface4
          },
          ContentSwitcherTabs: {
            contentSwitcherBackgroundColor: pallete.colors.surface1,
            activeTabColor: pallete.colors.FillButton,
            activeTabTextColor: palette.colors.white
          },
          Modal: {
            modalBackground: pallete.colors.surface1,
            modalFooterBg: pallete.colors.surface1,
            modalFooterTopBorder: pallete.colors.stroke2
          },
          BreadCrumbs: {
            activeColor: pallete.colors.textDark2,
            inactiveColor: pallete.colors.textDark5
          },
          Tabs: {
            activeTabTextColor: pallete.colors.primary2,
            inactiveTabTextColor: pallete.colors.textDark5
          },
          Input: {
            disableBackgroundColor: pallete.colors.white,
            disableTextColor: pallete.colors.textDark9
          },
          Pagination: {
            activeColor: pallete.colors.FillButton,
            inActiveColor: pallete.colors.primary
          },
          Radio: {
            backgroundColor: pallete.colors.textDark2,
            hoverColor: pallete.colors.textDark2
          },
          Tour: {}
        }
      },
      typography: {
        fontWeightBold: 700,
        fontWeightSemiBold: 600,
        fontWeightMedium: 500,
        fontWeightRegular: 400,
        variant: {
          h1: {
            fontSize: 24,
            lineHeight: 30
          },
          h2: {
            fontSize: 22,
            lineHeight: 28
          },
          h3: {
            fontSize: 20,
            lineHeight: 26
          },
          h4: {
            fontSize: 18,
            lineHeight: 24
          },
          h5: {
            fontSize: 16,
            lineHeight: 20
          },
          para: {
            fontSize: 14,
            lineHeight: 20
          },
          caption: {
            fontSize: 12,
            lineHeight: 16
          }
        }
      }
    })
  }, [])

  useEffect(() => {
    localStorage.setItem(
      StringConstants.LOCAL_STORAGE.NEW_DESIGN_SYSTEM_FLAG,
      'false'
    )
    if (process.env.REACT_APP_ENV! !== 'PRODUCTION') {
      switchToNewDesign()
    }
  }, [])

  const newDesignSystem = localStorage.getItem(
    StringConstants.LOCAL_STORAGE.NEW_DESIGN_SYSTEM_FLAG
  )

  function onRender(
    id: any,
    phase: any,
    actualDuration: any,
    baseDuration: any,
    startTime: any,
    commitTime: any
  ) {
    console.log(id, phase, actualDuration, baseDuration, startTime, commitTime)
  }

  useLayoutEffect(() => {
    const recaptchaBadge = document.querySelector(
      '.grecaptcha-badge'
    ) as HTMLElement
    if (recaptchaBadge) {
      if (shouldShowRecaptcha) {
        recaptchaBadge.style.removeProperty('visibility')
      } else {
        recaptchaBadge.style.setProperty('visibility', 'hidden')
      }
    }
  }, [location.pathname, shouldShowRecaptcha])

  return (
    <>
      <Profiler id='vigil' onRender={onRender}></Profiler>
      <SidebarOverlay expanded={uiStore.sidebarExpanded} />
      <>{loadProgressBar()}</>
      <Router>
        <GAComponent />
        <PageTracker />
        <PreventBackNavigation />
        <Routes>
          <Route
            path={routeConstants.PUBLIC_ERROR_MONITORING}
            element={<PublicShare />}
          />
          <Route
            path={routeConstants.INCIDENT_PUBLIC_SHARE}
            element={<IncidentPublicShare />}
          />
          <Route path={routeConstants.ERROR_PAGE} element={<ErrorPage />} />

          <Route
            path={routeConstants.UNDERMAINTENANCE_PAGE}
            element={<UnderMaintenancePage />}
          />

          <Route path='/public-view' element={<PublicViewPage />} />

          <Route
            path={routeConstants.UNAUTHORIZED_PAGE}
            element={<AccessDeniedPage />}
          />
          <Route path={routeConstants.NO_RECORD} element={<NoRecord />} />

          <Route
            path={routeConstants.RESET_PASSWORD}
            element={<CreateNewPassword />}
          />
          <Route
            path={routeConstants.FORGOT_PASSWORD}
            element={<ForgotPassword />}
          />
          <Route
            path={routeConstants.INVITE_MEMBERS}
            element={<CreateOrgInviteMembers inviteMembers />}
          />
          <Route
            path={routeConstants.CHECKS_PUBLIC_SHARE}
            element={<PublicViewPage />}
          />
          <Route element={<Layout />}>
            {/*Children  Routes for the new designs are passed here,
               since the Layout component is using <Outlet/> as children */}

            <Route
              path={routeConstants.NO_PROJECT}
              element={<NoProjectPage />}
            />
            <Route
              path={routeConstants.ESCALATION_SETS + '/*'}
              element={<EscalationsRouter />}
            />
            <Route
              path={routeConstants.PUBLIC_SHARE}
              element={<PublicShare />}
            />
            <Route
              path={routeConstants.MANAGE_INCIDENTS}
              element={<ManageIncident />}
            />
            <Route
              path={routeConstants.MANAGE_INCIDENTS_FROM_EXCEPTION}
              element={<ReportIncidentPage />}
            />
            <Route
              path={routeConstants.UPDATE_INCIDENT}
              element={<UpdateIncident />}
            />
            <Route
              path={routeConstants.INCIDENT_OVERVIEW}
              element={<IncidentOverView />}
            />
            <Route
              path={routeConstants.NOTIFICATIONS}
              element={<NotificationInvites />}
            />

            <Route
              path={routeConstants.NOTIFICATIONS}
              element={
                <Navigate to={routeConstants.NOTIFICATIONS} replace={true} />
              }
            />
            <Route path={routeConstants.INVITES} element={<Invites />} />

            <Route index element={<Home />} />

            <Route
              path={routeConstants.ACCOUNT_SETTING}
              element={<AccountSetting />}
            />

            <Route
              path={routeConstants.SETTINGS}
              element={<Navigate to={routeConstants.PROJECT_SETTINGS} />}
            />

            <Route
              path={routeConstants.PROJECT_SETTING + '/*'}
              element={<ProjectSettings />}
            />

            <Route path={routeConstants.ADDHOST} element={<AddHost />} />

            <Route
              path={routeConstants.ACCOUNT_SETTING}
              element={<AccountSetting />}
            />
            <Route
              path={routeConstants.MANAGE_ORGANIZATION + '/*'}
              element={<OrganizationSetting />}
            />
            <Route
              path={routeConstants.ROLES_AND_ACTIONS}
              element={<RolesAction />}
            />
            <Route
              path={routeConstants.VIEW_PROJECT}
              element={<ViewAllProject />}
            />

            <Route
              path={routeConstants.AHC + '/*'}
              element={<UCPageRouter />}
            />

            <Route
              path={routeConstants.JOB_MONITORING + '/*'}
              element={<JMRouter />}
            />

            <Route
              path={routeConstants.API_MONITORING + '/*'}
              element={<APIGroupedAll />}
            />
            <Route
              path={routeConstants.VIEW_GROUPED_API}
              element={<ViewGroupedAPIDetails />}
            />
            <Route
              path={routeConstants.GROUPED_USER_DETAILS}
              element={<GroupedUserViewDetails />}
            />

            <Route
              path={routeConstants.GROUPED_USER_API_LOGS_DETAILS}
              element={<GroupedUserAPILogDetails />}
            />

            <Route
              path={routeConstants.VIEW_ALL_API}
              element={<APIOverViewRequestResponseAttribute />}
            />
            <Route
              path={routeConstants.ARCHIVED_API_RECORDS}
              element={<ArchivedAPIRecords />}
            />
            <Route
              path={routeConstants.GROUPED_ERROR_MONITORING}
              element={<Exceptions />}
            />
            <Route
              path={routeConstants.ERROR_MONITORING}
              element={<SingleExceptionView />}
            />

            <Route
              path={routeConstants.THINGS_TO_DO}
              element={<ThingsToDoSection />}
            />

            <Route
              path={routeConstants.ERROR_DETAILS}
              element={<SingleLogView />}
            />

            <Route
              path={routeConstants.API_MONITORING + '/*'}
              element={<APIGroupedAll />}
            />
            <Route path={routeConstants.INCIDENT} element={<Incident />} />
            <Route
              path={routeConstants.REPORT_INCIDENT}
              element={<ReportIncidentPage />}
            />
            <Route
              path={routeConstants.VIEW_GROUPED_API}
              element={<ViewGroupedAPIDetails />}
            />
            <Route path={routeConstants.INCIDENT} element={<Incident />} />
            <Route
              path={routeConstants.VIEW_ALL_API}
              element={<APIOverViewRequestResponseAttribute />}
            />

            <Route path={routeConstants.OVERVIEW} element={<OverviewPage />} />
            <Route
              path={routeConstants.HEALTH_STATUS}
              element={<HealthStatus />}
            />

            <Route
              path={routeConstants.SALESFORCE_AUDIT + '/*'}
              element={<SalesforceAudit />}
            />
          </Route>

          {/* TODO Create organization is hidden in Beta */}
          {/* <Route
            path={routeConstants.CREATE_ORGANIZATION}
            element={<CreateOrgAssignMembers />}
          /> */}

          <Route
            path={routeConstants.SANDBOX_SCREEN}
            element={<SandboxScreen />}
          />

          <Route path={routeConstants.SIGN_UP} element={<SignUp />} />
          <Route path={routeConstants.SIGN_UP_ALTERNATE} element={<SignUp />} />
          <Route path={routeConstants.SIGN_IN} element={<SignIn />} />
          <Route path={routeConstants.SIGN_IN_ALTERNATE} element={<SignIn />} />
          <Route
            path={routeConstants.FORGOT_PASSWORD}
            element={<ForgotPassword />}
          />

          <Route path={routeConstants.COMING_SOON} element={<ComingSoon />} />

          <Route path={routeConstants.REDIRECT} element={<RedirectScreen />} />

          <Route
            path={routeConstants.PARTNER_INVITE_POPUP}
            element={<PartnerRedirect />}
          />
          <Route
            path={routeConstants.NO_ORGANIZATION}
            element={<NoOrganizationJoined />}
          />

          <Route
            path={routeConstants.INVITE_REDIRECT}
            element={<UserInviteRedirect />}
          />
          <Route
            path={routeConstants.PARTNER_REDIRECT}
            element={<PartnerRedirect />}
          />
          <Route
            path={routeConstants.VERIFY_USER_REDIRECT}
            element={<VerifyUserRedirect />}
          />

          <Route path={routeConstants.AHC_BADGE} element={<AHCBadgeView />} />
          <Route
            path='*'
            element={<Navigate to={routeConstants.ERROR_PAGE} replace={true} />}
          />
          <Route
            path={routeConstants.ONBOARD_USING_SSO}
            element={<SalesforcePage />}
          />
          <Route
            path={routeConstants.SLACK_OAUTH_REDIRECT}
            element={<SlackOauthRedirect />}
          />

          <Route
            path={routeConstants.CONNECT_TO_SALESFORCE}
            element={<SalesforceConnect />}
          />
        </Routes>

        <DDSToast position='bottom-right' variant='bottomBorder' />
      </Router>
    </>
  )
}

export default observer(App)
