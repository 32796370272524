import { AddFilled, ChevronRight, Edit, Renew } from '@carbon/icons-react'
import React, { useEffect, useState } from 'react'
import {
  getExceptionsTags,
  updateExceptionGroup,
  viewSingleExceptionGorup
} from '../../../api/exception/Exception.service'
import { useParams, useSearchParams } from 'react-router-dom'

import { Button } from '../../../components/New/Button/Button'
import ConfirmationPopup from '../../../components/common/Exceptions/ConfirmationPopup'
import { DDSTypography } from 'den-design-system'
import DocumentHeader from '../../../components/Helmet/DocumentHeader'
import ExceptionGroupedSearchTag from '../../../components/common/ExceptionSearchTag/ExceptionGroupedSearchTag'
import GroupedExceptionLogs from './Screens/GroupedExceptionLogs'
import { HeaderConstants } from '../../../constants/HeaderConstants'
import Overview from './Screens/Overview'
import { ProjectType } from '../../../enum/ProjectType.enum'
import SeverityDropDown from '../../../components/common/Exceptions/SeverityDropdown'
import { SeverityTypes } from '../../../api/exception/Exception.types'
import Spinner from '../../../components/common/Spinner/Spinner'
import StringConstants from '../../../constants/StringConstants'
import Tabs from '../../../components/common/DDS/Tabs/Tabs'
import { ToastMessageConstants } from '../../../constants/ToastMessageConstants'
import ToastNotification from '../../../components/common/DDS/Toast/Toast'
import Tooltip from '../../../components/New/Tooltip/Tooltip'
import { gaPageConstants } from '../../../constants/GAConstants'
import { getAllQueryParamsAsObjectFromUrl } from '../../../utils/UrlUtils'
import { isEmpty } from 'lodash'
import { observer } from 'mobx-react-lite'
import pallete from '../../../global/newPallete'
import routeConstants from '../../../constants/RouteConstants'
import { severityColorMap } from '../../../constants/ExceptionsPageConstants'
import { useAnalyticsEventTracker } from '../../../utils/GAUtils'
import { useStoreContext } from '../../../store/StoreContext'

const SingleExceptionView: React.FC = () => {
  const store = useStoreContext()

  const [searchParams, setSearchParams] = useSearchParams()
  const [activeTabIndex, setActiveIndex] = useState<string>('1')
  const [modalOpen, setModalOpen] = React.useState<boolean>(false)
  const [preference, setPreference] = React.useState<string>('')
  const [exceptionGroupType, setExceptionGroupType] = React.useState<string>('')
  const [exceptionCount, setExceptionCount] = React.useState<number>()
  const [isloadingTag, setIsLoadingTag] = useState<boolean>(false)
  const [changeToPreference, setToPreference] = React.useState<any>()
  const { id } = useParams()
  const [loading, setLoading] = useState<boolean>(false)
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false)
  const [loadingTag, setLoadingTag] = useState<boolean>(false)
  const gaEventTracker = useAnalyticsEventTracker(
    gaPageConstants.REFRESH_EXCEPTIONS_SINGLE_GROUP
  )

  const projectType = store.scopeStore.getSelectedProject().type
  const isFullStackProject = projectType === ProjectType.FULL_STACK_ONLY

  useEffect(() => {
    localStorage.setItem(
      StringConstants.ACTIVE_TAB,
      StringConstants.TAB_VALUES.EXCEPTION
    )
  }, [])

  const [exceptionDetails, setExceptionDetails] = useState<any>()

  useEffect(() => {
    const allQueryParams = getAllQueryParamsAsObjectFromUrl(location.search)
    store.exceptionsStoreV2.setActiveGroupedExceptionSeverity(
      allQueryParams['preferredSeverity']
    )
    setPreference(allQueryParams['preferredSeverity'])
  }, [store.exceptionsStoreV2.getActiveGroupedExceptionSeverity()])

  const fetchAnExceptionDetails = () => {
    viewSingleExceptionGorup({ $exceptionGroupId: id as string }).then(
      (response: any) => {
        setExceptionDetails(response.data)
        store.exceptionsStoreV2.setActiveGroupedException(response.data)
        setExceptionGroupType(response.data.exceptionGroupType)
        setExceptionCount(response.data.exceptionCount)
        store.exceptionStore.setIsRefreshed(false)
        setPreference(response.data.preferredSeverity)
        store.exceptionsStoreV2.setActiveGroupedException(response.data)
        store.uiStore.setGlobalLoader(false)
      }
    )
  }
  useEffect(() => {
    if (store.exceptionStore.getIsRefreshed()) {
      fetchAnExceptionDetails()
    }
  }, [store.exceptionStore.getIsRefreshed()])

  useEffect(() => {
    localStorage.setItem(
      StringConstants.ACTIVE_TAB,
      StringConstants.TAB_VALUES.EXCEPTION
    )
  }, [])

  useEffect(() => {
    const innerTabIndexFromParams = searchParams.get('groupTab')
    if (innerTabIndexFromParams) {
      setActiveIndex(innerTabIndexFromParams)
    }
    fetchAnExceptionDetails()
    const tabIndexFromParams = searchParams.get('tab')
    if (!isEmpty(exceptionGroupType)) {
      store.breadcrumbStore.setMultipleBreadcrumbsOptions([
        {
          label: (
            <div
              style={{
                pointerEvents:
                  isloadingTag || store.uiStore.getTagLoader() ? 'none' : 'auto'
              }}
              onClick={(e) => {
                if (loading || isloadingTag || store.uiStore.getTagLoader()) {
                  e.stopPropagation()
                }
              }}
            >
              {store.scopeStore.getSelectedProject().type ===
              ProjectType.FULL_STACK_ONLY
                ? tabIndexFromParams === '1'
                  ? 'Grouped Exceptions'
                  : 'All Exceptions'
                : tabIndexFromParams === '1'
                  ? 'Grouped Errors'
                  : 'All Errors'}
            </div>
          ),
          link: routeConstants.EXCEPTIONS + `?tab=${tabIndexFromParams}`
        },
        {
          label: (
            <Tooltip
              id={`${exceptionGroupType}`}
              position='bottom'
              className={`max-w-[45%] ${
                typeof exceptionGroupType === 'string' &&
                exceptionGroupType.length > 15
                  ? exceptionGroupType
                  : '!hidden'
              }`}
              label={exceptionGroupType}
            >
              <div
                style={{
                  pointerEvents:
                    isloadingTag || store.uiStore.getTagLoader()
                      ? 'none'
                      : 'auto'
                }}
                onClick={(e) => {
                  if (loading || isloadingTag || store.uiStore.getTagLoader()) {
                    e.stopPropagation()
                  }
                }}
                className='truncate max-w-[200px]'
              >
                {exceptionGroupType}
              </div>
            </Tooltip>
          ),
          link: '/'
        }
      ])
    }
  }, [exceptionGroupType])
  //Severity dropdown change function
  const updateExceptionGroupPreferrence = (severity: SeverityTypes) => {
    setLoading(true)
    setLoadingTag(true)
    updateExceptionGroup({
      ids: [id as string],
      preferredSeverity: severity
    })
      .then(() => {
        store.exceptionsStoreV2.setActiveGroupedExceptionSeverity(severity)
        fetchAnExceptionDetails()
        ToastNotification({
          type: 'success',
          message: isFullStackProject
            ? ToastMessageConstants.EXCEPTIONS.UPDATE_EXCEPTION_GROUP.SUCCESS
            : ToastMessageConstants.ERROR.UPDATE_ERROR_GROUP.SUCCESS
        })
        setLoading(false)
        setLoadingTag(false)
        setModalOpen(false)
      })
      .catch((err) => {
        ToastNotification({
          type: 'error',
          message: isFullStackProject
            ? ToastMessageConstants.EXCEPTIONS.UPDATE_EXCEPTION_GROUP.ERROR
            : ToastMessageConstants.ERROR.UPDATE_ERROR_GROUP.ERROR
        })

        setLoading(false)
        setLoadingTag(false)
        store.filterStore.setFilterDisabled(false)
      })
  }

  const tabOptions = [
    {
      label:
        activeTabIndex === '1' ? (
          <DDSTypography.Title
            type='h5'
            variant='semiBold'
            color={pallete.colors.primary2}
          >
            {'Overview'}
          </DDSTypography.Title>
        ) : (
          <DDSTypography.Title
            type='h5'
            variant='medium'
            color={pallete.colors.textDark5}
          >
            {'Overview'}
          </DDSTypography.Title>
        ),
      children: (
        <div>
          <Overview setLoadingTag={setLoadingTag} loadingTag={loadingTag} />
        </div>
      )
    },
    {
      label:
        activeTabIndex === '2' ? (
          <DDSTypography.Title
            type='h5'
            variant='semiBold'
            color={pallete.colors.primary2}
          >
            {isFullStackProject ? 'Exceptions' : 'Errors'}
          </DDSTypography.Title>
        ) : (
          <DDSTypography.Title
            type='h5'
            variant='medium'
            color={pallete.colors.textDark5}
          >
            {isFullStackProject ? 'Exceptions' : 'Errors'}
          </DDSTypography.Title>
        ),
      children: <GroupedExceptionLogs />
    }
  ]
  if (
    loading ||
    store.uiStore.getGlobalLoader() ||
    isloadingTag ||
    store.uiStore.getTagLoader()
  )
    return <Spinner />
  return (
    <div className='h-[100%]'>
      <DocumentHeader
        header={
          store.scopeStore.getSelectedProject().type ===
          ProjectType.FULL_STACK_ONLY
            ? HeaderConstants.EXCEPTIONS
            : HeaderConstants.ERROR
        }
      />
      <div className='py-[24px] h-[100%]'>
        <div className='flex flex-row justify-between items-start'>
          <div className='flex gap-y-[8px] flex-col'>
            <div>
              <DDSTypography.Title type='h3' variant='semiBold'>
                {exceptionGroupType}
              </DDSTypography.Title>
            </div>
            <div className='flex flex-row items-center gap-x-[4px]'>
              <div>
                <DDSTypography.Paragraph
                  size='caption'
                  variant='medium'
                  style={{ color: pallete.colors.textDark6 }}
                  className='flex items-center whitespace-nowrap'
                >
                  {isFullStackProject ? 'Exception Count:' : 'Error Count:'}
                </DDSTypography.Paragraph>
              </div>
              <div>
                <DDSTypography.Paragraph
                  size='para'
                  variant='semiBold'
                  style={{ color: pallete.colors.textDark3 }}
                  className='whitespace-nowrap text-center flex items-center'
                >
                  {exceptionCount}
                </DDSTypography.Paragraph>
              </div>

              {/* <div>
                {loading || loadingTag || store.uiStore.getGlobalLoader() ? (
                  '-'
                ) : (
                  <div className='flex flex-col top-[14px] gap-y-[16px] relative'>
                    <div className='flex flex-row gap-x-[8px] gap-y-[8px] relative flex-wrap'>
                      {exceptionDetails?.tags?.length > 0
                        ? exceptionDetails.tags
                            ?.slice(0, 5)
                            ?.map((tag: string, index: number) => (
                              <span
                                className='px-[8px] py-[4px] rounded-[100px] flex items-center'
                                key={index}
                                style={{
                                  backgroundColor: pallete.colors.surface4,
                                  cursor:
                                    isloadingTag || store.uiStore.getTagLoader()
                                      ? 'not-allowed'
                                      : 'pointer',
                                  pointerEvents:
                                    isloadingTag || store.uiStore.getTagLoader()
                                      ? 'none'
                                      : 'auto'
                                }}
                              >
                                <DDSTypography.Paragraph
                                  size='caption'
                                  variant='medium'
                                  color={pallete.colors.textDark4}
                                  className='text-center flex items-center'
                                >
                                  {tag}
                                </DDSTypography.Paragraph>
                              </span>
                            ))
                        : '-'}

                      <div className='flex justify-center items-center'>
                        {exceptionDetails?.tags?.length >= 5 ? (
                          <div
                            className='flex items-center justify-center px-[8px] py-[4px] rounded-full cursor-pointer hover:bg-blue-200'
                            style={{ backgroundColor: pallete.colors.surface4 }}
                            onClick={() => {
                              setIsPopupOpen(true)
                            }}
                          >
                            <span
                              style={{
                                fontWeight: 500,
                                fontSize: '12px',
                                color: pallete.colors.textDark4
                              }}
                              className='mr-[8px]'
                            >
                              Edit tags
                            </span>
                            <Edit
                              style={{
                                cursor:
                                  isloadingTag || store.uiStore.getTagLoader()
                                    ? 'not-allowed'
                                    : 'pointer',
                                pointerEvents:
                                  isloadingTag || store.uiStore.getTagLoader()
                                    ? 'none'
                                    : 'auto'
                              }}
                              size={16}
                              color={pallete.colors.FillButton}
                            />
                          </div>
                        ) : (
                          <div
                            className='flex items-center justify-center px-[8px] py-[4px] rounded-full shadow-md cursor-pointer hover:bg-blue-200'
                            style={{ backgroundColor: pallete.colors.surface4 }}
                            onClick={() => {
                              setIsPopupOpen(true)
                            }}
                          >
                            <span
                              style={{
                                fontWeight: 500,
                                fontSize: '12px',
                                color: pallete.colors.textDark4
                              }}
                              className='mr-[8px]'
                            >
                              Add tags
                            </span>
                            <AddFilled
                              style={{
                                cursor:
                                  isloadingTag || store.uiStore.getTagLoader()
                                    ? 'not-allowed'
                                    : 'pointer',
                                pointerEvents:
                                  isloadingTag || store.uiStore.getTagLoader()
                                    ? 'none'
                                    : 'auto'
                              }}
                              size={16}
                              color={pallete.colors.FillButton}
                            />
                          </div>
                        )}
                      </div>
                    </div>

                    <ExceptionGroupedSearchTag
                      isPopupOpen={isPopupOpen}
                      setIsLoadingTag={setIsLoadingTag}
                      isloadingTag={isloadingTag}
                      setIsPopupOpen={setIsPopupOpen}
                      exceptionId={id}
                      exceptionTagData={exceptionDetails?.tags}
                      fetchAnExceptionDetails={fetchAnExceptionDetails}
                    />
                  </div>
                )}
              </div> */}
            </div>
          </div>
        </div>
        <div className='mt-[16px] w-full relative'>
          <Tabs
            tabOptions={tabOptions}
            tabOptionsStyle={{
              minWidth: 'fit-content',
              width: 'auto',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              alignSelf: 'stretch',
              padding: '8px 12px',
              marginRight: '8px'
            }}
            onChange={(value: string) => {
              if (activeTabIndex != value) {
                setActiveIndex(value.toString())
                const queryParams = getAllQueryParamsAsObjectFromUrl(
                  location.search
                )
                setSearchParams({
                  ...queryParams,
                  groupTab: value.toString(),
                  page: '1'
                })
              }
            }}
            activeTab={activeTabIndex}
            className='override-tab-active-font'
          />
          <div className='absolute right-0 top-[-60px] flex flex-col gap-y-[28px] '>
            <div className='flex justify-end'>
              <Button
                id='refresh-btn'
                type='neutral'
                disabled={
                  loading ||
                  loadingTag ||
                  isloadingTag ||
                  store.uiStore.getTagLoader() ||
                  store.uiStore.getGlobalLoader()
                }
                startIcon={<Renew color={pallete.colors.textDark10} />}
                onClick={() => {
                  gaEventTracker(
                    gaPageConstants.REFRESH_EXCEPTIONS_SINGLE_GROUP
                  )
                  store.exceptionStore.setIsRefreshed(true)
                }}
              />
            </div>
            {preference && (
              <div className=' flex flex-row items-center gap-x-[8px]'>
                <DDSTypography.Title
                  type='h5'
                  variant='semiBold'
                  style={{ color: pallete.colors.textDark4 }}
                >
                  {isFullStackProject
                    ? 'Exception Group Severity Type :'
                    : 'Error Group Severity Type :'}
                </DDSTypography.Title>
                <div className='override-single-log-severity relative flex items-center'>
                  <SeverityDropDown
                    label={preference}
                    onSelect={(value: SeverityTypes) => {
                      if (value !== preference) {
                        setToPreference(value)
                        setModalOpen(true)
                      }
                    }}
                    insideTable={false}
                    disableNoSubscription={
                      store.userStore.getNoActiveSubscription() ||
                      loading ||
                      loadingTag ||
                      isloadingTag ||
                      store.uiStore.getTagLoader() ||
                      store.uiStore.getGlobalLoader()
                    }
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <ConfirmationPopup
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        title='Severity Type'
        primaryButtonClick={() =>
          updateExceptionGroupPreferrence(changeToPreference)
        }
        secondaryButtonClick={() => {
          setModalOpen(false)
        }}
        primaryButtonText='Yes'
        secondaryButtonText='No'
        loading={loading}
        primaryButtonDisabled={loading}
        secondaryButtonDisabled={loading}
        content={
          <div className='flex flex-col w-full gap-y-[24px] items-center justify-center '>
            <DDSTypography.Title
              variant='semiBold'
              type='h4'
              className='text-center'
            >
              {isFullStackProject
                ? 'Changing severity type: Upcoming exceptions will adopt the new severity, while existing ones remain unchanged.'
                : 'Changing severity type: Upcoming errors will adopt the new severity, while existing ones remain unchanged.'}
            </DDSTypography.Title>
            <div className='flex flex-row justify-center items-center gap-x-[8px] '>
              <div
                className='flex items-center justify-center h-[32px] px-[11px] rounded-md'
                style={{
                  color: 'red !important',
                  border: '1px solid #D9E0EA'
                }}
              >
                <DDSTypography.Title
                  variant='semiBold'
                  type='h4'
                  className='text-center'
                  color={severityColorMap[preference]}
                >
                  {preference}
                </DDSTypography.Title>
              </div>
              <div>
                <ChevronRight size={24} />
              </div>

              <div
                className={`flex items-center justify-center h-[32px] px-[11px] rounded-md`}
                style={{
                  color: 'red !important',
                  border: '1px solid #D9E0EA'
                }}
              >
                <DDSTypography.Title
                  variant='semiBold'
                  type='h4'
                  className='text-center'
                  color={severityColorMap[changeToPreference]}
                >
                  {changeToPreference as string}
                </DDSTypography.Title>
              </div>
            </div>
          </div>
        }
      />
    </div>
  )
}
export default observer(SingleExceptionView)
