import React, { useEffect, useState } from 'react'
import {
  getAllQueryParamsAsObjectFromUrl,
  replaceUrl
} from '../../../utils/UrlUtils'
import { isNull, isUndefined } from 'lodash'
import { useParams, useSearchParams } from 'react-router-dom'

import Breadcrumbs from '../../../components/common/DDS/Breadcrumbs/Breadcrumbs'
import { Button } from '../../../components/New/Button/Button'
import { DDSTypography } from 'den-design-system'
import { Renew } from '@carbon/icons-react'
import Spinner from '../../../components/common/Spinner/Spinner'
import ToastNotification from '../../../components/common/DDS/Toast/Toast'
import Tooltip from '../../../components/New/Tooltip/Tooltip'
import UCLogDetailsTable from '../../../components/UptimeChecksPage/UCLogDetailsTable'
import UptimeStringConstants from '../../../constants/UptimeStringConstants'
import { gaPageConstants } from '../../../constants/GAConstants'
import { getAhcLogsForEvent } from '../../../api/appHealthCheck/AHC.service'
import pallete from '../../../global/newPallete'
import routeConstants from '../../../constants/RouteConstants'
import { timeFormats } from '../../../enum/TIME'
import { timeZone } from '../../../utils/DateTimeUtils'
import { useAnalyticsEventTracker } from '../../../utils/GAUtils'
import { useStoreContext } from '../../../store/StoreContext'

const UCLogSpecificDetails = () => {
  const store = useStoreContext()
  const { id, eventId } = useParams()
  const [logsData, setLogsData] = useState<any>()
  const [loading, setLoading] = useState<any>(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const [totalCount, setTotalCount] = useState<number>(0)
  const gaEventTracker = useAnalyticsEventTracker(
    gaPageConstants.REFRESH_UPTIME_CHECK_LOG_SPECIFIC_VIEW
  )
  const fetchHourLogsForEvent = (page: number, filters?: any) => {
    setLoading(true)
    getAhcLogsForEvent({
      $ahcId: id,
      $eventId: eventId,
      page: page,
      size: 10,
      ...filters
    })
      .then((response: any) => {
        setLogsData(response.data)
        setTotalCount(response.totalCount)
      })
      .catch((err) => {
        ToastNotification({
          type: 'error',
          message: 'Failed to fetch logs for event'
        })
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    if (logsData) {
      store.breadcrumbStore.setMultipleBreadcrumbsOptions([
        {
          label: UptimeStringConstants.UPTIME_CHECKS,
          link: replaceUrl(
            routeConstants.AHC,
            store.scopeStore.getScope()['organization-id'] as string,
            store.scopeStore.getScope()['project-id'] as string,
            store.scopeStore.getScope()['environment-id'] as string
          )
        },
        {
          label: logsData.checkName,
          link:
            replaceUrl(
              routeConstants.AHC_DASHBOARD.replace(':id', id as string),
              store.scopeStore.getScope()['organization-id'] as string,
              store.scopeStore.getScope()['project-id'] as string,
              store.scopeStore.getScope()['environment-id'] as string
            ) + '?tab=1'
        }
      ])
    }
  }, [logsData])

  useEffect(() => {
    if (store.breadcrumbStore.getBreadcrumbsOptions().length > 2) {
      store.breadcrumbStore.sliceBreadcrumbOptions(0, 2)
    }
    if (!isUndefined(logsData)) {
      store.breadcrumbStore.appendBreadCrumbOptions({
        label: logsData?.eventEndTime
          ? timeZone(
              store.userStore.getUserPreference().timeZone,
              logsData?.eventStartTime ?? '',
              timeFormats.fullMonthDateYearTime
            ) +
            ' to ' +
            timeZone(
              store.userStore.getUserPreference().timeZone,
              logsData?.eventEndTime ?? new Date(),
              timeFormats.fullMonthDateYearTime
            )
          : timeZone(
              store.userStore.getUserPreference().timeZone,
              logsData?.eventStartTime ?? '',
              timeFormats.fullMonthDateYearTime
            ) + ' to Present',
        link:
          replaceUrl(
            routeConstants.AHC_HOUR_LOGS.replace(':id', id as string).replace(
              ':eventId',
              eventId as string
            ),
            store.scopeStore.getScope()['organization-id'] as string,
            store.scopeStore.getScope()['project-id'] as string,
            store.scopeStore.getScope()['environment-id'] as string
          ) + '?page=1'
      })
    }
  }, [logsData])

  const handleRefreshClick = () => {
    const filters: any = getAllQueryParamsAsObjectFromUrl(location.search)
    const page = parseInt(searchParams.get('page') ?? '1', 10)
    fetchHourLogsForEvent(page, filters)
  }
  return (
    <div className='flex flex-col w-full gap-[24px] py-[24px]'>
      <div className='flex justify-between'>
        <div className='w-[50%]'>
          {logsData && (
            <DDSTypography.Title type='h3' variant='semiBold'>
              {logsData?.eventEndTime
                ? timeZone(
                    store.userStore.getUserPreference().timeZone,
                    logsData?.eventStartTime ?? '',
                    timeFormats.fullMonthDateYearTime
                  ) +
                  ' to ' +
                  timeZone(
                    store.userStore.getUserPreference().timeZone,
                    logsData?.eventEndTime ?? new Date(),
                    timeFormats.fullMonthDateYearTime
                  )
                : timeZone(
                    store.userStore.getUserPreference().timeZone,
                    logsData?.eventStartTime ?? '',
                    timeFormats.fullMonthDateYearTime
                  ) + ' to Present'}
            </DDSTypography.Title>
          )}
        </div>

        <div>
          <Tooltip id='refresh-tooltip' label='Refresh' position='top'>
            <Button
              id='refresh'
              type='neutral'
              disabled={loading}
              startIcon={
                <Renew size={16} color={pallete.colors.neuButtonText} />
              }
              size='small'
              onClick={() => {
                gaEventTracker(
                  gaPageConstants.REFRESH_UPTIME_CHECK_LOG_SPECIFIC_VIEW
                )
                handleRefreshClick()
              }}
            />
          </Tooltip>
        </div>
      </div>

      <div>
        <UCLogDetailsTable
          logsData={logsData}
          loading={loading}
          totalCount={totalCount}
          fetchHoursLogsForEvent={fetchHourLogsForEvent}
        />
      </div>
    </div>
  )
}

export default UCLogSpecificDetails
