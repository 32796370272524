import { CaretDown, CaretUp, ChevronRight } from '@carbon/icons-react'
import { DDSChips, DDSTooltip, DDSTypography } from 'den-design-system'
import React, { useEffect, useRef, useState } from 'react'
import {
  createSearchParams,
  useNavigate,
  useSearchParams
} from 'react-router-dom'
import {
  gaEventConstants,
  gaPageConstants
} from '../../../../constants/GAConstants'
import {
  getAllQueryParamsAsObjectFromUrl,
  replaceUrl
} from '../../../../utils/UrlUtils'
import {
  getOrgTimeRange,
  msToTimeUnits,
  timeZone
} from '../../../../utils/DateTimeUtils'
import { isEqual, isNull } from 'lodash'

import ApiGroupingStringConstants from '../../../../constants/ApiGroupingStringConstants'
import { Link } from '@mui/material'
import NoFilterImage from '../../../../assets/images/folder-not-found 1.svg'
import NoRecord from '../../../common/NoContent/NoRecord'
import NoRecordImage from '../../../../assets/images/setting.svg'
import Spinner from '../../../common/Spinner/Spinner'
import StringConstants from '../../../../constants/StringConstants'
import Table from '../../../common/DDS/Table/Table'
import Tooltip from '../../../New/Tooltip/Tooltip'
import dayjs from 'dayjs'
import { mapStatusCodeToColor } from '../../../../utils/ApiStatusColorCode'
import { observer } from 'mobx-react-lite'
import pallete from '../../../../global/newPallete'
import routeConstants from '../../../../constants/RouteConstants'
import { timeFormats } from '../../../../enum/TIME'
import { useAnalyticsEventTracker } from '../../../../utils/GAUtils'
import { useStoreContext } from '../../../../store/StoreContext'

type SortOrder = 'ASC' | 'DESC' | 'CLEAR'
type SortState = null | SortOrder | 'CLEAR'
interface URLViewProps {
  loading: boolean
  setLoading: (loading: boolean) => void
  fetchAllRecords: (queryParams: any) => void
  data: any
  totalCount: any
  activeSortOrder: {
    field: string | null
    order: SortOrder | null
  }
  handleSortCycle: (sortField: string) => void
  setActiveSortOrder: (field: any) => void
}

const AllAPIViewURL: React.FC<URLViewProps> = ({
  loading,
  setLoading,
  fetchAllRecords,
  data,
  totalCount,
  activeSortOrder,
  handleSortCycle,
  setActiveSortOrder
}) => {
  const store = useStoreContext()

  const [searchParams, setSearchParams] = useSearchParams()

  const typographyRef = useRef<HTMLDivElement | null>(null)
  const [showTooltip, setShowTooltip] = useState(false)

  const [currentPage, setCurrentPage] = useState<number>(1)
  const gaEventTracker = useAnalyticsEventTracker(
    gaPageConstants.API_MONITORING_TABLE
  )

  const organizations = store.userStore.getOrganizations()
  const foundOrganization = organizations.find(
    (organization: any) =>
      organization.id === store.scopeStore.getOrganizationId()
  )

  const navigate = useNavigate()
  const allQueryParams = getAllQueryParamsAsObjectFromUrl(location.search)
  const orgTimeZone = store.userStore.getOrganizationPreference()?.timeZone

  const { startOfMonth, endOfCurrentDayTimestamp } =
    getOrgTimeRange(orgTimeZone)

  const paramsWithDateRange = {
    ...allQueryParams,
    'created-from': allQueryParams['created-from'] || String(startOfMonth),
    'created-to':
      allQueryParams['created-to'] || String(endOfCurrentDayTimestamp),
    'time-zone': orgTimeZone
  }

  useEffect(() => {
    if (!isNull(searchParams.get('response-status-code')))
      fetchAllRecords({
        ...paramsWithDateRange,
        size: StringConstants.DEFAULT_TABLE_SIZE
      })
    const checkWidth = () => {
      if (typographyRef.current) {
        const width = typographyRef.current.getBoundingClientRect().width
        setShowTooltip(width === 190)
      }
    }

    checkWidth()
    window.addEventListener('resize', checkWidth)

    return () => window.removeEventListener('resize', checkWidth)
  }, [])

  useEffect(() => {
    const pageFromQueryParams = searchParams.get('page')

    const queryParams = getAllQueryParamsAsObjectFromUrl(location.search)
    if (
      !isNull(pageFromQueryParams) &&
      isEqual(paramsWithDateRange, queryParams)
    ) {
      setCurrentPage(Number(pageFromQueryParams))
      fetchAllRecords({
        ...paramsWithDateRange,
        size: StringConstants.DEFAULT_TABLE_SIZE
      })
    } else {
      setSearchParams({ ...paramsWithDateRange, page: '1' })
      setCurrentPage(1)
    }
  }, [searchParams, store.scopeStore.getScope(), currentPage])

  useEffect(() => {
    if (store.apiDetailsStore.getRefresh() === true) {
      setLoading(true)
      store.uiStore.setGlobalLoader(true)

      fetchAllRecords({
        ...paramsWithDateRange,
        size: StringConstants.DEFAULT_TABLE_SIZE
      })
    }
  }, [store.apiDetailsStore.getRefresh()])

  const routeGroupedDetails = (id: number) => {
    const route = replaceUrl(
      routeConstants.VIEW_ALL_API,
      store.scopeStore.getScope()['organization-id'] as string,
      store.scopeStore.getScope()['project-id'] as string,
      store.scopeStore.getScope()['environment-id'] as string
    ).replace(':id', String(id))
    const queryParams = { tab: '2' }
    navigate({
      pathname: route,
      search: `?${createSearchParams(queryParams)}`
    })
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const sortField = searchParams.get('sortField')
    const sortOrder = searchParams.get('sortOrder') as SortOrder

    if (!sortField || !sortOrder) {
      setActiveSortOrder({ field: null, order: null })
    } else {
      setActiveSortOrder({ field: sortField, order: sortOrder })
    }
  }, [location.search])

  const allColumn = [
    {
      columnHeaderStyle: { width: '260px' },
      columnDataStyle: { width: '260px' },
      heading: (
        <div className='flex whitespace-nowrap justify-center space-x-[8px] items-center'>
          <div>
            <DDSTypography.Paragraph
              size='caption'
              variant='semiBold'
              color={pallete.colors.textDark6}
            >
              {ApiGroupingStringConstants.URL_TEMPLATE}
            </DDSTypography.Paragraph>
          </div>
          <div
            className='flex flex-col justify-center -space-y-[6px]'
            onClick={() => handleSortCycle('sort-url-template')}
          >
            <CaretUp
              size={12}
              style={{
                color:
                  activeSortOrder.field === 'sort-url-template' &&
                  activeSortOrder.order === 'ASC'
                    ? pallete.colors.FillButton
                    : activeSortOrder.field === 'sort-url-template' &&
                        activeSortOrder.order === 'DESC'
                      ? pallete.colors.outButtonDis
                      : pallete.colors.textDark6
              }}
              className='cursor-pointer'
            />
            <CaretDown
              size={12}
              style={{
                color:
                  activeSortOrder.field === 'sort-url-template' &&
                  activeSortOrder.order === 'ASC'
                    ? pallete.colors.outButtonDis
                    : activeSortOrder.field === 'sort-url-template' &&
                        activeSortOrder.order === 'DESC'
                      ? pallete.colors.FillButton
                      : pallete.colors.textDark6
              }}
              className='cursor-pointer'
            />
          </div>
        </div>
      ),
      dataLabel: 'urlTemplate',
      render: (text: any) => (
        <div className='flex justify-start text-start row items-start max-w-[260px]'>
          <div>
            <Tooltip
              id={`tooltip-name-${text}`}
              label={text}
              className={`override-tooltip-arrow ${
                typeof text === 'string' && text.length > 20 ? text : '!hidden'
              }`}
            >
              <div>
                <DDSTypography.Paragraph
                  size='para'
                  variant='medium'
                  color={pallete.colors.textDark4}
                  className='tooltip-container sm:block overflow-hidden truncate max-w-[260px]'
                >
                  {text || text === 0 ? text : '-'}
                </DDSTypography.Paragraph>
              </div>
            </Tooltip>
          </div>
        </div>
      )
    },
    {
      columnHeaderStyle: { width: '120px' },
      columnDataStyle: { width: '120px' },
      heading: (
        <div className='flex whitespace-nowrap justify-center space-x-[8px] items-center'>
          <div>
            <DDSTypography.Paragraph
              size='caption'
              variant='semiBold'
              color={pallete.colors.textDark6}
            >
              {ApiGroupingStringConstants.HTTP_METHOD}
            </DDSTypography.Paragraph>
          </div>
          <div
            className='flex flex-col justify-center -space-y-[6px]'
            onClick={() => handleSortCycle('sort-http-method')}
          >
            <CaretUp
              size={12}
              style={{
                color:
                  activeSortOrder.field === 'sort-http-method' &&
                  activeSortOrder.order === 'ASC'
                    ? pallete.colors.FillButton
                    : activeSortOrder.field === 'sort-http-method' &&
                        activeSortOrder.order === 'DESC'
                      ? pallete.colors.outButtonDis
                      : pallete.colors.textDark6
              }}
              className='cursor-pointer'
            />
            <CaretDown
              size={12}
              style={{
                color:
                  activeSortOrder.field === 'sort-http-method' &&
                  activeSortOrder.order === 'ASC'
                    ? pallete.colors.outButtonDis
                    : activeSortOrder.field === 'sort-http-method' &&
                        activeSortOrder.order === 'DESC'
                      ? pallete.colors.FillButton
                      : pallete.colors.textDark6
              }}
              className='cursor-pointer'
            />
          </div>
        </div>
      ),
      dataLabel: 'httpMethod',
      render: (text: any) => (
        <div className='flex justify-center max-w-[120px] text-center row items-center'>
          <DDSTypography.Paragraph
            size='para'
            variant='medium'
            color={pallete.colors.textDark4}
          >
            {text || text === 0 ? text : '-'}
          </DDSTypography.Paragraph>
        </div>
      )
    },

    {
      columnHeaderStyle: {},
      columnDataStyle: { width: '120px' },
      heading: (
        <div className='flex whitespace-nowrap justify-center space-x-[8px] items-center'>
          <div>
            <DDSTypography.Paragraph
              size='caption'
              variant='semiBold'
              color={pallete.colors.textDark6}
            >
              {ApiGroupingStringConstants.END_POINTS}
            </DDSTypography.Paragraph>
          </div>
          <div
            className='flex flex-col justify-center -space-y-[6px]'
            onClick={() => handleSortCycle('sort-end-point')}
          >
            <CaretUp
              size={12}
              style={{
                color:
                  activeSortOrder.field === 'sort-end-point' &&
                  activeSortOrder.order === 'ASC'
                    ? pallete.colors.FillButton
                    : activeSortOrder.field === 'sort-end-point' &&
                        activeSortOrder.order === 'DESC'
                      ? pallete.colors.outButtonDis
                      : pallete.colors.textDark6
              }}
              className='cursor-pointer'
            />
            <CaretDown
              size={12}
              style={{
                color:
                  activeSortOrder.field === 'sort-end-point' &&
                  activeSortOrder.order === 'ASC'
                    ? pallete.colors.outButtonDis
                    : activeSortOrder.field === 'sort-end-point' &&
                        activeSortOrder.order === 'DESC'
                      ? pallete.colors.FillButton
                      : pallete.colors.textDark6
              }}
              className='cursor-pointer'
            />
          </div>
        </div>
      ),
      dataLabel: 'url',
      render: (text: any) => (
        <DDSTooltip
          id={`${text}`}
          label={
            <DDSTypography.Paragraph
              size='para'
              variant='regular'
              color={pallete.colors.textDark4}
            >
              {text}
            </DDSTypography.Paragraph>
          }
          shape='roundedRectangle'
          position='top'
          className={`override-tooltip-arrow ${
            typeof text === 'string' && text.length > 20 ? text : '!hidden'
          }`}
          style={{ backgroundColor: pallete.colors.surface1, zIndex: 999999 }}
        >
          <div className='flex justify-center text-center row items-center w-[120px] override-tooltip-arrow'>
            <DDSTypography.Paragraph
              size='para'
              variant='medium'
              color={pallete.colors.textDark4}
              className='tooltip-container sm:block overflow-hidden truncate w-[120px]'
            >
              {text || text === 0 ? text : '-'}
            </DDSTypography.Paragraph>
          </div>
        </DDSTooltip>
      )
    },
    {
      columnHeaderStyle: { width: '100px' },
      columnDataStyle: { width: '100px' },
      heading: (
        <div className='flex whitespace-nowrap justify-center space-x-[8px] items-center'>
          <div>
            <DDSTypography.Paragraph
              size='caption'
              variant='semiBold'
              color={pallete.colors.textDark6}
            >
              {ApiGroupingStringConstants.START_TIME}
            </DDSTypography.Paragraph>
          </div>
          <div
            className='flex flex-col justify-center -space-y-[6px]'
            onClick={() => handleSortCycle('sort-start-time')}
          >
            <CaretUp
              size={12}
              style={{
                color:
                  activeSortOrder.field === 'sort-start-time' &&
                  activeSortOrder.order === 'ASC'
                    ? pallete.colors.FillButton
                    : activeSortOrder.field === 'sort-start-time' &&
                        activeSortOrder.order === 'DESC'
                      ? pallete.colors.outButtonDis
                      : pallete.colors.textDark6
              }}
              className='cursor-pointer'
            />
            <CaretDown
              size={12}
              style={{
                color:
                  activeSortOrder.field === 'sort-start-time' &&
                  activeSortOrder.order === 'ASC'
                    ? pallete.colors.outButtonDis
                    : activeSortOrder.field === 'sort-start-time' &&
                        activeSortOrder.order === 'DESC'
                      ? pallete.colors.FillButton
                      : pallete.colors.textDark6
              }}
              className='cursor-pointer'
            />
          </div>
        </div>
      ),
      dataLabel: 'startTime',
      render: (text: any) => (
        <DDSTooltip
          id={`${text}`}
          label={
            <DDSTypography.Paragraph
              size='para'
              variant='regular'
              color={pallete.colors.textDark4}
            >
              {timeZone(
                store.userStore.getOrganizationPreference().timeZone,
                text,
                timeFormats.monthDateYear
              )}
            </DDSTypography.Paragraph>
          }
          shape='roundedRectangle'
          position='top'
          className={`override-tooltip-arrow ${
            typeof text === 'string' && text.length > 20 ? text : '!hidden'
          }`}
          style={{ backgroundColor: pallete.colors.surface1, zIndex: 999999 }}
        >
          <div className='flex justify-center text-center row items-center max-w-[100px]  '>
            <DDSTypography.Paragraph
              size='para'
              variant='medium'
              color={pallete.colors.textDark4}
              className='tooltip-container sm:block overflow-hidden truncate max-w-[100px]'
            >
              {timeZone(
                store.userStore.getOrganizationPreference().timeZone,
                text,
                timeFormats.monthDateYear
              )}
            </DDSTypography.Paragraph>
          </div>
        </DDSTooltip>
      )
    },
    ...(foundOrganization?.subscription?.trackApiUsage === true
      ? [
          {
            columnHeaderStyle: {},
            columnDataStyle: { width: '120px' },
            heading: (
              <div className='flex whitespace-nowrap justify-center space-x-[8px] items-center'>
                <div>
                  <DDSTypography.Paragraph
                    size='caption'
                    variant='semiBold'
                    color={pallete.colors.textDark6}
                  >
                    {ApiGroupingStringConstants.USER}
                  </DDSTypography.Paragraph>
                </div>
                <div
                  className='flex flex-col justify-center -space-y-[6px]'
                  onClick={() => handleSortCycle('sort-url-user-name')}
                >
                  <CaretUp
                    size={12}
                    style={{
                      color:
                        activeSortOrder.field === 'sort-url-user-name' &&
                        activeSortOrder.order === 'ASC'
                          ? pallete.colors.FillButton
                          : activeSortOrder.field === 'sort-url-user-name' &&
                              activeSortOrder.order === 'DESC'
                            ? pallete.colors.outButtonDis
                            : pallete.colors.textDark6
                    }}
                    className='cursor-pointer'
                  />
                  <CaretDown
                    size={12}
                    style={{
                      color:
                        activeSortOrder.field === 'sort-url-user-name' &&
                        activeSortOrder.order === 'ASC'
                          ? pallete.colors.outButtonDis
                          : activeSortOrder.field === 'sort-url-user-name' &&
                              activeSortOrder.order === 'DESC'
                            ? pallete.colors.FillButton
                            : pallete.colors.textDark6
                    }}
                    className='cursor-pointer'
                  />
                </div>
              </div>
            ),
            dataLabel: 'apiUserName',
            render: (text: any) => (
              <div className='flex justify-center text-center row items-center max-w-[100px]'>
                <div>
                  <Tooltip
                    id={`tooltip-name-${text}`}
                    label={text}
                    className={`override-tooltip-arrow ${
                      typeof text === 'string' && text.length > 15
                        ? text
                        : '!hidden'
                    }`}
                  >
                    <div>
                      <DDSTypography.Paragraph
                        size='para'
                        variant='medium'
                        color={pallete.colors.textDark4}
                        className='tooltip-container sm:block overflow-hidden truncate max-w-[100px]'
                      >
                        {text || text === 0 ? text : '-'}
                      </DDSTypography.Paragraph>
                    </div>
                  </Tooltip>
                </div>
              </div>
            )
          }
        ]
      : []),
    {
      columnHeaderStyle: {},
      columnDataStyle: {},
      heading: (
        <div className='flex whitespace-nowrap justify-center space-x-[8px] items-center'>
          <div>
            <DDSTypography.Paragraph
              size='caption'
              variant='semiBold'
              color={pallete.colors.textDark6}
            >
              {ApiGroupingStringConstants.RESPONSE_STATUS}
            </DDSTypography.Paragraph>
          </div>
          <div
            className='flex flex-col justify-center -space-y-[6px]'
            onClick={() => handleSortCycle('sort-response-status')}
          >
            <CaretUp
              size={12}
              style={{
                color:
                  activeSortOrder.field === 'sort-response-status' &&
                  activeSortOrder.order === 'ASC'
                    ? pallete.colors.FillButton
                    : activeSortOrder.field === 'sort-response-status' &&
                        activeSortOrder.order === 'DESC'
                      ? pallete.colors.outButtonDis
                      : pallete.colors.textDark6
              }}
              className='cursor-pointer'
            />
            <CaretDown
              size={12}
              style={{
                color:
                  activeSortOrder.field === 'sort-response-status' &&
                  activeSortOrder.order === 'ASC'
                    ? pallete.colors.outButtonDis
                    : activeSortOrder.field === 'sort-response-status' &&
                        activeSortOrder.order === 'DESC'
                      ? pallete.colors.FillButton
                      : pallete.colors.textDark6
              }}
              className='cursor-pointer'
            />
          </div>
        </div>
      ),
      dataLabel: 'responseStatusCode',
      render: (text: any) => (
        <div className='flex row justify-center items-center text-center override-statusCode-center override-status-chip'>
          <DDSChips
            label={text}
            type='filled'
            shape='rounded'
            size='small'
            style={{
              backgroundColor: mapStatusCodeToColor(text),
              borderColor: 'transparent',
              height: '24px',
              width: '60px',
              justifyContent: 'center'
            }}
          />
        </div>
      )
    },
    {
      columnHeaderStyle: { width: '100px' },
      columnDataStyle: { width: '100px' },
      heading: (
        <div className='flex whitespace-nowrap justify-center space-x-[8px] items-center'>
          <div>
            <DDSTypography.Paragraph
              size='caption'
              variant='semiBold'
              color={pallete.colors.textDark6}
            >
              {ApiGroupingStringConstants.CLIENT_VERSION}
            </DDSTypography.Paragraph>
          </div>
          <div
            className='flex flex-col justify-center -space-y-[6px]'
            onClick={() => handleSortCycle('sort-client-version')}
          >
            <CaretUp
              size={12}
              style={{
                color:
                  activeSortOrder.field === 'sort-client-version' &&
                  activeSortOrder.order === 'ASC'
                    ? pallete.colors.FillButton
                    : activeSortOrder.field === 'sort-client-version' &&
                        activeSortOrder.order === 'DESC'
                      ? pallete.colors.outButtonDis
                      : pallete.colors.textDark6
              }}
              className='cursor-pointer'
            />
            <CaretDown
              size={12}
              style={{
                color:
                  activeSortOrder.field === 'sort-client-version' &&
                  activeSortOrder.order === 'ASC'
                    ? pallete.colors.outButtonDis
                    : activeSortOrder.field === 'sort-client-version' &&
                        activeSortOrder.order === 'DESC'
                      ? pallete.colors.FillButton
                      : pallete.colors.textDark6
              }}
              className='cursor-pointer'
            />
          </div>
        </div>
      ),
      dataLabel: 'clientVersion',
      render: (text: any) => (
        <div className='flex justify-center text-center row items-center max-w-[100px] '>
          <div>
            <Tooltip
              id={`tooltip-name-${text}`}
              label={text}
              className={`override-tooltip-arrow ${
                typeof text === 'string' && text.length > 15 ? text : '!hidden'
              }`}
            >
              <div>
                <DDSTypography.Paragraph
                  size='para'
                  variant='medium'
                  color={pallete.colors.textDark4}
                  className='tooltip-container sm:block overflow-hidden truncate max-w-[100px]'
                >
                  {text || text === 0 ? text : '-'}
                </DDSTypography.Paragraph>
              </div>
            </Tooltip>
          </div>
        </div>
      )
    },
    {
      columnHeaderStyle: {},
      columnDataStyle: {},
      heading: (
        <div className='flex whitespace-nowrap justify-center space-x-[8px] items-center'>
          <div>
            <DDSTypography.Paragraph
              size='caption'
              variant='semiBold'
              color={pallete.colors.textDark6}
            >
              {ApiGroupingStringConstants.RESPONSE_TIME}
            </DDSTypography.Paragraph>
          </div>
          <div
            className='flex flex-col justify-center -space-y-[6px]'
            onClick={() => handleSortCycle('sort-response-time')}
          >
            <CaretUp
              size={12}
              style={{
                color:
                  activeSortOrder.field === 'sort-response-time' &&
                  activeSortOrder.order === 'ASC'
                    ? pallete.colors.FillButton
                    : activeSortOrder.field === 'sort-response-time' &&
                        activeSortOrder.order === 'DESC'
                      ? pallete.colors.outButtonDis
                      : pallete.colors.textDark6
              }}
              className='cursor-pointer'
            />
            <CaretDown
              size={12}
              style={{
                color:
                  activeSortOrder.field === 'sort-all-exception-message' &&
                  activeSortOrder.order === 'ASC'
                    ? pallete.colors.outButtonDis
                    : activeSortOrder.field === 'sort-all-exception-message' &&
                        activeSortOrder.order === 'DESC'
                      ? pallete.colors.FillButton
                      : pallete.colors.textDark6
              }}
              className='cursor-pointer'
            />
          </div>
        </div>
      ),
      dataLabel: 'responseTimeInMilliseconds',
      render: (text: any) => (
        <div className='flex justify-center text-center row items-center'>
          <DDSTypography.Paragraph
            size='para'
            variant='medium'
            color={pallete.colors.textDark4}
          >
            {text ? msToTimeUnits(text) : '-'}
          </DDSTypography.Paragraph>
        </div>
      )
    },
    {
      columnHeaderStyle: {},
      columnDataStyle: {},
      heading: '',
      dataLabel: '',
      render: (text: any, fullItem: any) => (
        <div className='cursor-pointer'>
          <ChevronRight
            size={16}
            onClick={() => {
              gaEventTracker(gaEventConstants.VIEW_SINGLE_API_RECORD)
              routeGroupedDetails(fullItem.id)
            }}
            color={pallete.colors.FillButton}
          />
        </div>
      )
    }
  ]

  return (
    <>
      {loading || store.uiStore.getGlobalLoader() || !data ? (
        <Spinner />
      ) : (totalCount as number) > 0 ? (
        <>
          <div className='py-[24px]'>
            <Table
              tableStyle={{
                borderColor: pallete.colors.stroke2
              }}
              data={data}
              columns={allColumn}
              totalCount={totalCount as number}
              rowsPerPage={10}
              currentPage={currentPage}
              onPageChange={(page: number) => {
                const allQueryParams = getAllQueryParamsAsObjectFromUrl(
                  location.search
                )
                setSearchParams({
                  ...allQueryParams,
                  page: page.toString()
                })
                setCurrentPage(page)
              }}
              loading={loading}
            />
          </div>
        </>
      ) : (
        <>
          <NoRecord
            imageSrc={
              totalCount === 0 && store.filterStore.getFiltersApplied()
                ? NoFilterImage
                : NoRecordImage
            }
            style={{ height: '74vh' }}
            text={
              totalCount === 0 && store.filterStore.getFiltersApplied() ? (
                StringConstants.NO_RECORDS_FILTER
              ) : (
                <>
                  Configure API Monitoring.
                  <Link
                    href={StringConstants.FULLSTACK_URL}
                    target='_blank'
                    rel='noopener noreferrer'
                    style={{ marginLeft: '4px' }}
                  >
                    Learn More
                  </Link>
                </>
              )
            }
          />
        </>
      )}
    </>
  )
}

export default observer(AllAPIViewURL)
