export interface UpdateExceptionPayload {
  exceptionStatus?: ExceptionStatusTypes
  assignedTo?: string
  tags?: string[]
  severity?: SeverityTypes
  notifyFrequency?: NotificationFrequencyTypes
  isBookmarked?: boolean
  isPublic?: boolean
  $exceptionLogId?: string
  $exceptionGroupId?: string
  tagToRemove?: string[]
}

export interface UpdateEscalationSetPayload {
  severity?: SeverityTypes
  escalationSetName?: string
  isActive?: boolean
  $escalationSetId: string
}

export interface AddEscalationSetPayload {
  severity: SeverityTypes
  escalationSetName: string
  $escalationSetId?: string
}
export interface UpdateExceptionGroupPayload {
  preferredSeverity?: SeverityTypes
  ids: string[]
  tags?: string[]
  tagToRemove?: string
}

export interface UpdateExceptionGroupedPayload {
  preferredSeverity?: SeverityTypes
  ids?: string[]
  tags?: string[]
  tagToRemove?: string[]
  $exceptionGroupId?: string
}

export interface UpdateComment {
  $exceptionLogId: string
  $exceptionCommentId: string
  comment: string
}
export interface DeleteComment {
  $exceptionLogId: string
  $exceptionCommentId: string
}
export interface AddComment {
  $exceptionLogId: string
  comment: string
  threadId?: string
}

export enum ExceptionStatusTypes {
  UNRESOLVED = 'Unresolved',
  RESOLVED = 'Resolved',
  SNOOZED = 'Snoozed',
  IGNORED = 'Ignored'
}

export enum IncidentStatusTypes {
  UNRESOLVED = 'Unresolved',
  RESOLVED = 'Resolved',
  MONITORING = 'Monitoring',
  IDENTIFIED = 'Identified',
  IN_PROGRESS = 'In Progress'
}

export enum SeverityTypes {
  CRITICAL = 'Critical',
  MODERATE = 'Moderate',
  MAJOR = 'Major',
  MINOR = 'Minor'
}

export enum NotificationFrequencyTypes {
  EVERY_TIME = 'EVERY_TIME',
  EVERY_10_TIMES = 'EVERY_10_TIMES',
  EVERY_20_TIMES = 'EVERY_20_TIMES'
}
