import { Grid, Stack } from '@mui/material'

import React from 'react'
import { TypographyDDS } from '../common/Typography/TypographyDDS'
import UsageBar from './UsageBar'
import UsageCard from './common/UsageCard'
import { isNull } from 'lodash'

interface FileStorageCardProps {
  usage: number
  totalStorageInMB: number
  availableStorageInMB: number
  totalFiles?: number
}
const FileStorageCard: React.FC<FileStorageCardProps> = ({
  usage,
  totalStorageInMB,
  availableStorageInMB,
  totalFiles
}) => {
  const convertStorage = (size: number): string => {
    if (size >= 1024 * 1024) {
      return `${(size / (1024 * 1024)).toFixed(2)} TB`
    } else if (size >= 1024) {
      return `${(size / 1024).toFixed(2)} GB`
    } else {
      return `${size} MB`
    }
  }
  return (
    <UsageCard>
      <Grid container spacing={2} justifyContent='space-between'>
        <Grid item xs={2.4}>
          <Stack spacing={1}>
            <TypographyDDS.Title type='h5'>Storage Usage</TypographyDDS.Title>
            <TypographyDDS.Title type='h4' variant='bold' color='primary'>
              {isNaN(usage) || !isFinite(usage) ? '-' : usage + '%'}
            </TypographyDDS.Title>
            <UsageBar usage={usage} />
          </Stack>
        </Grid>
        <Grid item xs='auto'>
          <Stack>
            <TypographyDDS.Paragraph
              size='para'
              variant='semiBold'
              color='darkGrey'
            >
              Total Storage
            </TypographyDDS.Paragraph>
            <TypographyDDS.Title
              type='h4'
              variant='bold'
              style={{ whiteSpace: 'nowrap' }}
            >
              {isNull(totalStorageInMB)
                ? '-'
                : convertStorage(totalStorageInMB)}
            </TypographyDDS.Title>
          </Stack>
        </Grid>
        <Grid item xs='auto'>
          <Stack>
            <TypographyDDS.Paragraph
              size='para'
              variant='semiBold'
              color='darkGrey'
            >
              Available Storage
            </TypographyDDS.Paragraph>
            <TypographyDDS.Title
              type='h4'
              variant='bold'
              style={{ whiteSpace: 'nowrap' }}
            >
              {isNull(availableStorageInMB) || availableStorageInMB < 0
                ? '-'
                : convertStorage(availableStorageInMB)}
            </TypographyDDS.Title>
          </Stack>
        </Grid>
        <Grid item xs='auto'>
          <Stack>
            <TypographyDDS.Paragraph
              size='para'
              variant='semiBold'
              color='darkGrey'
            >
              Total Files
            </TypographyDDS.Paragraph>
            <TypographyDDS.Title
              type='h4'
              variant='bold'
              style={{ whiteSpace: 'nowrap' }}
            >
              {totalFiles}
            </TypographyDDS.Title>
          </Stack>
        </Grid>
      </Grid>
    </UsageCard>
  )
}

export default FileStorageCard
