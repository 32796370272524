import { makeAutoObservable, observable } from 'mobx'

import { RootStore } from './RootStore'
import StringConstants from '../constants/StringConstants'

interface SidebarItem {
  id?: number
  title?: string
  leftIcon?: any
  link?: string
  topIcon?: any
}

enum BannerDetailsSeverity {
  ERROR = 'error',
  INFO = 'info',
  WARNING = 'warning'
}

type BannerDetails = {
  title: string
  message: string
  severity?: BannerDetailsSeverity
}

export class UIStore {
  sidebarExpanded: boolean
  sidebarMenuExpanded: boolean
  globalLoader: boolean
  tagLoader: boolean
  projectEnvironmentLoader: boolean
  notificationSideNavbar: boolean
  axiosSource: any[]
  activeTab: string
  sidebarItem: SidebarItem
  bannerDetails: BannerDetails
  isAlertVisible: boolean

  constructor(private rootStore: RootStore) {
    this.sidebarExpanded = true
    this.sidebarMenuExpanded = false
    this.globalLoader = false
    this.tagLoader = false
    this.projectEnvironmentLoader = true
    this.activeTab = StringConstants.SIDE_NAV_BAR.FULL_STACK_PROJECTS.OVERVIEW
    this.notificationSideNavbar = false
    this.axiosSource = []
    this.sidebarItem = {}
    this.bannerDetails = {
      title: '',
      message: '',
      severity: undefined
    }
    this.isAlertVisible = true

    makeAutoObservable(this, {
      axiosSource: observable.struct
    })
  }

  setSidebarExpanded(expanded: boolean) {
    this.rootStore.uiStore.sidebarExpanded = expanded
  }

  getSidebarExpanded() {
    return this.rootStore.uiStore.sidebarExpanded
  }

  setBannerDetails(value: BannerDetails) {
    this.rootStore.uiStore.bannerDetails = value
  }

  getBannerDetails() {
    return this.rootStore.uiStore.bannerDetails
  }

  setIsAlertVisible(value: boolean) {
    this.rootStore.uiStore.isAlertVisible = value
  }

  getIsAlertVisible() {
    return this.rootStore.uiStore.isAlertVisible
  }

  setMenuExpanded(expanded: boolean) {
    this.rootStore.uiStore.sidebarMenuExpanded = expanded
  }

  getMenuExpanded() {
    return this.rootStore.uiStore.sidebarMenuExpanded
  }

  setSidebarItem(item: SidebarItem) {
    this.rootStore.uiStore.sidebarItem = item
  }

  getSidebarItem() {
    return this.rootStore.uiStore.sidebarItem
  }

  setActiveTab(activeTab: string) {
    this.rootStore.uiStore.activeTab = activeTab
  }
  getActiveTab() {
    return this.rootStore.uiStore.activeTab
  }

  setGlobalLoader(loading: boolean) {
    this.globalLoader = loading
  }

  getGlobalLoader() {
    return this.rootStore.uiStore.globalLoader
  }
  setTagLoader(loading: boolean) {
    this.tagLoader = loading
  }

  getTagLoader() {
    return this.rootStore.uiStore.tagLoader
  }

  setprojectEnvironmentLoader(loading: boolean) {
    this.projectEnvironmentLoader = loading
  }

  getprojectEnvironmentLoader() {
    return this.rootStore.uiStore.projectEnvironmentLoader
  }

  setNotificationSideNavbar(notificationSideNavbar: boolean) {
    this.notificationSideNavbar = notificationSideNavbar
  }

  getNotificationSideNavbar() {
    return this.rootStore.uiStore.notificationSideNavbar
  }

  getAxiosSource() {
    return this.rootStore.uiStore.axiosSource
  }

  setAxiosSource(axiosSource: any) {
    this.axiosSource.push(axiosSource)
  }

  reset() {
    this.sidebarExpanded = true
    this.globalLoader = false
    this.tagLoader = false
    this.notificationSideNavbar = false
    this.axiosSource = []
    this.sidebarItem = {}
    this.bannerDetails
    this.activeTab = StringConstants.SIDE_NAV_BAR.FULL_STACK_PROJECTS.OVERVIEW
  }
}
