import {
  gaEventConstants,
  gaPageConstants
} from '../../../../constants/GAConstants'

import { Button } from '../../../New/Button/Button'
import { ChevronRight } from '@carbon/icons-react'
import { DDSTypography } from 'den-design-system'
import Input from '../../../New/Input/Input'
import MiscHeader from '../../MiscellaneousScreen/Page/MiscHeader/MiscHeader'
import React from 'react'
import { ToastMessageConstants } from '../../../../constants/ToastMessageConstants'
import ToastNotification from '../../DDS/Toast/Toast'
import { Typography } from '../../DDS/Typography/Typography'
import pallete from '../../../../global/newPallete'
import { resendResetPassword } from '../../../../api/Auth.service'
import { useAnalyticsEventTracker } from '../../../../utils/GAUtils'
import { useStoreContext } from '../../../../store/StoreContext'
import vigilLogo from '../../../../assets/icons/Vigil Logo Greyed out.svg'

interface PasswordResetProps {
  email: string
  handleBack?: () => void
}

const CheckEmail: React.FC<PasswordResetProps> = ({ email, handleBack }) => {
  const [loading, setLoading] = React.useState(false)
  const store = useStoreContext()

  const handleOnClickResendLink = () => {
    setLoading(true)
    store.uiStore.setGlobalLoader(true)
    resendResetPassword(email)
      .then(() => {
        ToastNotification({
          type: 'success',
          message: ToastMessageConstants.AUTH.FORGOT_PASSWORD.SUCCESS
        })
      })
      .catch((err) => {
        ToastNotification({
          type: 'error',
          message: err
        })
      })
      .finally(() => {
        store.uiStore.setGlobalLoader(false)
        setLoading(false)
      })
  }

  const gaEventTracker = useAnalyticsEventTracker(
    gaPageConstants.PASSWORD_RESET
  )
  return (
    <div className='flex flex-col min-h-screen'>
      <MiscHeader />
      <div className='flex-grow flex flex-col items-center justify-center bg-white p-[32px]'>
        <div className='flex flex-col justify-center items-center gap-[24px] text-center'>
          <p
            style={{
              color: pallete.colors.textDark3,
              fontWeight: 700,
              fontSize: '32px',
              lineHeight: '40px'
            }}
          >
            Check your email
          </p>
          <DDSTypography.Title
            type='h3'
            variant='medium'
            color={pallete.colors.textDark4}
          >
            We’ve sent a password reset link to
          </DDSTypography.Title>
          <DDSTypography.Title
            type='h2'
            variant='bold'
            color={pallete.colors.textDark4}
          >
            {email}
            <span
              onClick={handleBack}
              className='underline cursor-pointer'
              style={{
                color: pallete.colors.textDark2,
                fontWeight: 500,
                fontSize: '20px',
                lineHeight: '26px',
                paddingLeft: '12px'
              }}
            >
              Update email
            </span>
          </DDSTypography.Title>
          <DDSTypography.Title
            type='h3'
            variant='medium'
            color={pallete.colors.textDark4}
          >
            Didn’t receive the link?
          </DDSTypography.Title>
          <Button
            label='Send Link'
            width='28vw'
            type='filled'
            size='small'
            onClick={() => {
              gaEventTracker(gaEventConstants.RESEND_LINK)
              handleOnClickResendLink()
            }}
            disabled={loading || store.uiStore.getGlobalLoader()}
            endIcon={<ChevronRight color={pallete.colors.white} />}
            id='home-screen-button'
          />
        </div>
      </div>
      <div
        style={{
          position: 'absolute',
          bottom: '32px',
          left: '50%',
          transform: 'translateX(-50%)'
        }}
      >
        <Typography.Paragraph
          size='caption'
          variant='medium'
          color='textDark10'
          style={{ display: 'flex', alignItems: 'center', gap: '4px' }}
        >
          © 2024
          <span>
            <img src={vigilLogo} alt='Vigil Icon' />
          </span>
        </Typography.Paragraph>
      </div>
    </div>
  )
}

export default CheckEmail
